<template>
  <div class="auth-row">
    <div class="auth-form">
      <div class="auth-card">
        <div class="language-main-block">
          <LocaleSwitcher />
          <!-- <div class="language" @click="() => this.onChangeLanguage('fr')" v-if="getLanguage == 'en'">
            <img src="@/assets/fonts/languages/english.png" width="20" /><span class="header5">English</span>
          </div>
          <div class="language" @click="() => this.onChangeLanguage('en')" v-if="getLanguage == 'fr'">
            <img src="@/assets/fonts/languages/france.png" width="20" /><span class="header5">French</span>
          </div> -->
          <!--  <WebIcon />
           <label class="header5">
              {{ this.$t("Generic.Common.select_language") }}
            </label> -->

          <!-- <div class="language-block" v-if="showLanguage">
            <label class="header5" @click="() => this.onChangeLanguage('en')"
              ><img src="@/assets/fonts/languages/english.png" width="20" /><span>English</span></label
            >
            <label class="header5" @click="() => this.onChangeLanguage('fr')"
              ><img src="@/assets/fonts/languages/france.png" width="20" /><span>French</span></label
            >
          </div> -->
        </div>
        <div class="auth">
          <AuthPageLogo />
          <!-- <InformationBanner /> -->
          <slot />
        </div>
      </div>
    </div>

    <div :style="{ backgroundColor: authImageBackgroundColor }" class="image">
      <img :src="imgSrc" alt="" />
    </div>
  </div>
</template>

<script>
import AuthPageLogo from "@/components/AuthPageLogo.vue";
import InformationBanner from "@/components/Banners/InformationBanner.vue";
import WebIcon from "vue-material-design-icons/Web.vue";
import { ChangeLanguage, getCurrentLanguage } from "@/helpers/utils/localizationUtils";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";

export default {
  name: "AuthLayout",
  components: {
    AuthPageLogo,
    InformationBanner,
    WebIcon,
    LocaleSwitcher,
  },
  data() {
    return {
      showLanguage: false,
    };
  },
  computed: {
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
    authImageBackgroundColor() {
      return this.$store.getters.configs.authImageBackgroundColor || this.primaryColor;
    },
    imgSrc() {
      const configs = this.$store.getters.configs;

      return `/img/illustrations/${configs.authImage || "login.svg"}`;
    },

    getLanguage() {
      return getCurrentLanguage();
    },
  },

  methods: {
    handleShowLanguage() {
      this.showLanguage = !this.showLanguage;
    },

    onChangeLanguage(locale) {
      if (locale) {
        ChangeLanguage(this, locale);
        this.showLanguage = false;
      }
    },
  },
};
</script>
<style>
@import "./authTemplate.css";
</style>
