<template>
  <v-card :loading="isLoading" flat>
    <v-card-title>
      <slot name="header" :match="match"></slot>
    </v-card-title>

    <v-card-text v-if="isLoading">
      <v-skeleton-loader type="article, image, article"></v-skeleton-loader>
    </v-card-text>
    <v-card-text v-else class="black--text">
      <!-- Job Title -->
      <router-link :to="`/match/${match.matchId}`" class="text-h5">{{ match.jobTitle }}</router-link>

      <!-- Company  -->
      <div class="text-overline">{{ match.companyName }}</div>

      <div class="d-flex" style="flex-wrap: wrap">
        <div class="mx-5 my-2">
          <div class="text--secondary">{{ $t("match.dateMatched") }}</div>
          <div class="text-body-1">{{ dateMatched }}</div>
        </div>
        <v-divider vertical />
        <div class="mx-5 my-2">
          <div class="text--secondary">{{ $t("match.company") }}</div>
          <div class="text-body-1">{{ match.companyName }}</div>
        </div>
        <v-divider vertical />

        <!-- Location -->
        <div class="mx-5 my-2">
          <div class="text--secondary">{{ $t("location") }}</div>
          <div class="text-body-1">{{ match.jobLocation }}</div>
        </div>
        <v-divider vertical />
        <div class="mx-5 my-2">
          <div class="text--secondary">{{ $t("match.jobPosting") }}</div>
          <a
            class="text-body-1"
            :href="match.jobUrl"
            @click="
              $amplitude.logEvent('CLICK_JOB_BOARD_URL', {
                matchId: match.matchId,
                from: 'single-match-view',
              })
            "
            target="_blank"
            rel="noreferrer"
            >{{ match.platform }}
            <v-icon small class="mb-2">open_in_new</v-icon>
          </a>
        </div>
        <v-divider vertical />
        <div class="mx-5 my-2">
          <div class="text--secondary">{{ $t("match.rate") }}</div>
          <v-icon @click="rateMatch(0)" class="ma-1">{{ ratedDown ? mdiThumbDown : mdiThumbDownOutline }}</v-icon>
          <v-icon @click="rateMatch(1)" class="ma-1">{{ ratedUp ? mdiThumbUp : mdiThumbUpOutline }} </v-icon>
        </div>
        <v-divider vertical />

        <div class="mx-5 my-2">
          <div class="text--secondary">{{ $t("match.set_in_interview") }}</div>
          <div class="interview_checkBox">
            <SwitchToggle :value="match.interview" @input="handleInterviewStatus"></SwitchToggle>
          </div>
        </div>

        <v-divider vertical v-if="hasJobPosterInfo && match.jobPosterName"></v-divider>
  
        <!-- Job Poster Name -->
        <div class="mx-5 my-2" v-if="hasJobPosterInfo && match.jobPosterName">
          <div class="text--secondary">{{ $t("results.jobPoster") }}</div>
          <div class="text-body-1">{{ match.jobPosterName }}</div>
        </div>

        <v-divider vertical v-if="hasJobPosterInfo && match.jobPosterTitle"></v-divider>

        <!-- Job Poster Title -->
        <div class="mx-5 my-2" v-if="hasJobPosterInfo && match.jobPosterTitle">
          <div class="text--secondary">{{ $t("match.jobPosterTitle") }}</div>
          <div class="text-body-1">{{ match.jobPosterTitle }}</div>
        </div>

        <v-divider vertical v-if="hasJobPosterInfo && match.jobPosterProfileUrl"></v-divider>

        <!-- Job Poster Profile URL -->
        <div class="mx-5 my-2" v-if="hasJobPosterInfo && match.jobPosterProfileUrl">
          <div class="text--secondary">{{ $t("match.jobPosterProfileUrl") }}</div>
          <a target="_blank" :href="`${match.jobPosterProfileUrl}`">{{ match.jobPosterProfileUrl }}</a>
        </div>
      </div>
      <v-banner outlined rounded class="my-5 pending-questions-banner" v-if="questions.length">
        <v-icon slot="icon" color="warning" size="36"> warning_amber </v-icon>
        <span v-html="$t('match.pendingQuestionsAlert')"></span>

        <template v-slot:actions>
          <div class="d-flex">
            <v-btn color="primary" text @click="goToQuestions">
              {{ $t("match.goToQuestions") }}
            </v-btn>
          </div>
        </template>
      </v-banner>
      <v-alert class="mx-5" dense text colored-border v-if="allowsOnDemand">
        <template v-slot:append>
          <v-btn text color="primary" @click="applyOnDemandDialog = true">{{ $t("match.apply") }}</v-btn>
        </template>
        <div>
          {{ $t("match.onDemandDescription") }}
        </div>
      </v-alert>
      <div class="text-h5 mb-2">{{ $t("match.timeline") }}</div>
      <v-divider />
      <v-container style="max-width: 600px">
        <v-timeline dense clipped>
          <div v-for="timelineItem in timelineInfo" :key="timelineItem.timestamp">
            <v-timeline-item class="mb-4" small v-if="timelineItem.type === 'application'">
              <v-row justify="space-between">
                <v-col cols="7">
                  <v-chip class="white--text ml-0" color="purple" label small>
                    {{ pascalCase(timelineItem.status) }}
                  </v-chip>
                  {{ timelineItem.status === "FAILED" ? $t("match.failedApplications") : $t("match.successAppication") }}
                </v-col>
                <v-col class="text-right" cols="5">
                  {{ timelineItem.formattedTimestamp }}
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item class="mb-4" small v-if="timelineItem.type === 'fail'">
              <v-row justify="space-between">
                <v-col cols="7">
                  {{ $t("match.unable") }}
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item class="mb-1 pb-3" small v-if="timelineItem.type === 'email'">
              <v-row justify="space-between">
                <v-col cols="7" v-if="timelineItem.emailStatus === 'Locked'">
                  <v-chip class="white--text ml-0" color="purple" label small>
                    {{ timelineItem.emailStatus }}
                  </v-chip>
                  An email was ready to be sent to
                  <base-mailto :email="timelineItem.companyEmail" /> but your limit was exceeded.
                  <span v-if="$store.getters.configs.showPricingPlans && currentUser && currentUser.pricingPlan === 1">
                    <router-link to="/profile/pricing">Upgrade</router-link>
                    to increase your limit.
                  </span>
                  <!-- <a :href="timelineItem.mailtoLink">Send manually.</a> -->
                </v-col>
                <v-col cols="7" v-else-if="timelineItem.emailStatus === 'Sent'">
                  <v-chip class="white--text ml-0" color="purple" label small>
                    {{ timelineItem.emailStatus }}
                  </v-chip>
                  {{ $t("match.emailedTo") }}
                  <base-mailto :email="timelineItem.companyEmail" />.
                  <a href="" @click.stop.prevent="openEmailDialog(timelineItem)">{{ $t("match.showMessage") }}</a>
                  <!-- <a :href="timelineItem.mailtoLink">Send again manually.</a> -->
                </v-col>
                <v-col cols="7" v-else-if="timelineItem.emailStatus === 'Opened'">
                  <v-chip class="white--text ml-0" color="purple" label small>
                    {{ timelineItem.emailStatus }}
                  </v-chip>
                  {{ $t("match.emailedTo") }}
                  <base-mailto :email="timelineItem.companyEmail" />.
                  <a href="" @click.stop.prevent="openEmailDialog(timelineItem)">{{ $t("match.showMessage") }}</a>
                  {{ $t("match.emailRead") }}
                  <!-- <a :href="timelineItem.mailtoLink">Send again manually.</a> -->
                </v-col>
                <v-col cols="7" v-else-if="timelineItem.emailStatus === 'Clicked'">
                  <v-chip class="white--text ml-0" color="purple" label small>
                    {{ timelineItem.emailStatus }}
                  </v-chip>
                  {{ $t("match.emailedTo") }}
                  <base-mailto :email="timelineItem.companyEmail" />.
                  <a href="" @click.stop.prevent="openEmailDialog(timelineItem)">{{ $t("match.showMessage") }}</a>
                  {{ $t("match.emailClicked") }}
                  <!-- <a :href="timelineItem.mailtoLink">Send again manually.</a> -->
                </v-col>
                <v-col cols="7" v-else-if="timelineItem.emailStatus === 'NotSent'">
                  <v-chip class="white--text ml-0" color="purple" label small>
                    {{ timelineItem.emailStatus }}
                  </v-chip>
                  We did not send an email to
                  <base-mailto :email="timelineItem.companyEmail" /> because you had disabled automated emails.
                  <!-- <a :href="timelineItem.mailtoLink">Send manually.</a> -->
                </v-col>
                <v-col cols="7" v-else-if="timelineItem.emailStatus === 'Dropped' || timelineItem.emailStatus === 'Bounced'">
                  <v-chip class="white--text ml-0" color="purple" label small>
                    {{ timelineItem.emailStatus }}
                  </v-chip>
                  We did not send an email to
                  <base-mailto :email="timelineItem.companyEmail" /> because the company's email address could not recieve
                  messages.
                  <!-- <a :href="timelineItem.mailtoLink">Send manually.</a> -->
                </v-col>
                <v-col cols="7" v-else>
                  <v-chip class="white--text ml-0" color="purple" label small>
                    {{ timelineItem.emailStatus }}
                  </v-chip>
                  An email could not be sent to
                  <base-mailto :email="timelineItem.companyEmail" /> because we ran into a problem with the company's email
                  address.
                  <!-- <a :href="timelineItem.mailtoLink">Send manually.</a> -->
                </v-col>
                <v-col class="text-right" cols="5">
                  {{ timelineItem.formattedTimestamp }}
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item class="mb-4" small v-if="timelineItem.type === 'match'">
              <v-row justify="space-between">
                <v-col cols="7">
                  {{ $t("match.matchedWithScore") }}
                </v-col>
                <v-col class="text-right" cols="5">
                  {{ timelineItem.formattedTimestamp }}
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item class="mb-4" hide-dot v-if="timelineItem.type === 'email'">
              <v-btn class="mx-0" :href="timelineItem.mailtoLink" target="_blank">
                {{ timelineItem.emailStatus === "Sent" ? "Send Email Again" : "Send Email Manually" }}
                <v-icon class="pl-2" small color="grey">open_in_new</v-icon>
              </v-btn>
            </v-timeline-item>
          </div>
        </v-timeline>
      </v-container>
      <div class="text-h5 mb-2">{{ $t("match.jobDescription") }}</div>
      <v-divider />
      <v-card class="pa-5" flat>
        <div v-if="match.jobSummaryHtml" class="text-body-1" v-html="match.jobSummaryHtml"></div>
        <i v-else>This match has no job description available</i>
      </v-card>
    </v-card-text>

    <v-dialog v-model="emailDialog" max-width="500">
      <v-card>
        <EmailMessageView :message="emailDialogData" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="applyOnDemandDialog" width="400">
      <v-card>
        <v-card-title class="headline">
          {{ $t("match.apply-on-demand") }}
        </v-card-title>
        <v-card-text>{{ $t("match.onDemandConfirmation") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" @click="applyOnDemandDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn class="ma-2" color="primary" @click="applyOnDemand">
            {{ $t("match.onDemandSubmit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import EmailMessageView from "@/components/EmailMessageView.vue";
import appSDK from "@/client/AppSDK";
import { mapGetters } from "vuex";
import { statusData } from "@/data/statuses.js";
import { mdiThumbUp, mdiThumbUpOutline, mdiThumbDown, mdiThumbDownOutline } from "@mdi/js";
import { highlightKeywords, pascalCase } from "@/assets/js/utils";
import { constant } from "@/store/constant";
import { Role } from "@/helpers/enums/eRole";
import { CheckBox, SwitchToggle } from "@/framework/index";

const user = constant.user;

export default {
  name: "LoopMatch",
  components: { EmailMessageView, SwitchToggle, CheckBox },

  props: {
    matchId: {
      required: true,
      type: String,
    },
    scrollTo: {
      required: false,
      type: String,
    },
  },

  data: () => ({
    match: {},
    ratedUp: false,
    ratedDown: false,
    mdiThumbUp,
    mdiThumbUpOutline,
    mdiThumbDown,
    mdiThumbDownOutline,
    isLoading: true,
    questions: [],
    emailDialog: false,
    emailDialogData: null,
    applyOnDemandDialog: false,
  }),

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    currentUser() {
      return this.getUser;
    },

    hasJobPosterInfo() {
      return this.$store.getters.configs?.showJobPosterInfo;
    },

    clientId() {
      // using match.userId because we could be just in the /match route
      return this.match.userId || this.$route.params.clientId;
    },
    allowsOnDemand() {
      // we allow it for admins and normal users
      return statusData(this.match.status).allowsOnDemand;
    },
    dateMatched() {
      const date = new Date(this.match.timestamp);
      const n = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      return n.toDateString();
    },
    timelineInfo() {
      if (this.match.outgoingEmails === undefined) {
        return [];
      }
      const decreasingTimestamp = (a, b) => b.dateTimestamp - a.dateTimestamp;

      const emails = this.match.outgoingEmails.map(outgoingEmail => ({
        ...outgoingEmail,
        mailtoLink: this.getMailtoLink(outgoingEmail),
        type: "email",
      }));
      const applications = this.match.jobApplications.map(jobApplication => ({
        ...jobApplication,
        type: "application",
      }));
      const matchedEvent = {
        type: "match",
        timestamp: this.match.timestamp,
      };
      let finalArray = emails.concat(applications).concat([matchedEvent]);
      finalArray.forEach(timelineItem => {
        timelineItem.dateTimestamp = new Date(timelineItem.timestamp);
      });
      finalArray.sort(decreasingTimestamp);
      finalArray.forEach(timelineItem => {
        timelineItem.formattedTimestamp = timelineItem.dateTimestamp.toDateString();
      });

      if (finalArray.every(timelineItem => timelineItem.type === "match") && this.match.status !== "FORM_HAS_QUESTIONS") {
        const failedToFind = {
          type: "fail",
        };
        finalArray.unshift(failedToFind);
      }

      return finalArray;
    },
  },
  async mounted() {
    this.fetchMatch();
  },

  watch: {
    isLoading(val) {
      if (val) return;
      if (this.scrollTo === "questions") {
        setTimeout(() => {
          this.scrollToQuestions();
        }, 1000);
      }
    },
  },

  methods: {
    pascalCase,

    async fetchMatch() {
      try {
        const response = await appSDK["match.show"](this.isUserPartner ? this.clientId : null, this.matchId);
        // should have userId, searchId for the header
        this.questions = response.questions || [];
        this.match = {
          ...response,
          jobSummaryHtml: highlightKeywords(response.jobSummaryHtml, response.keywords),
          outgoingEmails: response.outgoingEmails || [],
          jobApplications: response.jobApplications || [],
          interview: response.scheduleInterview || false,
        };
      } catch (e) {
        console.error(e);
        const response = await appSDK["match.show"](null, this.matchId);
        this.questions = response.questions || [];
        this.match = response;
      } finally {
        this.isLoading = false;
      }
    },

    openEmailDialog(timelineItem) {
      this.emailDialog = true;
      this.emailDialogData = {
        subject: timelineItem.subject,
        body: timelineItem.body,
        companyEmail: timelineItem.companyEmail,
      };
    },

    goToQuestions() {
      if (this.currentUser?.role == Role.USER || this.currentUser?.role == Role.MANAGED_USER) {
        this.$router.push({ path: "/questions", query: { matchId: this.matchId } });
      } else {
        this.$router.push({ path: `/questions/${this.clientId}`, query: { matchId: this.matchId } });
      }
    },

    handleSubmitMatchQuestions(payload) {
      this.isLoading = true;
      appSDK["submit.match.questions"](this.isUserPartner ? this.clientId : null, this.matchId, payload)
        .then(() => {
          this.$notify({
            message: this.$t("match.successQuestions"),
          });
          this.$amplitude.logEvent("SUBMIT_QUESTIONS", {
            from: "single-match-view",
            matchId: this.matchId,
          });
          this.$emit("onSubmitQuestions");
        })
        .catch(() =>
          this.$notify({
            message: this.$t("match.failQuestions"),
            type: "danger",
          }),
        )
        .finally(async () => {
          await this.fetchMatch();
          this.isLoading = false;
        });
    },

    getMailtoLink(outgoingEmail) {
      const encodeEmailBody = body =>
        encodeURIComponent(
          body
            .replace(/<p>/g, "")
            .replace(/<br>/g, "\n")
            .replace(/<\/p>/g, "\n")
            .replace(/\n\n\n/g, "\n\n"),
        ); // TODO encode subject
      return `mailto:${outgoingEmail.companyEmail}?subject=${outgoingEmail.subject}&body=${encodeEmailBody(
        outgoingEmail.body,
      )}`;
    },

    async applyOnDemand() {
      try {
        if (!this.allowsOnDemand) {
          return;
        }

        await appSDK["partner.applyOnDemand"](this.isUserPartner ? this.clientId : null, {
          matchIds: [this.matchId],
        });
        this.$notify({
          message: this.$t("match.submittingOnDemand"),
          type: "success",
        });
        this.$emit("onSubmitOnDemandRequest");
        this.$amplitude.logEvent("APPLY_ON_DEMAND", {
          how: "single-from-match",
          count: 1,
          matchIds: [this.matchId],
        });
      } catch (e) {
        console.error(e);
        this.$notify({
          message: this.$t("match.errorOnDemand"),
          type: "danger",
        });
      } finally {
        this.applyOnDemandDialog = false;
      }
    },

    // rating should be 0 or 1
    rateMatch(rating) {
      this.ratedUp = false;
      this.ratedDown = false;
      if (rating === 1) {
        this.ratedUp = true;
      }
      if (rating === 0) {
        this.ratedDown = true;
      }
      appSDK["match.rate"](this.matchId, { rating })
        .then(response => {
          this.$notify({
            message: this.$t("match.successRating"),
            type: "success",
          });
          this.$amplitude.logEvent("RATE_MATCH", {
            from: "single-match-view",
            rating,
          });
        })
        .catch(error => {
          this.$notify({
            message: this.$t("match.failRating"),
            type: "danger",
          });
        });
    },

    async handleInterviewStatus() {
      try {
        const result = await this.$store.dispatch(user.actions.UpdateMatchesInterviewStatus, {
          matchId: this.match.matchId,
          interviewStatus: !this.match.interview,
          user: this.clientId,
          isManagedUser: this.getUser.role == "managed-user",
        });
        await this.fetchMatch();
        this.$emit("OnInterviewChange");
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
        });
      }
    },
  },
};
</script>
<style>
.highlight {
  background-color: yellow;
}
.pending-questions-banner .v-banner__actions {
  margin-top: auto;
  margin-bottom: auto;
}

.interview_checkBox {
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
}
</style>
