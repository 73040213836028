import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user/index";
import loop from "./modules/loop";
import plans from "./modules/prices/index";
import configs from "./modules/configs";
import info from "./modules/info";
import client from "./modules/client";
import prices from "./modules/prices";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { user, loop, plans, configs, info, client: client, prices },
});

export default store;
