<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> Export Jobs </v-card-title>
      <v-card-text>
        <div class="ma-2 mt-4" v-if="loopId">
          Export jobs from your loop <b>{{ loopDescription }}</b>
        </div>
        <v-form v-model="isValid">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="fromMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="from"
                      clearable
                      readonly
                      label="From Date"
                      :rules="rules"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="from" @input="fromMenu = false"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  v-model="toMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="to"
                      clearable
                      readonly
                      label="To Date"
                      :rules="rules"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="to" @input="toMenu = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-checkbox
                label="Export matches only"
                v-model="onlyMatches"
                :messages="onlyMatches ? 'Only jobs that were matched will be exported' : 'All jobs found will be exported'"
              ></v-checkbox>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="danger" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="primary" text @click="exportJobs"> Export </v-btn>
      </v-card-actions>
    </v-card>
    <LoadingDialog :visible="isLoading" />
  </v-dialog>
</template>

<script>
import moment from "moment";
import appSDK from "@/client/AppSDK";
import LoadingDialog from "@/components/LoadingDialog.vue";

export default {
  components: { LoadingDialog },
  data() {
    return {
      dialog: false,
      isLoading: false,
      fromMenu: false,
      toMenu: false,
      isValid: false,
      onlyMatches: false,
      from: moment().subtract(1, "months").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      rules: [
        v => !!v || "From date is required",
        v => {
          const start = moment(this.from);
          const end = moment(this.to);
          const diff = Math.abs(moment.duration(start.diff(end)).asDays());
          return diff <= 31 || "Date range can not be more than 30 days";
        },
      ],
      loopId: null,
      loop: null,
    };
  },
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    loopDescription() {
      console.log(this.loop);
      if (!this.loop) return null;
      return this.loop.jobTitle + " - " + (this.loop.onlyRemoteJobs ? `Remote` : this.loop.jobLocation);
    },
    fileName() {
      if (this.loop) {
        return `${this.loopDescription} - jobs.csv`;
      }
      // TODO add the full name to the file name
      return `${this.clientId} - jobs.csv`;
    },
  },
  methods: {
    open(loop) {
      this.dialog = true;
      if (loop) {
        this.loopId = loop.searchId;
        this.loop = loop;
      }
    },
    async exportJobs() {
      if (!this.isValid) {
        return;
      }

      this.dialog = false;
      this.isLoading = true;
      try {
        let queryParams = {
          fromDate: moment(this.from).format("YYYY-MM-DD"),
          toDate: moment(this.to).format("YYYY-MM-DD"),
        };
        if (this.onlyMatches) {
          queryParams.onlyMatches = this.onlyMatches;
        }

        if (this.loopId) {
          const res = await appSDK["loop.jobs.export"](this.loopId, queryParams);
          this.saveFile(res);
        } else {
          const res = await appSDK["partner.jobs.export"](this.clientId, queryParams);

          this.saveFile(res);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    saveFile(blob) {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = this.fileName;
      a.click();
    },
  },
};
</script>

<style></style>
