<template>
  <v-row>
    <app-header />
    <div class="col-12 horizontal-scroll">
      <div class="d-flex flex-direction-row">
        <div>
          <h2 class="text-h1 mt-2 mx-3">
            {{ $t("board.title") }}
            <span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="black" v-bind="attrs" v-on="on">help_outline</v-icon>
                </template>
                {{ $t('kanban.titleTooltip', { brand: brand }) }}
              </v-tooltip>
            </span>

            <h5 class="text-subtitle-2" v-if="!onlyEmail">{{ $t("kanban.subtitle") }}</h5>
          </h2>
        </div>

        <div>
          <div class="d-flex justify-center pa-2 mx-3">
            <v-btn
              outlined
              @click="
                createJobDialog = true;
                selectedJob = {};
              "
            >
              {{ $t("board.addJob") }}
            </v-btn>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <div class="d-flex py-1 px-3">
          <div v-for="column in listsOfJobs" :key="column.columnId" class="px-0 py-3 column-width mr-4 d-flex flex-column">
            <h2>{{ $t(column.columnName) }}</h2>

            <draggable
              :list="column.jobs"
              :animation="200"
              @change="onChanged(column, $event)"
              ghost-class="ghost-card"
              group="jobs"
              style="flex: auto; min-height: 600px"
            >
              <!-- :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->

              <KanbanJobCard
                v-for="job in column.jobs"
                :key="job._id"
                :job="job"
                @click.native="openEditJobDialog(job)"
                class="mt-3 cursor-move"
              ></KanbanJobCard>
            </draggable>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="editJobDialog" :max-width="800">
      <KanbanJobDetails
        :key="editJobDialog"
        :initialJob="selectedJob"
        mode="edit"
        @submit="editJob"
        @delete="deleteJob(selectedJob._id)"
      />
    </v-dialog>

    <v-dialog v-model="createJobDialog" :max-width="800">
      <KanbanJobDetails :key="createJobDialog" mode="create" @submit="createJob" />
    </v-dialog>
  </v-row>
</template>

<script>
import appSDK from "@/client/AppSDK";
import KanbanJobCard from "@/components/dashboard/kanban/KanbanJobCard.vue";
import draggable from "vuedraggable";
import KanbanJobDetails from "@/components/dashboard/kanban/KanbanJobDetails.vue";
import { debounce } from "@/assets/js/utils";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: {
    draggable,
    KanbanJobCard,
    KanbanJobDetails,
  },

  data() {
    return {
      editJobDialog: false,
      selectedJob: {},
      createJobDialog: false,
      listsOfJobs: [],
      requestsQueue: [],
    };
  },

  async mounted() {
    this.listsOfJobs = await appSDK["kanban.get"]();
  },

  computed: {
    brand() {
      return this.$store.getters.configs.brand || "LoopCV";
    },
    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
  },

  methods: {
    refreshBoard: debounce(async function () {
      this.listsOfJobs = await appSDK["kanban.get"]();
    }, 2000),
    async moveRequest(columnId, event) {
      if (event.removed) {
      } else if (event.added) {
        await appSDK["kanban.move"](event.added.element._id, {
          action: "moveToAnotherColumn",
          newColumnId: columnId,
          newIndex: event.added.newIndex,
        });
      } else if (event.moved) {
        await appSDK["kanban.move"](event.moved.element._id, {
          action: "moveInSameColumn",
          newIndex: event.moved.newIndex,
        });
      }
    },
    async onChanged(column, event) {
      const req = this.moveRequest(column.columnId, cloneDeep(event));
      this.requestsQueue.push(req);
      if (this.requestsQueue.length === 1) {
        while (this.requestsQueue.length) {
          await this.requestsQueue[0];
          this.requestsQueue.shift();
        }
        this.refreshBoard();
      }
    },
    openEditJobDialog(job) {
      this.editJobDialog = true;
      this.selectedJob = job;
    },
    async createJob(job) {
      try {
        await appSDK["kanban.create"]({
          type: "custom",
          jobTitle: job.jobTitle,
          company: job.company,
          location: job.location,
          jobDescription: job.jobDescription,
          url: job.url,
        });
        this.listsOfJobs = await appSDK["kanban.get"]();
        this.createJobDialog = false;
        this.$notify({
          message: this.$t("board.jobSubmitted"),
          type: "success",
        });
        this.$amplitude.logEvent("KANBAN_ADD_CUSTOM_JOB");
      } catch (e) {
        console.error(e);
      }
    },
    async editJob(job) {
      try {
        await appSDK["kanban.edit"](job._id, {
          jobTitle: job.jobTitle,
          company: job.company,
          location: job.location,
          jobDescription: job.jobDescription,
          url: job.url,
        });
        this.listsOfJobs = await appSDK["kanban.get"]();
        this.editJobDialog = false;
        this.$notify({
          message: this.$t("board.jobSaved"),
          type: "success",
        });
        this.$amplitude.logEvent("KANBAN_EDIT_JOB");
      } catch (e) {
        console.error(e);
      }
    },
    async deleteJob(kanbanJobId) {
      try {
        await appSDK["kanban.delete"](kanbanJobId);
        this.listsOfJobs = await appSDK["kanban.get"]();
        this.editJobDialog = false;
        this.$notify({
          message: this.$t("board.jobDeleted"),
          type: "success",
        });
        this.$amplitude.logEvent("KANBAN_DELETE_JOB");
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
.cursor-move {
  cursor: move;
}
.horizontal-scroll {
  overflow-x: auto;
}
</style>
