const state = {
  packageVersion: process.env.APP_VERSION || 0,
  env: process.env.NODE_ENV
}
const getters = {
  appVersion: (state) => {
    return state.packageVersion
  },
  env: (state) => {
    return state.env
  },
}

export default { state, getters };
