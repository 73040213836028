<template>
  <v-alert dense text type="warning" outlined class="w-100 mx-1">
    <template #append>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :to="`${loopUrl}/edit`" icon x-small color="gray" v-bind="attrs" v-on="on">
            <app-icon name="pencil" />
          </v-btn>
        </template>
        <span>Edit this loop</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :to="`${loopUrl}/duplicate`" icon x-small color="gray" v-bind="attrs" v-on="on">
            <app-icon name="contentCopy" />
          </v-btn>
        </template>
        <span>Duplicate this loop</span>
      </v-tooltip>
    </template>
    <span
      >This loop is likely to have potential for improvement.
      <router-link :to="`${loopUrl}/stats`">Job boards (LinkedIn, Indeed, etc.) returned too few results.</router-link>
      Add more popular versions of the job title to get more matches.
      <router-link :to="`${loopUrl}/edit`">Edit this loop</router-link> or
      <router-link :to="`${loopUrl}/duplicate`">create a copy</router-link></span
    >
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  name: "FewResultsLoopAlert",
  data() {
    return {};
  },
  props: {
    clientId: {
      type: String,
      required: true,
    },
    loopId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    loopUrl() {
      return this.isUserPartner ? `/clients/${this.clientId}/loops/${this.loopId}` : `/loops/${this.loopId}`;
    },
  },
};
</script>
