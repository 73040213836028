<template>
  <v-sparkline
    :value="value"
    :gradient="gradient"
    :smooth="radius || false"
    :padding="padding"
    :line-width="width"
    :stroke-linecap="lineCap"
    :gradient-direction="gradientDirection"
    :fill="fill"
    :type="type"
    :auto-line-width="autoLineWidth"
    auto-draw
  ></v-sparkline>
  <!-- <div class="col-md-7">
          <slot name="header">
            <h5 class="text-subtitle-2">Emails per day</h5>
          </slot>
          <div class="chart-area">
            <line-chart
              style="height: 100%"
              chart-id="purple-line-chart"
              :chart-data="emailPerDay.chartData"
              :gradient-colors="emailPerDay.gradientColors"
              :gradient-stops="emailPerDay.gradientStops"
              :extra-options="emailPerDay.extraOptions"
            >
            </line-chart>
          </div>
        </div> -->
</template>

<script>
import moment from "moment";
import LineChart, { chartConfigs } from "@/components/LineChart.js";

const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];
export default {
  components: {
    LineChart,
  },
  data: () => ({
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: "round",
    gradient: gradients[5],
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    gradientDirection: "top",
    gradients,
    fill: false,
    type: "trend",
    autoLineWidth: false,

    emailPerDay: {
      extraOptions: chartConfigs,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Emails",
            fill: false,
            borderColor: "#344675",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#344675",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#344675",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [],
          },
        ],
      },
      gradientColors: [
        "rgba(255,255,255,0)",
        "rgba(255,255,255,0)",
        "rgba(255,255,255,0)",
      ],
      gradientStops: [1, 0.2, 0],
    },
  }),

  mounted() {
    this.getEmailPerData(this.stats.matchesWithEmailsPerDate);
  },

  methods: {
    getEmailPerData(emailSent) {
      if (!emailSent) return;

      emailSent = emailSent.filter((item) => item._id != null);

      if (emailSent?.length == 0) {
        this.emailPerDay.chartData.datasets[0].data = [0, 0];
        this.emailPerDay.chartData.labels = [
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
        this.emailPerDay.extraOptions.scales.yAxes[0].ticks.suggestedMax = 5;
        this.emailPerDay.extraOptions.scales.yAxes[0].ticks.suggestedMin = 0;
      } else {
        var posInd = 0;
        for (var temp of emailSent) {
          var dateData = moment(temp._id).format("YYYY-MM-DD");
          if (this.emailPerDay.chartData.labels.includes(dateData)) {
            var pos = this.emailPerDay.chartData.labels.findIndex(
              (d) => d == dateData
            );

            this.emailPerDay.chartData.datasets[0].data[parseInt(pos)] += 1;
          } else {
            this.emailPerDay.chartData.labels.push(dateData);
            this.emailPerDay.chartData.datasets[0].data[posInd] = temp.count;
            posInd++;
          }
        }

        this.emailPerDay.extraOptions.scales.yAxes[0].ticks.suggestedMax =
          Math.max(...this.emailPerDay.chartData.datasets[0].data);
        this.emailPerDay.extraOptions.scales.yAxes[0].ticks.suggestedMin = 0;
      }
      this.loading = false;
    },
  },
};
</script>