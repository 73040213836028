<template>
  <v-dialog v-model="show" width="700">
    <v-card class="pt-6 px-6 pb-3 text-body-1">
      <div class="text-h3 pb-3">{{ $t("match.Extension.send_matches") }}</div>

      <div class="alert" v-if="active">
        <Alert :text="$t('match.Extension.extension_active')" type="success" />
      </div>

      <div class="alert" v-else>
        <Alert type="error" :text="$t('match.Extension.notice_extension_installed')">
          <b @click="openExtension" class="extension"> {{ $t("Generic.Common.here") }}.</b>
        </Alert>
      </div>

      <div class="match_info">
        <label v-html="$t('match.Extension.send_value_matches', { value: matches.length })"> </label>
      </div>

      <div class="alert">
        <Alert :text="$t('match.Extension.notice_canceled')" type="warning" />
      </div>

      <div class="user_info">
        <label class="header5-bold">{{ clientInfo }}</label>
      </div>
      <div class="instruction">{{ $t("match.Extension.notice_platform") }}</div>
      <div class="instruction">
        <label
          >{{ $t("match.Extension.linkedIn_instruction") }}
          <a href="https://www.linkedin.com/jobs/application-settings/?hideTitle=true" target="_blank"
            >https://www.linkedin.com/jobs/application-settings/?hideTitle=true</a
          ></label
        >
      </div>
      <div v-if="user && user.phoneData">
        {{ $t("match.Extension.extension_phone") }}
        {{ user.phoneData ? user.phoneData.e164 : "" }}
      </div>
      <div class="instruction" v-else>{{ $t("match.Extension.set_extension_phone") }}</div>
      <v-card-actions class="justify-center">
        <v-btn elevation="0" @click="show = false">
          {{ $t("no") }}
        </v-btn>
        <v-btn color="primary" @click="triggerApply" :disabled="!applicationAllowed">{{ $t("yes") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import appSDK from "@/client/AppSDK";
import { CHROME_WEB_STORE_EXTENSION_ID } from "@/assets/js/constants.js";
import { Alert } from "@/framework";

export default {
  components: {
    Alert,
  },

  data() {
    return {
      show: false,
      matches: [],
      active: false,
      user: null,
      extensionId: CHROME_WEB_STORE_EXTENSION_ID,
    };
  },
  computed: {
    clientId() {
      return this.$route.params?.clientId || null;
    },

    applicationAllowed() {
      return this.active && this.user?.phoneData;
    },

    clientInfo() {
      if (!this.user) {
        return "User data not available";
      }
      return `User info: ${this.user.firstName} ${this.user.lastName} ${this.user.email}`;
    },

    userData() {
      // the userData object to send to the extension
      return {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        phoneData: this.user.phoneData,
        userId: this.user.userId,
        role: this.user.role,
      };
    },
  },

  created() {
    window.addEventListener("message", this.extensionListener, false);
    localStorage.removeItem("extensionId"); // clean any old entries
  },

  beforeDestroy() {
    window.removeEventListener("message", this.extensionListener);
  },

  methods: {
    async open(matches) {
      this.active = false;
      this.show = true;
      this.matches = matches;
      this.extensionId = CHROME_WEB_STORE_EXTENSION_ID;
      this.extensionIsInstalled()
        .then(installed => {
          if (installed) {
            this.active = true;
          }
        })
        .catch(error => {
          // this.$notify({
          //   message:
          //     "We could not communicate with the extension. Please make sure it is installed and check again.",
          //   type: "danger",
          // });
        });

      const user = await appSDK["user.get"](this.clientId);
      this.user = user;
    },

    extensionListener(event) {
      // We only accept messages from ourselves
      if (event.source != window) {
        return;
      }

      if (event.data.loopcvExtensionId && this.extensionId !== event.data.loopcvExtensionId) {
        console.log("Content script received: " + event.data.loopcvExtensionId);
        this.extensionId = event.data.loopcvExtensionId;
      }
    },

    extensionIsInstalled() {
      return new Promise((resolve, reject) => {
        if (!window.chrome) {
          resolve(false);
        }

        chrome.runtime.sendMessage(this.extensionId, { type: "hello" }, response => {
          console.log(response);
          if (response?.type === "ack") {
            resolve(true);
          }
        });
      });
    },

    triggerApply() {
      return new Promise((resolve, reject) => {
        if (!window.chrome) {
          this.$notify({
            message: "There was a problem sending the matches to the extension",
            type: "danger",
          });
          resolve(false);
        }

        const stripParams = url => {
          if (url?.includes("indeed.com")) {
            if (url?.includes("indeed.com/applystart?jk=")) {
              return url.replace("indeed.com/applystart", "indeed.com/viewjob").split("&")[0];
            } else {
              return url.replace("indeed.com/applystart", "indeed.com/viewjob");
            }
          }
          return url.split(/\?/)[0];
        };

        // check easyApply, status fields
        // check the url according to a regex
        let jobUrls = this.matches.map(({ matchId, jobTitle, url, company }) => ({
          matchId,
          jobTitle,
          url: stripParams(url),
          company,
        }));
        // TODO use job.applyLink, not job.url

        if (process.env.NODE_ENV === "development") {
          jobUrls = [].map(url => ({
            matchId: this.matches[0].matchId,
            url: stripParams(url),
            jobTitle: "jobTitle",
          }));
        }

        chrome.runtime.sendMessage(
          this.extensionId,
          {
            type: "triggerExtension",
            userData: this.userData,
            jobUrls,
            dashboardConfig: process.env.VUE_APP_CONFIGS,
          },
          response => {
            console.log(response);
            if (response?.type === "ack") {
              this.$notify({
                message: "Successfully sent matches for application",
                type: "success",
              });
              resolve(true);
              this.show = false;
              // this.$emit("sent-matches");
            } else if (response?.type === "error" && response?.error === "storage-quota-exceeded") {
              this.$notify({
                message: this.$t("errors.storageExceeded"),
                type: "danger",
              });
              reject(new Error("Storage quota exceeded"));
            } else {
              this.$notify({
                message: this.$t("errors.unexpected"),
                type: "danger",
              });
              reject(new Error("Extension error"));
            }
          },
        );
      });
    },

    openExtension() {
      window.open("https://chrome.google.com/webstore/detail/apply-tool/aleecdoflfiofldfpipjhcjoigokflmp", "_blank");
    },
  },
};
</script>

<style scoped>
.extension {
  color: #ff5252 !important;
  text-decoration: none;
  cursor: pointer;
}

.extension:hover {
  color: hsl(2, 100%, 40%) !important;
}

.instruction {
  padding: 10px;
  background-color: var(--off_grey);
  border-radius: 10px;
  margin-top: 10px;
}

.user_info {
  text-align: center;
  margin-bottom: 10px;
}

.alert {
  margin-block: 10px;
}

.match_info {
  text-align: center;
  margin-block: 5px;
}
</style>
