<template>
  <div class="datePickerBody" :style="{ width: width }">
    <CalendarIcon />
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          :label="label ? label : $t('Generic.Common.fill_date')"
          hint="MM/DD/YYYY format"
          persistent-hint
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" :max="maxDate" no-title @input="handleChange" color="#3f51b5"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { CalendarIcon } from "@/assets/fonts/icons/index";
export default {
  components: {
    CalendarIcon,
  },
  props: {
    value: String,
    width: String,
    label: String,
    max: Date,
  },

  data: vm => ({
    date: null,
    dateFormatted: vm.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
    menu: false,
  }),

  computed: {
    computedDateFormatted() {
      if (this.date === null) {
        return this.formatDate(this.value);
      } else {
        return this.formatDate(this.date);
      }
    },

    maxDate() {
      if (this.max) {
        return this.max.toISOString().slice(0, 10);
      }
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },

    value(val) {
      this.date = val;
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      if (date instanceof Date) return;
      if (date.includes("-")) {
        const [year, month, day] = date.split("-");
        return `${month}/${day}/${year}`;
      }
      return date;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    handleChange(value) {
      this.$emit("input", value);
      this.menu = false;
    },
  },
};
</script>

<style scoped>
.datePickerBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 125px;
  gap: 10px;
}

.datePickerBody .v-input {
  width: 50px;
  display: block;
}
</style>
