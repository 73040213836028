<template>
  <div class="outer-cvs">
    <div
      class="ma-2 pa-2 d-flex cv-tile align-center"
      v-for="template in userTemplates"
      :key="template.firebaseKey"
      :class="{
        'selected-box': selectedTemplateId === template.firebaseKey,
      }"
    >
      <span style="line-height: 42px; font-weight: 500" class="text-h4 px-2">{{ template.name }}</span>
      <v-spacer></v-spacer>
      <v-btn text tile @click="$emit('select-template', template)">{{ $t("plans.select") }}</v-btn>
      <v-progress-circular
        v-if="showDeleteButton && userTemplates.length >= 2 && !canDelete"
        indeterminate
        color="red"
      ></v-progress-circular>
      <v-btn
        icon
        color="#D8685E"
        v-if="showDeleteButton && userTemplates.length >= 2 && canDelete"
        @click="canDelete ? $emit('delete-template', template) : null"
        ><v-icon color="#D8685E">delete</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userTemplates: {
      type: Array,
      default: () => [],
    },
    selectedTemplateId: {
      type: String | Number,
    },
    showDeleteButton: {
      type: Boolean,
    },
    canDelete: Boolean,
  },
};
</script>

<style scoped>
.cv-tile {
  background: #f8f8fa 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}
.outer-cvs {
  max-height: 400px;
  overflow-y: auto;
}
.selected-box {
  background-color: #b5dabb;
  color: black;
}
</style>
