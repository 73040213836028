// helpers related to match rejection reasons and reasonSummary grouped statistics

export function calculateJobs(reasonSummary) {
	return reasonSummary?.reduce((a, b) => a + b.count, 0);
}

export function calculateMatches(reasonSummary) {
	return reasonSummary?.find(a => a.reason === "FOUND_MATCH")?.count || 0;
}

// show alert if there are too few jobs
export function tooFewJobs(reasonSummary) {
	return calculateJobs(reasonSummary) < 20;
}