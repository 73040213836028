<template v-slot:top>
  <div v-if="allowApplyOnDemand || allowExtension">
    <v-alert class="mx-5" dense text colored-border>
      <v-btn
        text
        color="primary"
        v-if="!selectionStatus && allowApplyOnDemand"
        @click="() => onChangeSelectionStatus('applyOnDemand')"
        >{{ $t("match.apply-on-demand") }}</v-btn
      >
      <v-btn
        text
        color="primary"
        v-if="!selectionStatus && allowExtension"
        @click="() => onChangeSelectionStatus('easyApply')"
        >{{ $t("match.apply-extension") }}</v-btn
      >
      <template v-slot:append>
        <v-btn text :disabled="!selectionStatus" @click="handleCancel">{{ $t("cancel") }}</v-btn>
        <v-btn
          text
          color="primary"
          :disabled="!localSelectedMatches.length"
          @click="handleApply"
          v-if="selectionStatus === 'applyOnDemand'"
          >{{ $t("match.apply") }}</v-btn
        >
        <v-btn
          text
          color="primary"
          :disabled="!localSelectedMatches.length"
          @click="handleApply"
          v-if="selectionStatus === 'easyApply'"
          >{{ $t("match.apply") }}</v-btn
        >
      </template>
      <div v-if="selectionStatus">
        <div v-if="localSelectedMatches.length">
          {{ $tc("results.matchesSelected", localSelectedMatches.length) }}

          {{
            selectionStatus === "applyOnDemand" ? $t("results.hitApply") : 'Hit "apply" to send the matches to the extension'
          }}
        </div>
        <div v-else>
          {{ selectionStatus === "applyOnDemand" ? $t("results.selectOnDemand") : "Select matches to send to the extension" }}
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;
export default {
  name: "Extension",

  props: {
    selectionStatus: {
      type: String,
    },
    allowExtension: {
      type: Boolean,
    },
    selectedMatches: {
      type: Array,
    },
  },

  data() {
    return {
      localSelectedMatches: [],
    };
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    currentUser() {
      return this.getUser;
    },

    allowApplyOnDemand() {
      return true;
    },
  },

  mounted() {
    this.localSelectedMatches = this.selectedMatches;
  },

  methods: {
    onChangeSelectionStatus(value) {
      this.$emit("onChangeSelectionStatus", value);
    },

    handleCancel() {
      this.$emit("onChangeSelectionStatus", null);
      this.$emit("onSelectedMatches", []);
    },

    updateSelectedMatches(value) {
      this.localSelectedMatches = value;
    },

    updateExtensionMatches(value) {
      this.extensionMatches = value;
    },

    handleApply() {
      this.$emit("onApply");
    },
  },
};
</script>
