<template>
  <div>
    <LoopLoading v-if="isProgress" @progressEnd="$emit('progressEnd')" />
    <div v-if="!isProgress">
      <!--#region Filters  -->
      <Filters :allow-extension="allowExtension" @onFilter="handleFilters" ref="filtersRef" />
      <!-- #endregion -->

      <div class="filter_tags">
        <div v-for="tag in this.filterTags">
          <FilterTag :label="tag.name" :type="tag.key" />
        </div>
      </div>

      <ExtensionAndApplyOnDemand
        ref="extensionRef"
        :allowExtension="allowExtension"
        :selectedMatches="selectedMatches"
        :selectionStatus="selectionStatus"
        @onChangeSelectionStatus="onChangeSelectionStatus"
        @onSelectedMatches="handleSelectedMatches"
        @onApply="handleExtensionDialog"
      />

      <Table
        ref="tableRefs"
        :options="JSON.stringify(options)"
        :totalMatches="totalMatches"
        :loading="loading"
        :matches="matches"
        :selectedMatches="selectedMatches"
        :allowExtension="allowExtension"
        :selectionStatus="selectionStatus"
        @onSelectedMatches="handleSelectedMatches"
        @options-updated="handleOptions"
        @onTagClick="handleTagClick"
        @onMatchRating="rateMatch"
        @onMatchDeleted="deleteMatch"
        @onDisplayMatch="handleMatchDataDisplay"
      />

      <DeleteDialog ref="deleteMatchDialog" @deleted-match="getMatches()" />

      <TriggerExtensionDialog ref="triggerExtensionDialog" />

      <v-dialog v-model="isDialogVisible" scrollable width="700">
        <LoopMatch
          :matchId="currentMatchId"
          :key="currentMatchId"
          :scrollTo="scrollMatchDialogTo"
          @onSubmitQuestions="handleAfterSubmit"
          @onSubmitOnDemandRequest="handleAfterSubmit"
          @OnInterviewChange="handleAfterSubmit"
        />
      </v-dialog>

      <ApplyOnDemandDialog ref="applyOnDemandRef" @onApply="applyOnDemand" />
    </div>
  </div>
</template>

<script>
import LoopMatch from "@/components/loop/LoopMatch.vue";
import appSDK from "@/client/AppSDK";
import FilterTag from "@/components/Filter/FilterTag.vue";
import LoopLoading from "@/components/LoopLoading.vue";
import { timestampToDateString } from "@/assets/js/utils.js";
import { statusData, statusDataTag } from "@/data/statuses.js";
import { mapGetters } from "vuex";
import { eFilterTypes } from "@/helpers/enums/eLoop";
import { constant } from "@/store/constant";
import Cookies from "js-cookie";
import { Filters, Table } from "@/components/LoopMatch/index.js";
import {
  ExtensionAndApplyOnDemand,
  DeleteDialog,
  ApplyOnDemandDialog,
  TriggerExtensionDialog,
} from "@/components/LoopMatch/index.js";

const user = constant.user;

export default {
  name: "LoopResults",
  components: {
    LoopMatch,
    DeleteDialog,
    TriggerExtensionDialog,
    FilterTag,
    Filters,
    Table,
    ExtensionAndApplyOnDemand,
    ApplyOnDemandDialog,
    LoopLoading,
  },
  props: {
    loopId: String,
    clientId: String,
    all: Boolean,
    matchId: String,
    isProgress: Boolean,
    handleProgressEnd: Function
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    currentUser() {
      return this.getUser;
    },

    getExtensionRoles() {
      return this.$store.getters.configs?.showExtensionForRoles;
    },

    initStatusFilter() {
      const options = [];
      const status = statusDataTag();
      status.forEach(element => {
        options.push({ text: element, value: element });
      });
      return options;
    },
  },

  data() {
    return {
      isDialogVisible: false,
      scrollMatchDialogTo: null,
      currentMatchId: "",
      filters: {
        applicationMethod: "all",
        platforms: [],
        company: "",
      },
      selectionStatus: null,
      totalMatches: 0,
      matches: [],
      loading: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
      selectedMatches: [],
      filterTags: [],
      allowExtension: false,
    };
  },

  async mounted() {
    const filterObj = Cookies.get("match_filter");
    if (filterObj) {
      this.filters = JSON.parse(filterObj);
      for (let value of this.filters.platforms) {
        this.addFilterTag(value, eFilterTypes.Platform);
      }
      this.addFilterTag(this.filters.applicationMethod, eFilterTypes.Applications);
    }
    if (
      this.$store.getters.isB2C &&
      this.getExtensionRoles?.includes("user") &&
      this.currentUser?.role === "user" &&
      (this.currentUser?.beta == 1 || this.currentUser.pricingPlan > 1)
    ) {
      this.allowExtension = true;
    } else if (this.$store.getters.isB2B && this.getExtensionRoles?.includes(this.currentUser?.role)) {
      this.allowExtension = true;
    }
    await this.getMatches();
    if (this.$route.params.matchId) {
      const match = this.matches.filter(x => x.matchId == this.$route.params.matchId); //"654cba527c8b0c81fc514e69"
      if (match.length > 0) {
        const match_string = JSON.stringify(match[0]);
        this.handleMatchDataDisplay(match_string);
      }
    }
  },

  methods: {
    // from questions submission or match apply on demand submission
    handleAfterSubmit() {
      this.isDialogVisible = false;
      this.getMatches();
    },

    async getMatches() {
      this.loading = true;
      try {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const filterData = {
          page,
          filter: this.filters.applicationMethod,
          version: "fast",
          perPage: itemsPerPage,
          sort: sortBy?.length ? `${sortBy[0]}:${sortDesc[0] ? "-1" : "1"}` : `matchCreatedAt:-1,tag:-1`,
          companyFilter: this.filters.company,
        };
        if (this.filters.applicationMethod == "applicationInInterview") {
          filterData.interview = true;
          filterData.filter = "all";
        }

        if (this.filters.platforms.length > 0) {
          filterData.platform = this.filters.platforms.join();
        }

        let result;
        if (this.all) {
          result = await appSDK["match.all.list"](this.isUserPartner ? this.clientId : null, filterData);
        } else {
          if (this.isUserPartner) {
            result = await appSDK["partner.match.list"](this.clientId, this.loopId, filterData);
          } else {
            result = await appSDK["match.list"](this.loopId, filterData);
          }
        }

        // fill the data
        this.matches = this.formatMatches(result.matches);
        this.totalMatches = result.total;
        this.$amplitude.logEvent("LOAD_MATCHES", {
          matchIds: result.matches.map(match => match.matchId),
        });
        this.updateTableMatches();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    formatMatches(matches) {
      matches.forEach(match => {
        const state = statusData(match.status);
        match.tag = state.tag;
        match.tagTooltip = match.reason || state.tagTooltip;
        match.tagColor = state.tagColor;
        match.matchCreatedAt = timestampToDateString(match.matchCreatedAt);
        match.isSelectable = state.allowsOnDemand;
        match.easyApply = !!match.easyApply;

        if (match.rating != 0 && match.rating != 1) {
          match.rating = -1;
        }
        if (match.remote && match.jobLocation == "Remote") {
          match.jobLocation = `Remote anywhere`;
        } else if (match.remote) {
          match.jobLocation = `${match.jobLocation} (Remote)`;
        }
      });
      return matches;
    },

    async applyOnDemand() {
      try {
        const matchIds = this.selectedMatches.map(match => match.matchId);
        await appSDK["partner.applyOnDemand"](this.clientId, {
          matchIds,
        });
        this.$notify({
          message: this.$t("match.submittingOnDemand"),
          type: "success",
        });
        this.$amplitude.logEvent("APPLY_ON_DEMAND", {
          how: "bulk",
          count: matchIds.length,
          matchIds,
        });
        await this.getMatches();
        this.selectedMatches = [];
      } catch (e) {
        console.error(e);
        this.$notify({
          message: this.$t("match.errorOnDemand"),
          type: "danger",
        });
      } finally {
        const applyOnDemandDialog = this.$refs.applyOnDemandRef;
        applyOnDemandDialog.applied();
      }
    },

    async rateMatch(value) {
      const { match, rating } = JSON.parse(value);
      if (rating !== 1 && rating !== 0) {
        throw new Error("rating should be 0 or 1");
      }

      try {
        const response = await appSDK["match.rate"](match.matchId, { rating });
        this.$notify({
          message: this.$t("match.successRating"),
          type: "success",
        });
        this.updateTableMatches();
        this.$amplitude.logEvent("RATE_MATCH", {
          from: "results-table",
          rating,
          matchId: match.matchId,
        });
        for (let obj of this.matches) {
          if (obj._id == match._id) {
            obj.rating = rating;
          }
        }
      } catch (error) {
        this.$notify({
          message: this.$t("match.failRating"),
          type: "danger",
        });
        this.getMatches(); // reset the optimistic update of rating
      }
    },

    async dismissFilter(item) {
      if (item.type === eFilterTypes.Applications) {
        this.filterTags = this.filterTags.filter(function (obj) {
          return obj.key != item.type;
        });
        this.filters.applicationMethod = "all";

        // Return to default status
      } else if (item.type === eFilterTypes.Platform) {
        this.filters.platforms = this.filters.platforms.filter(object => object != item.name);
        this.$refs.filtersRef.updatedPlatform(this.filters.platforms);
        this.filterTags = this.filterTags.filter(object => object.name != item.name);
      }
      Cookies.set("match_filter", JSON.stringify(this.filters), { secure: true, sameSite: 'Lax' });

      this.options.page = 1;
      const tableInstance = this.$refs.tableRefs;
      tableInstance.updateOptions(JSON.stringify(this.options));

      if (item.name === "Support Easy-Apply" || item.name === "Apply on Demand") {
        this.onChangeSelectionStatus(null);
        this.handleSelectedMatches([]);
      } else {
        const filtersInstance = this.$refs.filtersRef;
        filtersInstance.updateFilters(JSON.stringify(this.filters));
        await this.getMatches();
      }
    },

    addFilterTag(name, key) {
      if (key === eFilterTypes.Applications) {
        if (this.filterTags.length > 0) {
          this.filterTags = this.filterTags.filter(function (obj) {
            return obj.key != eFilterTypes.Applications;
          });
        }
        if (name == "applications") {
          this.filterTags.unshift({ name: this.$t("results.Tags.job_applications"), key });
        } else if (name == "questions") {
          this.filterTags.unshift({ name: this.$t("results.Tags.pending_questions"), key });
        } else if (name == "easyApply") {
          this.filterTags.unshift({ name: this.$t("results.Tags.support_easyApply"), key });
        } else if (name == "emails") {
          this.filterTags.unshift({ name: this.$t("results.Tags.emails"), key });
        } else if (name == "applyOnDemand") {
          this.filterTags.unshift({ name: this.$t("results.Tags.support_applyOnDemand"), key });
        } else if (name == "manualApplications") {
          this.filterTags.unshift({ name: this.$t("results.Tags.manual_applications"), key });
        } else if (name == "applicationInInterview") {
          this.filterTags.unshift({ name: this.$t("results.Tags.scheduled_interview"), key });
        }
      } else if (key === eFilterTypes.Platform) {
        if (name != "all") {
          this.filterTags.push({ name: name, key: key });
        }
      }
    },

    handleExtensionDialog() {
      if (this.selectionStatus == "applyOnDemand") {
        const applyOnDemandDialog = this.$refs.applyOnDemandRef;
        applyOnDemandDialog.showDialog();
      } else if (this.selectionStatus == "easyApply") {
        // You are using Chrome!
        if (navigator.userAgent.indexOf("Chrome") != -1) {
          this.$refs.triggerExtensionDialog.open(this.selectedMatches);
          // You are not using Chrome!
        } else {
          this.$notify({ message: this.$t("Generic.Messages.Error.required_chrome"), type: "danger" });
        }
      }
    },

    async handleFilters(values) {
      const model = JSON.parse(values);
      this.filters = model;

      this.options.page = 1;
      const tableInstance = this.$refs.tableRefs;
      tableInstance.updateOptions(JSON.stringify(this.options));

      this.filterTags = [];

      //Add filter tag to Platform
      for (let value of model.platforms) {
        this.addFilterTag(value, eFilterTypes.Platform);
      }

      // Add filter tag to Application Method
      this.addFilterTag(model.applicationMethod, eFilterTypes.Applications);

      await this.getMatches();
    },

    async handleOptions(value) {
      const model = JSON.parse(value);
      this.options = model;
      await this.getMatches();
    },

    async handleTagClick(match) {
      this.scrollMatchDialogTo = match.tag === "Pending Questions" ? "questions" : null;
      this.currentMatchId = match._id || match.id;
      this.isDialogVisible = true;
      this.$amplitude.logEvent("OPEN_MATCH_DIALOG", {
        matchId: this.currentMatchId,
      });

      // if (match.tag === "Apply manually" && this.isUserPartner) {
      //   try {
      //     await appSDK["partner.applyManuallyClick"](this.currentMatchId);
      //     await this.getMatches();
      //   } catch (e) {
      //     console.error(e);
      //   }
      // }
    },

    deleteMatch(value) {
      this.$refs.deleteMatchDialog.open(value);
    },

    updateTableMatches() {
      const tableInstance = this.$refs.tableRefs;
      tableInstance?.updateMatches(this.matches);
    },

    updateTableSelectedMatches() {
      const tableInstance = this.$refs.tableRefs;
      tableInstance.updateSelectedMatches(this.selectedMatches);
    },

    updateExtensionSelectedMatches() {
      const extension = this.$refs.extensionRef;
      extension.updateSelectedMatches(this.selectedMatches);
    },

    onChangeSelectionStatus(value) {
      this.selectionStatus = value;
      this.options.page = 1;
      this.options.itemsPerPage = 10;

      this.filters.applicationMethod = value || "all";
      this.handleFilters(JSON.stringify(this.filters));

      const filtersInstance = this.$refs.filtersRef;
      filtersInstance.updateFilters(JSON.stringify(this.filters));

      const tableInstance = this.$refs.tableRefs;
      tableInstance.updateOptions(JSON.stringify(this.options));
    },

    handleSelectedMatches(value) {
      if (value.length > 0) {
        this.selectedMatches = value;
      } else {
        this.selectedMatches = [];
      }
      this.updateExtensionSelectedMatches();
      this.updateTableSelectedMatches();
    },

    handleMatchDataDisplay(value) {
      const match = JSON.parse(value);
      this.scrollMatchDialogTo = null;
      this.currentMatchId = match._id || match.id;
      this.isDialogVisible = true;
      this.$amplitude.logEvent("OPEN_MATCH_DIALOG", {
        matchId: this.currentMatchId,
      });
    },
  },
};
</script>

<style scoped>
.filter_tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 10px;
}
</style>
