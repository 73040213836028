<template>
  <div class="block" :style="'width:' + this.width">
    <label class="input-title header5"
      >{{ title }} <span class="required">{{ required == true ? "*" : "" }}</span></label
    >
    <div :class="inputBlockClass">
      <input
        :class="inputClass"
        :placeholder="!uneditable ? placeholder : ''"
        :autocomplete="autocomplete"
        v-model="inputValue"
        :type="type"
        :disabled="uneditable"
        :id="id"
      />
      <span class="icons">
        <slot></slot>
      </span>
    </div>
    <label class="error-message header7">{{ error }}</label>
    <label v-if="errorExample != '' && error != ''" class="error-message-example header7">{{
      this.$t("Generic.Common.example", { value: errorExample })
    }}</label>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    error: {
      type: String,
      default: "",
    },
    errorExample: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: String,
      required: false,
    },
    value: {
      type: String || null,
    },
    icons: {
      type: Array,
      required: false,
    },
    type: {
      type: String,
      default: "text",
    },
    uneditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: String,
      required: false,
    },
    id: {
      type: String,
    },
  },

  data() {
    return {
      inputBlockClass: "input-block",
      inputClass: "header5 input-text-field",
    };
  },

  watch: {
    error(value) {
      if (value != "") {
        // @ts-ignore
        if (!this.inputBlockClass.includes(" hasError")) {
          this.inputBlockClass = this.inputBlockClass + " hasError";
        }
      } else {
        // @ts-ignore
        this.inputBlockClass = this.inputBlockClass.replace(" hasError", "");
      }
    },

    uneditable(value) {
      if (value != "") {
        this.inputBlockClass = this.inputBlockClass + " uneditable-input-block";
        this.inputClass = this.inputClass + " uneditable-input";
      } else {
        this.inputBlockClass = this.inputBlockClass.replace(" uneditable-input-block", "");
        this.inputClass = this.inputClass.replace(" uneditable-input", "");
      }
    },
  },

  created() {
    if (this.uneditable == true) {
      this.inputBlockClass = this.inputBlockClass + " uneditable-input-block";
      this.inputClass = this.inputClass + " uneditable-input";
    }
  },

  computed: {
    inputValue: {
      get() {
        if (this.value == null || this.value === "") {
          return "";
        } else {
          return this.value;
        }
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.block {
  display: flex;
  flex-direction: column;
}

.input-title {
  color: #989696;
}

.required {
  color: var(--blue);
}

/* input */
.input-block {
  margin-top: 7px;
  border: 1px solid #acacaf;
  border-radius: 6px;
  display: inline-block;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px;
  color: var(--black);
  gap: 10px;
}

.input-block::placeholder {
  color: #989696;
}
.input-block:focus-within {
  border: 1px solid var(--blue);
  border-radius: 6px;
  opacity: 1;
  outline-width: 0;
}

.input-text-field {
  width: 100%;
  height: 30px;
}

.input-text-field,
.input-text-field:focus-visible,
.input-text-field:focus {
  border: 0;
  outline-width: 0;
  outline: none;
}

.using-keyboard {
  border: 0;
  outline-width: 0;
}

/* uneditable input */
.uneditable-input-block {
  cursor: not-allowed;
  background-color: #f8f8fa;
  border: 0;
}

.uneditable-input {
  cursor: not-allowed;
  border: 0;
}

/* error input */
.hasError {
  border: 1px solid #d8685e;
  border-radius: 6px;
  opacity: 1;
}

.error-message {
  color: #d8685e;
  margin-top: 2px;
}

.error-message-example {
  color: #d8685e;
}

/* icons input */
.icons {
  color: #acacaf;
  cursor: pointer;
  left: auto;
  margin-bottom: -5px;
  display: inline-flex;
}

.icons span {
  margin-left: 5px;
}

.icons span:focus,
.icons span:focus-visible,
.icons span:hover {
  color: #989696 !important;
  outline-width: 0;
}
</style>

<!-- <TextField
      :title="'Label'"
      :required = 'true'
      :uneditable = 'true'
      :type='"text"'
      :value='"test"'
      :autocomplete='current-password'
      :error='"Some error"'
      :placeholder='"Username"'
      :errorExample = "'smtp.gmail.com'"
      ><EyeIn/>
      />
    -->
