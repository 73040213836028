<template>
  <div>
    <div v-if="cvs.length !== 0" class="col-12">
        <div class="text-body-1">
          {{ $t("SelectCV.selectTitle") }}
        </div>
      </div>
    <div class="row">
      <div v-for="cv in cvs" :key="cv.cvId" class="col-md-3 col-sm-12" @click="selectCV(cv)">
        <div :class="{ 'box-highlighted': selectedCVId === cv.cvId }" class="box pointer d-flex align-center pa-3">
          <div class="flex-grow-1" style="word-break: break-all">
            {{ cv.fileName }}
          </div>
          <div class="ml-1">
            <i class="tim-icons icon-simple-remove pointer" @click.stop="deleteCV(cv.cvId)"></i>
          </div>
        </div>
      </div>
      <div v-if="cvs.length === 0 && showEmptyListMessage" class="col-12">
        <div class="noCvUploaded">
          {{ $t("SelectCV.noCVs") }}
        </div>
        <p class="text-body-2" v-if="allowUpload" v-html="$t('loop.slides.cvs.optionalCVWarning')"></p>
      </div>
    </div>

    <div class="row" v-if="allowUpload">
      <div class="col-12" v-if="showLabel && cvs.length !== 0">
        <h4 class="text-subtitle-1">
          {{ $t("SelectCV.orUpload") }}
        </h4>
      </div>
      <div class="col-12">
        <div
          class="dropbox row"
          :class="dragging ? 'dragging' : ''"
          @dragenter.stop.prevent
          @dragover.stop.prevent="dragging = true"
          @dragleave="dragging = false"
          @drop.stop.prevent="handleDropUpload"
        >
          <AppLoader v-if="uploading" />
          <div class="col-12" v-if="!uploading" style="text-align: center">
            {{ $t("SelectCV.dropFile") }}
          </div>
          <v-btn tonal @click="$refs.fileInput.click()" v-if="!uploading">{{ $t("SelectCV.button") }}</v-btn>
        </div>
        <CVInputElement ref="fileInput" @regular-upload="handleRegularUpload" />
      </div>
    </div>

    <v-dialog v-model="showDeleteCVConfirmationModal" max-width="290">
      <v-card>
        <v-card-title>
          <div class="text-body-1">{{ $t("SelectCV.delete") }}</div>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn @click="showDeleteCVConfirmationModal = false">{{ $t("no") }}</v-btn>
          <v-btn @click="confirmDeletion">{{ $t("yes") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AppLoader from "../AppLoader.vue";
import SuccessAnimation from "@/components/Success.vue";
import { cvExtensionValid } from "@/assets/js/utils";
import CVInputElement from "@/components/cvs/CVInputElement.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import { bytesToMB } from "@/helpers/utils/numberUtils";
import { Role } from "@/helpers/enums/eRole";
import {CV_PDF_SIZE} from "@/assets/js/constants";
const user = constant.user;
const client = constant.client;

export default {
  name: "SelectCV",

  props: {
    allowUpload: {
      default: true,
      type: Boolean,
    },
    selectedCVId: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showEmptyListMessage: {
      type: Boolean,
      default: true,
    },
    clientId: {
      type: String,
      required: false,
    },
  },
  components: {
    SuccessAnimation,
    AppLoader,
    CVInputElement,
  },

  data() {
    return {
      showDeleteCVConfirmationModal: false,
      selectedCV: "",
      toDeleteCV: "",
      dragging: false,
      uploading: false,
    };
  },

  async mounted() {
    await this.getCVs();
  },

  computed: {
    ...mapGetters("user", {
      getUser: user.getters.getUser,
      getStatus: user.getters.getStatus,
      getCvList: user.getters.getCvs,
      getUploadCV: user.getters.getUploadedCV,
    }),

    currentUser() {
      return this.getUser;
    },

    status() {
      return this.getStatus;
    },

    cvs() {
      return this.getCvList;
    },

    uploadedCV() {
      return this.getUploadCV || null;
    },

    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },
  },
  methods: {
    async getCVs() {
      if (!this.currentUser) return; // add error
      try {
        await this.$store.dispatch(user.actions.GetCVs, this.clientId);
        this.autoSelectLastCV();
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
        });
      }
    },

    autoSelectLastCV() {
      if (!this.selectedCVId && this.cvs.length) {
        this.selectCV(this.cvs[this.cvs.length - 1]);
      }
      if (this.selectedCVId && this.cvs.length) {
        const cv = this.cvs.find(cv => cv.cvId === this.selectedCVId);
        if (cv) {
          this.selectCV(cv);
        }
      }
    },

    selectCV(cvData) {
      this.$emit("updateCVData", cvData);
    },

    handleRegularUpload(evt) {
      this.fileUpload(evt.target.files[0]);
    },

    handleDropUpload(evt) {
      this.dragging = false;
      this.fileUpload(evt.dataTransfer.files[0]);
    },

    async fileUpload(file) {
      if (!file) {
        return;
      }

      if (!cvExtensionValid(file.name)) {
        this.$notify({
          message: this.$t("errors.shouldBePdfOrWord"),
          type: "danger",
        });
        return;
      }

      if (this.cvs.some(cv => cv.fileName === file.name)) {
        this.$notify({
          message: this.$t("errors.sameFileName"),
          type: "danger",
        });
        this.$refs.fileInput.reset();
        return;
      }

      this.$amplitude.logEvent("UPLOAD_A_NEW_CV_IN_LOOP");

      this.$emit("updateCVData", { cvId: undefined });

      this.uploading = true;
      const payload = {
        file: file,
        clientId: this.clientId,
      };

      if (file.size > CV_PDF_SIZE) {
        this.$notify({
          message: this.$t("Generic.Messages.Error.file_size", { size: bytesToMB(file.size), limit: bytesToMB(CV_PDF_SIZE)}),
          type: "danger",
        });
      } else {
        try {
          const uploaded_cv = await this.$store.dispatch(user.actions.UploadCv, payload);
          if (uploaded_cv) {
            this.selectCV({ cvId: uploaded_cv.cvId, fileName: uploaded_cv.fileName });
          }
        } catch (e) {
          this.$notify({
            message: e.message || this.$t("Generic.Messages.Error.common_error", { email: this.supportEmail }),
            type: "danger",
          });
          console.log(e);
        }
      }
      this.$refs.fileInput.reset();
      this.getCVs();
      this.uploading = false;
    },

    async deleteCV(id) {
      this.toDeleteCV = id;
      this.showDeleteCVConfirmationModal = true;
    },

    async confirmDeletion() {
      this.showDeleteCVConfirmationModal = false;
      try {
        const payload = {
          userId: this.clientId,
          cvId: this.toDeleteCV,
        };

        if (this.currentUser.role === Role.MANAGED_USER || this.currentUser.role === Role.USER) {
          await this.$store.dispatch(user.actions.DeleteCV, payload);
        } else if (
          this.currentUser.role === Role.MANAGER ||
          this.currentUser.role === Role.ADMIN ||
          this.currentUser.role === Role.SupportAgent
        ) {
          await this.$store.dispatch(client.actions.DeleteCV, payload);
        }
        if (this.toDeleteCV === this.selectedCVId) {
          this.$emit("updateCVData", { cvId: undefined });
        }
        this.$notify({
          message: this.$t("Profile.success_delete_cv"),
          type: "success",
        });
      } catch (error) {
        this.$notify({
          message: this.$t("errors.cannotDeleteConnectedCV"),
          type: "danger",
        });
      }
      this.getCVs();
    },
  },
};
</script>
<style>
.dropbox {
  border: 2px dotted black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 2em;
  margin: 5px;
}
.dropbox.dragging {
  background-color: rgb(117, 163, 157);
  margin: 1em;
}

.box-highlighted {
  background-color: #9ff5ad !important;
}
.noCvUploaded {
  padding-left: 16px;
  padding-bottom: 32px;
  color: #B8B8B9
}
</style>
