<template>
  <v-dialog v-model="show" width="800">
    <v-card class="pt-6 px-6 pb-3">
      <div class="text-h3 pb-3">
        {{ this.$t("CV.set_default_cv") }}
      </div>
      <div class="text-body-1 mb-2 mt-2">
        {{ this.$t("CV.profile_subtitle") }}
      </div>
      <v-card-actions class="mt-1 justify-center">
        <v-btn elevation="0" @click="show = false">
          {{ this.$t("no") }}
        </v-btn>
        <v-btn color="primary" @click="setDefault">{{ this.$t("yes") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// TODO also show the file name here
export default {
  data() {
    return {
      show: false,
      cvId: null,
    };
  },
  methods: {
    open(cvId) {
      this.show = true;
      this.cvId = cvId;
    },
    setDefault() {
      this.$emit("confirm-set-default", this.cvId);
      this.show = false;
    },
  },
};
</script>

<style></style>
