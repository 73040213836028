<template>
  <app-loader v-if="isLoading" app />
</template>

<script>
import { constant } from "@/store/constant";
const user = constant.user;

export default {
  computed: {
    supportEmail() {
      return this.$store.getters.configs.supportEmail;
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  async mounted() {
    try {
      this.isLoading = true;
      const res = await this.$store.dispatch(user.actions.Billing);
      window.location.href = res.url;

      this.isLoading = false;
    } catch (e) {
      console.error(e);
      this.$notify({
        message: e.message || this.$t("Generic.Messages.Error.common_error", { email: this.supportEmail }),
        type: "danger",
      });
    }
  },
};
</script>
