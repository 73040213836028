<template>
  <v-row>
    <app-header />

    <div class="col-12 page">
      <div class="loop-action-wrapper">
        <Loop :action="action" :loopId="loopId" :clientId="clientId" />
      </div>
    </div>
  </v-row>
</template>
<script>
import Loop from "@/components/Loop.vue";

export default {
  components: {
    Loop,
  },
  props: ["action", "loopId", "clientId"],
};
</script>
<style>
.loop-action-wrapper {
  max-width: 1200px;
  margin: auto;
}
</style>

<style scoped>
@media screen and (max-width: 600px) {
  .page {
    padding-inline: 0px !important;
  }
}
</style>
