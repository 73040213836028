<template>
  <v-dialog v-model="appear" width="400">
    <v-card v-if="loading == false">
      <v-card-title class="headline">
        {{ $t("match.apply-on-demand") }}
      </v-card-title>
      <v-card-text>{{ $t("results.onDemandConfirmation") }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="dismissDialog">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn class="ma-2" color="primary" @click="apply">
          {{ $t("match.onDemandSubmit") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="loading == true"><app-loader /></v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      appear: false,
      loading: false,
    };
  },

  methods: {
    showDialog() {
      this.appear = true;
    },

    dismissDialog() {
      this.appear = false;
    },

    apply() {
      this.loading = true;
      this.$emit("onApply");
    },

    applied() {
      this.loading = false;
      this.appear = false;
    },
  },
};
</script>
