<template>
  <div>
    <div class="row">
      <div class="sidebar-left col-sm-12 col-md-4 col-lg-4">
        <h4 class="text-title-1">
          {{ $t("loop.slides.email.title") }}
        </h4>
        <div class="row">
          <div>
            <div >
              <br>
              {{ $t("loop.slides.email.popular") }}
            </div>
            <div class="col-12">
              <ListMyEmailTemplatesPresentation
                :userTemplates="popularTemplates"
                :selectedTemplateId="selectedTemplateId"
                @select-template="template => selectTemplate(template, 'popularTemplate')"
              />
            </div>
          </div>
          <div class="center-content">
            <div class="pt-12">
              {{ $t("loop.slides.email.my") }}
            </div>
            <div class="col-12">
              <div class="noTemplate" v-if="!userTemplates.length">
                {{ $t("loop.slides.email.noTemplates") }}
              </div>
              <ListMyEmailTemplatesPresentation
                :userTemplates="userTemplates"
                :selectedTemplateId="selectedTemplateId"
                :canDelete="!this.loadingDeleteTemplate"
                :showDeleteButton="true"
                @select-template="template => selectTemplate(template, 'userTemplate')"
                @delete-template="deleteTemplate"
              />
            </div>
          </div>
          <div class="col-md-12 center-content">
            <div class="buttonContainer">
              <v-btn id="search-button" @click="newTemplate">
                {{ $t("loop.slides.email.createButton") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="right-part col-sm-12 col-md-8 col-lg-8">
        <LoopEmailTemplatePresentation :template="activeInView" :editable="editMode" @update="handleEmailBodyChange" />
        <div v-if="hasCV" class="mb-4 ml-1">
          <v-icon color="grey" class="mr-1">info</v-icon>{{ $t("loop.slides.email.attachment") }}
        </div>
        <div v-else class="mb-4 ml-1">
          <v-icon color="red" class="mr-1">error</v-icon>{{ $t("loop.slides.email.noAttachment") }}
        </div>
        <v-btn color="secondary" v-if="editMode == false" @click="editMode = true">
          {{ $t("edit") }}
        </v-btn>
        <v-btn color="success" v-if="editMode" @click="save">
          {{ $t("save") }}
        </v-btn>
      </div>
    </div>

    <LoopTestEmail
      :editMode="editMode"
      :clientId="clientId"
      :subject="selectedTemplate.subject"
      :body="selectedTemplate.body"
      :loopOwner="loopOwner"
      class="col-md-12 mt-9"
    />
  </div>
</template>

<script>
import appSDK from "@/client/AppSDK";
import LoopEmailTemplatePresentation from "@/components/loop/LoopEmailTemplatePresentation.vue";
import { validEmailTemplateMustacheString } from "@/assets/js/utils";
import ListMyEmailTemplatesPresentation from "@/components/loop/EmailTemplates/ListMyEmailTemplatesPresentation.vue";
import LoopTestEmail from "@/components/loop/LoopTestEmail.vue";

export default {
  name: "LoopEmail",
  components: {
    LoopEmailTemplatePresentation,
    ListMyEmailTemplatesPresentation,
    LoopTestEmail,
  },
  props: {
    loopOwner: {
      type: Object,
      default: null,
    },
    clientId: {
      type: String,
    },
  },
  data() {
    return {
      popularTemplates: [],

      templateMode: "userTemplate",

      userTemplates: [],
      selectedTemplateId: null,
      selectedTemplate: {},
      loadingDeleteTemplate: false,

      editMode: false,

      activeInView: {
        name: "",
        subject: "",
        body: "",
        firebaseKey: "",
      },
    };
  },
  computed: {
    hasCV() {
      return this.$store.getters.getLoopData.hasCV;
    },
  },
  async created() {
    await this.getPopularTemplates();
    await this.getUserEmailTemplates();

    if (this.$store.getters.isEditOrDuplicate) {
      const templateKey = this.$store.getters.getLoopData.templateId;
      const currentTemplate = this.userTemplates.find(({ firebaseKey }) => firebaseKey === templateKey);
      if (currentTemplate) {
        this.selectTemplate(currentTemplate, "userTemplate");
      }

      return;
    }

    if (this.userTemplates.length === 0) {
      this.selectTemplate(this.popularTemplates[0], "popularTemplate");
    } else {
      this.selectTemplate(this.userTemplates[0], "userTemplate");
    }
  },
  watch: {
    async userTemplates(val, oldVal) {
      if (val.length === oldVal.length) return;
      this.selectTemplate(this.userTemplates[this.userTemplates.length - 1], "userTemplate");
    },
  },
  methods: {
    async getUserEmailTemplates() {
      this.userTemplates = await appSDK["template.list"](this.clientId);
    },
    handleEmailBodyChange(data) {
      this.activeInView.body = data.replace(/<\s*span[^>]*>(.*?)<\s*\/\s*span>/g, "$1");
    },
    validate() {
      if (this.editMode) {
        return this.$t("errors.clickSave");
      }

      if (this.userTemplates.length === 0) {
        return this.$t("errors.chooseEmailTemplate");
      }

      if (!this.selectedTemplateId) {
        return this.$t("errors.chooseEmailTemplate");
      }

      if (typeof this.selectedTemplateId !== 'string') {
        return this.$t("errors.chooseEmailTemplate");
      }

      return null;
    },
    postData() {
      const errors = this.validate();
      if (errors) {
        this.$amplitude.logEvent("LOOP_CREATION_VALIDATION_ERROR", {
          errorMessage: errors,
          slide: "LoopEmail",
        });
        this.$emit("errors", errors);
        return errors;
      }
      this.$store.commit("setLoopData", {
        mode: this.templateMode,
        templateId: this.selectedTemplateId,
        emailTemplate: this.selectedTemplate,
      });
    },

    async getPopularTemplates() {
      try {
        this.popularTemplates = [];

        const response = await appSDK["emails.getTemplates"]();
        const templates = this.hasCV ? response.templates : response.templatesNoAttachment;

        this.popularTemplates = templates.map((temp, id) => ({
          firebaseKey: id, // id will be a number, needed only for the :key in the child component
          body: temp.body || temp.text,
          ...temp,
        }));
      } catch (e) {
        console.error(e);
      }
    },

    selectTemplate(template, mode) {
      // template is the template object
      // index is the popular index if its popular, otherwise the index among the user templates
      // mode should be 'newTemplate' or 'popularTemplate' to make a new template, or anything else to make updates in place
      const { firebaseKey, name, subject } = template;
      this.templateMode = mode;

      this.activeInView = {
        name,
        subject,
        firebaseKey,
        body: template.text || template.body,
      };

      this.selectedTemplateId = null;
      if (mode !== "newTemplate") {
        this.selectedTemplateId = firebaseKey;
      }
      if (mode === "popularTemplate") {
        this.editMode = true;
      }
      this.selectedTemplate = template;
    },

    async deleteTemplate(template) {
      this.loadingDeleteTemplate = true;
      const { firebaseKey } = template;
      this.$amplitude.logEvent("DELETE_EMAIL_TEMPLATE");
      try {
        await appSDK["template.delete"](this.clientId, firebaseKey);
      } catch (e) {
        if (e?.responseContent?.error === "template-linked-to-loop") {
          this.$notify({
            message: "You can't delete this template, because it is attached to a running loop.",
            type: "danger",
          });
        } else {
          console.error(e);
        }
      } finally {
        await this.getUserEmailTemplates();
      }
      this.loadingDeleteTemplate = false;
    },

    newTemplate() {
      this.$amplitude.logEvent("CREATE_NEW_TEMPLATE_EMAIL");
      this.selectTemplate(
        {
          firebaseKey: null,
          name: "New Template",
          subject: "Email Subject",
          text: "{{COMPANY_NAME}} {{JOB_TITLE}} {{JOB_LOCATION}} {{USER_FIRSTNAME}} {{USER_LASTNAME}}",
        },
        "newTemplate",
      );

      this.editMode = true;
    },

    async save() {
      this.$amplitude.logEvent("SAVE_EMAIL_TEMPLATE");
      if (this.activeInView.body.length < 3 || this.activeInView.name.length < 3 || this.activeInView.subject.length < 3) {
        this.$notify({
          message: this.$t("errors.emailTemplateTooShort"),
          type: "danger",
        });
        return;
      }

      if (!validEmailTemplateMustacheString(this.activeInView.subject)) {
        this.$notify({
          message: this.$t("errors.emailTemplateBodyInvalid"),
          type: "danger",
        });
        return;
      }

      if (!validEmailTemplateMustacheString(this.activeInView.body)) {
        this.$notify({
          message: this.$t("errors.emailTemplateBodyInvalid"),
          type: "danger",
        });
        return;
      }

      this.editMode = false;

      const normalizedText = this.activeInView.body.replace(/<span[^>]*?data-mention-id[^>]*?>(.+?)<\/span>/g, "$1");
      const userTemplate = {
        text: normalizedText,
        name: this.activeInView.name,
        subject: this.activeInView.subject,
      };

      if (this.templateMode === "newTemplate" || this.templateMode === "popularTemplate") {
        try {
          await this.createTemplate(userTemplate);
        } catch (error) {
          console.error("Failed to create template:", error);
        }
      } else if (this.templateMode === "userTemplate") {
        await this.updateTemplate(this.selectedTemplateId, userTemplate);
        this.selectedTemplate = {
          firebaseKey: this.selectedTemplate.firebaseKey,
          body: userTemplate.text,
          subject: userTemplate.subject,
          name: userTemplate.name,
        };
      }

      await this.getUserEmailTemplates();
    },
    async createTemplate(userTemplate) {
      try {
        const result =  await appSDK["template.create"](this.clientId, {
          name: userTemplate.name,
          subject: userTemplate.subject,
          body: userTemplate.text,
        });

        this.$notify({
            message: this.$t("loop.slides.email.successSaved"),
            type: "success",
        });

        return result
      } catch (e) {
        console.error(e);
        throw (e)
      }
    },
    async updateTemplate(id, userTemplate) {
      try {
        await appSDK["template.edit"](this.clientId, id, {
          name: userTemplate.name,
          subject: userTemplate.subject,
          body: userTemplate.text,
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style>
.sidebar-left {
  width: 400px;
  height: 100%;
  display: inline-block;
}
.right-part {
  height: 100%;
  display: inline-block;
}

.selected-box {
  background-color: #9ff5ad;
  color: black;
}

.center-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.noTemplate {
  display: flex;
  justify-content: center;
  color: #B8B8B9
}
.buttonContainer {
  align-self: center;
}
</style>
