<template>
  <v-dialog v-model="isVisible" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Add New Manager
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Manager Profile</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="First name*"
                required
                v-model="model.firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Last name*"
                required
                v-model="model.lastName"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Email*"
                required
                v-model="model.userEmail"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Password*"
                required
                type="password"
                v-model="model.password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="updateDialogStatus(false)">
          Close
        </v-btn>
        <v-btn color="primary" dark @click="handleAddingNewManager">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateManager",
  data: () => ({
    isVisible: false,
    model: {
      firstName: "",
      lastName: "",
      userEmail: "",
      password: "",
    },
  }),
  methods: {
    updateDialogStatus(isVisible) {
      this.isVisible = isVisible;
    },
    handleAddingNewManager() {
      this.$emit("createManager", this.model);
      this.updateDialogStatus(false);
    },
  },
};
</script>