// helpers for the `status` field of a match object
import store from "@/store";

const lockedWhenInFreePlan = {
  tag: store.getters.configs?.showPricingPlans ? "Upgrade to apply" : "Limit exceeded",
  tagTooltip: "We did not apply to this job because your limit had been exceeded",
  tagColor: "red",
  allowsOnDemand: false,
};

const lockedWhenInPaidPlan = {
  tag: "Click to apply",
  tagTooltip: "We did not apply to this job because your limit had been exceeded, but now you can try again",
  tagColor: "default",
  allowsOnDemand: true,
};

const statusMapper = {
  FORM_APPLIED: {
    tag: "Applied via form",
    tagTooltip: "We applied automatically for this job",
    tagColor: "green",
    allowsOnDemand: false,
  },
  SHADOW_APPLIED: {
    tag: "Applied via form",
    tagTooltip: "We applied automatically for this job",
    tagColor: "green",
    allowsOnDemand: false,
  },
  MANUALLY_LOGGED_APPLICATION: {
    tag: "Applied manually",
    tagTooltip: "Manual application",
    tagColor: "green",
    allowsOnDemand: false,
  },
  SENT: {
    tag: "Applied via email",
    tagTooltip: "We have sent an email to this company",
    tagColor: "green",
    allowsOnDemand: false,
  },
  LOCKED: store.getters["user/getUser"]?.pricingPlan === 1 ? lockedWhenInFreePlan : lockedWhenInPaidPlan,
  ONLY_MATCH: {
    tag: "Click to apply",
    tagTooltip: "We did not apply to this job because you had disabled automated applications",
    tagColor: "blue",
    allowsOnDemand: true,
  },
  ONLY_MATCH_WITH_FORM: {
    tag: "Click to apply",
    tagTooltip: "We did not apply to this job because you had disabled automated applications",
    tagColor: "blue",
    allowsOnDemand: true,
  },
  FORM_HAS_QUESTIONS: {
    tag: "Answer questions to apply",
    tagTooltip: "We couldn't apply to this job; please click to answer the questions to proceed",
    tagColor: "blue",
    allowsOnDemand: false,
  },
  PENDING: {
    tag: "Submitting...",
    tagTooltip: "Application is in progress... Check again by refreshing in a couple of minutes.",
    tagColor: "default",
    allowsOnDemand: false,
  },
  ALREADY_SENT: {
    tag: "Skipped, already applied",
    tagTooltip: "We have sent an email to this company from another match",
    tagColor: "green",
    allowsOnDemand: false,
  },
  ALREADY_APPLIED: {
    tag: "Skipped, already applied",
    tagTooltip: "You have applied to this company from another match",
    tagColor: "green",
    allowsOnDemand: false,
  },
  TO_BE_SENT: {
    tag: "Resubmitting...",
    tagTooltip: "Application is in progress",
    tagColor: "default",
    allowsOnDemand: false,
  },
  APPLIED_MANUALLY: {
    tag: "Applied manually",
    tagTooltip: "Manual application",
    tagColor: "default",
    allowsOnDemand: false,
  },
  NO_AVAILABLE_APPLICATION_METHOD: {
    tag: "Unsupported",
    tagTooltip: "We did not manage to apply or send an email",
    tagColor: "default",
    allowsOnDemand: false,
  },
  FAILED: {
    tag: "Failed",
    tagTooltip: "We did not manage to apply or send an email",
    tagColor: "red",
    allowsOnDemand: false,
  },
  CLOSED: {
    tag: "Expired",
    tagTooltip: "This job has been expired",
    tagColor: "red",
    allowsOnDemand: false,
  },
  default: {
    tag: "Apply manually",
    tagTooltip: "We did not manage to apply or send an email",
    tagColor: "default",
    allowsOnDemand: false,
  },
};

export function statusData(status) {
  return statusMapper[status] || statusMapper.default;
}

export function statusDataTag() {
  const array = [];
  for (let status of Object.values(statusMapper)) {
    array.push(status.tag);
  }
  return array;
}
