<template>
  <v-row>
    <app-header />

    <FewResultsLoopAlert
      v-if="this.isUserPartner && tooFewJobs && !isProgress && !isLoading"
      :clientId="clientId"
      :loopId="loopId"
    />

    <div class="col-12">
      <div class="card">
        <div class="card-header" v-if="clientId">
          <v-row>
            <div class="col-md-5">
              <v-btn icon :to="`/clients/${clientId}/loops`" style="margin-top: 0px">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <span>All Loops</span>
            </div>

            <div class="col-md-7"></div>
          </v-row>
        </div>
        <div class="pa-5 pt-3 headerContainer">
          <h4 class="text-subtitle-2">
            <span>{{ $t("loopView.title") }}</span>
          </h4>
          <v-btn :to="getLoopEditPath()">
            <v-icon class="mr-2">edit</v-icon>
            {{ $t("loopView.info.editButton") }}
          </v-btn>
        </div>

        <div class="mb-8">
          <v-tabs fixed-tabs v-model="tab">
            <!-- TODO enable this when info.vue can handle partner info -->
            <v-tab to="info" v-if="!this.isUserPartner">{{ $t("loopView.tabs.info") }}</v-tab>
            <v-tab to="notes" v-if="this.isUserPartner">{{ $t("loopView.tabs.notes") }}</v-tab>
            <v-tab to="matches">{{ $t("loopView.tabs.results") }}</v-tab>
            <v-tab to="stats">{{ $t("loopView.tabs.stats") }}</v-tab>
          </v-tabs>
          
          <v-tabs-items>
            <keep-alive>
              <router-view
                :loading="isLoading"
                :loop="loop"
                :reasonSummary="reasonSummary"
                :cvs="cvs"
                :loopId="loopId"
                :clientId="clientId"
                :isProgress="isProgress"
                @progressEnd="handleProgressEnd"
                ref="currentTab"
              />
            </keep-alive>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </v-row>
</template>
<script>
import appSDK from "@/client/AppSDK";
import Loop from "@/components/Loop.vue";
import FewResultsLoopAlert from "@/components/partners/FewResultsLoopAlert.vue";
import { tooFewJobs } from "@/data/reasons.js";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  name: "LoopView",
  components: {
    Loop,
    FewResultsLoopAlert,
  },
  props: ["loopId", "clientId"],
  data() {
    return {
      tab: null,
      isLoading: false,
      isProgress: false,
      cvs: [],
      loop: {
        firebaseLoopData: {},
      },
      reasonSummary: null,
    };
  },
  mounted() {
    this.isProgress = !!this.$route.query.progress;
  },
  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    currentUser() {
      return this.getUser;
    },
    tooFewJobs() {
      return tooFewJobs(this.reasonSummary);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await Promise.allSettled([this.fetchCVS(), this.fetchLoop(), this.fetchReasonSummary()]);
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handleProgressEnd() {
      this.isProgress = false;
      this.fetchLoop();
      this.fetchReasonSummary();
      this.$refs.currentTab.getDataFromApi?.();
    },
    async fetchLoop() {
      this.loop = await appSDK["loop.show"](this.loopId, this.clientId);
    },
    async fetchCVS() {
      this.cvs = await appSDK["cvs.list"](this.clientId);
    },
    async fetchReasonSummary() {
      const res = await appSDK["loop.reasons"](this.clientId, this.loopId);
      this.reasonSummary = res.reasonSummary;
    },
    getLoopEditPath() {
      if (this.currentUser.role === "admin") {
        return `/clients/${this.clientId}/loops/${this.loopId}/edit`
      } else {
        return `/loops/${this.loopId}/edit`
      }
    }
  },
};
</script>
<style scoped>
.headerContainer {
  display: flex;
  justify-content: space-between;
}

</style>
