<template>
  <v-row>
    <AppHeader />
    <div class="col-12">
      <div class="text-center mt-5">
        <SadFaceIcon />
        <h2 class="title">{{ this.$t("Unauthorized.not_access") }}</h2>
        <h3>
          {{ this.$t("Unauthorized.go_to") }} <router-link to="/">{{ this.$t("page.home_page") }}</router-link>
          {{ this.$t("Unauthorized.or_contact") }} <a :href="'mailto:' + this.supportEmail">{{ this.supportEmail }}</a>
        </h3>
      </div>
    </div>
  </v-row>
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader.vue";
import SadFaceIcon from "@/assets/images/svg/sad_face.vue";
export default {
  components: {
    AppHeader,
    SadFaceIcon,
  },

  computed: {
    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },
  },
};
</script>
