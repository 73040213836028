<template>
  <div>
    <h4 class="text-subtitle-1">{{ $t("loop.slides.review.title") }}</h4>
    <LoopInfoPresentation
      :loop="loop"
      :email="email"
      :cvFilename="loop.selectedCVname"
      :fileUrl="loop.fileUrl"
      :directEmails="loop.directEmails"
      :formApplications="loop.enableFormApplications"
      :subject="emailTemplate.subject"
      :body="emailTemplate.body"
      :isPartner="this.isUserPartner"
    />
  </div>
</template>
<script>
import LoopInfoPresentation from "../ViewLoop/LoopInfoPresentation.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  name: "LoopReview",
  components: { LoopInfoPresentation },
  props: {
    clientId: {},
    loopOwner: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    loop() {
      const loop = this.$store.getters.getLoopData;

      return {
        ...loop,
        phone: loop.phoneData?.e164,
        jobTitle: loop.jobtitle,
        jobLocation: loop.location || "Remote",
        jobLevel: this.$store.getters.jobLevels[loop.joblevel],
        jobType: this.$store.getters.jobTypes[loop.jobtype],
      };
    },
    user() {
      return this.getUser;
    },
    emailTemplate() {
      return this.loop.emailTemplate || {};
    },
  },
  data() {
    return {
      email: null,
    };
  },
  async mounted() {
    this.email = this.loopOwner?.platformEmail;
  },
};
</script>
