<template>
  <div class="page">
    <app-header />
    <label class="header1 page-header">{{ this.$t("page.myApplications") }}</label>

    <div v-if="first_loading">
      <app-loader />
    </div>
    <div v-if="!first_loading">
      <!-- Empty List -->
      <Empty v-if="hasData == false" :title="this.$t('dashboard.Application.empty_application')" />

      <div v-if="hasData == true">
        <!-- Filters -->
        <div class="filterCard">
          <label class="header5-semi-bold">{{ this.$t("Filter.filters") }}</label>
          <div class="filterGroup">
            <Input
              :shadow="true"
              :placeholder="this.$t('Filter.filter_by_company')"
              :value="filters.company"
              @input="value => handleFilter(value, 'company')"
            />
          </div>
        </div>

        <div v-if="loading == true"><app-loader /></div>
        <div v-else>
          <Table :headers="headers" :data="applicationData" class="application-table"></Table>
          <Pagination
            class="footer"
            :selected="page"
            :total="total"
            :itemsPerPage="perPage"
            :itemsPerPageOption="[10, 20]"
            @page-change="handlePagination"
            @page-option-change="handlePageOption"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { constant } from "@/store/constant";
import { Empty, Pagination, Table, Input } from "@/framework/index";
import { formatDateToLocalMMDDYY } from "@/helpers/dateUtils";
import { eStatus } from "@/helpers/enums/eApplication";

const userStore = constant.user;

export default {
  name: "My_Application",

  components: {
    Pagination,
    Empty,
    Table,
    Input,
  },

  data() {
    return {
      total: 0,
      perPage: 10,
      page: 1,
      applicationData: [],
      filters: {
        company: "",
      },
      hasData: false,
      first_loading: false,
      loading: true,
    };
  },

  async mounted() {
    this.first_loading = true;
    await this.getMyApplication({ init: true });
    this.first_loading = false;
  },

  computed: {
    headers() {
      return [
        { name: "jobTitle", value: this.$t("Generic.Headers.job_title") },
        { name: "jobLocation", value: this.$t("Generic.Headers.location") },
        { name: "company", value: this.$t("Generic.Headers.company") },
        { name: "appliedAt", value: this.$t("Generic.Headers.appliedAt") },
        { name: "status", value: this.$t("results.headers.status") },
      ];
    },
  },

  methods: {
    async getMyApplication({ init = false }) {
      try {
        this.loading = true;
        const optionsObj = {
          per_page: this.perPage,
          page: this.page,
        };
        if (this.filters.company) {
          optionsObj.companyFilter = this.filters.company;
        }
        const response = await this.$store.dispatch(userStore.actions.GetMyApplication, optionsObj);
        this.applicationData = [];
        for (let application of response.applications) {
          let status = "";
          if (application.status === eStatus.Form_Applied) {
            status = this.$t("dashboard.applied_vie_form").toString();
          } else {
            status = this.$t("dashboard.applied_via_email").toString();
          }
          this.applicationData.push({
            jobTitle: application.jobTitle,
            jobLocation: application.jobLocation,
            company: application.company,
            appliedAt: formatDateToLocalMMDDYY(application.matchCreatedAt),
            status,
          });
        }
        this.total = response.total;
        if (init && response.total > 0) {
          this.hasData = true;
        }
        this.$forceUpdate();
      } catch (e) {
        console.error(e);
      }

      this.loading = false;
    },

    async handlePagination(value) {
      this.page = value;
      await this.getMyApplication({});
    },

    async handlePageOption(value) {
      this.perPage = value;
      await this.getMyApplication({});
    },

    async handleFilter(value) {
      this.filters.company = value;
      await this.getMyApplication({});
    },
  },
};
</script>

<style scoped>
.application-table {
  max-height: 60%;
  height: 60vh;
}

.filterGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filterCard {
  margin-top: 20px;
  margin-left: -10px;
  width: calc(100% + 30px);
  background-color: white;
  padding-inline: 14px;
  padding-block: 5px;
  margin-bottom: 5px;
  border-bottom: 0.5px solid #3f51b5;
}
</style>
