<template>
  <v-dialog
    v-model="dialog"
    content-class="full-screen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="800px"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        Edit SMTP Settings for {{ email }}
      </v-card-title>
      <v-card-text>
        <div class="ma-2 mt-4">
          <v-form @submit="save" ref="myForm">
            <div class="row">
              <div class="col-md-12 pb-2">
                <v-select
                  outlined
                  v-model="smtpService"
                  @change="changeSmtpService"
                  :items="smtpServices"
                  item-color="#35383c"
                  label="SMTP Service"
                ></v-select>
              </div>
              <div class="col-md-6 pb-2">
                <v-text-field
                  :label="'Host'"
                  :disabled="smtpService !== 'other'"
                  v-model="host"
                  outlined
                  :rules="[() => !!host || 'Host is required']"
                  autocomplete="off"
                />
              </div>
              <div class="col-md-6 pb-2">
                <v-text-field
                  :label="'Port'"
                  :disabled="smtpService !== 'other'"
                  v-model="port"
                  autocomplete="off"
                  :rules="[() => !!port || 'Port is required']"
                  outlined
                />
              </div>
              <div class="col-md-12 pb-2">
                <v-checkbox
                  class="mt-0 pt-0"
                  :disabled="smtpService !== 'other'"
                  label="Secure Connection"
                  v-model="secure"
                  :messages="
                    secure
                      ? 'A secure connection will be used for the SMTP connection'
                      : 'No secure connection is needed for the SMTP connection'
                  "
                ></v-checkbox>
              </div>
              <div class="col-md-6 pb-2">
                <v-text-field
                  :label="'Username'"
                  v-model="username"
                  autocomplete="off"
                  :rules="[() => !!username || 'Username is required']"
                  outlined
                />
              </div>
              <div class="col-md-6 pb-2">
                <v-text-field
                  :label="'Password'"
                  :type="hidePassword ? 'password' : 'text'"
                  v-model="password"
                  outlined
                  :rules="[() => !!password || 'Password is required']"
                  autocomplete="new-password"
                >
                  <v-btn
                    class="mt-n1"
                    slot="append"
                    icon
                    small
                    @click="hidePassword = !hidePassword"
                  >
                    <app-icon :name="hidePassword ? 'eye' : 'eyeOff'" />
                  </v-btn>
                </v-text-field>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> Cancel </v-btn>
        <v-btn rounded color="primary" :loading="loading" @click.prevent="save">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as yup from "yup";
import appSDK from "@/client/AppSDK";

const defaults = {
  gmail: {
    host: "smtp.gmail.com",
    port: 587,
    secure: false,
  },
  other: {
    host: "",
    port: "",
    secure: true,
  },
};

export default {
  components: {},
  data() {
    return {
      dialog: false,
      email: null,
      smtpServices: [
        { value: "gmail", text: "Gmail" },
        { value: "other", text: "Other" },
      ],
      smtpService: "gmail",
      host: "",
      port: "",
      secure: true,
      username: "",
      password: "",
      clientId: null,
      loading: false,
      hidePassword: true,
    };
  },
  computed: {},

  methods: {
    open(client) {
      this.email = client.email;
      this.clientId = client.uniqueId;
      this.dialog = true;
      this.username = "";
      this.password = "";
      this.$refs.myForm?.resetValidation();
      this.smtpService = "gmail";
      this.changeSmtpService("gmail");

      appSDK["user.get"](this.clientId)
        .then((user) => {
          if (!user.smtpSettings.username) {
            return;
          }
          this.smtpService = "other";
          this.username = user.smtpSettings.username;
          this.host = user.smtpSettings.host;
          this.port = user.smtpSettings.port;
          this.secure = user.smtpSettings.secure;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async save() {
      const schema = yup.object().shape({
        host: yup.string().required("Host is required"),
        port: yup.number().required("Port is required"),
        secure: yup
          .boolean()
          .required("Secure connection selection is required"),
        username: yup.string().required("Username is required"),
        password: yup.string().required("Password is required"),
      });

      try {
        this.loading = true;

        const body = {
          host: this.host,
          port: this.port,
          secure: this.secure,
          username: this.username,
          password: this.password,
        };

        await schema.validate(body);

        const parsed = schema.cast(body);

        await appSDK["partner.childUser.updateSMTP"](this.clientId, parsed);
        this.$notify({
          message: "The details were updated successfully",
          type: "success",
        });
        this.dialog = false;
      } catch (e) {
        if (e?.responseContent?.error === 'smtp-verification-failed') {
          this.$notify({
            message:
              "The credentials you added were invalid. Please try again.",
            type: "danger",
          });
          return;
        }

        if (e?.name !== "ValidationError") {
          console.error(e);
        }
        this.$notify({
          message:
            e?.errors?.[0] ||
            "There was an error processing the request; our team is notified. Please try again later.",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    changeSmtpService(val) {
      this.host = defaults[val].host;
      this.port = defaults[val].port;
      this.secure = defaults[val].secure;
      this.$refs.myForm?.resetValidation();
    },
  },
};
</script>

<style>
/* these styles should not be scoped */
.full-screen {
  width: auto;
}
</style>