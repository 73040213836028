import NotFound from "@/pages/NotFound.vue";
import NotAuthorized from "@/pages/NotAuthorized.vue";

import loop from "./partials/loop";
import user from "./partials/user";
import dashboard from "./partials/dashboard";
import auth from "./partials/auth";
import partner from "./partials/partner";

const notAuthorized = [{ path: "/not-authorize", component: NotAuthorized }];
const notFound = [{ path: "*", component: NotFound }];

export default [loop, user, dashboard, auth, partner, notAuthorized, notFound].flat();
