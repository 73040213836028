<template>
  <v-dialog v-model="show" width="400">
    <v-card class="pt-6 px-6 pb-3">
      <div class="text-h3 pb-3">{{ $t("Generic.Common.are_you_sure") }}</div>
      <div class="text-body-1">
        {{ $t("results.trash") }}
      </div>
      <v-checkbox v-model="rememberChoice" :label="`Remember my choice`"></v-checkbox>
      <v-card-actions class="justify-center">
        <v-btn elevation="0" @click="show = false">
          {{ $t("no") }}
        </v-btn>
        <v-btn color="primary" @click="confirmHandler">{{ $t("yes") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import appSDK from "@/client/AppSDK";

export default {
  data() {
    return {
      show: false,
      matchId: null,
      rememberChoice: false,
    };
  },
  methods: {
    open(matchId) {
      if (localStorage.getItem("rememberDeleteMatchDialog") === "true") {
        this.deleteMatch(matchId);
        return;
      }
      this.show = true;
      this.matchId = matchId;
    },
    confirmHandler() {
      if (this.rememberChoice) {
        localStorage.setItem("rememberDeleteMatchDialog", "true");
      }
      this.deleteMatch(this.matchId);
    },
    deleteMatch(matchId) {
      appSDK["match.delete"](matchId)
        .then(() => {
          this.$emit("deleted-match");
          this.$notify({
            message: "Match deleted",
            type: "success",
          });
          this.show = false;
        })
        .catch(e => {
          console.error(e);
          this.$notify({
            message: e.message || this.$t("Generic.Messages.Error.common_error", { email: this.supportEmail }),
            type: "danger",
          });
        });
    },
  },
};
</script>

<style></style>
