<template>
  <div>
    <h4 class="h2">{{ $t("loop.slides.settings.title") }}</h4>
    <div class="row">
      <div class="col-12">
        <div class="row" v-if="withoutApplications">
          <div class="col-9">
            <h4>
              {{ $t("loop.slides.settings.emails.title") }}
            </h4>
            <span class="text-body-2">
              {{ $t("loop.slides.settings.emails.description") }}
            </span>
          </div>
          <div class="col-3">
            <v-switch
              class="loop-settings-toggle"
              color="primary"
              v-model="enableEmailChecks"
            />
          </div>
        </div>

        <div class="row" v-if="!onlyEmail">
          <div class="col-9">
            <h4>
              {{ $t("loop.slides.settings.applications.title") }}
            </h4>
            <span class="text-body-2">
              {{
                $t("loop.slides.settings.applications.description", {
                  brand: $store.getters.brand,
                })
              }}
              <!-- TODO outdated link -->
              <a
                :href="exampleImage"
                target="_blank"
                rel="noopener noreferrer"
                @click.stop.prevent="$refs.exampleFormDialog.open()"
                >{{ $t("loop.slides.settings.applications.description2") }}</a
              >
              {{$t("loop.slides.settings.applications.description3")}}
            </span>
          </div>
          <div class="col-3">
            <v-switch
              class="loop-settings-toggle"
              color="primary"
              v-model="enableFormApplications"
              :disabled="!hasCV"
            />
          </div>
          <div v-if="!hasCV" class="mb-6 ml-1">
            <v-icon color="red" class="mr-1">error</v-icon>{{ $t("loop.slides.settings.applications.disabled") }}
          </div>
        </div>

        <!-- New Chrome Extension Section -->
        <div class="row" v-if="showExtensionSection">
          <div class="col-9">
            <h4>{{ $t("loop.slides.settings.extension.title") }}</h4>
            <span class="text-body-2">
              {{ $t("loop.slides.settings.extension.description") }}
            </span>
          </div>
          <div class="col-3">
            <v-btn
              class="loop-settings-toggle"
              color="primary"
              :href="chromeExtensionLink"
              target="_blank"
            >
              {{ $t("loop.slides.settings.extension.buttonText") }}
            </v-btn>
          </div>
        </div>

        <div v-if="enableFormApplications">
          <div class="row">
            <div class="col-12">
              <div class="text-overline">
                {{ $t("loop.slides.settings.additional.title") }}
              </div>
              <span class="text-body-2">
                {{ $t("loop.slides.settings.additional.description") }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4>
                {{ $t("phone") }} *
              </h4>
              <MazPhoneNumberInput v-model="phone" class="phone-input" @update="onPhoneUpdate" size="md" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4>{{ $t("city") }} *</h4>
              <v-text-field v-model="country" :placeholder="'Location that you are based in'" outlined />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <h4>
                {{ $t("loop.slides.settings.coverLetter.title") }}
              </h4>
              <v-textarea
                outlined
                v-model="coverLetter"
                type="text"
                name="text"
                :rows="12"
                dense
                :placeholder="$t('loop.slides.settings.coverLetter.description')"
              ></v-textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LoopCompanies class="mb-7" ref="loopCompanies" />
    <LoopKeywords class="my-7" ref="loopKeywords" />
    <LoopExcludeKeywords class="my-7" ref="loopExcludeKeywords" />
    <LoopJobMatch class="my-7" ref="loopJobMatch" />

    <ExampleFormDialog ref="exampleFormDialog" />
  </div>
</template>

<script>
import { MazPhoneNumberInput } from "maz-ui";

import LoopCompanies from "@/components/loop/LoopCompanies.vue";
import LoopKeywords from "@/components/loop/LoopKeywords.vue";
import LoopJobMatch from "@/components/loop/LoopJobMatch.vue";
import ExampleFormDialog from "@/components/ExampleFormDialog.vue";
import appSDK from "@/client/AppSDK";
import exampleImage from "@/assets/images/application-form-example.png";

import { validEnglishString } from "@/assets/js/utils";
import LoopExcludeKeywords from "./LoopExcludeKeywords.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  name: "LoopSettings",
  props: {
    user: {
      type: Object,
      default: null,
    },
    clientId: {
      type: String,
    },
    loopOwner: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    exampleImage,
    enableEmailChecks: process.env.VUE_APP_DEFAULT_AUTOAPPLY === "on", //enable or disable the two layers of checking emails
    enableFormApplications: false,
    phone: null,
    phoneData: null,
    country: null,
    coverLetter:
      "The role is very appealing to me, and I believe that my experience and education make me a highly competitive candidate for this position. Please see my resume for additional information on my experience. \n\nThank you for your time and consideration. \nI look forward to speaking with you about this employment opportunity.",
      // enableFollowUp: process.env.VUE_APP_DEFAULT_AUTOAPPLY === "on",
      // formApplicationsFollowUp:
      // "Hello,\nI just applied online using your portal. Please let me know if you would be available next week to plan a call in order to discuss more.\nThanks"
    chromeExtensionLink: "https://chrome.google.com/webstore/detail/apply-tool/aleecdoflfiofldfpipjhcjoigokflmp",
  }),
  components: {
    MazPhoneNumberInput,
    LoopCompanies,
    LoopKeywords,
    LoopJobMatch,
    ExampleFormDialog,
    LoopExcludeKeywords,
  },
  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    hasCV() {
      return this.$store.getters.getLoopData?.hasCV;
    },
    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
    withoutApplications() {
      return !this.$store.getters.configs?.disableApplications;
    },
    showExtensionSection() {
      return (
        this.$store.getters.configs?.showExtensionForRoles &&
        this.$store.getters.configs?.showExtensionForRoles.includes(this.getUser.role)
      );
    },
  },
  watch: {
    enableEmailChecks(val) {
      this.logEvent("ENABLE_EMAIL_CHECKS", val);
    },
    enableFormApplications(val) {
      this.logEvent("ENABLE_FORM_APPLICATIONS", val);
    },
    // enableFollowUp(val) {
    // this.logEvent("ENABLE_FOLLOW_UP", val);
    // }
  },
  created() {
    this.enableFormApplications = process.env.VUE_APP_DEFAULT_AUTOAPPLY === "on" && this.hasCV;
  },
  async mounted() {
    if (this.$store.getters.isEditOrDuplicate) {
      const loopData = this.$store.getters.getLoopData;
      this.enableEmailChecks = loopData.directEmails;
      this.enableFormApplications = loopData.enableFormApplications ? loopData.enableFormApplications : false;
      if (this.enableFormApplications) {
        this.phone = loopData.phone;
        this.coverLetter = loopData.coverLetter;
      }
    }
    this.country = this.loopOwner.country;
    if (this.loopOwner.phoneData) {
      this.phone = this.loopOwner.phoneData.e164;
    }
  },
  methods: {
    postData() {
      const errors = this.validate();
      if (errors) {
        this.$amplitude.logEvent("LOOP_CREATION_VALIDATION_ERROR", {
          errorMessage: errors,
          slide: "LoopSettings",
        });
        this.$emit("errors", errors);
        return errors;
      }

      const payload = {
        directEmails: this.enableEmailChecks, // enable/disable the two step process or not
        enableFormApplications: this.enableFormApplications,
        phoneData: this.enableFormApplications ? this.phoneData : null,
        coverLetter: this.enableFormApplications ? this.coverLetter : null,
        country: this.enableFormApplications ? this.country : null,
      };

      if (this.isUserPartner) {
        appSDK["partner.childUser.update"](this.clientId, {
          country: this.country,
        });
      } else {
        appSDK["user.updateInfo"]({
          country: this.country,
        });
      }
      this.$store.commit("setLoopData", payload);
      this.$refs.loopCompanies.postData();
      this.$refs.loopKeywords.postData();
      this.$refs.loopExcludeKeywords.postData();
      this.$refs.loopJobMatch.postData();
    },

    validate() {
      if (this.enableFormApplications) {
        if (!this.phoneData?.isValid) {
          return this.$t("errors.invalidPhone");
        }

        if (!validEnglishString(this.country)) {
          return this.$t("errors.shouldEnglishLocation");
        }

        if (!this.coverLetter) {
          return this.$t("errors.coverLetter");
        }
      }

      if (this.coverLetter.includes("{{") || this.coverLetter.includes("}}")) {
        return this.$t("errors.coverLetterVariables");
      }

      return null;
    },

    logEvent(name, value) {
      this.$amplitude.logEvent(`${name}:${value}`);
    },

    onPhoneUpdate(data) {
      this.phoneData = data;
    },
  },
};
</script>

<style>
.loop-settings-toggle {
  float: right;
}
textarea.form-control {
  max-height: unset !important;
  resize: vertical !important;
}
.maz-input {
  font-size: 0.75rem;
}
.maz-base-component {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}
.maz-input__input {
  font-size: 0.75rem;
}
.maz-input__icon {
  font-size: inherit;
}

.country-selector {
  width: 45% !important;
}
</style>
