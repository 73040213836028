<template>
  <v-row justify="center">
    <app-header></app-header>
    <div v-if="renderActiveSubscription">
      <v-alert class="mx-5 mt-4" dense type="success" outlined text :icon="false">
        <div class="alert_messaging">
          <label>{{ this.$t("plans.Common.already_active_subscription") }}</label>
        </div>
      </v-alert>
    </div>
    <div class="col-6" v-if="renderBillingForm">
      <div class="subtitleBlock subtitleBlock-register">
        <label class="header2-semi-bold">{{ $t("Payment.Common.billing") }} {{ $t("Generic.Common.details") }}</label>
      </div>

      <form @submit.prevent="subscribe">
        <Input
          class="input-form"
          :title="$t('Generic.Headers.company') + ' ' + $t('Generic.Headers.name')"
          :required="true"
          :type="'text'"
          :autocomplete="'organization'"
          :error="this.errors.companyName"
          :value="this.model.companyName"
          :placeholder="this.$t('Generic.Headers.company')"
          @input="value => handleInput(value, 'companyName')"
        />
        <Input
          class="input-form"
          :title="$t('Payment.Common.billing') + ' ' + $t('Generic.Form.email')"
          :required="true"
          :type="'email'"
          :autocomplete="'email'"
          :error="this.errors.billingEmail"
          :value="this.model.billingEmail"
          :placeholder="this.$t('Generic.Form.email')"
          @input="value => handleInput(value, 'billingEmail')"
        />
        <div class="address-details-container" >
          <label class="header1-semi-bold">{{ $t("plans.addressDetails") }}</label>
            <Input
              class="input-form"
              :type="'text'"
              :autocomplete="'address'"
              :error="this.errors.address"
              :value="this.model.address"
              :placeholder="$t('plans.address')"
              @input="value => handleInput(value, 'address')"
            />
            <Input
              class="input-form"
              :type="'text'"
              :autocomplete="'address-level2'"
              :error="this.errors.city"
              :value="this.model.city"
              :placeholder="$t('city')"
              @input="value => handleInput(value, 'city')"
            />
            <Input
              class="input-form"
              :type="'text'"
              :autocomplete="'address-level1'"
              :error="this.errors.state"
              :value="this.model.state"
              :placeholder="$t('plans.state')"
              @input="value => handleInput(value, 'state')"
            />
            <div class="input-form">
              <v-select
                :label="$t('plans.country')"
                v-model="model.country"
                :items="countryOptions"
                :error="!!this.errors.country"
                required
                outlined
                :class="{'error-fieldset': !!errors.country}"
              ></v-select>
              <div v-if="this.errors.country" class="error-message header7">{{ this.errors.country }}</div>
            </div>
            <Input
              class="input-form"
              :type="'text'"
              :autocomplete="'postal-code'"
              :error="this.errors.postalCode"
              :value="this.model.postalCode"
              :placeholder="$t('plans.zip')"
              @input="value => handleInput(value, 'postalCode')"
            />
        </div>
        <Input
          class="input-form"
          :title="$t('plans.vat')"
          :required="true"
          :type="'text'"
          :autocomplete="'off'"
          :error="this.errors.vatValue"
          :value="this.model.vatValue"
          :placeholder="$t('plans.vat')"
          @input="value => handleInput(value, 'vatValue')"
        />
        <div class="input-form">
          <label class="input-title header5">{{ $t("plans.vatType") }} <a>*</a></label>
          <v-select
            v-model="model.vatType"
            :items="vatOptions"
            :label="$t('plans.vatType')"
            :error="!!this.errors.vatType"
            :required="true"
            outlined
            :class="{'error-fieldset': !!errors.country, 'pt-2': true}"
          ></v-select>
          <div v-if="this.errors.vatType" class="error-message header7">{{ this.errors.vatType }}</div>
        </div>
        <Button
          class="mt-8"
          :title="this.$t('submit')"
          :type="'submit'"
          :outlined="false"
          :fullWidth="true"
          :loading="loading"
        ></Button>
        <div class="stripeContainer">
          <a class="Link Link--primary" href="https://stripe.com" target="_blank" rel="noopener">
            <div class="Footer-PoweredBy-Text Text-color--gray400 Text-fontWeight--400">
              <div class="Footer-PoweredBy Text-fontSize--12" style="min-width: 102px; white-space: pre;">
                Powered by 
                <svg focusable="false" width="33" height="15" role="img" aria-labelledby="stripe-title">
                  <title id="stripe-title">Stripe</title>
                  <g fill-rule="evenodd">
                    <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z">
                    </path>
                  </g>
                </svg>
            </div>
          </div>
        </a></div>
      </form>
    </div>
  </v-row>
</template>

<script>
import { Button, Input } from "@/framework/index";
import { constant } from "@/store/constant";
import {mapGetters} from "vuex";
import {
  validateForm,
  handleInput,
  validateEmail,
  validateCompany,
  validateAddress,
  validateCity,
  validateState,
  validateCountry,
  validatePostalCode,
  validateVAT,
  validateVatType
} from "@/helpers/utils/formUtils";
import { countriesAndVats } from "@/assets/data/vatTypes" 

const user = constant.user;

export default {
  name: "PartnerSignUp",
  components: {
    Input,
    Button,
  },
  data() {
    return {
      model: {
        billingEmail: "",
        companyName: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        vatValue: "",
        vatType: "",
      },
      errors: {
        billingEmail: "",
        companyName: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        vatValue: "",
        vatType: ""
      },
      loading: false,
      countriesAndVats
    };
  },

  computed: {
    ...mapGetters("user", {getUser: user.getters.getUser}),


    renderBillingForm() {
      return this.getUser.subscriptionStatus === "to_be_paid" || this.getUser.subscriptionStatus === "pending";
    },

    renderActiveSubscription() {
      return this.getUser.subscriptionStatus === "active";
    },

    countryOptions() {
      const uniqueCountries = new Set(this.countriesAndVats.map(item => item[0]));
      return Array.from(uniqueCountries).map(country => ({
        text: country,
        value: country
      }));
    },

    vatOptions() {
      return this.countriesAndVats
        .filter(item => item[0] === this.model.country)
        .map(item => ({
          value: item[1],
          text: item[2]
        }));
    },
  },

  methods: {
    getBaseUrl() {
      return window.location.origin;
    },
    
    async subscribe() {
      if (validateForm(this)) {
        this.loading = true;
        try {
          const response = await this.$store.dispatch(user.actions.SubscribePartnerBilling, {
            baseUrl: this.getBaseUrl(),
            priceId: this.getUser.priceId,
            ...this.model
          });

          window.open(response.url, '_self');
        } catch (e) {
          this.$notify({
            message: this.$t("Generic.Messages.Error.sign_in", { email: this.supportEmail }),
            type: "danger",
          });
        }
        this.loading = false;
      } else {
        validateEmail(this, this.model.billingEmail);
        validateCompany(this, this.model.companyName);
        validateAddress(this, this.model.address);
        validateCity(this, this.model.city);
        validateState(this, this.model.state);
        validateCountry(this, this.model.country);
        validatePostalCode(this, this.model.postalCode);
        validateVAT(this, this.model.vatValue);
        validateVatType(this, this.model.vatType);
      }
    },

    handleInput(value, name) {
      handleInput(this, value, name);
    },
  },
};
</script>

<style scoped>
@import "../../pages/auth/register/register.css";

.address-details-container {
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 16px;
  border-radius: 10px;
}

.v-input >>> .error--text {
  color: #7C7C7C !important;
  caret-color: #7C7C7C !important;
}

.error-fieldset >>> fieldset {
  border: 1px solid #D8685E !important;
}

.Footer-PoweredBy {
  display: flex;
  align-items: center;
}

.stripeContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.Text-color--gray400 {
  color: #7C7C7C;
}

.Footer-PoweredBy-Text {
  display: inline-block;
}

.Text-fontWeight--400 {
  font-weight: 400;
}

.Text-fontSize--12 {
    font-size: 12px;
}
</style>
