<template>
  <div class="px-3 pt-3 pb-3 kanban-job-card">
    <div class="d-flex justify-space-between">
      <div>
        <div class="text-body-1">{{ job.company }}</div>
        <div class="text-body-2">{{ job.jobTitle }}</div>
      </div>
      <v-icon> description </v-icon>
    </div>

    <div class="d-flex mt-2 justify-space-between items-center">
      <span class="text-caption text--secondary date-text">{{ date }}</span>

      <v-spacer></v-spacer>

      <v-chip v-if="job.type === 'system'" :color="color" outlined small> {{ tag }} </v-chip>
    </div>
  </div>
</template>

<script>
import { timestampToDateString } from "@/assets/js/utils";
import { statusData } from "@/data/statuses.js";

export default {
  components: {},
  props: {
    job: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    date() {
      return timestampToDateString(this.job.updatedAt);
    },
    tag() {
      return statusData(this.job.match.status).tag;
    },
    color() {
      return statusData(this.job.match.status).tagColor;
    },
  },
};
</script>

<style scoped>
.kanban-job-card {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: #fff;
  background-color: #fff;
}
.date-text {
  height: 24px;
  line-height: 24px;
  white-space: nowrap;
}
</style>
