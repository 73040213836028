<template>
  <v-card class="pa-5">
    <v-text-field class="headline my-3" v-model="job.jobTitle" outlined :label="$t('Generic.Headers.job_title')">
    </v-text-field>
    <v-text-field v-model="job.company" outlined dense v-bind:label="$t('Generic.Headers.company_name')"> </v-text-field>
    <v-text-field v-model="job.location" outlined dense :label="$t('Generic.Headers.location')"> </v-text-field>
    <v-banner rounded single-line class="mb-5" v-if="job.type === 'system'">
      <span>{{ $t("kanban.kanban_title") }}</span>
      <template v-slot:actions>
        <div class="d-flex">
          <v-btn color="primary" text :to="`/match/${job.matchId}`"> {{ $t("kanban.go_to_match") }} </v-btn>
        </div>
      </template>
    </v-banner>

    <v-divider class="pb-5" v-if="job.type !== 'system'"></v-divider>
    <div class="headline pb-5">{{ $t("Generic.Common.details") }}</div>

    <div class="d-flex">
      <v-text-field v-model="job.url" outlined dense label="URL"> </v-text-field>
      <v-btn icon :href="job.url" target="_blank"><v-icon>open_in_new</v-icon></v-btn>
    </div>

    <v-textarea :label="$t('kanban.job_description')" v-model="job.jobDescription" rows="5" outlined></v-textarea>
    <v-divider class="pb-5"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn outlined v-if="mode === 'edit'" @click="$emit('delete')" class="px-3 mr-2">{{
        $t("kanban.remove_From_Board")
      }}</v-btn>

      <div style="min-width: 120px">
        <v-btn large v-if="mode === 'edit'" dark block @click="$emit('submit', job)">{{ $t("Generic.Common.done") }}</v-btn>
        <v-btn large v-if="mode === 'create'" dark block @click="$emit('submit', job)">{{
          $t("Generic.Common.create")
        }}</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    initialJob: {
      type: Object,
      required: false,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      job: {},
    };
  },
  created() {
    if (this.mode === "edit") {
      this.job = { ...this.initialJob };
    }
  },
  mounted() {
    console.log("mounted");
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.job = {};
  },
};
</script>

<style></style>
