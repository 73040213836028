<template>
  <v-skeleton-loader v-if="cvs === undefined" class="mx-auto" type="sentences"></v-skeleton-loader>
  <div v-else class="outer-cvs">
    <div v-if="!cvs.length" class="col-12">{{ $t("SelectCV.noCVs") }}</div>
    <div v-if="cvs.length" class="ma-2 pa-2 d-flex align-center cv-tile" v-for="cv in cvs" :key="cv.cvId">
      <span style="line-height: 42px; font-weight: 500" class="text-h4 px-2 text-truncate">{{ cv.fileName }}</span>
      <!-- TODO -->
      <v-spacer></v-spacer>
      <!-- <v-btn text tile :href="cv.fileUrl">View</v-btn> TODO -->
      <v-progress-circular v-if="!canSetDefault" :width="3" color="green" indeterminate></v-progress-circular>
      <v-btn v-if="canSetDefault" text tile @click="canSetDefault ? $emit('set-default-cv', cv.cvId) : null">
        {{ $t("Buttons.set_as_default_upper") }}
      </v-btn>
      <v-progress-circular v-if="!canDelete" :width="3" indeterminate color="green"></v-progress-circular>
      <v-btn v-if="canDelete" text tile color="#D8685E" @click="canDelete ? $emit('delete-cv', cv.cvId) : null">{{
        $t("Buttons.delete_upper")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListCVsPresentation",
  props: {
    cvs: Array,
    canDelete: Boolean,
    canSetDefault: Boolean,
  },
  data: () => ({}),
  created() {},
  methods: {},
};
</script>

<style scoped>
.cv-tile {
  background: #f8f8fa 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}
.outer-cvs {
  max-height: 400px;
  overflow-y: auto;
}
</style>
