<template>
  <v-alert
    dense
    text
    type="info"
    outlined
    v-if="clientName"
    class="w-100 mx-1"
    :color="primaryColor"
  >
    <template #append>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            to="/clients"
            icon
            x-small
            color="red"
            v-bind="attrs"
            v-on="on"
          >
            <app-icon name="close" />
          </v-btn>
        </template>
        <span>Back to all clients</span>
      </v-tooltip>
    </template>
    <span
      >Viewing as: <b>{{ clientName || clientId }}</b></span
    >
  </v-alert>
</template>

<script>
import appSDK from "@/client/AppSDK";

export default {
  name: "ClientAlert",
  data: () => ({
    client: {},
  }),
  props: {
    clientId: {
      required: true,
    },
  },
  created() {
    this.fetchClientInfo();
  },
  computed: {
    clientName() {
      return `${this.client.firstName} ${this.client.lastName} ${
        this.client.email ? `(${this.client.email})` : ""
      }`;
    },
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
  },
  methods: {
    async fetchClientInfo() {
      try {
        const results = await appSDK["user.get"](this.clientId);
        this.client = { ...results };
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
