<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="viewClients"
      :options.sync="options"
      :server-items-length="totalManagers"
      class="elevation-1"
      item-key="uniqueId"
      :loading="loading"
      :item-class="itemRowBackground"
      :no-data-text="$t('partner.noManagers')"
    >
      <template v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <app-icon name="dotsVertical" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="deactivateManager(item)"
              v-if="item.status === 'active'"
            >
              <v-list-item-title> Deactivate manager </v-list-item-title>
            </v-list-item>
            <v-list-item @click="activeManager(item)" v-else>
              <v-list-item-title> Activate manager </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>{{ item.createdAtString }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import appSDK from "@/client/AppSDK";

export default {
  data() {
    return {
      loading: false,
      showDeactivated: false,
      totalManagers: 0,
      managers: [],
      options: {},
      footerProps: { disableItemsPerPage: true, disablePagination: true },
      headers: [
        {
          text: this.$i18n.t("partner.name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Email", align: "start", sortable: true, value: "email" },
        {
          text: this.$i18n.t("partner.createdAt"),
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: this.$i18n.t("partner.actions"),
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (
          newOptions.page === undefined ||
          newOptions.itemsPerPage === undefined
        ) {
          return;
        }
        if (
          newOptions.page === oldOptions.page &&
          newOptions.itemsPerPage === oldOptions.itemsPerPage
        ) {
          return;
        }
        this.getManagers();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    viewClients() {
      return this.managers.map((client) => {
        const createdAt = client.createdAt ? new Date(client.createdAt) : null;
        return {
          ...client,
          name: `${client.firstName} ${client.lastName}`,
          createdAt,
          createdAtString: createdAt ? createdAt.toDateString() : "-",
        };
      });
    },
    showSmtpSettings() {
      return this.$store.getters.configs?.showSmtpSettings;
    },
  },
  methods: {
    async getManagers(showDeactivated) {
      this.showDeactivated = showDeactivated;
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      try {
        const res = await appSDK["partner.childUser.list"]({
          perPage: itemsPerPage,
          role: "manager",
          page,
          status: showDeactivated ? "all" : "active",
        });
        this.managers = res.users;
        this.totalManagers = res.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async deactivateManager(item) {
      try {
        const { email, uniqueId } = item;
        if (!uniqueId) {
          throw new Error("uniqueId is not present");
        }
        await appSDK["partner.childUser.status"](uniqueId, {
          status: "inactive",
        });
        this.$notify({
          message: `Manager ${email} was successfully deactivated`,
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$notify({
          message: "There was an error deactivating the manager.",
          type: "danger",
        });
      }

      this.getManagers(this.showDeactivated);
    },
    async activeManager(item) {
      try {
        const { email, uniqueId } = item;
        if (!uniqueId) {
          throw new Error("uniqueId is not present");
        }
        await appSDK["partner.childUser.status"](uniqueId, {
          status: "active",
        });
        this.$notify({
          message: `Manager ${email} was successfully activated`,
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$notify({
          message: "There was an error activating the manager.",
          type: "danger",
        });
      }

      this.getManagers(this.showDeactivated);
    },
    itemRowBackground: function (item) {
      console.log("item", item);
      return item.status === "inactive" ? "bg-gray" : "";
    },
  },
};
</script>

<style>
.bg-gray {
  background: #f5b3b3;
}
</style>