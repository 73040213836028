<template>
  <div>
    <h4 class="h2">
      {{ $t("loop.slides.companies.title") }}
      <span>
        <v-tooltip bottom :max-width="700">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="black"
              v-bind="attrs"
              v-on="on"
              style="opacity: 0.5"
              >help_outline</v-icon>
          </template>
          {{ $t('loop.slides.companies.titleTooltip') }}
        </v-tooltip>
      </span>
    </h4>
    <div class="row">
      <div class="col-md-6 col-sm-12 col-lg-6">
        <div class="text-body-2">
          {{ $t("loop.slides.companies.subtitle") }}
        </div>
        <v-combobox
          item-color="#35383c"
          class="mt-3"
          outlined
          v-model="company"
          :items="companies"
          :search-input.sync="searchCompany"
          @change="onChange"
          :loading="loading"
          flat
          no-filter
          disable-lookup
          :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          placeholder="google.com"
          :label="$t('company')"
          :no-data-text="
            company
              ? $t('loop.slides.companies.didNotMatch', [company])
              : $t('loop.slides.companies.typeToSearch')
          "
          persistent-hint
          :hint="$t('loop.slides.companies.prompt')"
          item-text="name"
          return-object
        >
          <template v-slot:item="{ item }">
            <div>{{ item.name }} - {{ item.domain }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="col-md-6 col-sm-12 col-lg-6">
        <div class="text-body-2">
          {{ $t("loop.slides.companies.chosen") }}
        </div>
        <div class="row" v-if="eliminatedCompanies.length == 0">
          <div class="col-12 mt-4">
            <div class="text-body-2">
              {{ $t("loop.slides.companies.noChosen") }}
            </div>
          </div>
        </div>
        <div>
          <v-chip
            v-for="(company, index) in eliminatedCompanies"
            :key="(company, index)"
            close
            large
            class="ma-2"
            :ripple="false"
            label
            @click:close="removeCompanyFromEliminationList(index)"
          >
            <div v-if="typeof company === 'string'">
              {{ company }}
            </div>
            <div v-else>
              {{ company.name }} <br />
              {{ company.domain }}
            </div>
          </v-chip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/assets/js/utils";
import appSDK from "@/client/AppSDK";

export default {
  name: "LoopCompanies",
  data: () => ({
    companies: [],
    eliminatedCompanies: [],
    company: null,
    searchCompany: null,
    loading: false,
  }),
  watch: {
    searchCompany(value) {
      if (value) {
        this.getCompanies();
      }
    },
  },
  mounted() {
    if (this.$store.getters.isEditOrDuplicate) {
      this.eliminatedCompanies =
        this.$store.getters.getLoopData.eliminatedCompanies || []; // if [], firebase does not save it at all, so it is undefined
    }
  },
  methods: {
    onChange(item) {
      const itemFromSuggestions = !!item?.domain;
      if (itemFromSuggestions) {
        this.eliminateCompany();
      }
    },
    postData() {
      const eliminatedCompanies = this.eliminatedCompanies.map((company) => {
        if (typeof company === "string") {
          return company;
        }
        if (company.name && company.domain) {
          return `${company.name},,${company.domain}`;
        }
        if (company.name) {
          return company.name;
        }
      });
      this.$store.commit("setLoopData", {
        eliminatedCompanies,
      });
    },
    getCompanies: debounce(function () {
      if (!this.searchCompany) {
        return;
      }
      this.loading = true;

      return appSDK["loop.companies"](this.searchCompany)
        .then((res) => this.companies = res.slice(0, 5))
        .catch((error) => {
          console.error('Error fetching companies:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 400),

    eliminateCompany() {
      if (!this.company) {
        return;
      }

      const isCustom = typeof this.company === "string";

      this.eliminatedCompanies.unshift(this.company);
      this.$amplitude.logEvent("ELIMINATED_COMPANY_ADDED", {
        type: isCustom ? "custom" : "api",
        name: isCustom ? this.company : this.company.name,
      });

      // clear the input
      this.company = null;
      this.searchCompany = null;
      this.companies = [];
    },
    removeCompanyFromEliminationList(index) {
      this.eliminatedCompanies.splice(index, 1);
    },
  },
};
</script>
