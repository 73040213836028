<template>
  <div class="col-12">
    <form @submit.prevent="updatePassword">
      <div class="text-h3 ma-4">
        {{ $t("profile.password.title") }}
      </div>

      <div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <v-text-field
              :type="passwordType"
              autocomplete="new-password"
              :label="$t('profile.password.new')"
              v-model="model.password"
              outlined
              :error="!!errors.password"
              :error-messages="errors.password"
              @change="validate('password')"
            >
              <v-btn class="mt-n1" slot="append" icon small @click="hidePassword = !hidePassword">
                <app-icon :name="hidePassword ? 'eye' : 'eyeOff'" /> </v-btn
            ></v-text-field>
          </div>
          <div class="col-md-6 col-sm-12">
            <v-text-field
              :type="confirmPasswordType"
              autocomplete="new-password"
              :label="$t('profile.password.retype')"
              v-model="model.passwordConfirmation"
              outlined
              :error="!!errors.passwordConfirmation"
              :error-messages="errors.passwordConfirmation"
              @change="validate('passwordConfirmation')"
              ><v-btn class="mt-n1" slot="append" icon small @click="hideConfirmPassword = !hideConfirmPassword">
                <app-icon :name="hideConfirmPassword ? 'eye' : 'eyeOff'" /> </v-btn
            ></v-text-field>
          </div>
        </div>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn rounded color="primary" height="42" :loading="this.spinning" type="submit">
            {{ $t("profile.password.submit") }}
          </v-btn>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import * as yup from "yup";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant.js";

const user = constant.user;

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password should be at least 8 characters long")
    .required("Password is required"),
  passwordConfirmation: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default {
  name: "changePassword",

  data() {
    return {
      model: {
        password: "",
        passwordConfirmation: "",
      },
      errors: {},
      hidePassword: true,
      hideConfirmPassword: true,
      spinning: false,
    };
  },

  watch: {
    status(value) {
      switch (value) {
        case user.status.FailureUpdatePassword:
          this.spinning = false;
          this.$notify({
            message: "An error has occurred. Please try again later.",
            type: "danger",
          });
          break;
        case user.status.SuccessUpdatePassword:
          this.spinning = false;
          this.$notify({
            message: "Your password has been updated!",
            type: "success",
          });
          break;
      }
    },
  },

  computed: {
    ...mapGetters("user", {
      getUser: user.getters.getUser,
      getStatus: user.getters.getStatus,
      getLoading: user.getters.getLoading,
    }),

    email() {
      return this.getUser?.email;
    },

    status() {
      return this.getStatus;
    },

    loading() {
      return this.getLoading;
    },

    passwordType() {
      return this.hidePassword ? "password" : "text";
    },

    confirmPasswordType() {
      return this.hideConfirmPassword ? "password" : "text";
    },
  },

  methods: {
    validate(field) {
      schema
        .validateAt(field, this.model)
        .then(() => this.$set(this.errors, field, ""))
        .catch(err => this.$set(this.errors, field, err.message));
    },

    async updatePassword() {
      try {
        this.spinning = true;
        await schema.validate(this.model);
      } catch (errs) {
        errs.inner.forEach(error => {
          this.errors = { ...this.errors, [error.path]: error.message };
        });
        this.$notify({
          message: errs.toString(),
          type: "danger",
        });
        return;
      } finally {
        this.spinning = false;
      }

      this.spinning = true;
      this.$store.dispatch(user.actions.UpdatePassword, this.model.password);
    },
  },
};
</script>

<style scoped></style>
