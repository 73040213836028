<template>
  <div class="logo-wrapper">
    <img :src="logo" :alt="`${brand} logo`" class="auth-page-logo" />
  </div>
</template>

<script>
export default {
  computed: {
    logo() {
      return this.$store.getters.logoUrl;
    },
    brand() {
      return this.$store.getters.brand;
    },
  },
};
</script>

<style scoped>
.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  overflow: hidden;
  margin-left: -8px;
}

.auth-page-logo {
  width: 100%;
  object-fit: cover;
}
</style>
