<template>
  <v-dialog v-model="isVisible" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Assign Client To Manager</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <div v-if="!activeManagers.length" class="text-body-1">
              There are no active managers
            </div>
            <v-radio-group v-model="currentManagerId" column>
              <v-radio
                v-for="manager in activeManagers"
                :key="manager.uniqueId"
                :label="manager.email"
                :value="manager.uniqueId"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="updateDialogStatus(false)">
          Close
        </v-btn>
        <v-btn
          color="primary"
          @click="handleAssignManager"
          :disabled="!activeManagers.length"
        >
          Assign
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import appSDK from "@/client/AppSDK";

export default {
  props: {
    currentClient: {
      default: () => ({}),
    },
    managers: {
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      isVisible: false,
      currentManagerId: "",
    };
  },
  watch: {
    currentClient: {
      handler: function () {
        this.currentManagerId = this.currentClient.managedBy;
      },
      immediate: true,
    },
  },
  computed: {
    activeManagers() {
      return this.managers.filter((manager) => manager.status === "active");
    },
  },
  methods: {
    updateDialogStatus(status) {
      this.isVisible = status;
    },
    handleAssignManager() {
      this.$emit("assignManager", {
        clientId: this.currentClient.uniqueId,
        managerId: this.currentManagerId,
      });
      this.updateDialogStatus(false);
    },
    show() {
      this.updateDialogStatus(true);
    },
  },
};
</script>

<style>
.bg-gray {
  background: #f5b3b3;
}
</style>