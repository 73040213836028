<template>
  <v-row>
    <app-header></app-header>

    <div class="col-12">
      <div class="card px-2" style="box-shadow: none">
        <div class="card-header">
          <v-row>
            <div class="col-md-5 d-flex align-center">
              <v-btn icon :to="`/clients`" style="margin-top: 0px">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <span>All clients</span>
            </div>

            <div class="col-md-7">
              <div class="d-flex justify-end align-center">
                <v-switch
                  :value="autoApplyStatus"
                  :label="`Auto Application: ${autoApplyStatus ? 'enabled' : 'disabled'}`"
                  :loading="isLoading"
                  @change="handleAutoApplyChange"
                  class="mr-5"
                ></v-switch>

                <v-btn color="lighten-2" dark @click="() => $refs.exportJobsDialog.open()"> Export Jobs </v-btn>

                <v-btn elevation="2" color="primary" :to="`/clients/${clientId}/loops/create`"> Create Loop </v-btn>
              </div>
            </div>
          </v-row>
        </div>

        <ClientOverviewStats :clientId="clientId" class="mb-1" />

        <LoopTable class="px-2" />
      </div>
    </div>

    <ExportJobsDialog ref="exportJobsDialog" />
  </v-row>
</template>

<script>
import LoopTable from "@/components/partners/LoopTable.vue";
import appSDK from "@/client/AppSDK";
import ExportJobsDialog from "@/components/ExportJobsDialog.vue";
import ClientOverviewStats from "@/components/partners/ClientOverviewStats.vue";

export default {
  components: { LoopTable, ExportJobsDialog, ClientOverviewStats },
  data() {
    return {
      userInfo: {},
      isLoading: true,
    };
  },
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    autoApplyStatus() {
      return this.userInfo.enableEmailsApplications;
    },
  },
  created() {
    this.fetchUserInfo();
  },
  methods: {
    async handleAutoApplyChange(val) {
      this.isLoading = true;
      try {
        await appSDK["partner.childUser.update"](this.clientId, {
          enableEmailsApplications: val,
        });
        await this.fetchUserInfo();
      } finally {
        this.isLoading = false;
      }
    },
    async fetchUserInfo() {
      try {
        const results = await appSDK["user.get"](this.clientId);
        this.userInfo = { ...results };
      } finally {
        this.isLoading = false;
      }
    },
  },
};

function formatDate(date) {
  return date.toISOString().substr(0, 10);
}
</script>

<style scoped>
.center-vertically {
  height: 56px;
  line-height: 56px;
}
</style>
