import { state } from "./state";

export function getPlans() {
  return state.plans;
}

export function getStatus() {
  return state.status;
}

export function getPromoCode() {
  return state.promoCode;
}

export function getPromoCodeError() {
  return state.promoCodeError;
}
