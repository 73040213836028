<template>
  <div>
    <div v-if="!configure">
      <Empty :title="this.$t('Profile.Smtp.not_configure_info')" />

      <div class="add-button" v-if="readOnlyPage">
        <Button :title="this.$t('Profile.Smtp.configure_now')" @click="handleAdd"></Button>
      </div>
    </div>

    <form class="form" @submit.prevent="onSubmit" v-if="configure" autocomplete="off">
      <label class="header5">
        {{ this.$t("Profile.Smtp.title") }}
      </label>

      <div class="double-row-input">
        <Input
          :title="this.$t('Profile.Smtp.host')"
          :value="host"
          :required="true"
          :placeholder="'smtp.gmail.com'"
          @input="value => handleInput(value, 'host')"
          :error="errors.host"
          :uneditable="readOnlyPage"
        />
        <Input
          :title="this.$t('Profile.Smtp.port')"
          :value="port"
          :required="true"
          :placeholder="'587'"
          @input="value => handleInput(value, 'port')"
          :error="errors.port"
          :uneditable="readOnlyPage"
        />
      </div>
      <div class="double-row-input secure_connection">
        <div>
          <SwitchToggleInput
            :title="this.$t('Profile.Smtp.secure')"
            :value="secure"
            :disable="readOnlyPage"
            @input="handleSecure"
          />
        </div>
      </div>
      <div class="double-row-input">
        <Input
          class="large-input"
          :title="this.$t('Generic.Form.username')"
          :value="username"
          :required="true"
          :autocomplete="'false'"
          :placeholder="
            this.$t('Generic.Form.placeholder_value', { value: this.$t('Generic.Form.username').toString().toLowerCase() })
          "
          :error="errors.username"
          @input="value => handleInput(value, 'username')"
          :uneditable="readOnlyPage"
        />
        <Input
          v-if="!readOnlyPage"
          :autocomplete="'new-password'"
          :type="showPassword ? 'text' : 'password'"
          class="large-input"
          :required="true"
          :title="this.$t('Generic.Form.password')"
          :value="password"
          :placeholder="
            this.$t('Generic.Form.placeholder_value', { value: this.$t('Generic.Form.password').toString().toLowerCase() })
          "
          :error="errors.password"
          @input="value => handleInput(value, 'password')"
          ><EyeIn v-if="this.showPassword" @click="handleEyePassword" :size="18" />
          <EyeOut v-if="!this.showPassword" @click="handleEyePassword" :size="18" />
        </Input>
      </div>
      <div class="form-buttons" v-if="!readOnlyPage" :style="!readOnlyPage ? 'display:flex' : 'display:none'">
        <Button
          v-if="showClearBtn"
          :title="this.$t('Generic.Form.clear_all')"
          :buttonStyle="'clear'"
          @click="handleReset"
          :type="'reset'"
          :loading="false"
          :style="showClearBtn ? 'display: inherit' : 'display:none'"
        ></Button>
        <Button
          v-if="!showClearBtn"
          :title="this.$t('Generic.Form.cancel')"
          @click="this.handleCancel"
          :buttonStyle="'grey'"
          :type="'reset'"
          :loading="false"
          :style="!showClearBtn ? 'display: inherit' : 'display:none'"
        ></Button>
        <Button
          :title="this.$t('Generic.Form.update_settings')"
          :buttonStyle="'save'"
          :type="'submit'"
          :disabled="saveLoading"
          :loading="saveLoading"
        ></Button>
      </div>
      <div class="form-buttons" v-if="readOnlyPage" :style="readOnlyPage ? 'display:flex' : 'display:none'">
        <Button
          :title="this.$t('Generic.Form.delete_settings')"
          :buttonStyle="'delete'"
          @click="this.deleteSmtp"
          :loading="false"
        ></Button>
        <Button
          :title="this.$t('Generic.Form.edit_settings')"
          @click="onSubmit"
          :type="'submit'"
          :buttonStyle="'edit'"
          :loading="deleteLoading"
          :style="readOnlyPage ? 'display:inherit' : 'display:none'"
        ></Button>
      </div>
    </form>
  </div>
</template>

<script>
import EyeIn from "vue-material-design-icons/Eye.vue";
import EyeOut from "vue-material-design-icons/EyeOff.vue";
import { DOMAIN_REGEX, NUMBER_REGEX, IP_REGEX, PORT_REGEX } from "@/assets/js/constants";
import { constant } from "@/store/constant";
import { mapGetters } from "vuex";
import { SwitchToggleInput, Input, Button, Empty } from "@/framework/index";

const user = constant.user;

export default {
  components: {
    Input,
    SwitchToggleInput,
    Button,
    EyeIn,
    EyeOut,
    Empty,
  },

  data() {
    return {
      host: "",
      port: "",
      secure: true,
      username: "",
      password: "",
      showPassword: false,
      readOnlyPage: true,
      errors: {
        host: "",
        port: "",
        secure: "",
        username: "",
        password: "",
      },
      saveLoading: false,
      deleteLoading: false,
      configure: false,
    };
  },

  watch: {
    host(value) {
      if (!this.readOnlyPage && value && !value.match(DOMAIN_REGEX) && !value.match(IP_REGEX)) {
        this.errors.host = this.$t("Profile.Smtp.host_invalid_form").toString();
      } else {
        this.errors.host = "";
      }
    },

    port(value) {
      if (!this.readOnlyPage && value && !value.toString().match(PORT_REGEX)) {
        this.errors.port = this.$t("Generic.Form.valid_form_message", {
          value: this.$t("Profile.Smtp.port"),
        }).toString();
      } else {
        this.errors.port = "";
      }
    },

    password(value) {
      if (value != "") {
        this.errors.password = "";
      }
    },

    readOnlyPage(value) {
      if (value) {
        // @ts-ignore
        this.onInitPage();
      }
    },
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),

    smtpSettings() {
      return this.getUser.smtpSettings;
    },

    showClearBtn() {
      return this.host != "" || this.port != "" || this.username != "" || this.password != "";
    },

    supportEmail() {
      return this.$store.getters.configs.supportEmail;
    },
  },

  created() {
    this.onInitPage();
    if (this.getUser?.pricingPlan == 1) {
      this.$router.push("/not-authorize");
    }
  },

  methods: {
    onInitPage() {
      // @ts-ignore
      if (this.smtpSettings && this.smtpSettings.host) {
        const settings = this.smtpSettings;
        // @ts-ignore
        this.host = settings.host;
        // @ts-ignore
        this.port = settings.port.toString();
        // @ts-ignore
        this.secure = settings.secure;
        // @ts-ignore
        this.username = settings.username;
        this.configure = true;
      } else {
        this.host = "";
        this.port = "";
        this.secure = true;
        this.username = "";
        this.password = "";
        this.configure = false;
      }
    },

    onSubmit() {
      if (this.readOnlyPage) {
        this.readOnlyPage = false;
      } else {
        this.saveSmtp();
      }
    },

    async saveSmtp() {
      try {
        console.log(this.secure);
        this.saveLoading = true;
        if (this.validateForm()) {
          const payload = {
            host: this.host,
            port: parseInt(this.port),
            secure: this.secure,
            username: this.username,
            password: this.password,
          };
          await this.$store.dispatch(user.actions.UpdateSmtpSettings, payload);
          await this.$store.dispatch(user.actions.RefreshUserData);
          this.$notify({ message: this.$t("Profile.Smtp.update_successful"), type: "success" });
          this.handleCancel();
        }
      } catch (e) {
        this.$notify({ message: e?.responseContent?.message, type: "danger" });
        // this.$notify({ message: this.$t("Profile.Smtp.failure_update"), type: "danger" });
      } finally {
        this.saveLoading = false;
      }
    },

    validateForm() {
      let isValid = true;
      if (this.host == "") {
        this.errors.host = this.$t("Generic.Form.required_message", {
          value: this.$t("Profile.Smtp.host"),
        }).toString();
        isValid = false;
      }
      if (this.port == "") {
        this.errors.port = this.$t("Generic.Form.required_message", { value: this.$t("Profile.Smtp.port") }).toString();
        isValid = false;
      }
      if (this.username == "") {
        this.errors.username = this.$t("Generic.Form.required_message", {
          value: this.$t("Generic.Form.username"),
        }).toString();
        isValid = false;
      }
      if (this.password == "") {
        this.errors.password = this.$t("Generic.Form.required_message", {
          value: this.$t("Generic.Form.password"),
        }).toString();
        isValid = false;
      }
      return isValid;
    },

    async deleteSmtp() {
      try {
        this.deleteLoading = true;
        await this.$store.dispatch(user.actions.DeleteSmtpSettings);
        await this.$store.dispatch(user.actions.RefreshUserData);
        this.$notify({ message: this.$t("Profile.Smtp.success_delete"), type: "success" });
        this.onInitPage();
      } catch (e) {
        this.$notify({ message: this.$t("Profile.Smtp.failure_delete", { email: this.supportEmail }), type: "danger" });
      } finally {
        this.deleteLoading = false;
      }
    },

    /* handle form input  */

    handleInput(value, name) {
      this[name] = value;
    },

    handleEyePassword() {
      this.showPassword = !this.showPassword;
    },

    handleReset() {
      this.host = "";
      this.port = "";
      this.username = "";
      this.password = "";
      this.errors = { host: "", port: "", username: "", password: "", secure: "" };
    },

    handleCancel() {
      this.readOnlyPage = true;
    },

    handleAdd() {
      this.configure = true;
      this.readOnlyPage = false;
    },

    handleSecure() {
      this.secure = !this.secure;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .secure_connection {
    display: block;
  }

  .secure_connection > * {
    width: 100% !important;
  }
}
</style>
