class LoopSerializer {
  /**
   * Convert from vuex data model to "create/edit loop request body" model
   */
  static serialize(data) {
    return {
      templateId: data.emailTemplate.firebaseKey,
      cvId: data.cvId,
      fileUrl: data.fileUrl,
      jobtitle: data.jobtitle,
      location: data.location,
      scrapers: data.scrapers,
      jobtype: data.jobtype,
      joblevel: data.joblevel,
      onlyRemoteJobs: data.onlyRemoteJobs,
      jobMatchLevel: data.jobMatchLevel,
      eliminatedCompanies: data.eliminatedCompanies,
      keywords: data.keywords,
      excludedKeywords: data.excludedKeywords,
      directEmails: data.directEmails,
      enableFormApplications: data.enableFormApplications,
      autoApply: data.enableFormApplications,
      coverLetter: data.coverLetter,
      phoneData: data.phoneData,
      resumeText: data.resumeText,
      distanceRadius: data.distanceRadius,
    };
  }

  /**
   * Convert from "get loop" request model to vuex model
   */
  static deserialize(data) {
    return {
      templateId: data.templateId,
      cvId: data.cvId,
      fileUrl: data.fileUrl,
      jobtitle: data.jobTitle,
      location: data.jobLocation || data.location,
      scrapers: data.scrapers,
      jobtype: data.jobType,
      joblevel: data.jobLevel,
      onlyRemoteJobs: data.onlyRemoteJobs || data.onlyRemoteData,
      jobMatchLevel: data.jobMatchLevel,
      eliminatedCompanies: data.eliminatedCompanies,
      keywords: data.keywords,
      excludedKeywords: data.excludedKeywords,
      directEmails: data.directEmails,
      enableFormApplications: data.autoApply,
      autoApply: data.autoApply,
      coverLetter: data.coverLetter,
      phone: data.phone,
      resumeText: data.resumeText,
      distanceRadius: data.distanceRadius,
    };
  }
}

export default LoopSerializer;
