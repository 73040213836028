import Vue from "vue";

const bus = new Vue();

function eventsBus(Vue) {
  Vue.prototype.$bus = bus;
}

Vue.use(eventsBus);

export default eventsBus;
