import i18n from "@/core/i18n";

// TODO: on switch locale, change translations and data immediately and rerender
export function toCurrency(val, currency) {
  if (val === undefined || val === null || !currency) {
    return "-";
  }
  return new Intl.NumberFormat(i18n.locale, {
    style: "currency",
    currency,
  }).format(val);
}

export function formatEmailBody(value) {
  const new_value = value
    .replace(/\\r\\n/g, "<br/>")
    .replace(/\\\\r\\\\n/g, "<br/>")
    .replace(/\\n/g, "<br/>")
    .replace(/\n/g, "<br/>")
    .replace(/\\\\n/g, "<br/>")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/&lt;br\/&gt;/g, "<br/>");

  return new_value;
}
