<template>
  <div id="button">
    <div id="buttonDiv"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import { USER_TOKEN_COOKIE } from "@/assets/js/constants";
import Cookies from "js-cookie";
const user = constant.user;

const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
export default {
  components: {},

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters("user", {
      getUser: user.getters.getUser,
    }),

    partnerId() {
      return this.$store.getters.configs?.partnerIdToPassOnSignup;
    },

    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },

    connectedUser() {
      return this.getUser;
    },
  },

  mounted() {
    this.onInit();
  },

  methods: {
    onInit() {
      const width = screenWidth > 500 ? 400 : Math.trunc(250 + 250 * 0.3 - screenWidth * 0.04);

      try {
        if (
          // @ts-ignore
          typeof google === "undefined" ||
          // @ts-ignore
          typeof google.accounts === "undefined" ||
          // @ts-ignore
          typeof google.accounts.id === "undefined"
        ) {
          setTimeout(() => {
            this.onInit();
          }, 100);
        } else {
          // @ts-ignore
          google.accounts.id.initialize({
            client_id: "932820547310-e9ngskhnlo7b3fi0uipq4ah78pq86mek.apps.googleusercontent.com",
            callback: this.handleCredentialResponse,
          });
          // @ts-ignore
          google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
            theme: "outline",
            size: "large",
            shape: "pill",
            logo_alignment: "center",
            locale: "en_US",
            width: width,
          });
        }
      } catch (e) {
        console.error(e);
        this.$notify({ message: `Something went wrong please contact to ${this.supportEmail}`, type: "danger" });
      }
    },

    async handleCredentialResponse(response) {
      const payload = {
        token: response.credential,
        partnerId: this.partnerId,
      };
      try {
        await this.$store.dispatch(user.actions.GoogleSignIn, payload);
        this.$amplitude.logEvent("SIGN_IN", { method: "google" });
        await this.$store.dispatch(user.actions.Auth);
        if (this.connectedUser?.role != null) {
          await this.setUpFreePlan();
          const redirect = this.$route.query.redirect || "/";
          this.$router.push(redirect.toString());
        } else {
          Cookies.remove(USER_TOKEN_COOKIE);
          this.$notify({
            message: this.$t("Generic.Messages.Error.unauthorized", { email: this.supportEmail }),
            type: "danger",
          });
        }
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
        });
      }
    },

    async setUpFreePlan() {
      if (this.connectedUser?.pricingPlan == null || this.connectedUser?.pricingPlan == 0) {
        const payload = {
          userId: this.user?.id,
          pricingPlan: 1,
        };
        await this.$store.dispatch(user.actions.UpdateInfo, payload);
        await this.$store.dispatch(user.actions.Auth);
      }
    },
  },
};
</script>
<style scoped>
#buttonDiv {
  display: flex;
  justify-content: center;
}

#button {
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 960px) {
  .button {
    width: 100%;
  }
}
</style>
