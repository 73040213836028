import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import i18n from "./core/i18n";

// Plugins
import vuetify from "./plugins/vuetify";

import "./plugins/globalComponents";
import "./plugins/notification";
import "./plugins/eventsBus";
import "./plugins/amplitudePlugin";
import { constant } from "@/store/constant";

// @ts-ignore
if (process.env.NODE_ENV === "production") {
  Vue.config.devtools = true;
  Vue.config.productionTip = false;
}

export default function createApp() {
  try {
    if (process.env.VUE_APP_MODE === "b2c") {
      store.dispatch(constant.plans.actions.FetchPrices);
    }
  } catch (e) {
    console.error("error in promise prices", e);
  }

  return new Vue({
    router,
    i18n,
    store,
    vuetify,
    render: h => h(App),
  }).$mount("#app");
}
