<template>
  <div>
    <v-row class="align-center">
      <v-col class="pl-0">
        <div class="text-h3 py-2">{{ this.$t("Payment.Admin.user_on_plan") }}</div>
        <div class="pb-2 text-body-2" v-if="$store.getters.configs.showAdminPayments">
          {{ this.$t("Payment.Admin.subtitle") }}
        </div>
      </v-col>
      <v-col cols="auto">
        <div>
          <span class="mr-1">
            {{ this.$t("Generic.Common.create_client") }}
          </span>
          <v-btn fab elevation="2" height="40" width="40" @click="() => $refs.createClientDialog.open()">
            <v-icon>person_add</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="viewClients"
      :options.sync="options"
      :server-items-length="totalClients"
      item-key="uniqueId"
      :loading="loading"
      :no-data-text="$t('partner.noClients')"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon target="_blank" rel="noopener noreferrer" v-bind="attrs" v-on="on" @click="deleteUser(item)">
              <v-icon> delete </v-icon>
            </v-btn></template
          >
          <span>{{ deleteClientText }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span>{{ item.createdAtString }}</span>
      </template>
    </v-data-table>

    <CreateClientForm ref="createClientDialog" @modal-closed="this.clientCreated" @updateData="updateParentData" />
  </div>
</template>

<script>
import CreateClientForm from "./modals/CreateClientFormDialog.vue";
import { clientDataStatus, clientDataActions } from "@/store/modules/client/data";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { CreateClientForm },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showCreateUserForm: false,
      options: {},
      footerProps: { disableItemsPerPage: true, disablePagination: true },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (newOptions.page === undefined || newOptions.itemsPerPage === undefined) {
          return;
        }
        if (newOptions.page === oldOptions.page && newOptions.itemsPerPage === oldOptions.itemsPerPage) {
          return;
        }
        console.log("changed", newOptions.page, newOptions.itemsPerPage, oldOptions.page, oldOptions.itemsPerPage);
        this.fetchClients({ page: newOptions.page, itemsPerPage: newOptions.itemsPerPage });
      },
      deep: true,
    },
    status(status) {
      if (status == clientDataStatus.failureFetchClients) {
        this.$notify({
          message: this.$t("Generic.Messages.Error.fetch_users"),
          type: "danger",
        });
      }
    },
  },

  computed: {
    ...mapGetters("client", {
      loading: "getLoading",
      status: "getStatus",
      error: "getError",
      getDeleteEmailUser: "getDeleteEmailUser",
      totalClients: "getTotalClients",
      getClients: "getClients",
    }),

    viewClients() {
      const clients = this.getClients;
      if (clients) {
        return clients.map(client => {
          const createdAt = client.createdAt ? new Date(client.createdAt) : null;
          return {
            ...client,
            name: `${client.firstName} ${client.lastName}`,
            createdAt,
            createdAtString: createdAt ? createdAt.toDateString() : "-",
          };
        });
      } else {
        return [];
      }
    },

    headers() {
      return [
        {
          text: this.$i18n.t("partner.name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Email", align: "start", sortable: true, value: "email" },
        {
          text: this.$i18n.t("partner.createdAt"),
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: this.$i18n.t("partner.actions"),
          sortable: false,
          value: "actions",
        },
      ];
    },

    deleteClientText() {
      return this.$t("Generic.Common.delete_client");
    },
  },

  methods: {
    ...mapActions("client", [clientDataActions.fetchClients, clientDataActions.deleteClient]),

    async deleteUser(item) {
      const { uniqueId, email } = item;

      if (!uniqueId) {
        throw new Error("uniqueId is not present");
      }
      try {
        await this.deleteClient({ userId: uniqueId });
        this.$notify({
          message: this.$t("Generic.Messages.Success.user_delete", { email: email }),
          type: "success",
        });
        await this.fetchClients();

        this.updateParentData();
      } catch (error) {
        this.$notify({
          message: this.$t("Generic.Messages.Error.remove_user"),
          type: "danger",
        });
      }
    },

    clientCreated() {
      this.fetchClients();
    },

    updateParentData() {
      this.$emit("updateClient");
    },
  },
};
</script>

<style scoped>
.highlight-on-hover:hover {
  background-color: #f5f5f5;
}
</style>
