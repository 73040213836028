<template>
  <div>
    <v-progress-linear :active="loading" indeterminate></v-progress-linear>
    <div class="pa-5">
      <LoopInfoPresentation
        :loop="transformedLoop"
        :loading="loading"
        :cvFilename="cvFilename"
        :fileUrl="fileUrl"
        :directEmails="directEmails"
        :formApplications="formApplications"
        :subject="subject"
        :body="body"
        :email="email"
      />
    </div>
  </div>
</template>

<script>
import LoopInfoPresentation from "@/components/ViewLoop/LoopInfoPresentation.vue";
import appSDK from "@/client/AppSDK";
import { mapGetters } from "vuex";

export default {
  name: "LoopInfo",
  components: {
    LoopInfoPresentation,
  },
  props: {
    loop: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    cvs: {
      type: Array,
      default: () => [],
    },
    loopId: String,
    clientId: String,
  },
  computed: {
    ...mapGetters("user", { getUser: "getUser" }),

    user() {
      return this.getUser;
    },

    cvFilename() {
      const selectedCV = this.cvs.find(({ cvId }) => cvId === this.loop?.cvId);
      console.log("selectedCV", selectedCV);
      return selectedCV?.fileName;
    },
    fileUrl() {
      return this.loop.fileUrl;
    },
    subject() {
      return this.templates?.find(template => template.firebaseKey === this.loop.templateId)?.subject;
    },
    body() {
      return this.templates?.find(template => template.firebaseKey === this.loop.templateId)?.body;
    },
    directEmails() {
      return !!this.loop.directEmails;
    },
    formApplications() {
      return !!this.loop.autoApply;
    },
    email() {
      return this.user?.email;
    },
    transformedLoop() {
      return {
        ...this.loop,
        jobLevel: this.$store.getters.jobLevels[this.loop.jobLevel],
        jobType: this.$store.getters.jobTypes[this.loop.jobType],
      };
    },
  },
  data() {
    return {
      templates: [],
    };
  },
  async created() {
    this.templates = await appSDK["template.list"]();

    this.$store.dispatch("fetchConstants");
  },
  methods: {},
};
</script>

<style scoped>
.v-list-item__subtitle,
.v-list-item__title {
  white-space: normal;
  -webkit-line-clamp: unset;
}
</style>
