<template>
  <div>
    <p>{{ $t("examination.location") }}</p>
    <p>
      {{ $t("examination.yourLocation") }}
      <span style="color: #2196f3">{{ this.loop.jobLocation }}</span>
    </p>
    <p>{{ $t("examination.differentLocations") }}</p>

    <p v-if="$i18n.locale === 'en'">
      <v-icon color="red">error</v-icon> In order to increase your matches, you can
      <router-link :to="`${loopUrl}/edit`">edit your loop</router-link> or
      <router-link :to="`${loopUrl}/duplicate`">make a duplicate loop</router-link>
      in order to change the location.
    </p>
    <p v-else-if="$i18n.locale === 'fr'">
      <v-icon color="red">error</v-icon> Afin d'augmenter le nombre de correspondances, vous pouvez modifier votre boucle ou en
      faire une copie afin de changer l'emplacement.
    </p>

    <div v-if="valueCounts && valueCounts.length">
      <p>{{ $t("examination.commonLocations") }}</p>
      <v-simple-table class="mb-5">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t("location") }}</th>
              <th class="text-left">{{ $t("examination.howManyTimes") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in valueCounts" :key="item._id">
              <td>{{ item._id }}</td>
              <td>{{ item.count }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    {{ $t("examination.jobsWeRejectedLocation") }}

    <v-data-table
      :headers="headers"
      :items="jobs"
      :options.sync="options"
      :loading="loading"
      disable-sort
      :items-per-page="10"
      :server-items-length="jobCount"
      :footer-props="{
        disableItemsPerPage: true,
        showFirstLastPage: true,
      }"
    >
      <template v-slot:item.actions="{ item }">
        <!-- @TODO -->
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="handleMatchDataDisplay(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> description </v-icon>
            </v-btn>
          </template>
          <span>Job details</span>
        </v-tooltip> -->

        <DuplicateWithJobTitleButton
          v-if="item.job.length && isUserPartner"
          :item="item"
          :clientId="clientId"
          :loopId="loopId"
        />

        <v-tooltip bottom v-if="item.job.length">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small :href="item.job[0].url" target="_blank" rel="noopener noreferrer" v-bind="attrs" v-on="on">
              <v-icon> open_in_new </v-icon>
            </v-btn></template
          >
          <span>Original job posting</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import appSDK from "@/client/AppSDK";
import { mapGetters } from "vuex";
import { timestampToDateString } from "@/assets/js/utils.js";
import DuplicateWithJobTitleButton from "./DuplicateWithJobTitleButton.vue";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  components: { DuplicateWithJobTitleButton },
  props: {
    loopId: {},
    reason: {},
    loop: {},
    clientId: String,
    jobCount: Number,
  },

  computed: {
    ...mapGetters("user", { user: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    loopUrl() {
      return this.user?.is_partner ? `/clients/${this.clientId}/loops/${this.loopId}` : `/loops/${this.loopId}`;
    },
  },

  data() {
    return {
      headers: [
        {
          text: "Job Title",
          align: "start",
          sortable: false,
          value: "jobTitle",
        },
        { text: "Location", value: "jobLocation", sortable: false },
        {
          text: "Timestamp",
          value: "createdAt",
          sortable: false,
          width: 105,
        },
        {
          text: "View Details",
          value: "actions",
          sortable: false,
          align: "start",
          width: 125,
        },
      ],
      jobs: [],
      loading: true,
      options: { itemsPerPage: 10 },
      skip: 0,

      valueCounts: null,
    };
  },
  watch: {
    options: {
      async handler(v) {
        const newSkip = (v.page - 1) * 10;
        if (this.skip !== newSkip) {
          this.skip = newSkip;
          await this.getData();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getData();
    this.valueCounts = await this.getValueCounts();
  },
  methods: {
    async getData() {
      //this.loading = true;
      let data = await appSDK["loop.examination"](
        this.loopId,
        {
          reason: this.reason,
          type: "serial_sorted_join",
          skip: this.skip,
        },
        this.isUserPartner,
        this.clientId,
      );
      data.forEach(item => {
        item.createdAt = timestampToDateString(item.createdAt);
      });
      this.jobs = data;
      this.loading = false;
    },

    async getValueCounts() {
      let data = await appSDK["loop.examination"](
        this.loopId,
        {
          reason: this.reason,
          type: "value_counts",
        },
        this.isUserPartner,
        this.clientId,
      );
      return data.slice(0, 3);
    },
  },
};
</script>

<style></style>
