<template>
  <div>
    <h4 class="h2">
      {{ $t("loop.slides.jobMatch.title") }}
    </h4>
    <div class="row">
      <div class="col-12">
        <div class="text-subtitle-2">{{ this.choiceTitle }}</div>
        <center>
          <div>
            <v-slider
              min="25"
              max="75"
              step="25"
              hide-details
              v-model="jobMatchLevel"
              :tick-labels="tickLabels"
            >
            </v-slider>
          </div>
        </center>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <v-alert :color="primaryColor" style="color: #fff">
          <div class="d-flex">
            <v-icon color="grey" class="mr-2 mt-1 align-start">info</v-icon>
            <span class="text-body-2">
              {{ description }}
            </span>
          </div>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobMatchLevel: 50,
    };
  },
  watch: {
    // whenever question changes, this function will run
    jobMatchLevel() {
      this.$amplitude.logEvent(
        "JOB_MATCH_LEVEL_CHANGED_TO:" + this.jobMatchLevel
      );
    },
  },
  computed: {
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
    choiceTitle() {
      // TODO <b>
      const titlesByLevel = {
        25: this.$t("loop.slides.jobMatch.low1"),
        50: this.$t("loop.slides.jobMatch.middle1"),
        75: this.$t("loop.slides.jobMatch.high1"),
      };
      return titlesByLevel[this.jobMatchLevel];
    },
    description() {
      const descriptionsByLevel = {
        25: this.$t("loop.slides.jobMatch.low2"),
        50: this.$t("loop.slides.jobMatch.middle2"),
        75: this.$t("loop.slides.jobMatch.high2"),
      };
      return descriptionsByLevel[this.jobMatchLevel];
    },
    tickLabels() {
      return ["Low", "Middle", "High"].map(x => this.$t(x));
    },
  },
  mounted() {
    if (this.$store.getters.isEditOrDuplicate) {
      this.jobMatchLevel = this.$store.getters.getLoopData.jobMatchLevel;
    }
  },
  methods: {
    postData() {
      this.$store.commit("setLoopData", {
        jobMatchLevel: this.jobMatchLevel,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.slider {
  -webkit-appearance: none;
  width: 60%;
  height: 15px;
  border-radius: 5px;
  background: #7676bf;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #eff6fd;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #eff6fd;
  cursor: pointer;
}
</style>