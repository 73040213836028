<template>
  <textarea v-model="inputValue"></textarea>
</template>

<script>
export default {
  props: {
    value: {
      type: String || null,
    },
  },

  computed: {
    inputValue: {
      get() {
        // @ts-ignore
        return this.value;
      },
      set(val) {
        // @ts-ignore
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  border: 1px solid #e4ebe4;
  border-radius: 5px;
  resize: none;
  outline-width: 0;
  outline: none;
  height: 100px;
  padding: 10px;
}

textarea:hover {
  border: 1px solid #c7d6c7;
}
</style>
