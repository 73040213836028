<template>
  <v-skeleton-loader v-if="loading" type="article, image, list-item-two-line" />

  <div v-else>
    <div v-if="showJobTitleRow">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline my-3">
            {{ loop.jobTitle }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ $t("loopPreview.title") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </div>

    <v-list two-line class="pt-0">
      <v-subheader>
        {{ $t("loopPreview.info") }}
      </v-subheader>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> place </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ jobLocation }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t("loopPreview.location") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> search </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ jobBoards }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t("loopPreview.jobBoards") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> military_tech </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t(loop.jobLevel) }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t("experience") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> info </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t(loop.jobType) }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t("job-type") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> settings_ethernet </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            ><v-chip label class="mr-2 mb-1" v-for="keyword in loop.keywords" :key="keyword">{{ keyword }}</v-chip>
            {{ loop.keywords && loop.keywords.length > 0 ? "" : $t("loopPreview.noKeywords") }}
            <!-- when loading, it will display "No keywords" -->
          </v-list-item-title>
          <v-list-item-subtitle>{{ $t("loopPreview.keywords") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> code_off </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            ><v-chip label class="mr-2 mb-1" v-for="keyword in loop.excludedKeywords" :key="keyword">{{ keyword }}</v-chip>
            {{ loop.excludedKeywords && loop.excludedKeywords.length > 0 ? "" : $t("loopPreview.noKeywords") }}
            <!-- when loading, it will display "No keywords" -->
          </v-list-item-title>
          <v-list-item-subtitle>{{ $t("excludedKeywords") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> report_off </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            ><v-chip label class="mr-2 mb-1" v-for="company in loop.eliminatedCompanies" :key="company.name || company">{{
              company.name || company
            }}</v-chip>
            {{ loop.eliminatedCompanies && loop.eliminatedCompanies.length > 0 ? "" : $t("loopPreview.noCompanies") }}
            <!-- when loading, it will display "No excluded companies" -->
          </v-list-item-title>
          <v-list-item-subtitle>{{ $t("loopPreview.companies") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-subheader>
        {{ $t("loopPreview.yourInfo") }}
      </v-subheader>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> phone </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ loop.phone || $t("loopPreview.notAvailable") }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t("loopPreview.phone") }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>message-text</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> description </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ cvFilename || $t("loopPreview.notAvailable") }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t("loopPreview.cv") }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action v-if="fileUrl && !isPartner">
          <v-btn icon :href="fileUrl" target="_blank">
            <v-icon color="grey lighten-1">open_in_new</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="#27293d"> email </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ email }}</v-list-item-title>
          <v-list-item-subtitle>{{ isPartner ? "Client's email" : $t("loopPreview.email") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-subheader>
        {{ $t("loopPreview.options") }}
      </v-subheader>
      <v-list-item three-line>
        <v-list-item-icon>
          <v-icon color="#27293d"> forward_to_inbox </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("loopPreview.autoEmails.title") }}</v-list-item-title>
          <v-list-item-subtitle>{{
            directEmails ? $t("loopPreview.autoEmails.yes") : $t("loopPreview.autoEmails.no")
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <DisabledToggle :value="directEmails" />
        </v-list-item-action>
      </v-list-item>
      <v-list-item three-line v-if="!onlyEmail">
        <v-list-item-icon>
          <v-icon color="#27293d"> ballot </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t("loopPreview.autoApplications.title") }}</v-list-item-title>
          <v-list-item-subtitle>{{
            formApplications ? $t("loopPreview.autoApplications.yes") : $t("loopPreview.autoApplications.no")
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <DisabledToggle :value="formApplications" />
        </v-list-item-action>
      </v-list-item>

      <v-divider></v-divider>
      <v-subheader>
        {{ $t("loopPreview.template") }}
      </v-subheader>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title
            ><span class="text--secondary"> {{ $t("loopPreview.subject") }} </span>{{ subject }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <p class="px-5" v-html="body"></p>
      <v-divider></v-divider>
      <div v-if="formApplications">
        <v-subheader>
          {{ $t("loopPreview.cover") }}
        </v-subheader>
        <p class="px-5">
          <label class="cover-letter">
            {{ loop.coverLetter }}
          </label>
        </p>
        <v-divider></v-divider>
      </div>
    </v-list>
  </div>
</template>

<script>
import DisabledToggle from "@/components/ViewLoop/DisabledToggle.vue";
export default {
  components: {
    DisabledToggle,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Object,
    },
    cvFilename: {},
    fileUrl: {},
    directEmails: {},
    formApplications: {},
    subject: {},
    body: {},
    email: {
      type: String,
    },
    showJobTitleRow: {
      type: Boolean,
      default: true,
    },
    isPartner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    jobLocation() {
      if (this.loop.onlyRemoteJobs && this.loop.jobLocation === "Remote") {
        return this.$t("loopPreview.remote-anywhere");
      } else if (this.loop.onlyRemoteJobs) {
        return this.$t("loopPreview.remote-jobLocation", { 0: this.loop.jobLocation });
      } else {
        return this.loop.jobLocation;
      }
    },

    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },

    jobBoards() {
      return this.loop.scrapers?.join(", ");
    }
  },
};
</script>

<style scoped>
.cover-letter {
  white-space: pre-line;
}
</style>
