<template>
  <div class="pa-5">
    <div class="text-h5">Shared Comments</div>
    <div class="mb-3">
      These comments are shared between you and your client. Your client can see these by logging in with their account.
    </div>
    <SharedNotes
      :showCancel="false"
      :sharedNotes="sharedNotes"
      @submitNote="submitSharedNote($event)"
    />
  </div>
</template>

<script>
import appSDK from '@/client/AppSDK';
import SharedNotes from "@/components/partners/SharedNotes.vue";
export default {
  components: { SharedNotes },
  props: {
    loopId: String,
    clientId: String,
  },
  data() {
    return {
      sharedNotes: null,
    };
  },
  async created() {
    this.sharedNotes = await appSDK["partner.loop.sharedNotes.list"](
      this.clientId,
      this.loopId
    );
  },
  methods: {
    async submitSharedNote(text) {
      try {
        await appSDK["partner.loop.sharedNotes.create"](
          this.clientId,
          this.loopId,
          { text }
        );
        this.sharedNotes = await appSDK["partner.loop.sharedNotes.list"](
          this.clientId,
          this.loopId
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style>
</style>