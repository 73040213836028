<template>
  <AuthTemplate>
    <div class="subtitleBlock subtitleBlock-register">
      <label class="header2-semi-bold">{{ $t("Auth.Common.welcome") }}</label>
      <label class="subtitle header5">{{
        this.$t("Auth.Common.form_register_subtitle", { brand: $store.getters.brand })
      }}</label>
    </div>
    <div v-if="hasGoogleLogin">
      <div class="google-btn google-btn-register">
        <GoogleLoginButton />
      </div>
      <div class="or-lines">
        <hr />
        <span class="header5"> {{ $t("Auth.Common.or") }}</span>
        <hr />
      </div>
    </div>

    <form @submit.prevent="signUp">
      <div class="double-input" v-if="this.getFormFields.includes('first_name') && this.getFormFields.includes('last_name')">
        <Input
          class="input-form-small first-name"
          :title="this.$t('Generic.Form.first_name')"
          :required="true"
          :autocomplete="'given-name'"
          :error="this.errors.firstName"
          :value="this.model.firstName"
          :placeholder="this.$t('Generic.Form.first_name')"
          @input="value => handleInput(value, 'firstName')"
        />
        <Input
          class="input-form-small double_input_last_child"
          :title="this.$t('Generic.Form.last_name')"
          :required="true"
          :autocomplete="'family-name'"
          :error="this.errors.lastName"
          :value="this.model.lastName"
          :placeholder="this.$t('Generic.Form.last_name')"
          @input="value => handleInput(value, 'lastName')"
        />
      </div>
      <div v-if="this.getFormFields.includes('email')">
        <Input
          class="input-form-register"
          :title="this.$t('Generic.Form.email')"
          :required="true"
          :type="'email'"
          :autocomplete="'email'"
          :error="this.errors.email"
          :value="this.model.email"
          :placeholder="this.$t('Generic.Form.email')"
          @input="value => handleInput(value, 'email')"
        />
      </div>

      <div class="input-form-register" v-if="this.getFormFields.includes('job_location')">
        <label class="header5 input-title">{{ $t("loopPreview.location") }} <span class="required">*</span></label>
        <div class="input-field">
          <LocationAutocomplete :error="this.errors.jobLocation == '' ? false : true" @change="model.jobLocation = $event" />
          <label v-if="this.errors.jobLocation != ''" class="error-message header7">{{ this.errors.jobLocation }}</label>
        </div>
      </div>

      <div class="input-form-register" v-if="this.getFormFields.includes('phone')">
        <label class="header5 input-title">{{ $t("loopPreview.phone") }} <span class="required">*</span></label>
        <div class="input-field">
          <MazPhoneNumberInput
            :error="this.errors.phone == '' ? false : true"
            v-model="model.phone"
            class="phone-input"
            @update="handlePhone"
            size="md"
          />
          <label v-if="this.errors.phone != ''" class="error-message header7">{{ this.errors.phone }}</label>
        </div>
      </div>

      <div class="input-form-register" v-if="this.getFormFields.includes('resume')">
        <label class="header5 input-title">{{ $t("Generic.Common.resume") }} <span class="required">*</span></label>
        <div class="input-field">
          <v-file-input
            name="cv"
            label="Upload your Resume"
            outlined
            hint="PDF or Word file"
            persistent-hint
            :error="this.errors.resume == '' ? false : true"
            accept="application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ref="fileInput"
          ></v-file-input>
          <label v-if="this.errors.resume != ''" class="error-message header7">{{ this.errors.resume }}</label>
        </div>
      </div>

      <div class="terms-error header7">
        {{ this.errors.terms }}
      </div>
      <Button
        :title="this.$t('Auth.Common.create_an_account')"
        :type="'submit'"
        :outlined="false"
        :fullWidth="true"
        :loading="loading"
      ></Button>
    </form>
    <div class="login-block header5-semi-bold" v-if="whiteLabel">
      <label>
        {{ this.$t("Auth.Common.already_account") }}
        <span class="login" @click="login">{{ this.$t("Auth.Common.sign_in") }}</span></label
      >
    </div>
  </AuthTemplate>
</template>

<script>
import AuthTemplate from "@/pages/auth/components/AuthTemplate.vue";
import { mapGetters } from "vuex";
import GoogleLoginButton from "@/pages/auth/components/GoogleLoginButton.vue";
import { constant } from "@/store/constant";
import { USER_TOKEN_COOKIE } from "@/assets/js/constants";
import Cookies from "js-cookie";
import { Button, Input, CheckButton, Dropdown } from "@/framework/index";
import { MazPhoneNumberInput } from "maz-ui";
import LocationAutocomplete from "@/components/LocationAutocomplete.vue";
import EyeIn from "vue-material-design-icons/Eye.vue";
import EyeOut from "vue-material-design-icons/EyeOff.vue";
import { validateForm, handleInput, validateEmail, validateFirstName, validateLastName } from "@/helpers/utils/formUtils";

const user = constant.user;

export default {
  name: "SignUp",
  components: {
    AuthTemplate,
    GoogleLoginButton,
    Input,
    Button,
    CheckButton,
    EyeIn,
    EyeOut,
    MazPhoneNumberInput,
    LocationAutocomplete,
  },
  data() {
    return {
      model: {
        firstName: "",
        lastName: "",
        email: "",
        jobLocation: "",
        phone: "",
        phoneObj: {},
        formattedPhone: "",
        terms: false,
      },

      errors: {
        firstName: "",
        lastName: "",
        email: "",
        jobLocation: "",
        phone: "",
        resume: "",
        terms: "",
      },

      loading: false,
    };
  },

  computed: {
    ...mapGetters("user", {
      getStatus: user.getters.getStatus,
      getLoading: user.getters.getLoading,
      getUser: user.getters.getUser,
    }),

    getFormFields() {
      return this.$store.getters.configs.signUpFields;
    },

    getGroupNames() {
      return this.$store.getters.configs.groupNames;
    },

    getApiKey() {
      return this.$store.getters.configs.apiKey;
    },

    status() {
      return this.getStatus;
    },

    passwordType() {
      return this.hidePassword ? "password" : "text";
    },

    hasGoogleLogin() {
      return process.env.VUE_APP_CONFIGS == "loopcv-b2c";
    },

    isB2C() {
      return this.$store.getters.isB2C;
    },

    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },

    whiteLabel() {
      return !this.$store.getters.isWhitelabel;
    },

    brand() {
      return this.$store.getters.brand;
    },
  },

  watch: {
    "model.firstName"(value) {
      validateFirstName(this, value);
    },

    "model.lastName"(value) {
      validateLastName(this, value);
    },

    "model.email"(value) {
      validateEmail(this, value);
    },

    "model.jobLocation"(value) {},

    "model.phone"(value) {},

    "model.resume"(value) {},

    "model.password"(value) {
      validatePassword(this, value);
      if (this.model.passwordConfirm != "") {
        validateConfirmPassword(this, this.model.passwordConfirm);
      }
    },

    "model.passwordConfirm"(value) {
      validateConfirmPassword(this, value);
    },

    "model.terms"(value) {
      this.validateTerms(value);
    },
  },

  created() {},

  methods: {
    async signUp() {
      this.loading = true;
      try {
        this.$amplitude.logEvent("ATTEMPT_SIGN_IN_OR_SIGN_UP", {
          method: "email-password",
          type: "sign-up",
        });
        const data = this.createPostData();
        if (Object.values(this.errors).every(value => value === "")) {
          await this.$store.dispatch(user.actions.DynamicRegister, data);

          this.$amplitude.logEvent("SIGN_UP", { method: "email-password" });
          this.$router.push("/");
        }
      } catch (e) {
        if (e?.data?.error === "email-already-exists") {
          this.$notify({
            message: this.$t("Auth.Error.email_exists"),
            type: "danger",
          });
        } else if (e?.data?.message) {
          this.$notify({
            message: e.data.message,
            type: "danger",
          });
        } else {
          this.$notify({
            message: this.$t("Generic.Messages.Error.common"),
            type: "danger",
          });
        }
      }
      this.loading = false;
    },

    createPostData() {
      const data = {};

      if (this.model.email) {
        data.userEmail = this.model.email;
      } else {
        this.errors.email = this.$t("Generic.Form.required_message", {
          value: this.$t("Generic.Form.email"),
        });
      }
      if (this.getFormFields.includes("first_name")) {
        if (this.model.firstName) {
          data.firstName = this.model.firstName;
        } else {
          this.errors.firstName = this.$t("Generic.Form.required_message", {
            value: this.$t("Generic.Form.first_name"),
          });
        }
      }
      if (this.getFormFields.includes("last_name")) {
        if (this.model.lastName) {
          data.lastName = this.model.lastName;
        } else {
          this.errors.lastName = this.$t("Generic.Form.required_message", {
            value: this.$t("Generic.Form.last_name"),
          });
        }
      }
      if (this.getFormFields.includes("job_location")) {
        if (this.model.jobLocation) {
          data.location = this.model.jobLocation;
        } else {
          this.errors.jobLocation = this.$t("Generic.Form.required_message", {
            value: this.$t("loopPreview.location"),
          });
        }
      }
      if (this.getFormFields.includes("phone")) {
        if (this.model.phoneObj) {
          data.phoneData = JSON.stringify(this.model.phoneObj);
        }
        this.errors.phone = this.$t("Generic.Form.required_message", {
          value: this.$t("loopPreview.phone"),
        });
      }
      if (this.getFormFields.includes("resume")) {
        data.cv = document.querySelector('input[type="file"]').files[0];
        data.coverLetter =
          "The role is very appealing to me, and I believe that my experience and education make me a highly competitive candidate for this position. Please see my resume for additional information on my experience. \n\nThank you for your time and consideration. \nI look forward to speaking with you about this employment opportunity.";
        if (!data.cv) {
          this.errors.resume = this.$t("Generic.Form.required_message", {
            value: this.$t("Generic.Common.resume"),
          });
        }
      }

      if (this.getFormFields.includes("groupNames")) {
        data.groupName = this.getGroupNames;
      }

      if (this.getFormFields.includes("apiKey")) {
        data.apiKey = this.getApiKey;
      }

      return data;
    },

    login() {
      this.$router.push({ path: "/login" });
    },

    removeCookies() {
      Cookies.remove(USER_TOKEN_COOKIE);
      this.$notify({
        message: this.$t("Generic.Messages.Error.unauthorized", { email: this.supportEmail }),
        type: "danger",
      });
      this.login();
    },

    validateTerms(value) {
      if (value == false) {
        this.errors.terms = this.$t("Auth.Error.disagree_terms", { brand: this.brand }).toString();
      } else {
        this.errors.terms = "";
      }
    },

    // Handle Form
    handleInput(value, name) {
      handleInput(this, value, name);
    },

    handleEyePassword() {
      this.hidePassword = !this.hidePassword;
    },

    handleEyeConfirmPassword() {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    },

    handlePhone(data) {
      if (data && (data.countryCode || data.isValid || data.phoneNumber)) {
        this.model.phoneObj = data;
      }
    },
  },
};
</script>

<style scoped>
@import "./register.css";
</style>
