<template>
  <AuthTemplate>
    <div class="subtitleBlock">
      <label class="header2-semi-bold">{{ $t("Auth.Common.forgot_password") }}</label>
      <label class="subtitle header5">{{ this.$t("Auth.Common.forgot_your_password_subtitle") }}</label>
    </div>
    <form @submit.prevent="forgotPassword">
      <Input
        :type="'email'"
        :autocomplete="'email'"
        :value="this.model.email"
        class="input-form"
        :title="this.$t('Generic.Form.email')"
        :required="true"
        :error="this.errors.email"
        :errorExample="exampleEmail"
        :placeholder="this.$t('Generic.Form.placeholder_value', { value: this.$t('Generic.Form.email').toLowerCase() })"
        @input="value => handleInput(value, 'email')"
      />
      <Button
        class="button"
        :title="this.$t('Auth.Common.send_recovery_email')"
        :type="'submit'"
        :fullWidth="true"
        :loading="loading"
      ></Button>
    </form>

    <div class="back-to-login header5-semi-bold">
      <label>
        {{ this.$t("Auth.Common.return_to") }}
        <span class="login" @click="login">{{ this.$t("Auth.Common.sign_in") }}</span></label
      >
    </div>
  </AuthTemplate>
</template>

<script>
import AuthTemplate from "@/pages/auth/components/AuthTemplate.vue";
import { constant } from "@/store/constant";
import { Button, Input } from "@/framework/index";
import { validateForm, handleInput, validateEmail } from "@/helpers/utils/formUtils";

const user = constant.user;

export default {
  name: "ForgotPassword",
  data() {
    return {
      model: { email: "" },
      errors: { email: "" },
      exampleEmail: "",
      loading: false,
    };
  },
  components: {
    AuthTemplate,
    Input,
    Button,
  },

  watch: {
    "model.email"(value) {
      validateEmail(this, value);
    },
  },

  methods: {
    async forgotPassword() {
      if (validateForm(this)) {
        this.loading = true;
        try {
          await this.$store.dispatch(user.actions.ForgotPassword, this.model.email);
          this.$notify({
            message: this.$t("Generic.Messages.Success.forgot_password"),
            type: "success",
          });
        } catch (e) {
          if (e?.data?.message) {
            this.$notify({
              message: e.data.message,
              type: "danger",
            });
          } else {
            this.$notify({
              message: this.$t("errors.unexpected"),
              type: "danger",
            });
          }
        }
        this.loading = false;
      } else {
        validateEmail(this, this.email);
      }
    },

    login() {
      this.$router.push({ path: "/login" });
    },

    handleInput(value, name) {
      handleInput(this, value, name);
    },
  },
};
</script>

<style scoped>
.button {
  margin-top: 40px;
}

.back-to-login {
  margin-top: 20px;
}

.login {
  color: var(--blue);
  cursor: pointer;
  font-weight: 700;
}

.login:hover {
  text-decoration: underline;
}
</style>
