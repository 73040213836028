<template>
  <AuthTemplate>
    <div class="subtitleBlock subtitleBlock-register">
      <label class="header2-semi-bold">{{ $t("Auth.Common.partner_form_title") }}</label>
      <label class="subtitle header5">{{ this.$t("Auth.Common.partner_form_description", { brand: $store.getters.brand }) }}</label>
    </div>

    <form @submit.prevent="signUp">
      <div class="double-input">
        <Input
          class="input-form-small first-name"
          :title="this.$t('Generic.Form.first_name')"
          :required="true"
          :autocomplete="'given-name'"
          :error="this.errors.firstName"
          :value="this.model.firstName"
          :placeholder="this.$t('Generic.Form.first_name')"
          @input="value => handleInput(value, 'firstName')"
        />
        <Input
          class="input-form-small double_input_last_child"
          :title="this.$t('Generic.Form.last_name')"
          :required="true"
          :autocomplete="'family-name'"
          :error="this.errors.lastName"
          :value="this.model.lastName"
          :placeholder="this.$t('Generic.Form.last_name')"
          @input="value => handleInput(value, 'lastName')"
        />
      </div>
      <Input
        class="input-form-register"
        :title="this.$t('Generic.Headers.company')"
        :required="true"
        :type="'text'"
        :autocomplete="'organization'"
        :error="this.errors.company"
        :value="this.model.company"
        :placeholder="this.$t('Generic.Headers.company')"
        @input="value => handleInput(value, 'company')"
      />
      <Input
        class="input-form-register"
        :title="this.$t('Generic.Form.email')"
        :required="true"
        :type="'email'"
        :autocomplete="'email'"
        :error="this.errors.email"
        :value="this.model.email"
        :placeholder="this.$t('Generic.Form.email')"
        @input="value => handleInput(value, 'email')"
      />
      <div class="double-input">
        <Input
          class="input-form-small password"
          :title="this.$t('Generic.Form.password')"
          :required="true"
          :autocomplete="'new-password'"
          :error="this.errors.password"
          :value="this.model.password"
          :placeholder="this.$t('Generic.Form.password')"
          :type="this.hidePassword ? 'password' : 'text'"
          @input="value => handleInput(value, 'password')"
        >
          <EyeIn v-if="this.hidePassword" @click="handleEyePassword" />
          <EyeOut v-if="!this.hidePassword" @click="handleEyePassword" />
        </Input>
        <Input
          class="input-form-small double_input_last_child"
          :title="this.$t('Auth.Common.confirm_password')"
          :required="true"
          :autocomplete="'new-password'"
          :error="this.errors.passwordConfirm"
          :value="this.model.passwordConfirm"
          :type="this.hideConfirmPassword ? 'password' : 'text'"
          :placeholder="this.$t('Auth.Common.confirm_password')"
          @input="value => handleInput(value, 'passwordConfirm')"
        >
          <EyeIn v-if="this.hideConfirmPassword" @click="handleEyeConfirmPassword" />
          <EyeOut v-if="!this.hideConfirmPassword" @click="handleEyeConfirmPassword" />
        </Input>
      </div>
      <div class="terms">
        <CheckButton @input="value => handleInput(value, 'terms')" :checked="this.model.terms" />
        <label class="terms-title header5-semi-bold"
          >{{ this.$t("Auth.Common.agree_terms") }}
          <a target="_blank" href="https://www.loopcv.pro/terms">{{ this.$t("Auth.Common.terms") }}, </a>
          {{ this.$t("Generic.Common.and") }}
          <a target="_blank" href="https://www.loopcv.pro/privacy">{{ this.$t("Auth.Common.privacy_policy") }} *</a></label
        >
      </div>
      <div class="terms-error header7 pb-3">
        {{ this.errors.terms }}
      </div>
      <Button
        :title="this.$t('Auth.Common.create_an_account')"
        :type="'submit'"
        :outlined="false"
        :fullWidth="true"
        :loading="loading"
      ></Button>
    </form>
  </AuthTemplate>
</template>

<script>
import AuthTemplate from "@/pages/auth/components/AuthTemplate.vue";
import { Button, Input, CheckButton } from "@/framework/index";
import EyeIn from "vue-material-design-icons/Eye.vue";
import EyeOut from "vue-material-design-icons/EyeOff.vue";
import { constant } from "@/store/constant";
import {
  validateForm,
  handleInput,
  validateEmail,
  validateConfirmPassword,
  validatePassword,
  validateFirstName,
  validateLastName,
  validateCompany
} from "@/helpers/utils/formUtils";

const user = constant.user;

export default {
  name: "PartnerSignUp",
  components: {
    AuthTemplate,
    Input,
    Button,
    CheckButton,
    EyeIn,
    EyeOut,
  },
  data() {
    return {
      model: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        company: "",
        terms: false,
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        company: "",
        terms: "",
      },
      hidePassword: true,
      hideConfirmPassword: true,
      loading: false,
    };
  },

  computed: {
    brand() {
      return this.$store.getters.brand;
    },
  },

  methods: {
    async signUp() {
      if (validateForm(this) && this.model.terms === true) {
        this.loading = true;
        try {
          await this.$store.dispatch('user/partnerSignUp', {
            ...this.model
          });
          await this.$store.dispatch(user.actions.Auth);

          this.$router.push("/overview");
        } catch (e) {

          if (e?.data?.error === "email-already-exists") {
            this.$notify({
              message: this.$t("Auth.Error.email_exists"),
              type: "danger",
            });
          } else {
            this.$notify({
              message: this.$t("Generic.Messages.Error.sign_in", { email: this.supportEmail }),
              type: "danger",
            });
          }
        }
        this.loading = false;
      } else {
        validateFirstName(this, this.model.firstName);
        validateLastName(this, this.model.lastName);
        validateEmail(this, this.model.email);
        validateCompany(this, this.model.company);
        validatePassword(this, this.model.password);
        validateConfirmPassword(this, this.model.passwordConfirm);
        this.validateTerms(this.model.terms);
      }
    },

    validateTerms(value) {
      if (value == false) {
        this.errors.terms = this.$t("Auth.Error.disagree_terms", { brand: this.brand }).toString();
      } else {
        this.errors.terms = "";
      }
    },

    handleInput(value, name) {
      handleInput(this, value, name);
    },

    handleEyePassword() {
      this.hidePassword = !this.hidePassword;
    },

    handleEyeConfirmPassword() {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    },
  },
};
</script>

<style scoped>
@import "./register.css";
</style>
