<template>
  <div class="filters">
    <v-select
      v-model="filter.applicationMethod"
      :items="initApplicationMethodFilters"
      :label="$t('results.filter')"
      class=""
      outlined
      hide-details
      dense
      @change="handleApplicationMethod"
    ></v-select>
    <MultipleSelectDropDown
      :items="this.platformItems.map(obj => obj.text)"
      :label="this.$t('results.platforms')"
      :activeItem="filter.platforms"
      @change="handlePlatform"
    />
    <v-text-field
      outlined
      hide-details
      :label="$t('results.filterCompanies')"
      class="filter_companies"
      v-model="filter.company"
      dense
      @change="handleCompanies"
    ></v-text-field>
    <div @click="submit">
      <SubmitButton />
    </div>
  </div>
</template>

<script>
import MultipleSelectDropDown from "@/components/DropDowns/MultipleSelectDropDown.vue";
import { Platforms } from "@/assets/js/constants";
import Cookies from "js-cookie";

import { SubmitButton } from "@/framework";
export default {
  components: {
    SubmitButton,
    MultipleSelectDropDown,
  },

  props: {
    allowExtension: {
      type: Boolean,
    },
  },

  data() {
    return {
      platformItems: [],
      filter: {
        applicationMethod: "",
        platforms: [],
        company: "",
      },
    };
  },

  created() {
    Platforms.forEach(element => {
      this.platformItems.push({ text: element, value: element });
    });
  },

  mounted() {
    const filterObj = Cookies.get("match_filter");
    if (filterObj) {
      const filters = JSON.parse(filterObj);
      this.filter.applicationMethod = filters.applicationMethod;
      this.filter.platforms = filters.platforms;
      this.filter.company = filters.company;
    }
  },

  computed: {
    initApplicationMethodFilters() {
      const options = [
        { text: this.$t("results.filters.all"), value: "all" },
        { text: this.$t("results.filters.emails"), value: "emails" },
      ];
      if (!this.onlyEmail) {
        options.push({
          text: this.$t("results.filters.manual_applications"),
          value: "manualApplications",
        });
        options.push({
          text: this.$t("results.filters.automated_applications"),
          value: "applications",
        });
      }
      options.push({
        text: this.$t("results.filters.manual_applications_with_interview"),
        value: "applicationInInterview",
      });
      options.push(
        { text: this.$t("results.filters.questions"), value: "questions" },
        { text: this.$t("results.filters.applyOnDemand"), value: "applyOnDemand" },
      );
      if (this.allowExtension) {
        options.push({
          text: this.$t("results.filters.easyApply"),
          value: "easyApply",
        });
      }
      return options;
    },

    getApplicationMethodValue() {
      return this.applicationMethod;
    },

    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
  },

  methods: {
    submit() {
      Cookies.set("match_filter", JSON.stringify(this.filter), { secure: true, sameSite: 'Lax' });
      this.$emit("onFilter", JSON.stringify(this.filter));
    },

    handleApplicationMethod(value) {
      this.filter.applicationMethod = value;
    },

    handlePlatform(values) {
      this.filter.platforms = values;
    },

    handleCompanies(value) {
      this.filter.company = value;
    },

    updatedPlatform(value) {
      this.filter.platforms = value;
    },

    updateFilters(value) {
      this.filter = JSON.parse(value);
    },
  },
};
</script>

<style scoped>
.filters {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  padding-inline: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}
</style>

<style>
.filter_companies div div div label {
  top: 30% !important;
}
</style>
