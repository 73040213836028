<template>
  <div>
    <div>
      <div>
        <div class="d-flex pa-2">
          <div>
            <h5 class="text-body-1 my-2 mx-3">
              {{ $t(`automations.stats.statisticsOneClient`) }}
            </h5>
          </div>

          <v-spacer></v-spacer>
          <v-btn text :to="`/clients/${clientId}/matches`">View all Matches</v-btn>
        </div>
        <!-- <div class="filters">
          <DateTime
            @input="handleFromDate"
            :label="$t('Overview.ApplicationStats.from_date')"
            width="10rem"
            :value="filter.fromDate"
          />
          - &nbsp;
          <DateTime
            @input="handleToDate"
            :label="$t('Overview.ApplicationStats.to_date')"
            width="10rem"
            :value="filter.toDate"
          />
          <Dropdown
            :value="filter.dateOption"
            :options="[
              $t('Overview.ApplicationStats.Weekly'),
              $t('Overview.ApplicationStats.monthly'),
              $t('Overview.ApplicationStats.6_months'),
            ]"
            @input="handleDateOption"
          />
        </div> -->
        <app-loader v-if="loading" />
        <div v-else class="row">
          <div class="col-md-3 col-sm-6 col-xs-12 px-2">
            <StatsItemCard :value="stats.totalJobs" icon="xdCV" :label="$t('automations.stats.totalJobs')" />
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12 px-2">
            <StatsItemCard :value="stats.totalMatches" icon="xdConnections" :label="$t('automations.stats.totalMatches')" />
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12 px-2">
            <StatsItemCard
              :value="stats.totalMatchesWithEmails"
              icon="xdClosedEmail"
              :label="$t('automations.stats.emailsSent')"
            />
          </div>
          <div class="col-md-3 col-sm-6 col-xs-12 px-2" v-if="!onlyEmail">
            <StatsItemCard
              :value="stats.totalMatchesWithApplications"
              icon="xdApplication"
              :label="$t('automations.stats.applicationsSubmitted')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsItemCard from "@/components/overview/StatsItemCard.vue";
import appSDK from "@/client/AppSDK";
import { Dropdown, DateTime } from "@/framework";
import { formattedDateYYYYMMDD } from "@/helpers/dateUtils";

export default {
  components: {
    StatsItemCard,
    Dropdown,
    DateTime,
  },

  props: {
    clientId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      filter: {
        fromDate: "",
        toDate: "",
        dateOption: "",
      },
      stats: {},
    };
  },

  created() {
    const now = new Date();
    this.filter.fromDate = formattedDateYYYYMMDD(now);
    now.setDate(now.getDate() + 7);
    this.filter.toDate = formattedDateYYYYMMDD(now);
    this.filter.dateOption = this.$t("Overview.ApplicationStats.Weekly");
    if (!this.clientId) {
      console.error("no clientid");
    }
    this.getStatistics();
  },

  computed: {
    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
  },

  methods: {
    async handleFromDate(value) {
      this.filter.fromDate = value;
      this.filter.dateOption = this.$t("Overview.ApplicationStats.custom_date");
    },

    async handleToDate(value) {
      this.filter.toDate = value;
      this.filter.dateOption = this.$t("Overview.ApplicationStats.custom_date");
    },

    getStatistics() {
      this.loading = true;
      appSDK["partner.childUser.overviewStats"](this.clientId)
        .then(stats => {
          this.stats = stats;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },

    async handleDateOption(value) {
      const now = new Date();
      switch (value) {
        case "Last week":
          this.filter.dateOption = this.$t("Overview.ApplicationStats.Weekly");
          this.filter.fromDate = formattedDateYYYYMMDD(now);
          now.setDate(now.getDate() + 7);
          this.filter.toDate = formattedDateYYYYMMDD(now);
          break;
        case "Last month":
          this.filter.dateOption = this.$t("Overview.ApplicationStats.monthly");
          this.filter.fromDate = formattedDateYYYYMMDD(now);
          now.setMonth(now.getMonth() + 1);
          this.filter.toDate = formattedDateYYYYMMDD(now);
          break;
        case "Last 6 months":
          this.filter.dateOption = this.$t("Overview.ApplicationStats.6_months");
          this.filter.fromDate = formattedDateYYYYMMDD(now);
          now.setMonth(now.getMonth() + 6);
          this.filter.toDate = formattedDateYYYYMMDD(now);
          break;
      }
      this.getStatistics();
    },
  },
};
</script>

<style></style>
