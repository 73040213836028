<template>
  <v-row>
    <app-header />

    <div class="col-12">
      <div class="card">
        <div class="card-header" v-if="clientId">
          <v-row>
            <div class="col-md-5">
              <v-btn icon :to="`/clients/${clientId}/loops`" style="margin-top: 0px">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <span>All Loops</span>
            </div>

            <div class="col-md-7"></div>
          </v-row>
        </div>
        <h4 class="text-subtitle-2 mx-7 mb-2" v-if="!clientId">
          <span>{{ $t("results.results_loops") }}</span>
        </h4>
        <v-card flat color="white">
          <div class="mb-8">
            <Results all :clientId="clientId" />
          </div>
        </v-card>
      </div>
    </div>
  </v-row>
</template>

<script>
import Results from "@/pages/loops/results.vue";

export default {
  components: { Results },
  props: ["clientId"],
};
</script>

<style></style>
