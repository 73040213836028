<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel v-for="(item, i) in reasonItems" :key="i">
      <v-expansion-panel-header
        >{{ $t(`matchReasons.${item.reason}`) }} - {{ $t("jobs", [item.count]) }}</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <component
          :is="examinationComponent[item.reason]"
          :reason="item.reason"
          :jobCount="item.count"
          :loopId="loopId"
          :loop="loop"
          :clientId="clientId"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ExaminationJobTitle from "@/components/examination/ExaminationJobTitle.vue";
import ExaminationLocation from "@/components/examination/ExaminationLocation.vue";
import ExaminationKeywords from "@/components/examination/ExaminationKeywords.vue";
import ExaminationCV from "@/components/examination/ExaminationCV.vue";

export default {
  components: {
    ExaminationJobTitle,
    ExaminationLocation,
    ExaminationKeywords,
    ExaminationCV,
  },
  props: {
    loopId: {},
    reasonItems: {},
    loop: {},
    clientId: String,
  },
  data() {
    return {
      panel: null,
      items: 5,

      examinationComponent: {
        NOT_MATCH_DUE_CV_SCORE: "ExaminationCV",
        NOT_MATCH_DUE_DISTANCE: "ExaminationLocation",
        NOT_MATCH_DUE_ELIMINATED_COMPANY: null,
        NOT_MATCH_DUE_JOB_TITLE_SCORE: "ExaminationJobTitle",
        NOT_MATCH_DUE_MISSING_KEYWORDS: "ExaminationKeywords",
      },
    };
  },

  mounted() {
    console.log(this.reasonItems);
    this.reasonItems = this.reasonItems.filter(x => x.reason != "NOT_MATCHED_DUE_TO_BEING_DUPLICATE");
    this.reasonItems = this.reasonItems.filter(x => x.reason != "NOT_MATCH_DUE_EXCLUDED_KEYWORDS");
    this.reasonItems = this.reasonItems.filter(x => x.reason != "NOT_MATCH_DUE_LANGUAGE_REQUIREMENT");
    this.reasonItems = this.reasonItems.filter(x => x.reason != "NOT_MATCH_DUE_REMOTE_REQUIREMENT");
  },
};
</script>

<style></style>
