<template>
  <div>
    <app-loader v-if="loading" />
    <div v-else>
      <div>
        <h5 class="text-h1 my-5 mx-3 overview-header">{{ $t("overview.statistics") }}</h5>
        <div class="row">
          <div class="col-sm-4 col-xs-12">
            <StatsItemCard @click="goToLoops" :value="stats.totalLoops" icon="xdLoop" :label="$t('overview.activeLoops')" />
          </div>
          <div class="col-sm-4 col-xs-12">
            <StatsItemCard :value="stats.totalEmailTemplates" icon="xdOpenedEmail" :label="$t('overview.emailTemplates')" />
          </div>
          <div class="col-sm-4 col-xs-12">
            <StatsItemCard
              @click="goToProfile"
              :value="stats.totalCVs"
              icon="xdCV"
              iconStyles="padding-left: 4px;"
              :label="`CVs added to ${brand}`"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 col-xs-12">
            <StatsItemCard :value="stats.totalMatches" icon="xdConnections" :label="$t('automations.stats.totalMatches')" />
          </div>
          <div class="col-sm-4 col-xs-12">
            <StatsItemCard
              :value="stats.totalMatchesWithEmails"
              icon="xdClosedEmail"
              :label="$t('overview.emailsSent')"
              iconStyles="padding-left: 4px;"
            />
          </div>
          <div class="col-sm-4 col-xs-12" v-if="!onlyEmail">
            <StatsItemCard
              :value="stats.totalMatchesWithApplications"
              icon="xdApplication"
              :label="$t('automations.stats.applicationsSubmitted')"
            />
          </div>
        </div>
      </div>
      <div v-if="stats.totalLoops && stats.totalMatches">
        <h5 class="text-h1 my-5 mx-3">{{ $t("matches") }}</h5>
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <v-card flat>
              <div class="pa-3 d-flex align-center">
                <div class="icon-avatar size-38">
                  <app-icon name="xdConnections" style="width: 16px" />
                </div>
                <span class="text-h4 px-2">{{ $t("overview.matchesByJobTitle") }}</span>
              </div>
              <MatchesPerLoopPieChart class="ma-5" :matchesPerLoopSummary="stats.totalMatchesPerLoop" />
            </v-card>
          </div>
          <div class="col-md-8 col-sm-12">
            <v-card flat>
              <div class="pa-3 d-flex align-center">
                <div class="d-flex align-center">
                  <div class="icon-avatar size-38">
                    <app-icon name="xdSearch" style="padding-bottom: 2px; padding-left: 3px" />
                  </div>
                  <span class="text-h4 px-2">{{ $t("automations.stats.recentMatches") }}</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn text :to="`/matches`">{{ $t("overview.viewAll", [stats.totalMatches]) }}</v-btn>
              </div>
              <RecentMatchesTable :recentMatches="stats.recentMatches" />
            </v-card>
          </div>
        </div>
      </div>
      <div class="row" v-else-if="!stats.totalLoops && showLoops">
        <div class="flex-c">
          <v-btn color="primary" @click="goToNewLoop">
            {{ $t("automations.new") }}
          </v-btn>
        </div>
      </div>
      <div v-else-if="!stats.totalMatches && showLoops">
        <div class="row d-block text-center mt-5">
          {{ $t("overview.noMatches") }}
        </div>
        <div class="row">
          <div class="flex-c">
            <v-btn color="primary" @click="goToNewLoop">
              {{ $t("automations.new") }}
            </v-btn>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import StatsItemCard from "@/components/overview/StatsItemCard.vue";
import MatchesPerLoopPieChart from "@/components/overview/MatchesPerLoopPieChart.vue";
import RecentMatchesTable from "@/components/overview/RecentMatchesTable.vue";
import { constant } from "@/store/constant";
const user = constant.user;

export default {
  components: {
    StatsItemCard,
    MatchesPerLoopPieChart,
    RecentMatchesTable,
  },

  data() {
    return {
      loading: true,

      stats: {},
    };
  },

  computed: {
    brand() {
      return this.$store.getters.brand;
    },
    showLoops() {
      return !this.$store.getters.configs?.hideLoops;
    },
    supportEmail() {
      return this.$store.getters.configs.supportEmail;
    },
    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
  },

  created() {
    this.getStats();
  },

  methods: {
    goToNewLoop() {
      this.$amplitude.logEvent("CREATE_NEW_LOOP_OVERVIEW_BUTTON");
      this.$router.push("/loops/create");
    },

    goToLoops() {
      this.$amplitude.logEvent("CHECK_LOOPS_OVERVIEW_BUTTON");
      this.$router.push("/loops");
    },

    goToProfile() {
      this.$amplitude.logEvent("CVS_UPLOADED_OVERVIEW_BUTTON");
      this.$router.push("/profile");
    },

    async getStats() {
      try {
        this.stats = await this.$store.dispatch(user.actions.OverviewStats);
      } catch (e) {
        this.$notify({
          message: e.message || this.$t("Generic.Messages.Error.common_error", { email: this.supportEmail }),
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.overview-header {
  font: normal normal 800 18px/24px Manrope;
  letter-spacing: 0.27px;
  color: #35383c;
  opacity: 1;
}
</style>
