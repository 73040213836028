<template>
  <v-card flat height="97" class="d-flex justify-center align-center px-1">
    <v-list-item two-line>
      <v-list-item-content>
        <div class="stats-item-card-label">
          {{ label }}
        </div>
        <div class="text-h3">
          {{ value || 0 }}
        </div>
      </v-list-item-content>
      <div class="icon-avatar">
        <app-icon :name="icon" :style="iconStyles"></app-icon>
      </div>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  components: {
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconStyles: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
        duration: 3,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.stats-item-card-label {
  font: normal normal 600 15px/20px Manrope;
  letter-spacing: 0.15px;
  color: #35383c;
  opacity: 1;
}
</style>