import * as Sentry from "@sentry/vue";
import { Vue as VueIntegration, CaptureConsole, ExtraErrorData } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
import Vue from "vue";
import createApp from "./createApp";

// Styles
import "@/scss/index.scss";
import "@/assets/css/nucleo-icons.css";

if (process.env.VUE_APP_SENTRY === "enable") {
  try {
    console.log(process.env.APP_VERSION);
    Sentry.init({
      Vue,
      attachProps: true,
      logErrors: true,
      tracing: true,
      tracingOptions: {
        trackComponents: true,
      },
      attachStacktrace: true,
      dsn: "https://bbd65ecdb53b44419dc23257186b5ca0@o343450.ingest.sentry.io/5424407",
      release: `loopcv-dashboard@${process.env.APP_VERSION}`,
      ignoreErrors: [
        "[Rewardful] Request failed",
        "Invalid referral",
        "[analytics.js] Failed to load Analytics.js",
        "Non-Error exception captured",
        "Non-Error promise rejection captured",
        "Network request failed",
        "Failed to fetch",
        "NetworkError",
        "A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
        "prosemirror-model",
        "Failed to execute 'setStart' on 'Range'",
        "Navigation cancelled",
        "Avoided redundant navigation",
        "Redirected when going from",
        "Error sending segment performance metrics",
        "NavigationDuplicated",
      ],
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ["api.loopcv.com", "app.loopcv.pro", "b2b-api-staging.loopcv.com"],
        }),
        new ExtraErrorData({ depth: 6 }), // get nested properties from console
        new CaptureConsole({
          levels: ["error"],
        }),
      ],
      sampleRate: process.env.NODE_ENV === "development" ? 1 : process.env.VUE_APP_MODE == "b2b" ? 0.3 : 0.05, // lower than 1 in production
      tracesSampleRate: process.env.NODE_ENV === "development" ? 1 : 0.01, // lower than 1 in production
      environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
      normalizeDepth: 6, // default is 3, but suppresses nested properties in objects
    });

    // Global error handler for Vue.js
    Vue.config.errorHandler = (err, vm, info) => {
      Sentry.captureException(err);
    };

    // Global error handler for unhandled promise rejections
    window.addEventListener('unhandledrejection', event => {
      Sentry.captureException(event.reason);
    });

  } catch (e) {
    console.warn(e);
  }
}

createApp();
