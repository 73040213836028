<template>
  <AuthTemplate>
    <div class="subtitleBlock subtitleBlock-register">
      <label class="header2-semi-bold">{{ $t("Auth.Common.welcome") }}</label>
      <label class="subtitle header5">{{ this.$t("Auth.Common.login-subtitle", { brand: $store.getters.brand }) }}</label>
    </div>
    <div v-if="hasGoogleLogin">
      <div class="google-btn google-btn-register">
        <GoogleLoginButton />
      </div>
      <div class="or-lines">
        <hr />
        <span class="header5"> {{ $t("Auth.Common.or") }}</span>
        <hr />
      </div>
    </div>

    <form @submit.prevent="signUp">
      <div class="double-input">
        <Input
          class="input-form-small first-name"
          :title="this.$t('Generic.Form.first_name')"
          :required="true"
          :autocomplete="'given-name'"
          :error="this.errors.firstName"
          :value="this.model.firstName"
          :placeholder="this.$t('Generic.Form.first_name')"
          @input="value => handleInput(value, 'firstName')"
        />
        <Input
          class="input-form-small double_input_last_child"
          :title="this.$t('Generic.Form.last_name')"
          :required="true"
          :autocomplete="'family-name'"
          :error="this.errors.lastName"
          :value="this.model.lastName"
          :placeholder="this.$t('Generic.Form.last_name')"
          @input="value => handleInput(value, 'lastName')"
        />
      </div>
      <Input
        class="input-form-register"
        :title="this.$t('Generic.Form.email')"
        :required="true"
        :type="'email'"
        :autocomplete="'email'"
        :error="this.errors.email"
        :value="this.model.email"
        :placeholder="this.$t('Generic.Form.email')"
        @input="value => handleInput(value, 'email')"
      />
      <div class="double-input">
        <Input
          class="input-form-small password"
          :title="this.$t('Generic.Form.password')"
          :required="true"
          :autocomplete="'new-password'"
          :error="this.errors.password"
          :value="this.model.password"
          :placeholder="this.$t('Generic.Form.password')"
          :type="this.hidePassword ? 'password' : 'text'"
          @input="value => handleInput(value, 'password')"
        >
          <EyeIn v-if="this.hidePassword" @click="handleEyePassword" />
          <EyeOut v-if="!this.hidePassword" @click="handleEyePassword" />
        </Input>
        <Input
          class="input-form-small double_input_last_child"
          :title="this.$t('Auth.Common.confirm_password')"
          :required="true"
          :autocomplete="'new-password'"
          :error="this.errors.passwordConfirm"
          :value="this.model.passwordConfirm"
          :type="this.hideConfirmPassword ? 'password' : 'text'"
          :placeholder="this.$t('Auth.Common.confirm_password')"
          @input="value => handleInput(value, 'passwordConfirm')"
        >
          <EyeIn v-if="this.hideConfirmPassword" @click="handleEyeConfirmPassword" />
          <EyeOut v-if="!this.hideConfirmPassword" @click="handleEyeConfirmPassword" />
        </Input>
      </div>
      <div class="terms">
        <CheckButton @input="value => handleInput(value, 'terms')" :checked="this.model.terms" />
        <label class="terms-title header5-semi-bold"
          >{{ this.$t("Auth.Common.agree_terms") }}
          <a target="_blank" href="https://www.loopcv.pro/terms">{{ this.$t("Auth.Common.terms") }}, </a>
          <a target="_blank" href="https://www.loopcv.pro/questions/refund/">{{ this.$t("Auth.Common.refund") }}</a>
          {{ this.$t("Generic.Common.and") }}
          <a target="_blank" href="https://www.loopcv.pro/privacy">{{ this.$t("Auth.Common.privacy_policy") }} *</a></label
        >
      </div>
      <div class="terms-error header7">
        {{ this.errors.terms }}
      </div>
      <Button
        :title="this.$t('Auth.Common.create_an_account')"
        :type="'submit'"
        :outlined="false"
        :fullWidth="true"
        :loading="loading"
      ></Button>
    </form>
    <div class="login-block header5-semi-bold" v-if="whiteLabel">
      <label>
        {{ this.$t("Auth.Common.already_account") }}
        <span class="login" @click="login">{{ this.$t("Auth.Common.sign_in") }}</span></label
      >
    </div>
  </AuthTemplate>
</template>

<script>
import AuthTemplate from "@/pages/auth/components/AuthTemplate.vue";
import { mapGetters } from "vuex";
import GoogleLoginButton from "@/pages/auth/components/GoogleLoginButton.vue";
import { constant } from "@/store/constant";
import { USER_TOKEN_COOKIE } from "@/assets/js/constants";
import Cookies from "js-cookie";
import { Button, Input, CheckButton } from "@/framework/index";
import EyeIn from "vue-material-design-icons/Eye.vue";
import EyeOut from "vue-material-design-icons/EyeOff.vue";
import {
  validateForm,
  handleInput,
  validateEmail,
  validateConfirmPassword,
  validatePassword,
  validateFirstName,
  validateLastName,
} from "@/helpers/utils/formUtils";

const user = constant.user;

export default {
  name: "SignUp",
  components: {
    AuthTemplate,
    GoogleLoginButton,
    Input,
    Button,
    CheckButton,
    EyeIn,
    EyeOut,
  },
  data() {
    return {
      model: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        terms: false,
      },

      errors: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        terms: "",
      },
      hidePassword: true,
      hideConfirmPassword: true,
      loading: false,
    };
  },

  computed: {
    ...mapGetters("user", {
      getStatus: user.getters.getStatus,
      getLoading: user.getters.getLoading,
      getUser: user.getters.getUser,
    }),

    status() {
      return this.getStatus;
    },

    passwordType() {
      return this.hidePassword ? "password" : "text";
    },

    hasGoogleLogin() {
      return process.env.VUE_APP_CONFIGS == "loopcv-b2c";
    },

    isB2C() {
      return this.$store.getters.isB2C;
    },

    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },

    whiteLabel() {
      return !this.$store.getters.isWhitelabel;
    },

    brand() {
      return this.$store.getters.brand;
    },
  },

  watch: {
    "model.firstName"(value) {
      validateFirstName(this, value);
    },

    "model.lastName"(value) {
      validateLastName(this, value);
    },

    "model.email"(value) {
      validateEmail(this, value);
    },

    "model.password"(value) {
      validatePassword(this, value);
      if (this.model.passwordConfirm != "") {
        validateConfirmPassword(this, this.model.passwordConfirm);
      }
    },

    "model.passwordConfirm"(value) {
      validateConfirmPassword(this, value);
    },

    "model.terms"(value) {
      this.validateTerms(value);
    },
  },

  created() {
    if (this.$store.getters.isB2B && !this.$store.getters.configs?.userSignupAllowed) {
      this.$router.push("/login");
    }
  },

  methods: {
    async signUp() {
      if (validateForm(this) && this.model.terms === true) {
        this.loading = true;
        try {
          this.$amplitude.logEvent("ATTEMPT_SIGN_IN_OR_SIGN_UP", {
            method: "email-password",
            type: "sign-up",
          });
          const data = {
            ...this.model,
            firstName: this.model.firstName.trim(),
            lastName: this.model.lastName.trim(),
            partnerId: this.$store.getters.configs?.partnerIdToPassOnSignup,
          };
          await this.$store.dispatch(user.actions.SignUp, data);
          this.$amplitude.logEvent("SIGN_UP", { method: "email-password" });
          if (this.isB2C) {
            const plan = "1"; // TODO use `this.$route.query.plan || "1";`
            await this.$store.dispatch(user.actions.UpdateInfo, { pricingPlan: parseInt(plan.toString()) });
          }
          this.$router.push("/loops/create");
        } catch (e) {
          if (e?.data?.error === "email-already-exists") {
            this.$notify({
              message: this.$t("Auth.Error.email_exists"),
              type: "danger",
            });
          } else if (e?.data?.message) {
            this.$notify({
              message: e.data.message,
              type: "danger",
            });
          } else {
            this.$notify({
              message: this.$t("Generic.Messages.Error.common"),
              type: "danger",
            });
          }
        }
        this.loading = false;
      } else {
        validateFirstName(this, this.model.firstName);
        validateLastName(this, this.model.lastName);
        validateEmail(this, this.model.email);
        validatePassword(this, this.model.password);
        validateConfirmPassword(this, this.model.passwordConfirm);
        this.validateTerms(this.model.terms);
      }
    },

    login() {
      this.$router.push({ path: "/login" });
    },

    removeCookies() {
      Cookies.remove(USER_TOKEN_COOKIE);
      this.$notify({
        message: this.$t("Generic.Messages.Error.unauthorized", { email: this.supportEmail }),
        type: "danger",
      });
      this.login();
    },

    validateTerms(value) {
      if (value == false) {
        this.errors.terms = this.$t("Auth.Error.disagree_terms", { brand: this.brand }).toString();
      } else {
        this.errors.terms = "";
      }
    },

    // Handle Form
    handleInput(value, name) {
      handleInput(this, value, name);
    },

    handleEyePassword() {
      this.hidePassword = !this.hidePassword;
    },

    handleEyeConfirmPassword() {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    },
  },
};
</script>

<style scoped>
@import "./register.css";
</style>
