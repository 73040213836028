<template>
  <v-select
    class="dropDown"
    v-model="values"
    :items="items"
    chips
    :label="this.label"
    multiple
    outlined
    @change="onChangeValues"
  ></v-select>
</template>

<script>
export default {
  name: "MultipleSelectDropDown",

  props: {
    label: {
      type: String,
      required: true,
    },
    activeItem: {
      type: [],
      required: true,
    },
    items: {
      type: [],
      required: true,
    },
  },

  data() {
    return {
      optionsElement: {},
      values: [],
    };
  },

  watch: {
    activeItem(value) {
      this.values = value;
    },
  },

  mounted() {
    this.values = this.activeItem;
  },

  methods: {
    onChangeValues(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style>
.dropDown div div {
  margin: 0;
}

.v-input__slot {
  min-height: 50px !important;
}

.v-text-field__details {
  display: none !important;
}

.v-select__selections {
  min-height: 0px !important;
  padding: 0px !important;
}

.v-select__selections input {
  cursor: pointer !important;
}
</style>
