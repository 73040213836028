<template>
  <v-alert
    v-if="showConsentBanner"
    class="warning-banner w-100"
    rounded
    outlined
    shaped
    dense
    :single-line="isDesktop"
    :two-line="!isDesktop"
  >
    <template v-slot:icon>
      <v-icon size="24"> warning_amber </v-icon>
    </template>

    <span class="text-body-1">
      {{ $t("shareDataBanner.title") }}
    </span>
    <span class="text-body-1" v-if="isDesktop">
      {{ $t("shareDataBanner.clickYes") }}
    </span>

    <template v-slot:append>
      <v-btn outlined @click="submit(false)" v-if="isDesktop">{{ $t("no") }}</v-btn>
      <v-btn outlined @click="submit(true)" v-if="isDesktop">{{ $t("yes") }}</v-btn>
      <v-btn outlined @click="dialog = true">{{ $t("shareDataBanner.showMore") }}</v-btn>
      <v-dialog v-model="dialog" max-width="800">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("shareDataBanner.dialogTitle") }}
          </v-card-title>

          <v-card-text
            >{{ $t("shareDataBanner.desc1") }} <br />
            {{ $t("shareDataBanner.desc2") }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined rounded class="ma-2" @click="submit(false)">{{ $t("deny") }}</v-btn>
            <v-btn dark rounded class="ma-2" @click="submit(true)">{{ $t("allow") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

const mql = window.matchMedia("(min-width: 600px)");

export default {
  data: () => ({
    dialog: false,
    isDesktop: true,
  }),

  components: {},

  watch: {
    dialog(value) {
      this.$amplitude.logEvent("TOGGLE_UPGRADE_BANNER_DIALOG", { show: value });
    },
  },

  computed: {
    ...mapGetters("user", {
      getUser: user.getters.getUser,
      getStatus: user.getters.getStatus,
      isUserPartner: user.getters.isUserPartner,
    }),

    user() {
      return this.getUser;
    },

    showConsentBanner() {
      if (!this.user?.email) {
        return false;
      }
      return !this.isUserPartner && this.user?.shareMyData === undefined;
    },
  },

  mounted() {
    this.isDesktop = mql.matches;
    mql.addEventListener("change", this.handleResize);
  },

  beforeDestroy() {
    mql.removeEventListener("change", this.handleResize);
  },

  methods: {
    handleResize(e) {
      this.isDesktop = e.matches;
    },

    async submit(value) {
      try {
        const payload = {
          userId: this.user?.id,
          shareMyData: value,
        };
        await this.$store.dispatch(user.actions.UpdateInfo, payload);
        await this.$store.dispatch(user.actions.RefreshUserData);
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
        });
      }
    },
  },
};
</script>
