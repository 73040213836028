<template>
  <div>
    <div class="modal-container" @click="close"></div>
    <div class="modal-content">
      <div class="modal-header">
        <Close class="modal-close" @click="close" />
        <label class="header3">{{ email.subject }}</label>
      </div>
      <hr />
      <div class="modal-body">
        <div class="headers">
          <label class="header4">{{ email.sender }}</label>
          <label class="header4"> {{ email.receiveDate }} </label>
        </div>
        <div class="labels">
          <Chip
            class="label"
            :color="email.category_color"
            :title="email.label_show"
            v-if="email.label_show && email.category_color && email.label != 'None'"
          />
          <Chip class="company" :color="'purple'" :title="email.company" v-if="!email.company && email.company != ''" />
        </div>
        <div class="body header5">
          <pre v-html="renderedBody"></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chip from "@/framework/Components/Chip.vue";
import Close from "vue-material-design-icons/Close.vue";
import { formatEmailBody } from "@/helpers/utils/stringUtils";

export default {
  components: {
    Close,
    Chip,
  },

  props: {
    email: {
      type: Object,
      required: true,
    },
  },

  created() {
    console.log(this.email);
  },

  computed: {
    renderedBody() {
      return formatEmailBody(this.email.body);
    },
  },

  methods: {
    close() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style scoped>
@import "./email.module.css";
</style>
