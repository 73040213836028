<template>
  <AuthTemplate :isLoading="this.loading">
    <v-card-title>Sign up</v-card-title>
    <v-card-subtitle> Fill the details and we will send you a link with credentials </v-card-subtitle>
    <v-card-text>
      <form @submit="signUp">
        <div class="row">
          <LocationAutocomplete @change="location = $event" />
          <div class="pa-0 col-12">
            <MazPhoneNumberInput v-model="phone" class="phone-input" @update="onPhoneUpdate" size="md" />
          </div>
          <div class="mt-5 col-12 px-0">
            <v-file-input
              name="cv"
              label="Upload your Resume"
              outlined
              hint="PDF or Word file"
              persistent-hint
              accept="application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ref="fileInput"
            ></v-file-input>
          </div>
        </div>
        <v-col cols="12">
          <v-btn block large dark color="primary" @click="signUp"> Sign Up </v-btn>
        </v-col>

        <v-row dense class="mt-1">
          <v-col cols="12">
            <span>{{ $t("Auth.Common.already_account") }}</span> {{ " " }}
            <router-link to="/login" class="linkcolor">
              {{ $t("Auth.Common.sign_in") }}
            </router-link>
          </v-col>
        </v-row>
      </form>
    </v-card-text>
  </AuthTemplate>
</template>

<script>
import AuthTemplate from "@/pages/auth/components/AuthTemplate.vue";
import * as yup from "yup";
import { MazPhoneNumberInput } from "maz-ui";
import LocationAutocomplete from "@/components/LocationAutocomplete.vue";
import { ENGLISH_REGEX } from "@/assets/js/constants";
import { validEnglishString } from "@/assets/js/utils";
import deburr from "lodash.deburr";
import { constant } from "@/store/constant";
import { mapGetters } from "vuex";
import { USER_TOKEN_COOKIE } from "@/assets/js/constants";
import Cookies from "js-cookie";

const user = constant.user;

const schema = yup.object().shape({
  userEmail: yup.string().email("The email address passed is not valid").required("The email address is not available"),
  firstName: yup
    .string()
    .required("The first name is not available")
    .matches(ENGLISH_REGEX, "Please use English characters only in first name"),
  lastName: yup
    .string()
    .required("The last name is not available")
    .matches(ENGLISH_REGEX, "Please use English characters only in last name"),
  location: yup
    .string()
    .required("Location is required")
    .typeError("Location is required")
    .test(x => x && validEnglishString(deburr(x))),
  groupName: yup.string().required("The group is not available").typeError("The group is not available"),
  phoneData: yup.string().required("Phone is required"),
  cv: yup
    .mixed()
    .required("Add a resume")
    .test(x => x instanceof File),
});

export default {
  data() {
    return {
      userEmail: "",
      groups: ["PowerBI", "Tableau", "Qlik", "DW & ETL"],
      groupName: "",
      phone: null,
      phoneData: null,
      location: "",
      firstName: "",
      lastName: "",
      cv: "",
      coverLetter:
        "The role is very appealing to me, and I believe that my experience and education make me a highly competitive candidate for this position. Please see my resume for additional information on my experience. \n\nThank you for your time and consideration. \nI look forward to speaking with you about this employment opportunity.",
      errors: {},
    };
  },
  components: {
    AuthTemplate,
    MazPhoneNumberInput,
    LocationAutocomplete,
  },

  computed: {
    ...mapGetters("user", {
      getError: user.getters.getError,
      getStatus: user.getters.getStatus,
      getLoading: user.getters.getLoading,
      getUser: user.getters.getUser,
    }),

    error() {
      return this.getError;
    },

    status() {
      return this.getStatus;
    },

    loading() {
      return this.getLoading;
    },

    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },
  },
  created() {
    if (!this.$route.query) {
      return;
    }
    const { email, firstname, LastName, lastname, group } = this.$route.query;
    if (email) {
      this.userEmail = email.toString();
    }
    if (firstname) {
      this.firstName = firstname.toString();
    }
    if (LastName) {
      this.lastName = LastName.toString();
    }
    if (lastname) {
      this.lastName = lastname.toString();
    }
    if (group && [1, 2, 3, 4].includes(parseInt(group.toString(), 10))) {
      const groupNumber = parseInt(group.toString(), 10);
      this.groupName = this.groups[groupNumber - 1];
    }
  },
  methods: {
    async signUp() {
      const postdata = {
        userEmail: this.userEmail,
        location: this.location,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneData: JSON.stringify(this.phoneData),
        // @ts-ignore
        cv: document.querySelector('input[type="file"]').files[0],
        groupName: this.groupName,
        coverLetter: this.coverLetter,
      };

      if (!this.phoneData?.isValid) {
        this.$notify({
          message: this.$t("errors.invalidPhone"),
          type: "danger",
        });
        return;
      }

      try {
        await schema.validate(postdata);
      } catch (errs) {
        errs.inner.forEach(error => {
          this.errors = { ...this.errors, [error.path]: error.message };
        });
        this.$notify({
          message: errs.toString(),
          type: "danger",
        });
        console.log(this.$route.fullPath, postdata);
        console.error(errs.toString());
        return;
      }

      try {
        await this.$store.dispatch(user.actions.ColaberrySignUp, postdata);
        await this.$store.dispatch(user.actions.Auth);
        if (this.getUser?.role != null) {
          this.$notify({
            message: "Sign up complete",
            type: "success",
          });
          this.$amplitude.logEvent("SIGN_UP", { method: "colaberry" });
          this.$router.push("/overview");
        } else {
          Cookies.remove(USER_TOKEN_COOKIE);
          this.$notify({
            message: this.$t("Generic.Messages.Error.unauthorized", { email: this.supportEmail }),
            type: "danger",
          });
        }
      } catch (e) {
        if (e.statusCode == 409) {
          return this.$notify({
            message: this.$t("Auth.Error.email_exists"),
            type: "danger",
          });
        } else {
          this.$notify({
            message: this.$t("Generic.Messages.Error.sign_in", { email: this.supportEmail }),
            type: "danger",
          });
        }
      }
    },

    validate(field) {
      const postdata = {
        userEmail: this.userEmail,
        location: this.location,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneData: JSON.stringify(this.phoneData),
        // @ts-ignore
        cv: document.querySelector('input[type="file"]').files[0],
        groupName: this.groupName,
        coverLetter: this.coverLetter,
      };

      schema
        .validateAt(field, postdata)
        .then(() => this.$set(this.errors, field, ""))
        .catch(err => this.$set(this.errors, field, err.message));
    },

    onPhoneUpdate(data) {
      this.phoneData = data;
    },
  },
};
</script>
