<template>
  <v-app-bar app flat class="clean-header">
    <img :src="logo" alt="LoopCV logo" class="header-logo" />

    <v-spacer></v-spacer>

    <div class="EmptyLayout_logout" @click="logout">
      <LogoutIcon />
      <label class="header5">{{ $t("logout") }}</label>
    </div>
  </v-app-bar>
</template>

<script>
import NewPayment from "@/components/subscription_plan/NewPayment.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { LogoutIcon } from "@/assets/fonts/icons/index";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  components: {
    NewPayment,
    LocaleSwitcher,
    LogoutIcon,
  },

  watch: {
    status(value) {
      switch (value) {
        case user.status.FailureSignOut:
          this.$notify({
            message: this.$t("errors.unexpected"),
            type: "danger",
          });
          break;

        case user.status.SuccessAuth:
          this.$router.replace("/loops/create");
          break;
      }
    },
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, getStatus: user.getters.getStatus }),

    user() {
      return this.getUser;
    },

    logo() {
      return this.$store.getters.logoUrl;
    },

    preselectedPlan() {
      // @ts-ignore
      return parseInt(this.$route.query?.plan, 10); // may be NaN
    },

    status() {
      return this.getStatus;
    },
  },

  methods: {
    pricingPlanSubmitted() {
      this.$store.dispatch(user.actions.Auth);
    },

    async logout() {
      await this.$store.dispatch(user.actions.SignOut);
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.header-logo {
  height: 40px;
}
.clean-header {
  background-color: var(--white);
}
.setupInfo {
  font-size: 20px;
  margin-top: 1em;
}
.setupHeader {
  text-align: left;
  font: normal normal 800 18px/24px Manrope;
  letter-spacing: 0.27px;
  color: #35383c;
}
.outer-page {
  background-color: #f8f8fa;
  height: 100%;
}
.clean-main {
  max-width: 1000px;
}
.main-panel {
  height: 100%;
}

.EmptyLayout_logout {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding-block: 10px;
  padding-inline: 10px;
}

.EmptyLayout_logout:hover {
  background-color: var(--hover_button);
  border-radius: 10px;
}
</style>
