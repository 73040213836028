<template>
  <input
    type="file"
    style="display: none"
    ref="fileInput"
    accept="application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    id="uploader"
    name="cvUpload"
    @change="$emit('regular-upload', $event)"
  />
</template>

<script>
import { resetFileInput } from "@/assets/js/utils";
export default {
  methods: {
    click() {
      this.$refs.fileInput.click();
    },
    reset() {
      resetFileInput(this.$refs.fileInput);
    },
  },
};
</script>

<style></style>
