<template>
  <v-row>
    <div class="col-12">
      <div class="text-center">
        <h2 class="title text-danger">404 Not Found</h2>
        <h2 class="title">Oops! It seems that this page does not exist.</h2>
        <h3>Go to the <router-link to="/">home page</router-link></h3>
      </div>
    </div>
  </v-row>
</template>

<script>
export default {};
</script>
