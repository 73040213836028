<template>
  <div class="card card_billing_body">
    <label class="header2 bold">{{ this.$t("Settings.Billing") }}</label>

    <app-loader v-if="loading"></app-loader>
    <div class="licenses" v-else>
      <label class="header3" v-if="licenses == 1">{{ $t("Settings.one_licenses") }} </label>
      <label class="header3" v-else-if="licenses == 0"> {{ $t("Settings.no_licenses") }}</label>
      <label class="header3" v-else>{{ $t("Settings.number_of_licenses", { value: licenses }) }}</label>
      <div class="details">
        <div class="currency">
          <NextSubscriptionIcon />
          <label class="header4 light">{{ $t("Settings.next_payment") }}: {{ this.getNextPayment }}</label>
        </div>

        <div class="currency">
          <CurrencyIcon />
          <label class="header4 light">{{ $t("Settings.total_amount") }}: {{ getFormattedCurrency }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateToLocalMMDDYY } from "@/helpers/dateUtils";
import { toCurrency } from "@/helpers/utils/stringUtils";
import { CurrencyIcon, NextSubscriptionIcon } from "@/assets/fonts/icons";
import { number } from "yup";
export default {
  components: {
    CurrencyIcon,
    NextSubscriptionIcon,
  },

  props: {
    licenses: number,
    amount: number,
    nextPayment: null,
    currency: String,
  },

  computed: {
    getNextPayment() {
      return formatDateToLocalMMDDYY(this.nextPayment);
    },
    getFormattedCurrency() {
      return toCurrency(this.amount, this.currency);
    },
  },
};
</script>
<style scoped>
.card_billing_body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.details {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.licenses {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.currency {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
</style>
