<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon fab variant="text" v-bind="attrs" v-on="on" @click="$emit('click')"
        ><v-icon large :color="color">bolt</v-icon></v-btn
      >
    </template>
    <span>{{ $t("Questions.fill_suggested_answer") }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>
