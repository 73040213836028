<template>
  <div>
    <h4 class="text-subtitle-1">{{ $t("loop.slides.info.title") }}</h4>
    <div class="row">
      <div class="col-md-12">
        <v-combobox
          outlined
          item-color="#35383c"
          clearable
          open-on-clear
          :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          v-model="jobTitle"
          :loading="loading"
          :items="jobs"
          :search-input.sync="searchJobTitle"
          flat
          :hint="$t('loop.slides.info.jobTitleHint')"
          no-filter
          disable-lookup
          persistent-hint
          :placeholder="$t('loop.slides.info.softwareEngineer')"
          :label="$t('jobTitle')"
          :no-data-text="
            searchJobTitle ? $t('LocationAutocomplete.noResults', [searchJobTitle]) : $t('loop.slides.info.typeToSearch')
          "
        ></v-combobox>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <LocationAutocomplete :initialLocation="initialLocation" @change="location = $event" :disabled="remoteAnywhere" />
        <v-slider
          v-if="showDistanceRadius"
          v-model="distanceRadius"
          :disabled="onlyRemoteJobs"
          class="align-center mt-5"
          :height="30"
          persistent-hint
          ticks
          :step="10"
          :max="100"
          :min="10"
          label="Distance radius"
          :hint="`Allow jobs up to ${distanceRadius}km away from ${location || 'the specified location'}`"
        >
        </v-slider>
        <v-checkbox v-model="onlyRemoteJobs" hide-details
          ><template v-slot:label>
            <div>{{ $t("loop.slides.info.remote") }}</div>
          </template></v-checkbox
        >

        <div :class="isB2C ? (connectedUser.pricingPlan <= 1 ? 'disabled' : '') : ''">
          <v-checkbox
            v-model="remoteAnywhere"
            :label="$t('loop.slides.info.anywhere')"
            :hint="
              isB2C && connectedUser.pricingPlan > 1
              ? $t('loop.slides.info.anywhereHint')
              : ''
            "
            persistent-hint
            :disabled="isB2C && connectedUser.pricingPlan <= 1 "
          >
          <template v-slot:label>
            <div>
              {{ $t('loop.slides.info.anywhere') }}
              <span class="label-subtext">
                {{ $t('loop.slides.info.paidPlanOnly') }}
              </span>
            </div>
          </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-select
          v-model="scrapers"
          :items="jobBoardPlatforms"
          :label="$t('loop.slides.info.jobBoardPlatform')"
          multiple
          outlined
        ></v-select>
        <div class="v-messages theme--light">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              {{$t('loop.slides.info.jobBoardPlatformHint')}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <v-select
          outlined
          v-model="jobLevel"
          :items="jobLevelsObjects"
          item-text="value"
          item-value="index"
          item-color="#35383c"
          :label="$t('experience')"
        ></v-select>
      </div>
      <div class="col-md-6">
        <v-select
          outlined
          v-model="jobType"
          :items="jobTypesObjects"
          item-text="value"
          item-value="index"
          item-color="#35383c"
          :label="$t('job-type')"
        ></v-select>
      </div>
    </div>

    <h4 class="text-subtitle-3">{{ $t("loop.slides.cvs.title") }}</h4>
    <SelectCV
      @updateCVData="updateCVData"
      :selectedCVId="cvId"
      :allowUpload="true"
      :preselectLastCV="!$store.getters.isEditMode"
      :showLabel="true"
      :clientId="clientId"
    />
  </div>
</template>

<script>
import { InfoIcon } from "@/assets/fonts/icons";
import { debounce, validEnglishString, validKeywordString } from "@/assets/js/utils";
import LocationAutocomplete from "@/components/LocationAutocomplete.vue";
import SelectCV from "@/components/cvs/SelectCV.vue";
import appSDK from "@/client/AppSDK";
import deburr from "lodash.deburr";
import { constant } from "@/store/constant";
import { mapGetters } from "vuex";
import {dashboardRepo} from "@/repositories";
const userStore = constant.user;

export default {
  name: "LoopInfo",
  components: {
    SelectCV,
    LocationAutocomplete,
    InfoIcon,
  },
  props: {
    clientId: String,
    user: {
      type: Object,
      default: null,
    },
    showDistanceRadius: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    jobs: [],
    scrapers: [],
    jobBoardPlatforms: [],

    jobTitle: "",
    location: "",
    initialLocation: "",
    jobLevel: 0,
    jobType: 0,

    distanceRadius: 30,
    onlyRemoteJobs: false,
    remoteAnywhere: false,

    loading: false,
    searchJobTitle: null,

    // CV data
    totalCVs: null,
    cvId: null,
    selectedCVname: "",
    selectedCVUrl: "",
  }),
  computed: {
    ...mapGetters("user", { connectedUser: userStore.getters.getUser }),

    jobLevelsObjects() {
      return this.$store.getters.jobLevels.map((value, index) => ({ index, value: this.$t(value) }));
    },
    jobTypesObjects() {
      return this.$store.getters.jobTypes.map((value, index) => ({ index, value: this.$t(value) }));
    },

    isB2C() {
      if (this.$store.getters.isB2C) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    user: {
      handler(data) {
        // this.location = data.country;
        // this.validLocation = true;
      },
      immediate: true,
    },
    searchJobTitle(val) {
      val && val !== this.jobTitle && this.getJob(val);
    },
    location(val) {
      this.$amplitude.logEvent("SELECT_LOCATION_INPUT", { action: this.$store.state.loop.action });
    },
    onlyRemoteJobs(newValue) {
      if (newValue === false) {
        this.remoteAnywhere = false;
      }
    },
    remoteAnywhere(newValue) {
      if (newValue === true) {
        this.onlyRemoteJobs = true;
      }
    },
  },
  async mounted() {
    // populate fields if edit mode
    if (this.$store.getters.isEditOrDuplicate) {
      const jobTitle = this.$store.getters.getLoopData.jobtitle;
      if (jobTitle) {
        this.jobTitle = jobTitle;
        this.jobs.push(this.jobTitle);
      }
      this.scrapers = this.$store.getters.getLoopData.scrapers;
      this.jobLevel = this.$store.getters.getLoopData.joblevel;
      this.jobType = this.$store.getters.getLoopData.jobtype;
      this.onlyRemoteJobs = this.$store.getters.getLoopData.onlyRemoteJobs || false;

      const location = this.$store.getters.getLoopData.location;
      if (location == "Remote" && this.onlyRemoteJobs) {
        this.remoteAnywhere = true;
      } else {
        this.initialLocation = location;
        this.location = location;
        this.distanceRadius = this.$store.getters.getLoopData.distanceRadius;
      }
    }
    // this probably needs to be on mounted(), not created()
    // @TODO if created with invalid location/jobTitle, it will stay invalid

    if (this.$store.getters.getLoopData.cvId) {
      this.cvId = this.$store.getters.getLoopData.cvId;
      console.log(this.cvId);
    }

    this.jobBoardPlatforms = await dashboardRepo.getScrapers();
  },
  methods: {
    getJob: debounce(async function (newjob) {
      try {
        if (newjob.length < 3) {
          return;
        }
        if (!newjob?.trim()) {
          return;
        }
        this.$amplitude.logEvent("TYPE_JOB_TITLE_INPUT", { action: this.$store.state.loop.action });
        this.loading = true;
        const response = await appSDK["jobs.getByName"](newjob);
        this.jobs = response.slice(0, 10);
      } catch (error) {
        console.error(error);
        this.jobs = [];
      } finally {
        this.loading = false;
      }
    }, 500),

    //#region form actions
    validate() {
      if (!this.jobLevelsObjects.length || !this.jobTypesObjects.length) {
        return this.$t("errors.tryAgain");
      }

      if (!validKeywordString(this.jobTitle)) {
        return this.$t("errors.shouldEnglishJobTitle");
      }

      const shouldValidateLocation = !this.onlyRemoteJobs || (this.onlyRemoteJobs && !this.remoteAnywhere);
      if (shouldValidateLocation && !(this.location && validEnglishString(deburr(this.location)))) {
        return this.$t("errors.chooseLocation");
      }

      // CV validation
      if (!this.totalCVs) {
        return null;
      }
      if (!this.cvId && this.cvId !== 0) {
        return this.$t("errors.chooseCV");
      }
      if (!this.selectedCVUrl) {
        return this.$t("errors.tryAgainCV");
      }

      return null;
    },

    postData() {
      const errors = this.validate();
      if (errors) {
        this.$amplitude.logEvent("LOOP_CREATION_VALIDATION_ERROR", { errorMessage: errors, slide: "LoopInfo" });
        this.$emit("errors", errors);
        return errors;
      }

      const storeData = {
        // data get from the current user
        userEmail: this.user.email, // the email of the user
        userName: this.user.firstName + " " + this.user.lastName, // the full name of the user

        // data from the form
        location: deburr(this.location),
        distanceRadius: this.distanceRadius,
        onlyRemoteJobs: this.onlyRemoteJobs,
        
        scrapers: this.scrapers,
        // TODO: change key to camel
        jobtitle: this.jobTitle,
        jobtype: this.jobType, // type of the job
        joblevel: this.jobLevel, // level of the job

        // CV data
        cvId: this.cvId, // it is only saved on firebase for later use on /automations editing. useless on /cvs
        fileUrl: this.selectedCVUrl,
        selectedCVname: this.selectedCVname, // not used on the backend
        hasCV: !!this.cvId,
      };

      if (this.onlyRemoteJobs && this.remoteAnywhere) {
        storeData.location = "Remote";
        delete storeData.distanceRadius;
      }

      this.$store.commit("setLoopData", storeData);
    },

    //#endregion

    //#region on Actions buttons
    updateCVData({ cvId, fileName, filePath } = { cvId: null, fileName: "", filePath: "" }) {
      this.cvId = cvId;
      this.selectedCVname = fileName;
      this.selectedCVUrl = filePath;
    },
    //#endregion
  },
};
</script>
<style scoped>
datalist {
  display: inline-block;
}

.disabled {
  cursor: not-allowed !important;
}
.label-subtext {
  font-weight:bold;
  font-size: smaller;
  color: #19AAA0;
  padding-left: 8px;
}
</style>
