<template>
  <div>
    <EmptyLayout />
    <div class="page_body">
      <div class="card_position">
        <div class="card_body">
          <label class="header3 card_header">{{ this.$t("Profile.complete_profile_title") }}</label>

          <div class="card_main_body">
            <Input
              :title="this.$t('Generic.Form.first_name')"
              :placeholder="
                this.$t('Generic.Form.placeholder_value', { value: this.$t('Generic.Form.first_name').toLowerCase() })
              "
              :error="this.errors.firstName"
              @input="value => handleFirstName(value)"
              :value="this.model.firstName"
            />

            <Input
              :title="this.$t('Generic.Form.last_name')"
              :placeholder="
                this.$t('Generic.Form.placeholder_value', { value: this.$t('Generic.Form.last_name').toLowerCase() })
              "
              :error="this.errors.lastName"
              @input="value => handleLastName(value)"
              :value="this.model.lastName"
            />
          </div>

          <div class="card_buttons">
            <Button
              :title="this.$t('Auth.Common.sign_in')"
              @click="submit"
              :outlined="false"
              :fullWidth="true"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import { Input, Button } from "@/framework/index";
import { validateFirstName, validateLastName } from "@/helpers/utils/formUtils";
import { constant } from "@/store/constant";
import { mapGetters } from "vuex";

const user = constant.user;

export default {
  components: {
    EmptyLayout,
    Input,
    Button,
  },

  data() {
    return {
      loading: false,
      model: { firstName: "", lastName: "" },
      errors: {
        lastName: "",
        firstName: "",
      },
    };
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),

    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },
  },

  mounted() {
    this.model.firstName = this.getUser.firstName || "";
    this.model.lastName = this.getUser.lastName || "";
  },

  methods: {
    handleFirstName(value) {
      validateFirstName(this, value);
      this.model.firstName = value;
    },

    handleLastName(value) {
      validateLastName(this, value);
      this.model.lastName = value;
    },

    async submit() {
      try {
        this.loading = true;
        await this.$store.dispatch(user.actions.UpdateInfo, {
          firstName: this.model.firstName,
          lastName: this.model.lastName,
        });
        await this.$store.dispatch(user.actions.RefreshUserData);
        this.$notify({
          message: this.$t("Generic.Messages.Success.update_profile"),
          type: "success",
        });
        this.$router.push("/");
      } catch (e) {
        this.$notify({
          message: e.message || this.$t("Generic.Messages.Error.common_error", { email: this.supportEmail }),
          type: "danger",
        });
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.page_body {
  height: calc(100% - 100px);
  position: fixed;
  width: 100%;
}

.card_position {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card_body {
  background-color: var(--white);
  width: 50vw;
  border-radius: 30px;
  padding-block: 40px;
  padding-inline: 40px;
  box-shadow: var(--shadow);
}

.card_main_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.card_buttons {
  margin-top: 20px;
}
</style>
