import Overview from "@/pages/Overview.vue";
import Inbox from "@/pages/dashboard/inbox.vue";
import KanbanBoard from "@/pages/dashboard/KanbanBoard.vue";
import ApplicationVue from "@/pages/dashboard/application.vue";
import { Role, ALL_ROLES } from "@/helpers/enums/eRole";
import Billing from "@/pages/dashboard/billing.vue";

export default [
  { path: "/", redirect: "/overview" },
  {
    path: "/overview",
    name: "overview",
    component: Overview,
    meta: { authorized: ALL_ROLES },
  },
  {
    path: "/inbox",
    name: "inbox",
    component: Inbox,
    meta: { authorized: [Role.MANAGED_USER, Role.USER] },
  },
  {
    path: "/board",
    name: "board",
    component: KanbanBoard,
    meta: { authorized: [Role.MANAGED_USER, Role.USER] },
  },

  {
    path: "/myApplications",
    name: "myApplications",
    component: ApplicationVue,
    meta: { authorized: [Role.USER, Role.MANAGED_USER] },
  },

  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: { authorized: [Role.USER] },
  },
];
