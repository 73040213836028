<template>
  <v-row class="center">
    <app-header />

    <div class="mx-2 col-12 small-card">
      <slot name="header">
        <div class="row">
          <div class="col">
            <h2 class="heading">{{ $t("page.settings") }}</h2>
          </div>
        </div>
      </slot>
      <div class="billing" v-if="hasActiveSubscription">
        <Billing
          :key="billingKey"
          :amount="billingDetails.amount"
          :currency="billingDetails.currency"
          :licenses="billingDetails.licenses"
          :nextPayment="billingDetails.nextPayment"
        />
      </div>

      <div v-if="hasActiveSubscription && showAdminPayments">
        <AdminPaymentHistory :invoices="getInvoices" />
      </div>

      <AdminUsersList @updateClient="updateBillingData" />
    </div>
  </v-row>
</template>

<script>
import StripeCheckout from "@/components/subscription_plan/StripeCheckout.vue";
import PricingPlansBoxes from "@/components/subscription_plan/PricingPlansBoxes.vue";
import AdminPaymentHistory from "./AdminPaymentHistory.vue";
import { toCurrency } from "@/helpers/utils/stringUtils.js";
import AdminUsersList from "./AdminUsersList.vue";
import { mapGetters, mapState } from "vuex";
import Billing from "@/components/settings/billing/Billing.vue";
import { constant } from "@/store/constant";

export default {
  components: {
    StripeCheckout,
    PricingPlansBoxes,
    AdminPaymentHistory,
    AdminUsersList,
    Billing,
  },
  data: () => ({
    showChangePlanDialog: false,
    newPlanSelected: -1,
    amount: 0,
    hasActiveSubscription: false,
    billingKey: 0,
    billingDetails: {
      licenses: 0,
      amount: 0,
      nextPayment: null,
      currency: "",
    },
  }),

  computed: {
    ...mapState("client", ["subscription"]),

    ...mapGetters("user", { getUser: "getUser" }),

    nextPaymentAttempt() {
      return new Date(1662594618 * 1000).toDateString();
    },
    user() {
      return this.getUser;
    },

    showAdminPayments() {
      return this.$store.getters.configs.showAdminPayments;
    },

    formattedCurrentPaymentAmount() {
      return toCurrency((this.getSubscription?.amount / 100).toFixed(2), this.getSubscription?.currency);
    },

    getInvoices() {
      return this.getSubscription?.invoices;
    },
    licensesToPurchase() {
      // should not create subscription with 0 licenses ($0) in order to trigger 3D Secure
      return 1;
    },
    paymentAmount() {
      return this.licensesToPurchase * this.pricePerUser;
    },
    formattedPaymentAmount() {
      if (!this.paymentAmount) {
        return "-";
      }
      return toCurrency(this.paymentAmount.toFixed(2), this.currency);
    },
    formattedPricePerUser() {
      return toCurrency(this.pricePerUser, this.currency);
    },

    getNumberOfLicences() {
      return this.getSubscription?.currentLicenses;
    },
  },

  async mounted() {
    await this.getBillingData();
  },

  methods: {
    async getBillingData() {
      try {
        const result = await this.$store.dispatch(constant.user.actions.GetSubscription);
        if (result.status == "active") {
          this.hasActiveSubscription = true;
          this.billingDetails.amount = result.amount;
          this.billingDetails.currency = result.currency;
          this.billingDetails.licenses = result.licenses;
          this.billingDetails.nextPayment = result.nextPayment;
        } else {
          this.hasActiveSubscription = false;
        }
      } catch (e) {}
    },

    async updateBillingData() {
      await this.getBillingData();
      this.billingKey++;
    },
  },
};
</script>
<style>
.flex-b {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pad-c {
  padding: 1em;
}

.billing {
  margin-bottom: 10px;
}
</style>
