<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card>
      <v-card-title class="headline">
        {{ $t("googleLogin.title") }}
      </v-card-title>

      <v-card-text>
        {{ $t("googleLogin.notice") }}
        <v-text-field :label="$t('Generic.Form.first_name')" v-model="validationFirstName" required outlined></v-text-field>
        <v-text-field :label="$t('Generic.Form.last_name')" v-model="validationLastName" required outlined></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="submitValidationNames"> {{ $t("ok") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import appSDK from "@/client/AppSDK";
import { triggerRewardfulConversion, validNames } from "@/assets/js/utils";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: true,
      validationFirstName: "",
      validationLastName: "",
    };
  },
  methods: {
    async submitValidationNames() {
      const user = this.getUser;
      if (user) {
        if (!validNames(this.validationFirstName, this.validationLastName)) {
          this.dialog = true;
          this.$notify({
            message: this.$t("googleLogin.errorEnglish"),
            type: "danger",
          });
          return;
        } else {
          await appSDK["user.partialCreate"]({
            userId: user.uid,
            userEmail: user.email,
            firstName: this.validationFirstName,
            lastName: this.validationLastName,
          });
          triggerRewardfulConversion(user.email); // after stripe customer creation
        }
      }
      this.dialog = false;
    },
  },

  computed: {
    ...mapGetters("user", { getUser: "getUser" }),
  },
};
</script>

<style></style>
