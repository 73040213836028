import EmailValidator from "email-validator";
import { ENGLISH_REGEX, urlRegex } from "@/assets/js/constants";

export function handleInput(vm, value, name) {
  if (value === undefined) {
    vm.model[name] = "";
  } else {
    vm.model[name] = value;
  }
}

export function validateForm(vm) {
  if (
    Object.values(vm.errors).every(val => val === "") &&
    Object.keys(vm.model)
      .filter(key => typeof vm.model[key] === "string")
      .every(key => vm.model[key] != "")
  ) {
    return true;
  } else {
    return false;
  }
}

export function validatePassword(vm, value, { soft = false } = {}) {
  if (value == "") {
    vm.errors.password = vm
      .$t("Generic.Form.required_message", {
        value: vm.$t("Generic.Form.password"),
      })
      .toString();
  } else if (value.length < 8 && !soft) {
    vm.errors.password = vm.$t("Auth.Error.password_length").toString();
  } else {
    vm.errors.password = "";
  }
}

export function validateConfirmPassword(vm, value) {
  if (vm.model.password != value) {
    vm.errors.passwordConfirm = vm.$t("Auth.Error.password_does_not_match").toString();
  } else {
    vm.errors.passwordConfirm = "";
  }
}

export function validateEmail(vm, value) {
  if (value == "") {
    vm.errors.email = vm.$t("Generic.Form.required_message", { value: vm.$t("Generic.Form.email") }).toString();
  } else if (!EmailValidator.validate(value)) {
    vm.errors.email = vm.$t("Generic.Form.valid_form_message", { value: vm.$t("Generic.Form.email") }).toString();
  } else {
    vm.errors.email = "";
  }
}

export function validateFirstName(vm, value) {
  if (value == "") {
    vm.errors.firstName = vm
      .$t("Generic.Form.required_message", {
        value: vm.$t("Generic.Form.first_name"),
      })
      .toString();
  } else if (!value.match(ENGLISH_REGEX)) {
    vm.errors.firstName = vm.$t("Generic.Error.onlyEnglish").toString();
  } else {
    vm.errors.firstName = "";
  }
}

export function validateLastName(vm, value) {
  if (value == "") {
    vm.errors.lastName = vm
      .$t("Generic.Form.required_message", {
        value: vm.$t("Generic.Form.last_name"),
      })
      .toString();
  } else if (!value.match(ENGLISH_REGEX)) {
    vm.errors.lastName = vm.$t("Generic.Error.onlyEnglish").toString();
  } else {
    vm.errors.lastName = "";
  }
}

export function validateCompany(vm, value) {
  if (value == "") {
    vm.errors.company = vm
      .$t("Generic.Form.required_message", {
        value: vm.$t("Generic.Headers.company"),
      })
      .toString();
  } else if (!value.match(ENGLISH_REGEX)) {
    vm.errors.company = vm.$t("Generic.Error.onlyEnglish").toString();
  } else {
    vm.errors.company = "";
  }
}

export function validateUrl(vm, value) {
  if (!value.match(urlRegex)) {
    return vm.$t("ManualApplication.url_incorrect_form").toString();
  } else {
    return "";
  }
}

export function validateAddress(vm, value) {
  if (value === "") {
    vm.errors.address = vm.$t("Generic.Form.required_message", { value: vm.$t("plans.address") }).toString();
  } else {
    vm.errors.address = "";
  }
}

export function validateCity(vm, value) {
  if (value === "") {
    vm.errors.city = vm.$t("Generic.Form.required_message", { value: vm.$t("city") }).toString();
  } else if (!value.match(ENGLISH_REGEX)) {
    vm.errors.city = vm.$t("Generic.Error.onlyEnglish").toString();
  } else {
    vm.errors.city = "";
  }
}

export function validateState(vm, value) {
  if (value === "") {
    vm.errors.state = vm.$t("Generic.Form.required_message", { value: vm.$t("plans.state") }).toString();
  } else if (!value.match(ENGLISH_REGEX)) {
    vm.errors.state = vm.$t("Generic.Error.onlyEnglish").toString();
  } else {
    vm.errors.state = "";
  }
}

export function validateCountry(vm, value) {
  if (value === "") {
    vm.errors.country = vm.$t("Generic.Form.required_message", { value: vm.$t("plans.country") }).toString();
  } else if (!value.match(ENGLISH_REGEX)) {
    vm.errors.country = vm.$t("Generic.Error.onlyEnglish").toString();
  } else {
    vm.errors.country = "";
  }
}

export function validatePostalCode(vm, value) {
  if (value === "") {
    vm.errors.postalCode = vm.$t("Generic.Form.required_message", { value: vm.$t("plans.zip") }).toString();
  } else if (!/^\d{5}(-\d{4})?$/.test(value)) {
    vm.errors.postalCode = vm.$t("Generic.Form.valid_form_message", { value: vm.$t("plans.zip") }).toString();
  } else {
    vm.errors.postalCode = "";
  }
}

export function validateVAT(vm, value) {
  if (value === "") {
    vm.errors.vatValue = vm.$t("Generic.Form.required_message", { value: vm.$t("plans.vat") }).toString();
  } else {
    vm.errors.vatValue = "";
  }
}

export function validateVatType(vm, value) {
  if (value === "") {
    vm.errors.vatType = vm.$t("Generic.Form.required_message", { value: vm.$t("plans.vatType") }).toString();
  } else {
    vm.errors.vatType = "";
  }
}
