<template>
  <v-dialog
    v-model="dialog"
    content-class="full-screen"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="600px"
  >
    <v-card>
      <v-card-title class="text-h5">
        Change client password
      </v-card-title>
      <v-card-text>
        <div>
          You are changing the password for <b>{{ email }}</b>
          <br />
          The client can login on
          <router-link to="/login">{{ loginUrl }}</router-link> using this
          password to manage the loops and check the results.
          <v-form @submit.prevent="save" ref="myForm">
            <div class="row">
              <div class="col-md-12 pb-0">
                <v-text-field
                  v-model="email"
                  :placeholder="$t('email')"
                  type="email"
                  autocomplete="email"
                  outlined
                  disabled
                  hide-details
                >
                </v-text-field>
              </div>
              <div class="col-md-12 pb-0">
                <v-text-field
                  :label="'Password'"
                  :type="hidePassword ? 'password' : 'text'"
                  v-model="password"
                  outlined
                  :rules="[() => !!password || 'Password is required']"
                  autocomplete="new-password"
                  @keyup.enter="save"
                  @keyup.esc="dialog = false"
                >
                  <v-btn
                    class="mt-n1"
                    slot="append"
                    icon
                    small
                    @click="hidePassword = !hidePassword"
                  >
                    <app-icon :name="hidePassword ? 'eye' : 'eyeOff'" />
                  </v-btn>
                </v-text-field>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> Cancel </v-btn>
        <v-btn rounded color="primary" :loading="loading" @click.prevent="save">
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as yup from "yup";
import appSDK from "@/client/AppSDK";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      email: null,
      password: "",
      clientId: null,
      loading: false,
      hidePassword: true,
    };
  },
  computed: {
    loginUrl() {
      const loadedOnIframe = window.location != window.parent.location;
      if (loadedOnIframe) {
        return this.$router.resolve("/login").href;
      }
      return location.origin + this.$router.resolve("/login").href;
    },
  },

  methods: {
    open(item) {
      this.email = item.email;
      this.clientId = item.uniqueId;
      this.dialog = true;
      this.password = "";
      this.$refs.myForm?.resetValidation();
    },
    async save() {
      const schema = yup.object().shape({
        password: yup
          .string()
          .required("Password is required")
          .min(8, "Password should be at least 8 characters long"),
      });

      try {
        this.loading = true;

        const body = {
          password: this.password,
        };

        await schema.validate(body);

        const parsed = schema.cast(body);

        await appSDK["partner.childUser.changePassword"](this.clientId, parsed);
        this.$notify({
          message: "The password was updated successfully",
          type: "success",
        });
        this.dialog = false;
      } catch (e) {
        if (e?.name !== "ValidationError") {
          console.error(e);
        }
        this.$notify({
          message:
            e?.errors?.[0] ||
            "There was an error processing the request; our team is notified. Please try again later.",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
/* these styles should not be scoped */
.full-screen {
  width: auto;
}
</style>
