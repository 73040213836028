import Vue from "vue";

const amplitudeEnabled = process.env.VUE_APP_AMPLITUDE === "enable";

class AmplitudePlugin {
  logEvent(event, eventProperties = {}) {
    if (amplitudeEnabled && analytics) {
      try {
        analytics.track(event, eventProperties);
      } catch (e) {
        console.log(e);
      }
    }
  }
  identify(userId, userProperties) {
    if (amplitudeEnabled && analytics) {
      try {
        analytics.identify(userId, userProperties);
      } catch (e) {
        console.log(e);
      }
    }
  }
}

export const amplitudePluginSingleton = new AmplitudePlugin();

function amplitudePlugin(Vue) {
  Vue.prototype.$amplitude = amplitudePluginSingleton;
}

Vue.use(amplitudePlugin);

export default amplitudePlugin;
