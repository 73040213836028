<template>
  <v-autocomplete
    item-color="#35383c"
    outlined
    clearable
    open-on-clear
    :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
    v-model="location"
    item-text="description"
    return-object
    :loading="loading"
    :items="locations"
    @change="onChange"
    :search-input.sync="searchLocation"
    flat
    :hint="$t('partner.typeCityOrCountry')"
    no-filter
    disable-lookup
    :error="error"
    persistent-hint
    :placeholder="$t('LocationAutocomplete.example')"
    :label="label"
    :disabled="disabled"
    :no-data-text="searchLocation ? $t('LocationAutocomplete.noResults', [searchLocation]) : $t('LocationAutocomplete.type')"
  >
  </v-autocomplete>
</template>

<script>
import { debounce } from "@/assets/js/utils";
import i18n from "@/core/i18n";
import axios from "axios";

export default {
  name: "LocationAutocomplete",
  props: {
    initialLocation: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: i18n.t("loopPreview.location"),
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      locations: [],
      location: this.initialLocation,
      searchLocation: this.initialLocation,
    };
  },
  watch: {
    searchLocation(val) {
      val && val !== this.location && this.getLocation(val);
    },
    initialLocation(val) {
      if (val) {
        this.location = val;
        this.locations.push(val);
      }
    },
  },
  methods: {
    getLocation: debounce(async function (query) {
      try {
        const result = await axios.get("https://photon.komoot.io/api/?layer=city&layer=country", {
          params: {
            q: query,
            limit: 5,
            lang: "en",
          },
        });
        let predictions = result.data.features.map(f => f.properties);
        this.locations = predictions.map(p => {
          if (p.type === "city" && !p.name.includes('(')) {
            return `${p.name}, ${p.country}`;
          } else if (p.type === "country") {
            return p.country;
          } else {
            console.error(new Error("Invalid location type: " + p.type));
            return null;
          }
        }).filter(p => p !== null);
      } catch (error) {
        console.error("Komoot error: " + error?.message);
      }
      // TODO exclude "Remote, OR, USA"
    }, 500),

    onChange(event) {
      if (!event) {
        this.$emit("change", null);
        return;
      }

      this.$emit("change", event); // emit the formatted string of the location
    },
  },
};
</script>

<style></style>
