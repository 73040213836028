const configs = process.configs;

console.log(configs);

const state = {
  configs,
};

const getters = {
  isB2B() {
    return process.env.VUE_APP_MODE === "b2b";
  },

  isB2C() {
    return process.env.VUE_APP_MODE === "b2c";
  },

  configs(state) {
    return state.configs;
  },
  logoUrl(state) {
    return `/img/logos/${state.configs.logo}`;
  },
  brand(state) {
    return state.configs.brand || "LoopCV";
  },
  primaryColor(state) {
    return state.configs.theme?.primary || "#4992F0";
  },
  isWhitelabel() {
    return state.configs.brand.toLowerCase() !== "loopcv";
  },
};

export default { getters, state };
