<template>
  <v-row class="center">
    <app-header />

    <div class="col-12 small-card">
      <EditProfileForm :model="model" @update="handleUpdate"> </EditProfileForm>
    </div>
  </v-row>
</template>

<script>
import EditProfileForm from "@/pages/user/Profile/EditProfileForm.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
const user = constant.user;

export default {
  components: {
    EditProfileForm,
  },
  data() {
    return {
      model: {
        email: "",
        firstName: "",
        lastName: "",
        country: "",
        pricingPlan: 1,
      },
    };
  },

  async mounted() {
    const user = this.user;
    if (user) {
      this.model.email = user.email;
      this.model.firstName = user.firstName;
      this.model.lastName = user.lastName;
      this.model.country = user.country;
      this.model.pricingPlan = user.pricingPlan;
    }
  },

  methods: {
    handleUpdate([field, value]) {
      this.model[field] = value;
    },
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),

    user() {
      return this.getUser;
    },
  },
};
</script>
