<template>
  <div>
    <div class="ma-3 d-flex justify-space-between">
      <div class="text-h1">
        {{ isCreateOrDuplicate ? $t("loop.title.new") : $t("loop.title.edit") }}
        <span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="black" v-bind="attrs" v-on="on">help_outline</v-icon>
            </template>
            {{ $t('loop.titleTooltip') }}
          </v-tooltip>
        </span>
        <h5 v-if="slideIndex !== 2" class="text-subtitle-2">{{ $t("loop.subtitle") }}</h5>
        <div v-if="slideIndex === 2 ">
          <h5 class="text-subtitle-2" v-html="$t('loop.templateDescription')"/>
          <h5 class="text-subtitle-2" v-html="$t('loop.templatePlaceholders')"/>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="loop-body ma-3">
      <app-loader />
    </div>
    <div v-else class="loop-body ma-3">
      <v-stepper v-model="slideIndex" v-if="currentUser.userId" flat>
        <v-stepper-header>
          <template v-for="slide in slides">
            <v-stepper-step
              :complete="slideIndex > slide.index"
              :editable="slideIndex > slide.index && !disabledUpsert"
              :step="slide.index"
              :key="'step' + slide.index"
              >{{ $t(`slideTitles.${slide.title}`) }}</v-stepper-step
            >
            <v-divider v-if="slide.index !== slides.length" :key="'divider' + slide.index"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content v-for="slide in slides" :step="slide.index" :key="'slide' + slide.index">
            <component
              v-if="loopOwner"
              ref="slide"
              :is="slide.component"
              :slideIndex="slide.index"
              :user="currentUser"
              :loopOwner="loopOwner"
              :clientId="clientId"
              :showDistanceRadius="isUserPartner"
              @errors="handleErrors"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <center v-if="showAnimation" style="margin-top: 5em">
        <img src="/img/illustrations/undraw_sunlight_tn7t.svg" width="300" />
        <template v-if="isCreateOrDuplicate">
          <h4>{{ $t("loop.success.congrats") }}</h4>
          <h5>
            {{ $t("loop.success.successText.create") }}
          </h5>
        </template>
        <template v-else-if="isEditMode">
          <h5>
            {{ $t("loop.success.successText.edit") }}
          </h5>
        </template>
        <h5 v-if="isUserPartner">
          {{ $t("loop.success.clickMyLoops") }}
        </h5>
        <ProgressAnimation rounded></ProgressAnimation>
      </center>

      <v-dialog v-model="showPricingPlansDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">
            <div>Maximum Loops Reached</div>
          </v-card-title>
          <v-card-text class="text-body-2">
            You have reached your limit of maximum loops. In order to create more loops please upgrade your plan. Contact us on
            <i><base-mailto :email="supportEmail" /></i> for more details.
            <br />
            Go to your
            <router-link to="/profile/pricing">profile</router-link> in order to change your pricing plan.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="showPricingPlansDialog = false"> Cancel </v-btn>
            <v-btn color="primary" to="/profile/pricing">Go to profile</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="loop-nav">
      <v-btn text x-large :disabled="slideIndex === 1 || disabledPrevious" @click="previousSlide">
        {{ $t("loop.buttons.previous") }}
      </v-btn>

      <v-btn x-large v-if="slideIndex < slides.length" color="primary" :loading="buttonLoading" @click="nextSlide">
        {{ $t("loop.buttons.next") }}
      </v-btn>

      <v-btn v-else x-large color="primary" :loading="buttonLoading" :disabled="disabledUpsert" @click="handleLoopUpsert">
        {{ isCreateOrDuplicate ? $t("loop.buttons.action.create") : $t("loop.buttons.action.edit") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import LoopInfo from "@/components/loop/LoopInfo.vue";
import LoopEmail from "@/components/loop/LoopEmail.vue";
import LoopSettings from "@/components/loop/LoopSettings.vue";
import LoopReview from "@/components/loop/LoopReview.vue";
import ProgressAnimation from "@/components/Progress.vue";
import NewPayment from "@/components/subscription_plan/NewPayment.vue";
import appSDK from "@/client/AppSDK";
import { LoopSerializer } from "@/serializer";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  name: "Loop",

  components: {
    LoopInfo,
    LoopEmail,
    LoopSettings,
    LoopReview,
    NewPayment,
    ProgressAnimation,
  },
  data() {
    return {
      buttonLoading: false,
      disabledPrevious: false,
      disabledUpsert: false,
      isLoading: true,
      slides: [
        { index: 1, title: "Search Info", component: "loopInfo" },
        { index: 2, title: "Email Template", component: "loopEmail" },
        { index: 3, title: "Settings", component: "loopSettings" },
        { index: 4, title: "Review", component: "loopReview" },
      ],

      slideIndex: 1,
      showAnimation: false,
      showPricingPlansDialog: false,
      loopOwner: null,
    };
  },
  props: ["action", "loopId", "clientId"],
  computed: {
    ...mapGetters("user", { user: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    isEditMode() {
      return this.action === "edit";
    },

    isCreateMode() {
      return this.action === "create";
    },

    isDuplicateMode() {
      return this.action === "duplicate";
    },

    isCreateOrDuplicate() {
      return this.isCreateMode || this.isDuplicateMode;
    },

    currentUser() {
      return this.user;
    },

    supportEmail() {
      return this.$store.getters.configs.supportEmail;
    },
  },
  async mounted() {
    try {
      if (this.user.loopCount >= 1 && this.user.pricingPlan === 1 && !this.isEditMode){
        this.showPricingPlansDialog = true;
      }

      this.loopOwner = await appSDK["user.get"](this.clientId);
      this.$store.commit("clearLoopData"); // we clear the loopPostData, don't leave leftovers from previous loop action
      this.$store.commit("setLoopAction", this.action);
      await this.$store.dispatch("fetchConstants");
      if (this.isEditMode || this.isDuplicateMode) {
        let loopData;
        const is_partner = this.isUserPartner;
        if (is_partner) {
          loopData = await appSDK["loop.show"](this.loopId, this.clientId);
        } else {
          loopData = await appSDK["loop.show"](this.loopId, null);
        }
        this.$store.commit("setLoopData", LoopSerializer.deserialize(loopData));
      }
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    nextSlide() {
      if (this.$refs.slide.length > 0 && this.slideIndex > 0) {
        const errors = this.$refs.slide[this.slideIndex - 1].postData();
        if (errors) return;
        this.slideIndex++;
        this.scrollToTop();
        this.$amplitude.logEvent("CLICK_NEXT_IN_LOOP_SLIDE_" + this.slideIndex);
      }
    },

    handleErrors(errors) {
      this.$notify({
        message: errors,
        type: "danger",
      });
    },
    previousSlide(index) {
      this.slideIndex--;
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    //#region Final step
    handleLoopUpsert() {
      this.$amplitude.logEvent("LOOP_UPSERT", { action: this.action });
      this.buttonLoading = true;
      this.disabledPrevious = true;
      return this.isEditMode ? this.saveLoopEdits() : this.startTheLoop();
    },

    // After create loop
    async startTheLoop() {
      const data = this.$store.getters.getLoopData;
      const serializedData = LoopSerializer.serialize(data);

      let results;
      try {
        const is_partner = this.isUserPartner;
        if (is_partner) {
          results = await appSDK["partner.loop.create"](this.clientId, serializedData);
        } else {
          results = await appSDK["loop.create"](serializedData);
        }
        this.$store.dispatch(user.actions.UpdateLoopCount, 1);
        this.handleAfterLoopUpsert(results);
      } catch (error) {
        this.handleLoopErrors(error);
      } finally {
        this.buttonLoading = false;
      }
    },

    // after edit loop
    async saveLoopEdits() {
      const data = this.$store.getters.getLoopData;
      const serializedData = LoopSerializer.serialize(data);

      try {
        let results;
        if (this.isUserPartner) {
          results = await appSDK["partner.loop.edit"](this.clientId, this.loopId, serializedData);
        } else {
          results = await appSDK["loop.edit"](this.loopId, serializedData);
        }
        this.handleAfterLoopUpsert(results);
      } catch (error) {
        this.handleLoopErrors(error);
      } finally {
        this.buttonLoading = false;
      }
    },

    // go to results screen
    handleAfterLoopUpsert(res) {
      this.disabledUpsert = true;
      if (!res?.loopId) {
        this.showAnimation = true;
        this.slideIndex = this.slideIndex + 1;
        return;
      }
      const is_partner = this.isUserPartner;
      if (is_partner) {
        this.$router.push(`/clients/${this.clientId}/loops/${res.loopId}?progress=true`);
      } else {
        this.$router.push(`/loops/${res.loopId}${this.isCreateMode ? "?progress=true" : ""}`);
      }
    },

    // can't go to result screen because of error
    handleLoopErrors(error) {
      this.disabledPrevious = false;
      if (error?.responseContent?.error === "maximum-loops-reached") {
        if (this.$store.getters.configs?.showPricingPlans) {
          this.showPricingPlansDialog = true;
        } else {
          this.handleErrors("You have reached your limit of maximum loops.");
        }
        return;
      }
      console.error("error", error); // similar handling to other cases after api calls
      this.handleErrors(this.$t("errors.loopUpsertUnexpectedError"));
    },

    //#endregion
  },
};
</script>
<style scoped>
@import "../assets/css/tooltip.css";

.reviewDetails {
  max-height: 7em;
  overflow-y: auto;
  overflow-x: hidden;
}

.file-upload .input-wrapper {
  border-radius: 5px 5px 5px 5px;
  width: 100%;
  height: 100%;
  margin: 1em;
}

.pad-c {
  padding: 1em;
  color: white;
}

.flex-b {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loop-nav {
  text-align: right;
  float: right;
  margin-top: 10px;
  margin-bottom: 100px;
}

@media screen and (max-width: 600px) {
  .loop-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
}
</style>
<style>
@import "../assets/css/tooltip.css";

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
<style scoped>
.loop-body {
  clear: both; /* needed to not mess with float: right buttons */
}

@media screen and (max-width: 600px) {
  .loop-body {
    margin: 0 !important;
  }
}
</style>
