<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small @click="duplicateLoopWithThisJobTitle(item)" v-bind="attrs" v-on="on">
        <v-icon> content_copy </v-icon>
      </v-btn></template
    >
    <span>Duplicate loop with this job title</span>
  </v-tooltip>
</template>

<script>
import appSDK from "@/client/AppSDK";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  props: {
    item: {},
    clientId: {},
    loopId: {},
  },

  computed: {
    ...mapGetters("user", { user: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),
  },

  methods: {
    async duplicateLoopWithThisJobTitle(item) {
      if (!this.isUserPartner) {
        console.error("cannot copy if you are not a partner");
        return;
      }

      try {
        const newJobTitle = item.jobTitle;

        await appSDK["partner.loop.copy"](this.clientId, this.loopId, {
          jobtitle: newJobTitle,
        });
        this.$notify({
          message: `The loop was successfully duplicated with job title "${newJobTitle}"`,
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$notify({
          message: "There was an error duplicating the loop.",
          type: "danger",
        });
      }
    },
  },
};
</script>

<style></style>
