<template>
  <v-data-table
    :headers="headers"
    :items="localMatches"
    :options.sync="localOptions"
    :server-items-length="totalMatches"
    :loading="loading"
    :footer-props="{
      disableItemsPerPage: false,
      itemsPerPageOptions: [10, 20, 50, 100],
      showFirstLastPage: true,
      itemsPerPageText: $t('results.rowsPerPage'),
    }"
    :show-select="!!selectionStatus"
    :selectable-key="selectableKey"
    v-model="localSelectedMatches"
    item-key="matchId"
  >
    <!-- Job Title -->
    <template v-slot:item.jobTitle="{ item }">
      <a :href="`/match/${item._id}`" @click.stop.prevent="handleMatchDataDisplay(item)">{{ item.jobTitle }}</a>
      <EasyApplyIndicatorDot v-if="item.easyApply && allowExtension" :platform="item.platform" />
    </template>

    <template v-slot:item.jobPosterName="{ item }">
      <span>{{ item.jobPosterName || '-' }}</span>
    </template>

    <!-- Status -->
    <template v-slot:item.tag="{ item }">
      <div style="display: flex; flex-direction: row; gap: 5px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              :color="item.tagColor"
              outlined
              small
              v-bind="attrs"
              v-on="on"
              :href="`/match/${item._id}#questions`"
              @click.stop.prevent="handleTagClick(item)"
            >
              {{ item.tag }}
            </v-chip>
          </template>
          <span>{{ item.tagTooltip }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.scheduleInterview">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="purple"
              outlined
              small
              v-bind="attrs"
              v-on="on"
              :href="`/match/${item._id}#questions`"
              @click.stop.prevent="handleTagClick(item)"
            >
              {{ $t("results.interview") }}
            </v-chip>
          </template>
          <span>{{ $t("results.Tags.scheduled_interview") }}</span>
        </v-tooltip>
      </div>
    </template>

    <!-- Actions -->
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click.stop.prevent="rateMatch(item, 1)"
            v-bind="attrs"
            v-on="on"
            x-small
            :color="item.rating === 1 ? 'green' : 'gray'"
          >
            <v-icon>
              {{ item.rating === 1 ? mdiThumbUp : mdiThumbUpOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("results.like") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click.stop.prevent="rateMatch(item, 0)"
            v-bind="attrs"
            v-on="on"
            x-small
            :color="item.rating === 0 ? 'red' : 'gray'"
          >
            <v-icon>
              {{ item.rating === 0 ? mdiThumbDown : mdiThumbDownOutline }}
            </v-icon>
          </v-btn></template
        >
        <span>{{ $t("results.dislike") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            :href="item.url"
            @click="
              // @ts-ignore
              $amplitude.logEvent('CLICK_JOB_BOARD_URL', {
                matchId: item.matchId,
                from: 'results-table',
              })
            "
            target="_blank"
            rel="noopener noreferrer"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> open_in_new </v-icon>
          </v-btn></template
        >
        <span>{{ $t("results.original") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            @click.stop.prevent="deleteMatch(item.matchId)"
            rel="noopener noreferrer"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> delete_outline </v-icon>
          </v-btn></template
        >
        <span>{{ $t("results.trash") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$store.getters.configs?.showJobPosterInfo">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            @click.stop.prevent="openJobPosterProfileUrl(item.jobPosterProfileUrl)"
            rel="noopener noreferrer"
            v-bind="attrs"
            v-on="on"
            :disabled="!item.jobPosterProfileUrl"
          >
            <v-icon> person_outline </v-icon>
          </v-btn>
        </template>
        <span>{{ $t("results.profileUrl") }}</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import EasyApplyIndicatorDot from "@/components/EasyApplyIndicatorDot.vue";
import { equalObjects, compareArrays } from "@/helpers/utils/objectUtils";
import { mdiThumbUp, mdiThumbUpOutline, mdiThumbDown, mdiThumbDownOutline, mdiDotsVertical } from "@mdi/js";

export default {
  components: {
    EasyApplyIndicatorDot,
  },

  props: {
    options: {
      type: String,
    },
    totalMatches: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    matches: {
      type: Array,
    },
    selectedMatches: {
      type: Array,
    },
    allowExtension: {
      type: Boolean,
    },
    selectionStatus: {
      type: String,
    },
  },

  data() {
    return {
      headers: null,
      localOptions: {},
      localMatches: [],
      localSelectedMatches: [],
      mdiThumbUp,
      mdiThumbUpOutline,
      mdiThumbDown,
      mdiThumbDownOutline,
    };
  },

  watch: {
    localOptions(value) {
      if (!equalObjects(JSON.parse(this.options), value)) {
        this.$emit("options-updated", JSON.stringify(value));
      }
    },

    localSelectedMatches(value) {
      if (!compareArrays(this.selectedMatches, value)) {
        this.$emit("onSelectedMatches", value);
      }
    },

    '$i18n.locale': {
    handler(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateHeaders();
      }
    },
    immediate: true,
  },
  },

  computed: {
    selectableKey() {
      if (this.selectionStatus === "applyOnDemand") {
        return "isSelectable";
      } else if (this.selectionStatus === "easyApply") {
        return "easyApply";
      } else {
        return null;
      }
    },
  },

  created() {
    this.updateHeaders();
    this.localOptions = JSON.parse(this.options);
  },

  mounted() {
    this.localMatches = this.matches;
    this.localSelectedMatches = this.selectedMatches;
    this.localExtensionMatches = this.extensionMatches;
  },

  methods: {
    handleMatchDataDisplay(match) {
      this.$emit("onDisplayMatch", JSON.stringify(match));
    },

    async handleTagClick(match) {
      this.$emit("onTagClick", match);
    },

    rateMatch(match, rating) {
      this.$emit("onMatchRating", JSON.stringify({ match, rating }));
    },

    deleteMatch(matchId) {
      this.$emit("onMatchDeleted", matchId);
    },

    updateMatches(matches) {
      this.localMatches = matches;
    },

    updateSelectedMatches(matches) {
      this.localSelectedMatches = matches;
    },

    updateOptions(value) {
      this.localOptions = JSON.parse(value);
    },

    updateHeaders() {
      this.headers = [
        {
          text: this.$t("results.headers.jobTitle"),
          align: "start",
          sortable: false,
          value: "jobTitle",
        },
        { text: this.$t("company"), value: "company", sortable: false },
        { text: this.$t("location"), value: "jobLocation", sortable: false },
        {
          text: this.$t("results.headers.status"),
          value: "tag",
          sortable: false,
        },
        { text: this.$t("results.platform"), value: "platform", sortable: false },
        {
          text: this.$t("results.headers.matchedAt"),
          value: "matchCreatedAt",
          sortable: false,
          width: 125,
        },
        {
          text: this.$t("results.headers.actions"),
          value: "actions",
          sortable: false,
          align: "start",
          width: 170,
        },
      ];

      if (this.$store.getters.configs?.showJobPosterInfo) {
        this.headers.splice(5, 0, {
          text: this.$t("results.jobPoster"),
          value: "jobPosterName",
          sortable: false
        });
      }
    },

    openJobPosterProfileUrl(url) {
      window.open(url, '_blank');
    },
  },
};
</script>
