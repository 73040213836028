<template>
  <div>
    <div class="tab_body">
      <div
        :class="tab == 1 ? 'tab selected' : 'tab'"
        v-on:click="() => handleChangeTab(1)"
        v-if="this.showJobApplicationsGraph"
      >
        <h5 class="text-h1 my-5 mx-3">{{ this.$t("Overview.ApplicationStats.title") }}</h5>
      </div>
      <div
        :class="tab == 2 ? 'tab selected' : 'tab'"
        v-on:click="() => handleChangeTab(2)"
        v-if="this.showOutgoingEmailsGraph"
      >
        <h5 class="text-h1 my-5 mx-3">{{ this.$t("Overview.ApplicationStats.title_email") }}</h5>
      </div>
    </div>
    <div class="application_per_day">
      <div class="loading_body" v-if="loading">
        <app-loader />
      </div>
      <StackedBarChart v-else :labels="this.labels" :chartData="this.data" :isEmail="tab == 2" />
    </div>
  </div>
</template>

<script>
import { StackedBarChart, Dropdown, DateTime } from "@/framework/index";
import { constant } from "@/store/constant";
import { formattedDateYYYYMMDD } from "@/helpers/dateUtils";

export default {
  components: {
    StackedBarChart,
    Dropdown,
    DateTime,
  },

  props: {
    fromDate: String,
    toDate: String,
  },

  data() {
    return {
      loading: true,
      data: {},
      tab: 1,
      labels: [],
    };
  },

  watch: {
    async fromDate() {
      await this.getData();
    },

    async toDate() {
      await this.getData();
    },
  },
  async mounted() {
    if (!this.showJobApplicationsGraph) {
      this.tab = 2;
    }

    await this.getData();
  },

  computed: {
    showJobApplicationsGraph() {
      return this.$store.getters.configs.showJobApplicationsGraph;
    },

    showOutgoingEmailsGraph() {
      return this.$store.getters.configs.showOutgoingEmailsGraph;
    },

    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
  },

  methods: {
    async handleChangeTab(tab) {
      this.loading = true;
      this.tab = tab;
      await this.getData();
    },

    async getData() {
      try {
        this.loading = true;
        if (this.tab == 1) {
          const passDate = new Date();
          passDate.setDate(passDate.getDate() - 7);
          const result = await this.$store.dispatch(constant.user.actions.GetApplications, {
            fromDate: this.fromDate,
            toDate: this.toDate,
          });
          this.labels = [...new Set(result.map(item => formattedDateYYYYMMDD(new Date(item.date))))];
          this.data = result;
        } else if (this.tab == 2) {
          const currentDate = new Date();
          const passDate = new Date();
          passDate.setDate(passDate.getDate() - 7);
          const payload = {
            isEmail: true,
            fromDate: this.fromDate,
            toDate: this.toDate,
          };
          const result = await this.$store.dispatch(constant.user.actions.GetApplications, payload);
          this.labels = [...new Set(result.map(item => formattedDateYYYYMMDD(new Date(item.date))))];
          this.data = result;
        }
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.loading_body {
  background-color: var(--white);
}
.tab_body {
  display: flex;
  flex-direction: row;
  gap: 1.5px;
}
.tab {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  cursor: pointer;
  background-color: var(--white);
  opacity: 0.4;
}

.tab:hover {
  border-top: 1px solid #0000001e;
  border-right: 1px solid #0000001e;
  border-left: 1px solid #0000001e;
  box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.1);
}

.selected {
  opacity: 1 !important;
  border-top: 1px solid #0000001e;
  border-right: 1px solid #0000001e !important;
  border-left: 1px solid #0000001e !important;
  box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.1);
}

.selected:hover {
  border-top: 1px solid #0000001e !important;
  border-right: 1px solid #0000001e !important;
  border-left: 1px solid #0000001e !important;
}
</style>
