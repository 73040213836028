import Vue from "vue";
import RouterPrefetch from "vue-router-prefetch";
import VueRouter from "vue-router";

import router from "./router";

// order of middlewares is important
import "./middlewares/migration";
import "./middlewares/authentication";
import "./middlewares/authorization";
import "./middlewares/skipPricingPlans";
import "./middlewares/locale";
import "./middlewares/logNavigationsToAmplitude";

Vue.use(VueRouter);
Vue.use(RouterPrefetch);

export default router;
