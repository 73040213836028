import {I18N_LOCAL_STORAGE_NAME} from "@/assets/js/constants";
import i18n from "@/core/i18n";

export function ChangeLanguage(vm, locale) {
  if (i18n.locale !== locale) {
    vm.$amplitude.logEvent("SWITCH_LOCALE", {
      from: vm.$i18n.locale,
      to: locale,
    });
    i18n.locale = locale;

    localStorage.setItem(I18N_LOCAL_STORAGE_NAME, locale);
  }
}

export function getCurrentLanguage() {
  return i18n.locale;
}
