<template>
  <div class="loadingContainer pt-8">
    <div class="text-center">
      <!-- <p>{{ $t('loading.great_job') }}</p> -->
      <p class="text-subtitle-2 bold">{{ $t('loading.great_job_processing') }}</p>
      <vue-lottie
          :options="lottieOptions"
          :height="300" 
          :width="300"
          :isStopped="animationStopped"
          :isPaused="animationPaused"
      />
      <div class="text-messages">
        <p class="text-subtitle-2">{{ $t('loading.heads_up_message') }}</p>
        <p class="text-subtitle-2 bold">{{ $t('loading.hang_tight_message') }}</p>
        <p class="text-body-2">{{ $t('loading.good_things_message') }}</p>
        <p class="text-body-2">{{ $t('loading.good_luck') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueLottie from 'vue-lottie';

export default {
  name: "LoopLoading",
  components: {
    VueLottie,
  },
  props: {},
  data() {
    return {
      lottieOptions: {
        loop: true,
        autoplay: true,
        animationData: require('../assets/animations/Animation-infinite.json'),
        animationSpeed: 1
      },
      animationStopped: false,
      animationPaused: false,
      value: 0,
      interval: 0,
    };
  },
  mounted() {
    this.queryAndIndeterminate();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    queryAndIndeterminate() {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          clearInterval(this.interval);
          this.$emit("progressEnd");
        }
        this.value = Math.min(100, Math.round(this.value + 15));
      }, 1000);
    },
  },
};
</script>

<style>
.loadingContainer {
  display: flex;
  justify-content: center;
}

.text-center {
  text-align: center;
  max-width: 70%;
}
.bold {
  font-weight: bold;
}
</style>
