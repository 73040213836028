<template>
  <v-banner
    v-if="!this.isUserPartner && this.pricingPlan && showPricingPlans && this.subscriptionStatus === 'trialStart'"
    app
    class="warning-banner w-100"
    outlined
    color="white"
    rounded
    shaped
    small
    :single-line="isDesktop"
    :two-line="!isDesktop"
  >
    <template v-slot:icon>
      <v-icon size="24"> warning_amber </v-icon>
    </template>

    <div class="text-body-1" :class="{ 'text-truncate': isDesktop }">
      Free trial is active - {{ remainingDays }} days left. Upgrade to a paid plan to keep applying for jobs.
    </div>

    <template v-slot:actions>
      <v-dialog v-model="dialog" max-width="800">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined v-bind="attrs" v-on="on"> {{ $t("upgrade-now") }}</v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5">
            {{ $t("pricing-plan") }}
          </v-card-title>

          <PricingPlans></PricingPlans>
        </v-card>
      </v-dialog>
    </template>
  </v-banner>
</template>

<script>
import PricingPlans from "@/components/subscription_plan/PricingPlans.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  name: "FreeTrialBanner",
  data: () => ({
    dialog: false,
    isDesktop: true,
  }),
  components: {
    PricingPlans,
  },
  watch: {
    dialog(value) {
      this.$amplitude.logEvent("TOGGLE_UPGRADE_BANNER_DIALOG", { show: value });
    },
  },
  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    user() {
      return this.getUser;
    },

    pricingPlan() {
      return this.getUser?.pricingPlan;
    },

    subscriptionStatus() {
      return this.getUser?.subscriptionStatus;
    },

    showPricingPlans() {
      return this.$store.getters.configs?.showPricingPlans;
    },
    remainingDays() {
      if (this.user.remainingDays <= 0) {
        return 0;
      }
      return this.user.remainingDays;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isDesktop = window.screen.availWidth > 600;
    },
  },
};
</script>
