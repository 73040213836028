<template>
  <div class="pb-4">
    <PieChart v-if="chartdata" :chartData="chartdata" :options="options" />
    <div v-else>Graph is not available</div>
  </div>
</template>

<script>
import PieChart from "@/components/ViewLoop/stats/PieChart.vue";
export default {
  components: { PieChart },
  props: {
    matchesPerLoopSummary: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 50,
        legend: {
          position: "bottom",
          align: "start",
          labels: {
            textAlign: "left",
          },
        },
      },
    };
  },
  computed: {
    chartdata() {
      if (!this.matchesPerLoopSummary) return {};
      if (!this.matchesPerLoopSummary.length) return {};
      let matchesPerLoopSummary = this.matchesPerLoopSummary.filter(
        (item) => item.totalMatches > 0
      );
      if (!matchesPerLoopSummary) return {};

      matchesPerLoopSummary.sort(function (a, b) {
        if (a.searchId < b.searchId) {
          return -1;
        }
        if (a.searchId > b.searchId) {
          return 1;
        }
        return 0;
      });

      return {
        datasets: [
          {
            data: matchesPerLoopSummary.map((item) => item.totalMatches),
            backgroundColor: [
              "#9CCC65",
              "#EC407A",
              "#29B6F6",
              "#FFCA28",
              "#AB47BC",
              "#EF5350",
              "#26A69A",
            ],
            // backgroundColor: [
            //   "rgba(255, 99, 132, 0.2)",
            //   "rgba(54, 162, 235, 0.2)",
            //   "rgba(255, 206, 86, 0.2)",
            //   "rgba(75, 192, 192, 0.2)",
            //   "rgba(153, 102, 255, 0.2)",
            //   "rgba(255, 159, 64, 0.2)"
            // ],
            borderAlign: "center",
          },
        ],
        labels: matchesPerLoopSummary.map((item) => item.jobTitle),
      };
    },
  },
};
</script>

<style>
</style>