<template>
  <div class="center">
    <v-data-table
      :headers="headers"
      :items="loops"
      disable-pagination
      hide-default-footer
      :expanded.sync="expanded"
      item-key="searchId"
      :loading="isLoading"
    >
      <template v-slot:no-data>
        <div class="d-flex flex-column align-center pa-5">
          <span>This client has no loops right now.</span>
          <v-btn color="primary" class="mt-5" :to="`/clients/${clientId}/loops/create`">Create one now!</v-btn>
        </div>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <LoopInfoPresentation :loop="item" :email="'email'" :showJobTitleRow="false" />
        </td>
      </template>
      <template v-slot:item.partnerNotes="{ item }">
        <v-edit-dialog
          large
          persistent
          :return-value.sync="item.partnerNotes"
          @save="savePartnerNotes(item.searchId, item.partnerNotes)"
        >
          <div class="partnerNotes">{{ item.partnerNotes || "&mdash;" }}</div>
          <template v-slot:input>
            <v-textarea v-model="item.partnerNotes" :rules="[]" label="Edit"></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.sharedNotes="{ item }">
        <v-menu :close-on-content-click="false" v-model="item.sharedNotesMenu" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <div class="sharedNotes" v-bind="attrs" v-on="on">
              <span class="text-underline">{{ item.sharedNotesPreview || "(Click to add)" }} </span
              ><v-icon x-small>open_in_new</v-icon>
            </div>
          </template>

          <v-card>
            <SharedNotes
              key="item.searchId"
              :sharedNotes="item.sharedNotes"
              @submitNote="submitSharedNote(item, $event)"
              @cancel="item.sharedNotesMenu = false"
            />
          </v-card>
        </v-menu>
      </template>
      <template v-slot:item.name="{ item }">
        <v-edit-dialog :return-value.sync="item.name" @save="saveLoopName(item.searchId, item.name)">
          <span class="text-underline">{{ item.name || "(Click to add)" }}</span>
          <template v-slot:input>
            <v-text-field v-model="item.name" :rules="[max100chars]" label="Edit" single-line counter></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.settings="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip :color="item.tagColor" outlined x-small v-bind="attrs" v-on="on">
              {{ item.tag }}
            </v-chip>
          </template>
          <span>{{ item.tagTooltip }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.totalJobs="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-badge color="yellow" dot :value="item.totalJobs < 20">
              <!-- TODO extract condition -->

              <div v-bind="attrs" v-on="on">
                <router-link style="color: inherit" :to="`/clients/${clientId}/loops/${item.searchId}/stats`">
                  <span>{{ item.totalJobs }}</span>
                </router-link>
              </div></v-badge
            >
          </template>
          <div>
            The number of total jobs we found when searching for "{{ item.jobTitle }} @ {{ item.jobLocation }}" on job boards
            (LinkedIn, Indeed, etc.)
          </div>
          <div v-if="item.totalJobs < 20">
            Recommendation: Edit or duplicate this loop with a more popular job title or broader location in order to get more
            results from the job boards.
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :to="`/clients/${clientId}/loops/${item.searchId}/matches`"
                @click="$amplitude.logEvent('PARTNER_LOOP_RESULTS')"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> list </v-icon>
              </v-btn>
            </template>
            <span>View loop results</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :to="`/clients/${clientId}/loops/${item.searchId}/edit`"
                @click="$amplitude.logEvent('PARTNER_LOOP_EDIT')"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> edit </v-icon>
              </v-btn>
            </template>
            <span>Edit this loop</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :to="`/clients/${clientId}/loops/${item.searchId}/duplicate`"
                @click="$amplitude.logEvent('PARTNER_LOOP_DUPLICATE')"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> content_copy </v-icon>
              </v-btn>
            </template>
            <span>Duplicate this loop</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                target="_blank"
                rel="noopener noreferrer"
                v-bind="attrs"
                v-on="on"
                @click="handleDeleteButtonClick(item)"
              >
                <v-icon> delete </v-icon>
              </v-btn></template
            >
            <span>Delete this loop</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="isDeleteModalVisible" max-width="500">
      <v-card class="pa-5">
        <center>
          <h4>{{ $t("automations.deleteDialog.question") }}</h4>
        </center>
        <center>
          <v-btn @click="isDeleteModalVisible = false" elevation="0">
            {{ $t("no") }}
          </v-btn>
          <v-btn color="error" @click="handleLoopDelete">{{ $t("yes") }}</v-btn>
        </center>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import appSDK from "@/client/AppSDK";

import LoopInfoPresentation from "../ViewLoop/LoopInfoPresentation.vue";
import SharedNotes from "./SharedNotes.vue";

export default {
  components: { LoopInfoPresentation, SharedNotes },
  data() {
    return {
      max100chars: v => !v || v.length <= 100 || "Input too long!",
      isDeleteModalVisible: false,
      currentLoop: "",
      expanded: [],
      footerProps: { disableItemsPerPage: true, disablePagination: true },
      headers: [],
      loops: [],
      isLoading: false,
    };
  },
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
  },
  created() {
    this.initTableHeader();
    this.getLoops();
  },
  methods: {
    initTableHeader() {
      const headers = [
        { text: "Name", align: "start", sortable: true, value: "name" },
        {
          text: "Job Title",
          align: "start",
          sortable: true,
          value: "jobTitle",
        },
        {
          text: "Location",
          align: "start",
          sortable: true,
          value: "jobLocation",
        },
        // { text: "CV", align: "start", sortable: true, value: "cv" },
        { text: "Settings", align: "start", sortable: true, value: "settings" },
        {
          text: "Jobs",
          align: "start",
          sortable: true,
          value: "totalJobs",
        },
        {
          text: "Matches",
          align: "start",
          sortable: true,
          value: "totalMatches",
        },
        {
          text: "Emails",
          align: "start",
          sortable: true,
          value: "totalMatchesWithEmails",
        },
      ];

      if (!this.onlyEmail) {
        headers.push({
          text: "Applications",
          align: "start",
          sortable: true,
          value: "totalMatchesWithApplications",
        });
      }

      headers.push({
        text: "Shared Notes",
        align: "start",
        sortable: false,
        value: "sharedNotes",
        width: 160,
      });

      headers.push({
        text: "Actions",
        sortable: false,
        value: "actions",
        width: 200,
        height: 30,
      });
      this.headers = headers;
    },

    async saveLoopName(loopId, newName) {
      try {
        await appSDK["partner.loop.edit.name"](this.clientId, loopId, {
          name: newName,
        });
      } catch (e) {
        console.error(e);
      }
    },

    async savePartnerNotes(searchId, partnerNotes) {
      try {
        // await appSDK['partner.loop.partnerNotes.edit']()
      } catch {}
    },

    async submitSharedNote(loop, text) {
      try {
        await appSDK["partner.loop.sharedNotes.create"](this.clientId, loop.searchId, { text });
        const sharedNotes = await appSDK["partner.loop.sharedNotes.list"](this.clientId, loop.searchId);

        const sharedNotesPreview = sharedNotes
          .map(note => note.text)
          .slice(0, 3)
          .join("\n");

        loop.sharedNotes = sharedNotes;
        loop.sharedNotesPreview = sharedNotesPreview;
      } catch (e) {
        console.error(e);
      }
    },

    async getLoops() {
      this.isLoading = true;
      try {
        const res = await appSDK["partner.loop.list"](this.clientId);
        this.loops = await Promise.all(
          res.map(async loop => {
            if (loop.onlyRemoteJobs === true && loop.jobLocation === "Remote") {
              loop.jobLocation = this.$t("loopPreview.remote-anywhere");
            } else if (loop.onlyRemoteJobs) {
              loop.jobLocation = this.$t("loopPreview.remote-jobLocation", { 0: loop.jobLocation });
            }

            const tag = this.getTag(loop);
            const tagColorMapper = {
              "Emails & Applications": "green",
              "Emails only": "purple",
              "Applications only": "blue",
              "Job search only": "grey",
            };
            const tagTooltipMapper = {
              "Emails & Applications": "This loop has automated emails and form applications enabled",
              "Emails only": "This loop will only send emails to companies, not apply online",
              "Applications only": "This loop will only apply to forms online, not send emails",
              "Job search only": "This loop has emails and applications disabled, you can only view the jobs we found",
            };

            const sharedNotes = loop.sharedNotes || [];

            const sharedNotesPreview = sharedNotes
              .map(note => note.text)
              .slice(0, 3)
              .join("\n");
            return {
              ...loop,
              tag,
              tagColor: tagColorMapper[tag],
              tagTooltip: tagTooltipMapper[tag],
              sharedNotes,
              sharedNotesPreview,
              sharedNotesMenu: false,
            };
          }),
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    getTag(loop) {
      if (loop.directEmails && loop.autoApply) {
        return "Emails & Applications";
      } else if (loop.directEmails) {
        return "Emails only";
      } else if (loop.autoApply) {
        return "Applications only";
      } else {
        return "Job search only";
      }
    },
    handleDeleteButtonClick(loop) {
      this.currentLoop = loop.searchId;
      this.isDeleteModalVisible = true;
    },
    async handleLoopDelete() {
      if (!this.currentLoop) return;
      this.isLoading = true;
      try {
        await appSDK["partner.loop.delete"](this.clientId, this.currentLoop);
        await this.getLoops();
      } catch (error) {
        console.error(error);
      } finally {
        this.isDeleteModalVisible = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.partnerNotes {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}
.text-underline {
  text-decoration: underline;
}
div.v-data-table table > thead > tr > th.sortable {
  white-space: nowrap;
}
</style>
