<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    max-width="70%"
  >
    <v-card>
      <v-card-title class="text-h5"> {{ $t('loop.slides.example.title') }} </v-card-title>
      <v-card-text class="dialog-content">
        {{ $t('loop.slides.example.description') }}
      </v-card-text>
      <div class="image-container">
          <img :src="example" alt="Example" class="example-image" />
          <div class="overlay"></div>
          <div class="overlay-text">EXAMPLE</div>
        </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          {{ $t('loop.slides.example.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import example from "@/assets/images/application-form-example.png";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      example,
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style>
.dialog-content img {
  max-width: 100%;
  height: auto;
}

.image-container {
  position: relative;
  display: block;
  max-width: 100%;
}

.example-image {
  width: 100%;
  display: block;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  color: #A3A3A3;
  font-size: 7.5em;
  opacity: 0.8;
  z-index: 10;
  font-weight: bold;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(169, 169, 169, 0.3);
  z-index: 5;
}

</style>
