<template>
  <v-app>
    <TheSidebar />
    <v-main>
      <v-container class="container-xl fill-height align-start pa-3">
        <FreeTrialBanner />
        <!-- <SupportUserBanner v-if="isB2C" /> -->
        <ClientAlert v-if="clientId" :clientId="clientId" />
        <span class="w-100" v-if="showCarrusBanner">
          <UpgradeBanner />
          <CarrusBanner />
          <ConsentBanner />
        </span>
        <slot />
      </v-container>
    </v-main>

    <slot name="app" />
  </v-app>
</template>
<script>
import TheSidebar from "./partials/TheSidebar.vue";
import ContentFooter from "./partials/ContentFooter.vue";
import UpgradeBanner from "./partials/UpgradeBanner.vue";
import ClientAlert from "./partials/ClientAlert.vue";
import FreeTrialBanner from "./partials/FreeTrialBanner.vue";
import CarrusBanner from "@/components/Banners/CarrusBanner.vue";
import ConsentBanner from "@/components/Banners/ConsentBanner.vue";
import SupportUserBanner from "./partials/SupportUserBanner.vue";

export default {
  name: "DefaultLayout",
  components: {
    TheSidebar,
    ContentFooter,
    ClientAlert,
    UpgradeBanner,
    FreeTrialBanner,
    CarrusBanner,
    ConsentBanner,
    SupportUserBanner,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    showCarrusBanner() {
      return this.$store.getters.configs.showCarrusBanner || false;
    },

    isB2C() {
      return this.$store.getters.isB2C;
    },
  },
};
</script>
<style lang="scss" scoped>
.warning-banner {
  margin: 0 0 5px;
}
.container {
  max-width: none; // TODO good on large screens?
  background-color: #f8f8fa;
  align-content: flex-start;
}

@media screen and (max-width: 600px) {
  .container {
    padding-inline: 0px !important;
  }
}
</style>
