<template>
  <div>
    <bubble-menu
      class="bubblemenu"
      :editor="editor"
      :tippy-options="{ duration: 100 }"
      v-if="editor"
    >
      <button
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <app-icon name="bold" />
      </button>
      <button
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <app-icon name="italic" />
      </button>
      <button
        @click="editor.chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        <app-icon name="strike" />
      </button>
    </bubble-menu>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Mention from "@tiptap/extension-mention";
import History from "@tiptap/extension-history";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";

import AppIcon from "../AppIcon";
import suggestion from "./suggestion";

export default {
  components: {
    EditorContent,
    BubbleMenu,
    AppIcon,
  },

  props: {
    value: {
      require: true,
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  methods: {
    handleEditorUpdate({ editor }) {
      this.$emit("update", editor.getHTML());
    },
  },

  watch: {
    editable(v) {
      this.editor.setEditable(v);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        History,
        Bold,
        Italic,
        Strike,
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          renderLabel({ options, node }) {
            return `{{${node.attrs.label || node.attrs.id}}}`;
          },
          suggestion,
        }),
      ],
      editable: this.editable,
      content: this.value,
      onUpdate: this.handleEditorUpdate,
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}
.editor {
  position: relative;
  padding: 10px;
  margin-bottom: 5px;
  line-height: 1.75em;
}

.mention {
  border: 1px solid $color-black;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
  box-decoration-break: clone;
}

.ProseMirror:focus {
  outline: none;
}

.bubblemenu {
  display: flex;
  background: $color-white;
  padding: 0.1rem 0.3rem;
  border-radius: 0.4rem;
  border: 1px solid $color-black;
}

.bubblemenu button {
  display: inline-flex;
  background: transparent;
  border: 0;
  color: rgba($color-black, 0.5);
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: rgba($color-black, 0.4);
  }

  &.is-active {
    color: rgba($color-black, 1);
  }

  > .v-icon {
    color: currentColor;
  }
}
</style>