import { ALL_ROLES, Role } from "@/helpers/enums/eRole";

import Profile from "@/pages/Profile.vue";
import InitialPricingSelection from "@/pages/subscription_plan/InitialPricingSelection.vue";
import ProfileInfo from "@/pages/user/Profile/ProfileInfo.vue";
import PricingPlans from "@/components/subscription_plan/PricingPlans.vue";
import settings from "@/pages/settings/settings.vue";
import SmtpSettings from "@/components/profile/SmtpSettings.vue";
import Questions from "@/pages/user/Questions/questions.vue";
import CompleteProfile from "@/pages/user/CompleteProfile/completeProfile.vue";
import ManualApplication from "@/pages/client/ManualApplication.vue";

export default [
  {
    path: "/profile",
    name: "profile",
    redirect: { name: "profile-info" },
    component: Profile,
    meta: { authorized: Role.ALL_ROLES },
    children: [
      {
        path: "info",
        name: "profile-info",
        component: ProfileInfo,
        meta: { authorized: Role.ALL_ROLES },
      },
      {
        path: "pricing",
        name: "profile-pricing",
        component: PricingPlans,
        meta: { authorized: Role.ALL_ROLES },
      },
      {
        path: "smtp",
        name: "profile-smtp",
        component: SmtpSettings,
        meta: { authorized: [Role.USER, Role.MANAGED_USER] },
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: { authorized: [Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/setuppricing",
    name: "InitialPricingSelection",
    component: InitialPricingSelection,
    meta: { layout: "auth" },
  },
  {
    path: "/questions/:clientId",
    name: "QuestionsClient",
    component: Questions,
    props: true,
    meta: { authorized: [Role.ADMIN, Role.SupportAgent, Role.MANAGER] },
  },
  {
    path: "/questions",
    name: "Questions",
    component: Questions,
    meta: { authorized: [Role.USER, Role.MANAGED_USER] },
  },
  {
    path: "/completeProfile",
    name: "CompleteProfile",
    component: CompleteProfile,
    meta: { layout: "auth" },
  },
  {
    path: "/manualApplication/:clientId",
    name: "ManualApplication",
    component: ManualApplication,
    props: true,
    meta: { authorized: [Role.ADMIN, Role.SupportAgent] },
  },
];
