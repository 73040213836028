<template>
  <v-row>
    <app-header />
    <div class="col-12">
      <div v-if="this.userLoaded">
        <PartnerOverviewStats v-if="this.isUserPartner" />
        <UserOverviewStats v-else />
      </div>
    </div>
  </v-row>
</template>
<script>
import UserOverviewStats from "@/components/overview/UserOverviewStats.vue";
import PartnerOverviewStats from "@/components/partners/PartnerOverviewStats.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  components: { UserOverviewStats, PartnerOverviewStats },

  computed: {
    ...mapGetters("user", {
      getUser: user.getters.getUser,
      userLoaded: user.getters.userLoaded,
      isUserPartner: user.getters.isUserPartner,
    }),

    user() {
      return this.getUser;
    },
  },
};
</script>
<style>
.pad {
  padding-left: 3em;
}
.flex-b {
  display: flex;
  justify-content: center;
}
.flex-c {
  /* width: 100px; */
  padding-top: 1em;
  padding-bottom: 1em;
  margin: 0 auto;
  /* margin-top: 0.3em; */
}
</style>
