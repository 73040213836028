<template>
  <div class="mb-9">
    <br />
    <div class="actionLabel">
      <h3 @click="hideHistory()">{{ this.$t("Payment.Common.payment_history") }}</h3>
    </div>
    <v-card elevation="0" outlined class="my-2 pa-2" v-show="isVisible">
      <div class="text-center" v-show="!hasData">
        {{ this.$t("Payment.Admin.no_invoices") }}
      </div>
      <v-data-table v-show="hasData" item-key="uniqueId" :headers="headers" :items="historyItem" :loading="loading">
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon :href="item.url">
                <v-icon>download</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-data-table></v-card
    >
  </div>
</template>

<script>
import { toCurrency } from "@/helpers/utils/stringUtils.js";
export default {
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isVisible: true,
      hasData: false,
      loading: false,
      headers: [
        { value: "status", sortable: false },
        {
          text: this.$t("Generic.Common.date"),
          value: "date",
          align: "end",
        },
        { text: this.$t("Generic.Common.actions"), align: "end", sortable: false, value: "actions" },
      ],
      historyItem: [],
    };
  },

  mounted: function () {
    this.initTable();
  },

  watch: {
    "$i18n.locale": function () {
      this.initTable();
    },
  },

  methods: {
    hideHistory() {
      this.isVisible = !this.isVisible;
    },

    initTable() {
      this.historyItem = [];
      if (this.invoices.length > 0) {
        this.hasData = true;
      } else {
        this.hasData = false;
      }
      for (let invoice of this.invoices) {
        const item = {};
        item.date = invoice.date || "";
        item.status = this.$t("Payment.Common.successful_payment_price", {
          price: toCurrency((invoice.amount / 100).toFixed(2), invoice.currency),
        });
        item.url = invoice.url;
        this.historyItem.push(item);
      }
    },
  },
};
</script>
