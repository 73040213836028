<template>
  <AuthTemplate>
    <div class="subtitleBlock">
      <label class="header2-semi-bold">{{ $t("Auth.Common.new_password") }}</label>
      <label class="subtitle header5">{{ this.$t("Auth.Common.new_password_subtitle") }}</label>
    </div>

    <form @submit.prevent="resetPassword">
      <Input
        class="input-form"
        :title="this.$t('Auth.Common.new_password')"
        :required="true"
        :error="this.errors.password"
        :value="this.model.password"
        :type="hidePassword ? 'password' : 'text'"
        :placeholder="this.$t('Generic.Form.placeholder_value', { value: this.$t('Auth.Common.new_password').toLowerCase() })"
        @input="value => handleInput(value, 'password')"
        ><EyeIn v-if="!this.hidePassword" @click="handleEyePassword" />
        <EyeOut v-if="this.hidePassword" @click="handleEyePassword"
      /></Input>
      <Input
        class="input-form"
        :title="this.$t('Auth.Common.confirm_new_password')"
        :required="true"
        :error="this.errors.passwordConfirm"
        :value="this.model.passwordConfirm"
        :type="hideConfirmPassword ? 'password' : 'text'"
        :placeholder="
          this.$t('Generic.Form.placeholder_value_again', { value: this.$t('Auth.Common.new_password').toLowerCase() })
        "
        @input="value => handleInput(value, 'passwordConfirm')"
        ><EyeIn v-if="!this.hideConfirmPassword" @click="handleEyeConfirmPassword" />
        <EyeOut v-if="this.hideConfirmPassword" @click="handleEyeConfirmPassword"
      /></Input>
      <Button
        class="submit-button"
        :title="this.$t('Auth.Common.reset_password')"
        :type="'submit'"
        :outlined="false"
        :fullWidth="true"
        :loading="loading"
      ></Button>
    </form>
  </AuthTemplate>
</template>

<script>
import AuthTemplate from "@/pages/auth/components/AuthTemplate.vue";
import { Button, Input } from "@/framework/index";
import EyeIn from "vue-material-design-icons/Eye.vue";
import EyeOut from "vue-material-design-icons/EyeOff.vue";
import { constant } from "@/store/constant";
import { handleInput, validateForm, validateConfirmPassword, validatePassword } from "@/helpers/utils/formUtils";
const user = constant.user;

export default {
  name: "ResetPassword",

  data() {
    return {
      model: {
        password: "",
        passwordConfirm: "",
      },
      errors: {
        password: "",
        passwordConfirm: "",
      },
      loading: false,
      hidePassword: true,
      hideConfirmPassword: true,
    };
  },

  components: {
    AuthTemplate,
    Input,
    Button,
    EyeIn,
    EyeOut,
  },

  watch: {
    "model.password"(value) {
      validatePassword(this, value);
    },

    "model.passwordConfirm"(value) {
      validateConfirmPassword(this, value);
    },
  },

  methods: {
    async resetPassword() {
      if (validateForm(this) && this.model.password == this.model.passwordConfirm) {
        this.loading = true;
        try {
          const { sessionId, email } = this.$route.query;
          const payload = {
            sessionId,
            email,
            password: this.model.password,
          };
          await this.$store.dispatch(user.actions.ResetPassword, payload);
          this.$router.push("/login");
        } catch (e) {
          console.error(e);
          if (e.responseContent?.message === "SessionId is missing") {
            this.$notify({
              message: this.$t("Generic.Messages.Error.reset_password_error"),
              type: "danger",
            });
            this.$router.push("/forgotPassword");
          }
        }
        this.loading = false;
      } else {
        validatePassword(this, this.model.password);
        validateConfirmPassword(this, this.model.passwordConfirm);
      }
    },

    // Handle Form
    handleInput(value, name) {
      handleInput(this, value, name);
    },

    handleEyePassword() {
      this.hidePassword = !this.hidePassword;
    },

    handleEyeConfirmPassword() {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    },
  },
};
</script>
