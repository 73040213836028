<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="viewClients"
      :options.sync="options"
      :server-items-length="totalClients"
      class="elevation-1"
      item-key="uniqueId"
      :loading="loading"
      :no-data-text="$t('partner.noClients')"
    >
      <template v-slot:item.manager="{ item }">
        <v-chip
          v-if="item.managedBy && getManager(item.managedBy)"
          small
          dark
          outlined
          @click="assignManager(item)"
          :color="getManager(item.managedBy).status === 'active' ? 'primary' : 'red'"
        >
          {{ getManager(item.managedBy).email }}
        </v-chip>
        <v-btn v-else color="primary" small @click="assignManager(item)"> Assign to manager </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom v-if="showSmtpSettings">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="handleClientEditSMTP(item)" v-bind="attrs" v-on="on">
              <v-icon> mail_outline </v-icon>
            </v-btn>
          </template>
          <span>Edit Client SMTP Details</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!onlyEmail">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="handleClientManualApplication(item)" v-bind="attrs" v-on="on">
              <ManualApplicationIcon />
            </v-btn>
          </template>
          <span>{{ $t("ManualApplication.manual_application_hint") }}</span>
        </v-tooltip>

        <v-tooltip v-if="!onlyEmail" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="handleClientQuestions(item)" v-bind="attrs" v-on="on">
              <QuestionIcon />
            </v-btn>
          </template>
          <span>{{ $t("Questions.client_questions") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="handleClientDisplay(item)" v-bind="attrs" v-on="on">
              <v-icon> open_in_new </v-icon>
            </v-btn>
          </template>
          <span>Open client loops</span>
        </v-tooltip>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <app-icon name="dotsVertical" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="changePasswordForUser(item)">
              <v-list-item-title> Change password for user </v-list-item-title>
            </v-list-item>

            <template v-if="(role === 'admin' || role === 'support-agent') && showManagerAdministration">
              <v-list-item @click="assignManager(item)">
                <v-list-item-title> Assign to manager </v-list-item-title>
              </v-list-item>
              <v-list-item @click="removeManager(item)">
                <v-list-item-title> Remove assigned manager </v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item v-if="showGoogleAuth" @click="googleLogin(item)">
              <v-list-item-title> Connect to Gmail </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span>{{ item.createdAtString }}</span>
      </template>
    </v-data-table>

    <ClientSMTPDialog ref="smtpDialog" />

    <ChangePasswordForUserDialog ref="changePasswordForUserDialog" />
  </div>
</template>

<script>
import appSDK from "@/client/AppSDK";
import ClientSMTPDialog from "@/components/partners/ClientSMTPDialog.vue";
import ChangePasswordForUserDialog from "@/components/partners/ChangePasswordForUserDialog.vue";
import { mapGetters } from "vuex";
import { QuestionIcon } from "@/assets/fonts/pages/index.js";
import { ManualApplicationIcon } from "@/assets/fonts/icons/index";
function triggerGooglePopup() {
  return new Promise(function (resolve, reject) {
    const client = google.accounts.oauth2.initCodeClient({
      client_id: "582685598002-etdtnc1jnjoj71p7i5i4fso9al37re5q.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/gmail.send",
      ux_mode: "popup",
      select_account: true,
      callback: response => {
        console.log(response);
        if (response.error) {
          reject(response);
        } else {
          resolve(response);
        }
      },
    });
    console.log(client);
    client.requestCode();
  });
}

export default {
  components: { ClientSMTPDialog, ChangePasswordForUserDialog, QuestionIcon, ManualApplicationIcon },
  props: {
    managers: {
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      totalClients: 0,
      clients: [],
      options: {},
      footerProps: { disableItemsPerPage: true, disablePagination: true },
    };
  },
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (newOptions.page === undefined || newOptions.itemsPerPage === undefined) {
          return;
        }
        if (newOptions.page === oldOptions.page && newOptions.itemsPerPage === oldOptions.itemsPerPage) {
          return;
        }
        this.getClients();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("user", { getUser: "getUser" }),

    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
    viewClients() {
      return this.clients.map(client => {
        const createdAt = client.createdAt ? new Date(client.createdAt) : null;
        return {
          ...client,
          name: `${client.firstName} ${client.lastName}`,
          createdAt,
          createdAtString: createdAt ? createdAt.toDateString() : "-",
        };
      });
    },
    showSmtpSettings() {
      return this.$store.getters.configs?.showSmtpSettings;
    },
    currentUser() {
      return this.getUser;
    },

    role() {
      return this.getUser?.role || null;
    },

    showManagerAdministration() {
      return this.$store.getters.configs?.showManagerAdministration;
    },
    showManualApplications() {
      return this.$store.getters.configs?.showManualApplications;
    },
    showGoogleAuth() {
      return this.$store.getters.configs?.showGoogleAuth;
    },
    headers() {
      const filteredHeaders = [
        {
          text: this.$i18n.t("partner.name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", align: "start", sortable: false, value: "email" },
        {
          text: this.$i18n.t("partner.createdAt"),
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Jobs",
          align: "start",
          sortable: false,
          value: "totalJobs",
        },
        {
          text: "Matches",
          align: "start",
          sortable: false,
          value: "totalMatches",
        },
      ];

      if (!this.onlyEmail) {
        filteredHeaders.push({
          text: "Applications",
          align: "start",
          sortable: false,
          value: "totalApplications",
        });
      }

      // if (this.showManualApplications) {
      //   filteredHeaders.push({
      //     text: "Manual Applications",
      //     align: "start",
      //     sortable: false,
      //     value: "totalManualApplications",
      //   });
      // }

      filteredHeaders.push({
        text: "Emails",
        align: "start",
        sortable: false,
        value: "totalEmails",
      });

      if (this.showManagerAdministration && (this.role === "admin" || this.role == "support-agent")) {
        filteredHeaders.push({
          text: "Manager",
          align: "start",
          sortable: false,
          value: "manager",
        });
      }

      filteredHeaders.push({
        text: this.$i18n.t("partner.actions"),
        align: "start",
        sortable: false,
        value: "actions",
      });

      return filteredHeaders;
    },
  },
  methods: {
    handleClientDisplay(item) {
      this.$amplitude.logEvent("PARTNER_SHOW_CLIENT_LOOPS_BUTTON", {
        clientId: item.uniqueId,
      });
      this.$router.push(`/clients/${item.uniqueId}/loops`);
    },
    async getClients() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      try {
        const res = await appSDK["partner.childUser.list"]({
          perPage: itemsPerPage,
          page,
        });
        this.clients = res.users;
        this.totalClients = res.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    handleClientEditSMTP(item) {
      this.$refs.smtpDialog.open(item);
    },
    assignManager(user) {
      this.$emit("showManagersList", user);
    },
    removeManager(user) {
      this.$emit("removeManager", user);
    },
    getManager(id) {
      return this.managers.find(manager => manager.uniqueId === id);
    },
    async googleLogin(item) {
      try {
        const response = await triggerGooglePopup();
        await appSDK["partner.childUser.updateGmail"](item.uniqueId, {
          code: response.code,
        });
        this.$notify({
          message: "Gmail was successfully connected for this user.",
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$notify({
          message: "There was an error updating the Google Details for the user.",
          type: "danger",
        });
      }
    },
    changePasswordForUser(item) {
      this.$refs.changePasswordForUserDialog.open(item);
    },

    handleClientQuestions(item) {
      this.$router.push(`/questions/${item.uniqueId}` );
    },

    handleClientManualApplication(item) {
      this.$router.push({ path: `/manualApplication/${item.uniqueId}` });
    },
  },
};
</script>

<style></style>
