<template>
  <div style="display: flex; justify-content: center">
    <div v-if="page == 1">
      <div v-if="message">
        <div>{{ message }}</div>
        <br />
      </div>
      <center>
        <PricingPlansBoxes :activePricing="currentPricingPlanView" @selected="this.pricingPlanSelected" />
      </center>
    </div>
    <div class="row" v-if="page == 2" style="max-width: 600px">
      <BackButton @click="page = 1" />
      <div class="col-12">
        <center>
          <div>
            <StripeCheckout
              :cost="newPlanCost"
              :plan="newPlanSelected"
              :trial="false"
              @payment-success="this.onPaymentSuccess"
            />
          </div>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
import StripeCheckout from "@/components/subscription_plan/StripeCheckout.vue";
import PricingPlansBoxes from "@/components/subscription_plan/PricingPlansBoxes.vue";
import BackButton from "@/components/BackButton.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
const user = constant.user;
const plans = constant.plans;

export default {
  components: {
    PricingPlansBoxes,
    BackButton,
    StripeCheckout,
  },

  props: {
    message: {
      type: String,
      default: "",
    },
    allPlansActive: {
      type: Boolean,
      default: false,
    },
    preselectedPlan: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      page: 1,
      newPlanSelected: -1,
    };
  },

  created() {
    if (this.preselectedPlan) {
      this.page = 2;
      this.newPlanSelected = this.preselectedPlan;
    }
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),
    ...mapGetters("plans", { getPlans: plans.getters.GetPlans }),

    user() {
      return this.getUser;
    },

    currentPricingPlanView() {
      return this.allPlansActive ? null : this.user.pricingPlan;
    },

    pricingPlansData() {
      if (process.env.VUE_APP_MODE === "b2c") {
        return this.getPlans.plans;
      } else {
        return this.$store.getters.pricingPlansData;
      }
    },

    newPlanCost() {
      const plan = this.pricingPlansData.find(({ index }) => index === this.newPlanSelected);
      return plan?.cost || 0;
    },
  },

  methods: {
    async pricingPlanSelected(pricingPlan) {
      if (pricingPlan === 1 && this.pricingPlansData[0].cost === 0) {
        await this.$store.dispatch(user.actions.UpdateInfo, { pricingPlan: 1 });
        this.$emit("successfulSubmit", pricingPlan);
      } else {
        this.page = 2;
        this.newPlanSelected = pricingPlan;
      }
    },

    onPaymentSuccess() {
      this.$emit("successfulSubmit", this.newPlanSelected);
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
