<template>
  <v-dialog v-model="dialog" width="700">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2"
        >{{ $t("partner.createAccount", { brand: $store.getters.brand }) }}
      </v-card-title>
      <v-card-text>
        <div class="row mb-4 mt-4">
          {{ $t("partner.createAccountDesc") }}
        </div>

        <!-- Form  -->
        <v-form>
          <v-text-field
            outlined
            :label="$t('Generic.Form.first_name')"
            placeholder="John"
            v-model="newClientData.firstName"
            :rules="[rules.required, rules.name]"
            validate-on-blur
          />
          <v-text-field
            outlined
            :label="$t('Generic.Form.last_name')"
            placeholder="Doe"
            v-model="newClientData.lastName"
            :rules="[rules.required, rules.name]"
            validate-on-blur
          />

          <v-text-field
            outlined
            :label="$t('Generic.Form.email')"
            placeholder="Email"
            v-model="newClientData.userEmail"
            :rules="[rules.required, rules.email]"
            validate-on-blur
          />

          <LocationAutocomplete
            @change="newClientData.country = $event"
            :label="$t('Generic.Form.location')"
            :rules="[rules.required, rules.name]"
            validate-on-blur
            :key="locationComponentKey"
          />
        </v-form>

        <!-- Action buttons -->
        <v-divider class="mt-4"></v-divider>
        <div class="row">
          <div class="col-7"></div>
          <div class="col-5 d-flex justify-end">
            <v-btn text @click="this.closeDialog">{{ $t("partner.cancel") }}</v-btn
            ><v-btn text color="primary" @click="this.createClientMethod">{{ $t("partner.createClient") }}</v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { validEnglishString, validNames, validEmail } from "@/assets/js/utils.js";
import LocationAutocomplete from "@/components/LocationAutocomplete.vue";
import { fetchClients } from "@/store/modules/client/actions";
import { clientDataActions } from "@/store/modules/client/data";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  components: { LocationAutocomplete },
  props: {
    show: {},
  },
  data() {
    return {
      dialog: false,
      newClientData: {
        userEmail: null,
        firstName: null,
        lastName: null,
        country: null,
      },
      rules: {
        required: value => !!value || this.$i18n.t("partner.required"),
        // value => (value && value.length >= 3) || 'Min 3 characters',
        max40: value => value.length <= 40 || this.$i18n.t("partner.max40"),
        email: value => {
          return validEmail(value) || this.$i18n.t("partner.invalidEmail");
        },
        name: value => (value && validEnglishString(value)) || this.$i18n.t("partner.englishCharactersOnly"),
      },
      locationComponentKey: 0,
    };
  },
  methods: {
    ...mapActions("client", [clientDataActions.createClient]),
    open() {
      this.dialog = true;
    },

    closeDialog() {
      this.newClientData.userEmail = null;
      this.newClientData.firstName = null;
      this.newClientData.lastName = null;
      this.newClientData.country = null;
      this.locationComponentKey += 1;
      this.dialog = false;
    },

    async createClientMethod() {
      if (
        !this.newClientData.userEmail?.length ||
        !this.newClientData.firstName?.length ||
        !this.newClientData.lastName?.length ||
        !this.newClientData.country?.length ||
        !validNames(this.newClientData.firstName, this.newClientData.lastName)
      ) {
        this.$notify({
          message: this.$i18n.t("Payment.Messages.Error.field_fields"),
          type: "danger",
        });
        return;
      }
      const client = this.newClientData;
      try {
        await this.createClient({ client });
        this.$notify({
          message: this.$t("partner.create_client_successful"),
          type: "success",
        });
        this.closeDialog();
        this.$emit("modal-closed");
        this.$emit("updateData");
      } catch (error) {
        if (error?.responseContent?.error === "email-already-exists") {
          this.$notify({
            message: this.$t("Auth.Error.email_exists"),
            type: "danger",
          });
        } else if (error?.responseContent?.error === "maximum-managed-users-reached") {
          this.$notify({
            message: this.$t("Generic.Messages.Error.maximum_users_limit"),
            type: "danger",
          });
        } else {
          this.$notify({
            message: this.$t("Generic.Messages.Error.common"),
            type: "danger",
          });
        }
      }
    },
  },
};
</script>

<style></style>
