<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-2 mt-n2">{{ $t("loop.slides.email.name") }}</div>
        <v-text-field outlined :disabled="!editable" v-model="template.name" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-2 mt-n2">{{ $t("loop.slides.email.subject") }}</div>
        <v-text-field
          outlined
          :disabled="!editable"
          v-model="template.subject"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-2 mt-n2">{{ $t("loop.slides.email.body") }}</div>
        <div class="editor__wrapper">
          <Editor
            class="editor"
            :value="serializeEmailData(template.body)"
            :key="template.firebaseKey"
            :editable="editable"
            @update="handleEmailBodyChange"
          />
          <v-alert
            v-if="editable"
            dense
            text
            v-html="$t('loop.slides.email.hint')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/editor/Editor.vue";

export default {
  name: "LoopEmailTemplatePresentation",
  // these are the correct terminology: name, subject, body
  props: {
    template: {},
    editable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Editor,
  },
  methods: {
    serializeEmailData(data) {
      return data.replace(
        /({{(.+?)}})/g,
        `<span data-type="mention" class="mention" data-id="$2" contenteditable="false">$1</span>`
      );
    },

    handleEmailBodyChange(data) {
      this.$emit("update", data);
    },
  },
};
</script>
