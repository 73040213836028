<template>
  <div>
    <p>
      {{ $t("examination.CV") }}
    </p>
    <p>
      {{ $t("examination.jobsWeRejectedCV") }}
    </p>

    <p>
      {{ $t("examination.notPerfect") }}
    </p>

    <p>
      {{ $t("examination.toIncreaseMatchesCV") }}
    </p>

    <v-data-table
      :headers="headers"
      :items="jobs"
      :options.sync="options"
      :loading="loading"
      disable-sort
      :items-per-page="10"
      :server-items-length="jobCount"
      :footer-props="{
        disableItemsPerPage: true,
        showFirstLastPage: true,
      }"
    >
      <template v-slot:item.actions="{ item }">
        <!-- @TODO -->
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="handleMatchDataDisplay(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> description </v-icon>
            </v-btn>
          </template>
          <span>Job details</span>
        </v-tooltip> -->

        <DuplicateWithJobTitleButton
          v-if="item.job.length && isUserPartner"
          :item="item"
          :clientId="clientId"
          :loopId="loopId"
        />

        <v-tooltip bottom v-if="item.job.length">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small :href="item.job[0].url" target="_blank" rel="noopener noreferrer" v-bind="attrs" v-on="on">
              <v-icon> open_in_new </v-icon>
            </v-btn></template
          >
          <span>{{ $t("results.original") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import appSDK from "@/client/AppSDK";
import { mapGetters } from "vuex";
import { timestampToDateString } from "@/assets/js/utils.js";
import DuplicateWithJobTitleButton from "./DuplicateWithJobTitleButton.vue";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  components: { DuplicateWithJobTitleButton },
  props: {
    loopId: {},
    reason: {},
    loop: {},
    clientId: String,
    jobCount: Number,
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),

    isUserPartner() {
      return this.getUser?.is_partner;
    },
  },

  data() {
    return {
      headers: [
        {
          text: "Job Title",
          align: "start",
          sortable: false,
          value: "jobTitle",
        },
        { text: "Location", value: "jobLocation", sortable: false },
        {
          text: "Timestamp",
          value: "createdAt",
          sortable: false,
          width: 105,
        },
        {
          text: "View Details",
          value: "actions",
          sortable: false,
          align: "start",
          width: 125,
        },
      ],
      jobs: [],
      loading: true,
      options: { itemsPerPage: 10 },
      skip: 0,
    };
  },
  watch: {
    options: {
      async handler(v) {
        const newSkip = (v.page - 1) * 10;
        if (this.skip !== newSkip) {
          this.skip = newSkip;
          await this.getData();
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      let data = await appSDK["loop.examination"](
        this.loopId,
        {
          reason: this.reason,
          type: "serial_jobs_common_sorted",
          skip: this.skip,
        },
        this.isUserPartner,
        this.clientId,
      );
      data.forEach(item => {
        item.createdAt = timestampToDateString(item.createdAt);
      });
      this.jobs = data;
      this.loading = false;
    },
  },
};
</script>

<style></style>
