<template>
  <v-row>
    <app-header></app-header>
    <div class="col-12">
      <v-row>
        <v-col>
          <h2 class="heading">
            <span>{{ $t("partner.clients") }}</span>
          </h2>
        </v-col>
        <!-- TODO notice that we moved the button -->
      </v-row>
      <ClientTable
        ref="clientsTable"
        :managers="managers"
        @showManagersList="handleShowAssignManagerList"
        @removeManager="handleRemoveManager"
      />
      <ManagersList
        ref="managersList"
        :currentClient="currentClient"
        :managers="managers"
        @assignManager="handleManagerAssign"
      />
    </div>
  </v-row>
</template>

<script>
import appSDK from "@/client/AppSDK";

import ClientTable from "@/components/partners/ClientTable.vue";
import ManagersList from "@/components/partners/ManagersList.vue";
export default {
  components: { ClientTable, ManagersList },
  data() {
    return {
      showCreateUserForm: false,
      loading: false,
      currentClient: {},
      managers: [],
    };
  },
  computed: {
    showManagerAdministration() {
      return this.$store.getters.configs?.showManagerAdministration;
    },
  },
  created() {
    if (this.showManagerAdministration) {
      this.fetchManagers();
    }
  },
  methods: {
    handleShowAssignManagerList(user) {
      this.currentClient = user;
      this.$refs.managersList.show();
    },
    async fetchManagers() {
      this.loading = true;
      try {
        const res = await appSDK["partner.childUser.list"]({
          perPage: -1,
          role: "manager",
          status: "all",
        });
        this.managers = res.users;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    update() {
      this.$refs.clientsTable.getClients();
    },
    handleRemoveManager(user) {
      this.handleManagerAssign({ clientId: user.uniqueId, managerId: "" });
    },
    async handleManagerAssign({ clientId, managerId }) {
      console.log("managerId", managerId);
      console.log("clientId", clientId);
      this.loading = true;
      try {
        await appSDK["partner.childUser.update"](clientId, {
          managedBy: managerId,
        });
        this.$notify({
          message: "Manager assigned successfully",
          type: "success",
        });
        this.update();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.center-vertically {
  height: 56px;
  line-height: 56px;
}
</style>
