<template>
  <div>
    <v-progress-linear
      :value="value"
      :active="show"
      height="25"
      striped
      :rounded="rounded"
    >
      <span class="white--text">{{ $t('matching-in-progress') }}</span>
    </v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "ProgressAnimation",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      value: 0,
      interval: 0,
    };
  },
  mounted() {
    this.queryAndIndeterminate();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    queryAndIndeterminate() {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          clearInterval(this.interval);
          this.$emit("progressEnd");
        }
        this.value = Math.min(100, Math.round(this.value + 100 / 30));
      }, 1000);
    },
  },
};
</script>
