<template>
  <div>
    <v-switch
      color="primary"
      :value="false"
      disabled
      v-if="value === false"
    ></v-switch>
    <v-switch
      color="primary"
      value
      input-value="true"
      disabled
      v-if="value === true"
    ></v-switch>
  </div>
</template>

<script>
export default {
  props: {
    value: {}
  }
};
</script>

<style></style>
