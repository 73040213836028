<template>
  <div class="page">
    <app-header />

    <div v-if="loading"><app-loader /></div>
    <div v-else-if="user != null">
      <div class="user-header">
        <div class="client_actions" v-if="isAdminOrManager && clientId">
          <div class="user-group">
            <div class="icon-rounded" @click="returnToAllClients">
              <ArrowLeftIcon />
            </div>
            <label class="header2-bold user" @click="returnToAllClients">{{ $t("partner.back_to_all_clients") }}</label>
          </div>
        </div>
        <div v-if="totalQuestions > 0" class="questions_sorting">
          <Dropdown
            :options="[$t('Questions.date'), $t('Questions.occurrences')]"
            :value="$t(`Questions.${filters.sortBy}`)"
            @input="handleSort"
            :title="$t('Questions.sort_by')"
          />
          <Input
            :value="filters.search"
            id="search"
            :title="$t('Questions.search')"
            :placeholder="$t('Questions.search_by_title')"
            @input="handleSearch"
            ><SearchIcon />
          </Input>
        </div>
        <div v-if="totalQuestions > 0" class="questions_top_elements">
          <CircleProgressBar :current="answered" :total="totalQuestions" />
        </div>
      </div>

      <!-- Empty List -->
      <div v-if="!hasData && !questionsLoading">
        <Empty :title="$t('Questions.empty_questions')" />
      </div>

      <!--  Questions List -->
      <div v-else class="user-title">
        <!-- Filters -->
        <div class="filter-block">
          <div>
            <div class="answer_questions_filter">
              <SwitchToggle :value="filters.showAnswer" @input="handleShowAnswer">
                <label class="header5-semi-bold">{{ $t("Questions.show_answered_questions") }}</label></SwitchToggle
              >
            </div>
            <div class="match_filter" v-if="params.matchId">
              <SwitchToggle :value="filters.showMatches" @input="handleShowMatchesQuestion">
                <label class="header5-semi-bold">{{ $t("Questions.similar_matches_questions") }}</label></SwitchToggle
              >
            </div>
          </div>

          <div class="questions-per-page-block">
            <label class="header5-semi-bold">{{ $t("Questions.questions_per_page") }} </label>
            <Dropdown
              :options="[3, 10, 20, 30, 50, 100]"
              dbStyle="mini"
              :value="options.items.toString()"
              @input="updateOptions"
            />
          </div>
        </div>

        <!-- Questions -->
        <div class="questions-block" v-if="showQuestions">
          <div v-if="!questionsLoading">
            <div v-for="(question, index) in questions" :key="index">
              <Question
                class="question"
                @updateQuestion="value => updateQuestion(index, value)"
                v-if="question != null"
                :question="question"
              />
            </div>
          </div>
          <div v-else>
            <app-loader />
          </div>
        </div>

        <div class="buttons-group previous-next" v-if="!questionsLoading && answeredMatch">
          <PreviousButton @previous="previousQuestions" v-if="showPreviousButton" />
          <div v-if="options.page === 1"></div>
          <!-- spacer -->

          <div v-if="options.page === totalPages"></div>
          <!-- spacer -->
          <NextButton @next="nextQuestions" v-if="showNextButton" />
        </div>
        <div
          class="buttons-group submit"
          v-if="!showSuccessPage && !showEmptyAnsweredQuestionPage && !questionsLoading && answeredMatch"
        >
          <Button
            :title="filters.showAnswer ? $t('Buttons.update') : $t('submit')"
            :buttonStyle="'save'"
            @click="saveQuestions"
            :loading="submitLoading"
          />
        </div>

        <div class="success-page" v-if="showSuccessPage">
          <Success
            :title="
              params.matchId
                ? $t('Questions.all_answered_is_completed_for_this_match')
                : $t('Questions.all_answered_is_completed')
            "
          >
          </Success>
        </div>
        <div v-if="showEmptyAnsweredQuestionPage">
          <Empty
            :title="
              params.matchId
                ? $t('Questions.empty_answered_questions_for_this_match')
                : $t('Questions.empty_answered_questions')
            "
          />
        </div>
        <div v-if="answeredMatch == 0 && !questionsLoading && filters.search">
          <Empty :title="`${$t('Questions.empty_questions_search')} '${filters.search}'`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { constant } from "@/store/constant";
import Question from "@/components/user/questions/Question.vue";
import {
  Empty,
  Button,
  CircleProgressBar,
  CheckBox,
  Success,
  SwitchToggle,
  NextButton,
  PreviousButton,
  Dropdown,
  Input,
} from "@/framework/index";
import { mapGetters } from "vuex";
import { Role } from "@/helpers/enums/eRole";
import { ArrowLeftIcon, SearchIcon } from "@/assets/fonts/icons/index";
import { MatchIcon } from "@/assets/fonts/icons/index";
import Cookies from "js-cookie";

const userAction = constant.user;

export default {
  name: "Questions",
  components: {
    Empty,
    Question,
    ArrowLeftIcon,
    Button,
    CircleProgressBar,
    CheckBox,
    Success,
    SwitchToggle,
    NextButton,
    PreviousButton,
    MatchIcon,
    Dropdown,
    Input,
    SearchIcon,
  },

  props: {
    clientId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      hasData: false,
      user: null,
      questions: [],
      totalQuestions: 0,
      adminOrManagerUser: false,
      answered: 0,
      answeredMatch: 0,
      options: { page: 1, items: 10 },
      loading: false,
      submitLoading: false,
      filters: {
        showAnswer: false,
        showMatches: false,
        sortBy: "occurrences",
        search: "",
      },
      totalPages: 0,
      questionsLoading: false,
      params: {
        matchId: { type: String },
      },
    };
  },

  computed: {
    ...mapGetters("user", { getUser: userAction.getters.getUser }),

    isAdminOrManager() {
      return this.getUser.role == Role.ADMIN || this.getUser.role == Role.MANAGER || this.getUser.role == Role.SupportAgent;
    },

    isUser() {
      return this.getUser.role == Role.USER || this.getUser.role == Role.MANAGED_USER;
    },

    showQuestions() {
      return this.questions.length > 0 || this.questionsLoading;
    },

    showSuccessPage() {
      if (!this.filters.showAnswer) {
        return this.totalPages < this.options.page && !this.questionsLoading && !this.filters.search;
      }
      return false;
    },

    showEmptyAnsweredQuestionPage() {
      if (this.filters.showAnswer) {
        if (this.answered == 0 && !this.questionsLoading && !this.params.matchId) {
          return true;
        } else if (this.params.matchId && !this.questionsLoading && this.answeredMatch == 0) {
          return true;
        }
      }
      return false;
    },

    showNextButton() {
      return this.options.page < this.totalPages;
    },

    showPreviousButton() {
      return this.options.page > 1;
    },
  },

  async created() {
    localStorage.removeItem("question-client"); // clean unused key
    if (this.isAdminOrManager) {
      if (this.clientId) {
        this.user = {
          id: this.clientId,
        };
      } else {
        console.error("questions page loaded as an admin without clientId");
      }
    } else if (this.isUser) {
      this.user = this.getUser;
    }
  },

  async mounted() {
    const filters_str = Cookies.get("questions_filter");
    if (filters_str) {
      this.filters = JSON.parse(filters_str);
    }
    this.loading = true;
    this.params.matchId = this.$route.query.matchId;
    if (this.params.matchId) {
      this.filters.showMatches = true;
    }

    if (this.user) {
      await this.getQuestions();
    }
    this.hasData = this.totalQuestions > 0;

    this.loading = false;
  },

  methods: {
    async getQuestions() {
      try {
        this.questionsLoading = true;
        const payload = {
          partner_id: this.user.id,
          items: this.options.items,
          page: this.options.page,
          isAnswered: this.filters.showAnswer,
          role: this.getUser.role,
        };
        if (this.filters.sortBy === "date") {
          payload.sort = "updatedAt";
        } else {
          payload.sort = "occurrences";
        }
        if (this.filters.search !== "") {
          payload.search = this.filters.search;
        }
        if (this.params.matchId && this.filters.showMatches) {
          payload.matchId = this.params.matchId;
        }
        const res = await this.$store.dispatch(userAction.actions.GetMyQuestions, payload);
        this.answeredMatch = res.total;
        this.totalQuestions = res.numberOfQuestions;
        this.answered = res.numberOfAnsweredQuestions;
        if (res) {
          this.questions = res.questions;
          for (const [index, question] of this.questions.entries()) {
            question.index = index + 1 + (this.options.page - 1) * this.options.items;
          }

          this.totalPages = parseInt(Math.floor(this.answeredMatch / this.options.items));
          if (this.answeredMatch % this.options.items > 0) {
            this.totalPages++;
          }
        }
        this.clearInputs();
        this.$forceUpdate();
      } catch (e) {
        console.error(e);
      } finally {
        this.questionsLoading = false;
      }
    },

    async saveQuestions() {
      try {
        this.submitLoading = true;
        const payload = {
          partner_id: this.user.id,
          answers: [],
          role: this.getUser.role,
        };
        for (let question of this.questions) {
          if (question.answer && question.question_type != "terms") {
            payload.answers.push({ id: question._id, answer: question.answer });
          } else if (
            question.answer &&
            question.question_type == "terms" &&
            (this.question.answer === true || this.question.answer === false)
          ) {
            payload.answers.push({ id: question._id, answer: question.answer });
          }
        }

        const res = await this.$store.dispatch(userAction.actions.UpdateQuestion, payload);
        this.answered = res.numberOfAnswered;
        // maybe directly go to the next page
        // this.options.page++;
        this.clearInputs();
        await this.getQuestions();

        this.$notify({
          message: this.$t("Questions.update_question"),
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.$notify({
          message: this.$t("Questions.update_question_failed"),
          type: "danger",
        });
      } finally {
        this.submitLoading = false;
      }
    },

    async previousQuestions() {
      this.options.page = this.options.page - 1;
      await this.getQuestions();
    },

    async nextQuestions() {
      this.options.page = this.options.page + 1;
      await this.getQuestions();
    },

    async updateOptions(value) {
      this.options.items = value;
      this.options.page = 1;
      await this.getQuestions();
    },

    returnToAllClients() {
      this.$router.push("/clients");
    },

    updateQuestion(index, value) {
      // reactive update in-place
      this.questions.splice(index, 1, value);
    },

    async handleShowAnswer() {
      this.filters.showAnswer = !this.filters.showAnswer;
      this.options.page = 1;
      await this.getQuestions();
    },

    async handleShowMatchesQuestion() {
      this.filters.showMatches = !this.filters.showMatches;
      this.options.page = 1;
      await this.getQuestions();
    },

    clearInputs() {
      const questionsBlock = document.querySelector(".questions-block");
      if (questionsBlock && questionsBlock.length > 0) {
        const inputList = questionsBlock.querySelectorAll("input[type='text']");
        for (let i = 0; i < inputList.length; i++) {
          if (inputList[i].id !== "search") {
            inputList[i].value = "";
          }
        }
        const textareaList = document.querySelectorAll("textarea");
        for (let i = 0; i < textareaList.length; i++) {
          textareaList[i].value = "";
        }
      }
    },

    async handleSort(sort) {
      this.filters.sortBy = sort.toString().toLowerCase();
      Cookies.set("questions_filter", JSON.stringify(this.filters), { secure: true, sameSite: 'Lax' });
      await this.getQuestions();
    },

    async handleSearch(value) {
      this.filters.search = value;
      Cookies.set("questions_filter", JSON.stringify(this.filters), { secure: true, sameSite: 'Lax' });
      if (value.length > 3 || value.length == 0) {
        this.options.page = 1;
        await this.getQuestions();
      }
    },
  },
};
</script>

<style scoped>
@import "./questions.module.css";
</style>
