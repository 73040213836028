import { render, staticRenderFns } from "./Success.vue?vue&type=template&id=130e587c&scoped=true&"
var script = {}
import style0 from "./Success.vue?vue&type=style&index=0&id=130e587c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130e587c",
  null
  
)

export default component.exports