<template>
  <AuthTemplate>
    <div class="subtitleBlock">
      <label class="header2-semi-bold">{{ $t("Auth.Common.welcome_back") }}</label>
      <label class="subtitle header5">{{ this.$t("Auth.Common.login-subtitle", { brand: $store.getters.brand }) }}</label>
    </div>
    <div v-if="hasGoogleLogin">
      <div class="google-btn">
        <GoogleLoginButton />
      </div>
      <div class="or-lines">
        <hr />
        <span class="header5"> {{ $t("Auth.Common.or") }}</span>
        <hr />
      </div>
    </div>
    <form @submit.prevent="signIn">
      <Input
        class="input-form"
        :title="this.$t('Generic.Form.email')"
        :required="true"
        :autocomplete="'email'"
        :error="this.errors.email"
        :value="this.model.email"
        :placeholder="this.$t('Generic.Form.placeholder_value', { value: this.$t('Generic.Form.email').toLowerCase() })"
        @input="value => handleInput(value, 'email')"
      />
      <div class="password">
        <Input
          class="input-form"
          :title="this.$t('Generic.Form.password')"
          :required="true"
          :autocomplete="'current-password'"
          :error="this.errors.password"
          :type="passwordType"
          :value="this.model.password"
          :placeholder="this.$t('Generic.Form.placeholder_value', { value: this.$t('Generic.Form.password').toLowerCase() })"
          @input="value => handleInput(value, 'password')"
        >
          <EyeIn v-if="this.hidePassword" @click="handleEyePassword" />
          <EyeOut v-if="!this.hidePassword" @click="handleEyePassword" />
        </Input>
        <label class="forgot-password header6" @click="forgotPassword">{{ this.$t("Auth.Common.forgot_password") }}</label>
      </div>
      <div class="login-btn">
        <Button
          :title="this.$t('Auth.Common.sign_in')"
          :type="'submit'"
          :outlined="false"
          :fullWidth="true"
          :loading="loginLoading"
        ></Button>
      </div>
    </form>
    <div class="create-account-block header5-semi-bold" v-if="showSignup">
      <label>
        {{ this.$t("Auth.Common.not_register") }}
        <span class="create-account" @click="register">{{ this.$t("Auth.Common.create_account") }}</span></label
      >
    </div>
  </AuthTemplate>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import { USER_TOKEN_COOKIE } from "@/assets/js/constants";
import { constant } from "@/store/constant";
import GoogleLoginButton from "@/pages/auth/components/GoogleLoginButton.vue";
import AuthTemplate from "@/pages/auth/components/AuthTemplate.vue";
import InformationBanner from "@/components/Banners/InformationBanner.vue";
import { Button, Input, CheckButton } from "@/framework/index";
import AuthPageLogo from "@/components/AuthPageLogo.vue";
import EyeIn from "vue-material-design-icons/Eye.vue";
import EyeOut from "vue-material-design-icons/EyeOff.vue";
import { validateForm, handleInput, validateEmail, validatePassword } from "@/helpers/utils/formUtils";
import * as Sentry from "@sentry/vue";

const user = constant.user;

export default {
  name: "login",
  components: {
    AuthTemplate,
    GoogleLoginButton,
    InformationBanner,
    Button,
    AuthPageLogo,
    Input,
    CheckButton,
    EyeIn,
    EyeOut,
  },
  data() {
    return {
      model: { email: "", password: "" },
      errors: {
        email: "",
        password: "",
      },
      hidePassword: true,
      loginLoading: false,
    };
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),

    passwordType() {
      return this.hidePassword ? "password" : "text";
    },

    isB2B() {
      return this.$store.getters.isB2B;
    },

    showSignup() {
      return this.$store.getters.configs?.userSignupAllowed || !this.isB2B;
    },

    hasGoogleLogin() {
      return process.env.VUE_APP_CONFIGS == "loopcv-b2c";
    },

    supportEmail() {
      return this.$store.getters.configs?.supportEmail;
    },

    imgSrc() {
      const configs = this.$store.getters.configs;

      return `/img/illustrations/${configs.authImage || "login.svg"}`;
    },
  },

  watch: {
    "model.email"(value) {
      validateEmail(this, value);
    },

    "model.password"(value) {
      validatePassword(this, value, { soft: true });
    },
  },

  methods: {
    async signIn() {
      if (validateForm(this)) {
        this.loginLoading = true;
        this.$amplitude.logEvent("ATTEMPT_SIGN_IN_OR_SIGN_UP", {
          method: "email-password",
          type: "sign-in",
        });

        try {
          const payload = {
            email: this.model.email,
            password: this.model.password,
          };

          const encryptedPartnerId = this.$route.query.p;
          if (encryptedPartnerId) {
            Cookies.set("encryptedPartnerId", encryptedPartnerId, { secure: true, sameSite: 'Lax' });
          }
          await this.$store.dispatch(user.actions.SignIn, payload);
          this.$amplitude.logEvent("SIGN_IN", { method: "email-password" });
          await this.$store.dispatch(user.actions.Auth);
          if (this.getUser?.role != null) {
            await this.setUpFreePlan();
            const redirect = this.$route.query.redirect || "/";
            this.$router.push(redirect.toString());
          } else {
            Cookies.remove(USER_TOKEN_COOKIE);
            this.$notify({
              message: this.$t("Generic.Messages.Error.unauthorized", { email: this.supportEmail }),
              type: "danger",
            });
          }
        } catch (e) {
          if (e?.status === 401) {
            this.$notify({
              message: this.$t("Generic.Messages.Error.email_password_error"),
              type: "danger",
            });
          } else {
            Sentry.captureException(e);
            this.$notify({
              message: this.$t("Generic.Messages.Error.login_error"),
              type: "danger",
            });
          }
        }
        this.loginLoading = false;
      } else {
        validateEmail(this, this.model.email);
        validatePassword(this, this.model.password, { soft: true });
      }
    },

    async setUpFreePlan() {
      if (this.getUser?.role === "user" && (!this.getUser?.pricingPlan || this.getUser?.pricingPlan == 0)) {
        const payload = {
          userId: this.user?.id,
          pricingPlan: 1,
        };
        await this.$store.dispatch(user.actions.UpdateInfo, payload);
        await this.$store.dispatch(user.actions.Auth);
      }
    },

    /* Email Field */
    handleInput(value, name) {
      handleInput(this, value, name);
    },

    handleEyePassword() {
      this.hidePassword = !this.hidePassword;
    },

    register() {
      this.$router.push({ path: "/register" });
    },

    forgotPassword() {
      this.$router.push({ path: "/forgotPassword" });
    },
  },
};
</script>

<style scoped>
@import "./login.css";
</style>
