<template>
  <div class="pa-5">
    <!-- <EmailsPerDay /> -->
    <v-skeleton-loader
      v-if="loading"
      type="article, image, list-item-two-line"
    />

    <template v-else>
      <PieChart
        v-if="chartdata && reasonSummary && reasonSummary.length"
        :chartData="chartdata"
        :options="options"
      />

      <i18n path="stats.description" tag="p" class="text-body-1 mt-10">
        <template #totalJobs><span style="color: #2196f3">{{ totalJobs }}</span></template>
        <template #totalMatches><span style="color: #2196f3">{{ totalMatches }}</span></template>
      </i18n>

      <Examination
        :clientId="clientId"
        :loopId="loopId"
        :loop="loop"
        :reasonItems="reasonItems"
      />
    </template>
  </div>
</template>

<script>
import EmailsPerDay from "@/components/ViewLoop/stats/EmailsPerDay.vue";
import PieChart from "@/components/ViewLoop/stats/PieChart.vue";
import Examination from "@/components/Examination.vue";
import { calculateJobs, calculateMatches } from "@/data/reasons.js";

export default {
  components: { PieChart, Examination },
  props: {
    loop: {
      type: Object,
    },
    reasonSummary: {
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    loopId: String,
    clientId: String,
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 50,
        legend: {
          position: "top",
        },
      },
    };
  },
  computed: {
    reasonItems() {
      if (!this.reasonSummary) {
        return [];
      }
      let filtered = this.reasonSummary.filter(
        (item) =>
          item.reason !== "FOUND_MATCH" &&
          item.reason !== "NOT_MATCH_DUE_ELIMINATED_COMPANY"
      );
      filtered.sort((item1, item2) => {
        return item2.count - item1.count;
      });
      return filtered;
    },
    totalJobs() {
      return calculateJobs(this.reasonSummary); // @TODO or compute it from the other endpoint
    },
    totalMatches() {
      return calculateMatches(this.reasonSummary);
    },
    chartdata() {
      if (!this.reasonSummary) return {};
      this.reasonSummary.sort(function (a, b) {
        if (a.reason < b.reason) {
          return -1;
        }
        if (a.reason > b.reason) {
          return 1;
        }
        return 0;
      });
      return {
        datasets: [
          {
            data: this.reasonSummary.map((item) => item.count),
            backgroundColor: [
              "#9CCC65",
              "#EC407A",
              "#29B6F6",
              "#FFCA28",
              "#AB47BC",
              "#EF5350",
              "#26A69A",
            ],
            // backgroundColor: [
            //   "rgba(255, 99, 132, 0.2)",
            //   "rgba(54, 162, 235, 0.2)",
            //   "rgba(255, 206, 86, 0.2)",
            //   "rgba(75, 192, 192, 0.2)",
            //   "rgba(153, 102, 255, 0.2)",
            //   "rgba(255, 159, 64, 0.2)"
            // ],
            borderAlign: "center",
          },
        ],
        labels: this.reasonSummary
          .map((item) => item.reason)
          .map((x) => this.$t(`matchReasons.${x}`)),
      };
    },
  },
};
</script>

<style></style>
