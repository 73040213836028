<template>
  <div>
    <div class="text-subtitle-1 bold">{{ $t("loop.slides.test.title") }}</div>
    <div class="text" v-if="this.isUserPartner">
      Once we find a job with your criteria, we will send an email
      <i>from</i> your client's address <i>to</i> the company. <br /><b>Now</b>, you can get an email like this to your address
      below, for testing purposes.
    </div>
    <div class="text-body-1 pb-4" v-else>
      {{ $t("loop.slides.test.subtitle") }}
    </div>
    <v-row justify="center" align="center" no-gutters>
      <v-text-field
        :disabled="isDisableText"
        outlined
        :class="`mt-3 ${isDisableText ? 'disabled' : ''}`"
        v-model="testEmail"
        type="email"
        name="email"
        :placeholder="$t('loop.slides.test.yourEmailAddress')"
      />
      <v-spacer/>
      <v-btn
        color="#081929"
        style="color: white"
        rounded
        :disabled="editMode"
        :loading="buttonLoading"
        @click="sendTestEmail"
      >
        {{ $t("loop.slides.test.button") }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import appSDK from "@/client/AppSDK";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  props: {
    clientId: {
      type: String,
    },
    subject: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
    loopOwner: {
      type: Object,
      default: null,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    testEmail: "",
    buttonLoading: false,
  }),
  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    cvId() {
      return this.$store.getters.getLoopData.cvId;
    },
    selectedCVname() {
      return this.$store.getters.getLoopData.selectedCVname;
    },
    jobTitle() {
      return this.$store.getters.getLoopData.jobtitle;
    },
    location() {
      return this.$store.getters.getLoopData.location;
    },

    isDisableText() {
      if (this.$store.getters.isB2C) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    loopOwner: {
      immediate: true,
      handler(data) {
        this.testEmail = data.email;
      },
    },
  },
  methods: {
    async sendTestEmail() {
      if (this.templateZeroBasedId === null) {
        this.$notify({
          message: "Template not selected",
          type: "danger",
        });
        return;
      }

      if (this.testEmail.length < 3) {
        this.$notify({
          message: "Please provide an email address",
          type: "danger",
        });
        return;
      }

      try {
        this.$amplitude.logEvent("SEND_TEST_EMAIL", {
          testEmailRecipient: this.testEmail,
        });
        this.buttonLoading = true;

        const postdata = {
          userId: this.loopOwner.userId,
          userEmail: this.loopOwner.platformEmail,
          fileName: this.selectedCVname, // undefined when hasCV == false
          firstName: this.loopOwner.firstName,
          lastName: this.loopOwner.lastName,
          testEmail: this.testEmail,
          emailSubject: this.subject,
          emailText: this.body,
          jobTitle: this.jobTitle,
          jobLocation: this.location,
          cvId: this.cvId,
        };

        appSDK["loop.sendTestEmail"](postdata)
          .then(response => {
            this.$notify({
              message: this.$t("loop.slides.test.success"),
              type: "success",
            });
            this.buttonLoading = false;
          })
          .catch(error => {
            if (error.statusCode == 429) {
              this.$notify({
                message: error.responseContent,
                type: "danger",
              });
            } else {
              this.$notify({
                message: this.$t("loop.slides.test.failure"),
                type: "danger",
              });
            }
            this.buttonLoading = false;
            console.error(error);
          })
          .finally(() => {
            this.buttonLoading = false;
          });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.disabled,
.disabled ::v-deep * {
  cursor: not-allowed;
}
</style>
