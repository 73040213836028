import router from "../router";
import i18n from "../../core/i18n";
import * as Sentry from "@sentry/vue";
import {I18N_LOCAL_STORAGE_NAME} from "@/assets/js/constants";

router.beforeEach((to, from, next) => {
  const locale = localStorage.getItem(I18N_LOCAL_STORAGE_NAME);

  if (i18n.availableLocales.includes(locale)) {
    i18n.locale = locale;

    if (process.env.VUE_APP_SENTRY === "enable") {
      try {
        Sentry.setTag("i18n.locale", locale);
      } catch (e) {
        console.warn(e);
      }
    }
  }
  next();
});
