<template>
  <div v-if="this.getUserLoaded">
    <div class="row">
      <div class="col-12">
        <form>
          <div class="row">
            <div class="col-md-12">
              <Input
                :title="$t('email')"
                :value="model.email"
                :error="errors.email"
                :error-messages="errors.email"
                autocomplete="email"
                @input="handleEmail"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <v-text-field
                :label="$t('Generic.Form.first_name')"
                :value="model.firstName"
                :placeholder="$t('Generic.Form.placeholder_value', { value: $t('Generic.Form.first_name') })"
                autocomplete="given-name"
                outlined
                :error="!!errors.firstName"
                :error-messages="errors.firstName"
                @input="$emit('update', ['firstName', $event])"
                @change="validate('firstName')"
              />
            </div>
            <div class="col-md-6">
              <v-text-field
                :label="$t('Generic.Form.last_name')"
                :value="model.lastName"
                :placeholder="$t('Generic.Form.placeholder_value', { value: $t('Generic.Form.last_name') })"
                autocomplete="family-name"
                outlined
                :error="!!errors.lastName"
                :error-messages="errors.lastName"
                @input="$emit('update', ['lastName', $event])"
                @change="validate('lastName')"
              />
            </div>
          </div>
          <div class="row" v-if="!this.isUserPartner">
            <div class="col-md-12">
              <v-text-field
                :label="$t('location')"
                :value="model.country"
                :placeholder="$t('location')"
                outlined
                name="address"
                optional
                autocomplete="street-address"
                :error="!!errors.country"
                :error-messages="errors.country"
                @input="$emit('update', ['country', $event])"
                @change="validate('country')"
              />
            </div>
          </div>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" height="42" :loading="spinning" @click="this.updateData">
              {{ $t("save") }}
            </v-btn>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <ChangePassword />
    </div>
    <div v-if="!this.isUserPartner" class="row">
      <div class="col-12">
        <div class="text-h3 ma-4">
          {{ $t("cvs") }}
        </div>
        <ListCVsPresentation
          :cvs="this.cvList"
          :canDelete="!this.loadingDeleteCv"
          :can-set-default="!this.loadingSetDefaultCV"
          @delete-cv="deleteCV"
          @set-default-cv="$refs.setToAllLoopsDialog.open($event)"
        />
        <v-btn class="ml-3" text @click="$refs.fileInput.click()" :disabled="uploading" :loading="uploading">{{
          $t("SelectCV.button")
        }}</v-btn>
        <CVInputElement ref="fileInput" @regular-upload="handleRegularUpload" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-5">
        <div class="text-h3 ma-4">
          {{ this.$t("Profile.account_manager") }}
          <!-- <v-chip outlined label color="red">DANGER!</v-chip> -->
        </div>
        <v-card
          v-if="!this.isUserPartner"
          outlined
          class="pa-3 ma-1 mb-4"
          @click="showDeleteAccountDialog = true"
          :disabled="subscriptionIsActive"
        >
          <div class="text-h4" style="font-weight: 500">{{ this.$t("Profile.delete_account") }}</div>
          <i v-if="subscriptionIsActive"> {{ this.$t("Profile.error_active_subscription") }} </i>
          <div v-if="!subscriptionIsActive">{{ this.$t("Profile.delete_permanently") }}</div>
        </v-card>
        <div v-if="!this.isUserPartner">
          {{ this.$t("Profile.manage_loops") }}
          <router-link to="/loops">{{ this.$t("page.loopsList") }}</router-link
          >.
        </div>
        <div class="mt-3">
          {{ $t("profile.supportRequests") }}
          <i><base-mailto :email="supportEmail" /></i>.
        </div>
      </div>
    </div>
    <v-dialog v-model="showDeleteAccountDialog" max-width="700">
      <v-card>
        <v-card-title class="headline">
          {{ $t("profile.delete.title", { brand: $store.getters.brand }) }}
        </v-card-title>
        <v-card-text>
          <i18n path="profile.delete.sure" tag="div">
            <b>{{ email }}</b>
          </i18n>
          <v-text-field v-model="emailValidation" :label="$t('profile.delete.email')"></v-text-field
        ></v-card-text>
        <v-card-actions class="end">
          <v-btn
            class="ma-2"
            @click="
              showDeleteAccountDialog = false;
              emailValidation = null;
            "
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn class="ma-2" @click="this.deleteAccount">
            {{ $t("profile.delete.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SetCVToAllLoopsDialog ref="setToAllLoopsDialog" @confirm-set-default="this.setDefaultCV" />
  </div>
</template>

<script>
import * as yup from "yup";
import ChangePassword from "@/pages/user/Profile/ChangePassword.vue";
import PricingPlans from "@/components/subscription_plan/PricingPlans.vue";
import ListCVsPresentation from "@/components/ListCVsPresentation.vue";
import { CV_PDF_SIZE, ENGLISH_REGEX } from "@/assets/js/constants";
import { cvExtensionValid } from "@/assets/js/utils";
import CVInputElement from "@/components/cvs/CVInputElement.vue";
import SetCVToAllLoopsDialog from "@/components/cvs/SetCVToAllLoopsDialog.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import { validateEmail } from "@/helpers/utils/formUtils";
import { Input } from "@/framework";
import {bytesToMB} from "@/helpers/utils/numberUtils";

const user = constant.user;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(ENGLISH_REGEX, "Please use English characters only in first name"),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(ENGLISH_REGEX, "Please use English characters only in last name"),
  country: yup.string().matches(ENGLISH_REGEX, "Please use English characters only in location").nullable(),
});

export default {
  components: {
    ChangePassword,
    PricingPlans,
    ListCVsPresentation,
    CVInputElement,
    SetCVToAllLoopsDialog,
    Input,
  },

  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      spinning: false,
      showDeleteAccountDialog: false,
      emailValidation: null,
      errors: {},
      cvs: [],
      uploading: false,
      loadingDeleteCv: false,
      loadingSetDefaultCV: false,
    };
  },

  computed: {
    ...mapGetters("user", {
      getUser: user.getters.getUser,
      userLoaded: user.getters.getLoading,
      isUserPartner: user.getters.isUserPartner,
      getStatus: user.getters.getStatus,
      getUserLoaded: user.getters.userLoaded,
      getCvs: user.getters.getCvs,
      getUploadedCV: user.getters.getUploadedCV,
    }),

    status() {
      return this.getStatus;
    },

    connectedUser() {
      return this.getUser;
    },

    cvList() {
      return this.getCvs;
    },

    supportEmail() {
      return this.$store.getters.configs.supportEmail;
    },

    brand() {
      return this.$store.getters.brand;
    },

    email() {
      return this.getUser?.email;
    },

    currentPricingPlan() {
      return this.getUser?.pricingPlan;
    },

    pricingPlansData() {
      return this.$store.getters.pricingPlansData;
    },

    subscriptionIsActive() {
      // TODO should be 1, 2, 3
      if (!this.currentPricingPlan) {
        return false;
      }
      if (!this.$store.getters.configs?.showPricingPlans) {
        return false;
      }

      const activePlansData = this.pricingPlansData.find(({ index }) => index === this.currentPricingPlan);
      if (!activePlansData) {
        return false;
      }
      return activePlansData.cost !== 0;
    },
  },

  created() {
    this.listCVs();
  },

  methods: {
    validate(field) {
      schema
        .validateAt(field, this.model)
        .then(() => this.$set(this.errors, field, ""))
        .catch(err => this.$set(this.errors, field, err.message));
    },

    async deleteAccount() {
      try {
        if (this.emailValidation === this.email) {
          if (this.getUser.pricingPlan == 1) {
            const res = await this.$store.dispatch(user.actions.DeleteUser);
            if (res === "OK") {
              this.$notify({
                message: this.$t("Generic.Messages.Success.delete_account"),
                type: "success",
              });
              this.showDeleteAccountDialog = false;
              await this.$store.dispatch(user.actions.SignOut);
              this.$router.push("/login");
            }
          } else {
            this.showDeleteAccountDialog = false;
            this.$notify({
              message: this.$t("Auth.Error.delete_account_has_plan"),
              type: "danger",
            });
          }
        } else {
          this.$notify({
            message: this.$t("Auth.Error.wrong_email_text", { email: this.emailValidation }),
            type: "danger",
          });
        }
      } catch (e) {
        // @ts-ignore
        this.$notify({
          message: e.message || this.$t("Generic.Messages.Error.common_error", { email: this.supportEmail }),
          type: "danger",
        });
      }
    },

    async updateData() {
      try {
        await schema.validate(this.model);
      } catch (errs) {
        errs.inner.forEach(error => {
          this.errors = { ...this.errors, [error.path]: error.message };
        });
        this.$notify({
          message: errs.toString(),
          type: "danger",
        });
        return;
      }
      this.spinning = true;
      const data = {
        country: this.model.country || this.connectedUser.country,
        firstName: this.model.firstName || this.user.firstName,
        lastName: this.model.lastName || this.user.lastName,
        email: this.model.email || this.user.email,
      };
      try {
        await this.$store.dispatch(user.actions.UpdateInfo, data);
        this.$notify({
          message: this.$t("Generic.Messages.Success.update_profile"),
          type: "success",
        });
      } catch (e) {
        this.$notify({
          message: e?.data?.error === "Email already exists" 
            ? this.$t("Auth.Error.email_exists")
            : this.$t("errors.unexpected"),
          type: "danger",
        });
      }

      this.spinning = false;
    },

    async deleteCV(cvId) {
      this.loadingDeleteCv = true;
      const payload = {
        userId: null,
        cvId,
      };
      try {
        const res = await this.$store.dispatch(user.actions.DeleteCV, payload);
        console.log(res);
        this.$notify({
          message: this.$t("Profile.success_delete_cv"),
          type: "success",
        });
      } catch (error) {
        let message = this.$t("Profile.failure_set_default_cv", { email: this.supportEmail });

        if (error.responseContent.error === 'cv-connected-to-active-loop') {
          message = this.$t("errors.cannotDeleteConnectedCV");
        }

        this.$notify({
          message: message,
          type: "danger",
        });
      }
      await this.listCVs();
      this.loadingDeleteCv = false;
    },

    async setDefaultCV(cvId) {
      this.loadingSetDefaultCV = true;
      try {
        await this.$store.dispatch(user.actions.SetDefaultCv, cvId);
        this.$notify({
          message: this.$t("Profile.success_set_default_cv"),
          type: "success",
        });
      } catch (error) {
        this.$notify({
          message: this.$t("Profile.failure_set_default_cv", { email: this.supportEmail }),
          type: "danger",
        });
      } finally {
        this.loadingSetDefaultCV = false;
      }
    },

    async listCVs() {
      try {
        await this.$store.dispatch(user.actions.GetCVs);
      } catch (error) {
        this.$notify({
          message: this.$t("Profile.failure_to_get_cv", { email: this.supportEmail }),
          type: "danger",
        });
      }
    },

    handleRegularUpload(evt) {
      this.fileUpload(evt.target.files[0]);
    },

    async fileUpload(file) {
      if (!file) {
        return;
      }

      if (!cvExtensionValid(file.name)) {
        this.$notify({
          message: this.$t("errors.shouldBePdfOrWord"),
          type: "danger",
        });
        return;
      }

      if (this.cvs && this.cvs.length > 0 && this.cvs.some(cv => cv.fileName === file.name)) {
        // @ts-ignore
        this.$notify({
          message: this.$t("errors.sameFileName"),
          type: "danger",
        });
        // @ts-ignore
        this.$refs.fileInput.reset();
        return;
      }

      // @ts-ignore
      this.$amplitude.logEvent("UPLOAD_CV", { from: "profile" });

      if (file.size > CV_PDF_SIZE) {
        this.$notify({
          message: this.$t("Generic.Messages.Error.file_size", { size: bytesToMB(file.size) }),
          type: "danger",
        });
      } else {
        try {
          this.uploading = true;
          const payload = {
            file: file,
            clientId: null,
          };
          const cv = await this.$store.dispatch(user.actions.UploadCv, payload);
          this.$refs.setToAllLoopsDialog.open(cv.cvId);
        } catch (error) {
          this.$notify({
            message: error.message || this.$t("errors.unexpected"),
            type: "danger",
          });
        }
      }
      // @ts-ignore
      this.$refs.fileInput.reset();
      // @ts-ignore
      this.listCVs();
      this.uploading = false;
    },

    handleEmail(value) {
      validateEmail(this, value);
      this.$emit("update", ["email", value]);
    },
  },
};
</script>

<style></style>
