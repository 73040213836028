<template>
  <div class="component">
    <div class="table">
      <div class="table-header" v-if="headerData">
        <div
          class="table-header-cell"
          v-for="(header, index) in headerData"
          :key="index"
          :style="showHeader({ header, index }) ? 'display:none ' : ''"
          @click="() => sortable(header, index)"
        >
          <label
            :class="header.hasOwnProperty('sortable') ? 'header6-semi-bold pointer' : 'header6-semi-bold'"
            @mouseover="handleMouseOver(index)"
            @mouseleave="handleMouseLeave(index)"
            >{{ header.value }}
            <ArrowUpTableIcon v-if="arrowUp(index)" />
            <ArrowDownTableIcon v-if="arrowDown(index)" />
          </label>
        </div>
      </div>
      <div class="table-data" :style="selectedData ? 'cursor:pointer' : ''">
        <div class="table-row" v-for="(row, index) in tableData" :key="index" @click="() => handleSelectValue(row)">
          <div
            class="table-cell"
            v-for="(cell, index) in row"
            :key="index"
            :style="!showElement({ row, cell }) ? 'display:none' : ''"
          >
            <span v-if="showElement({ row, cell })"> {{ cell }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowUpTableIcon, ArrowDownTableIcon, ArrowUpDownTableIcon } from "@/assets/fonts/icons/index.js";
import SelectedIcon from "@/assets/fonts/Selected.vue";
export default {
  components: {
    ArrowUpTableIcon,
    ArrowDownTableIcon,
    ArrowUpDownTableIcon,
    SelectedIcon,
  },
  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
      validator: value => {
        if (Array.isArray(value)) {
          return value.every(item => typeof item === "object");
        } else {
          return false;
        }
      },
    },
    data: {
      type: Array,
      default: () => [],
      validator: value => {
        if (Array.isArray(value)) {
          return value.every(item => typeof item === "object");
        } else {
          return false;
        }
      },
    },
    selectedData: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      tableData: [],
      headerData: [],
    };
  },

  computed: {
    arrowUp() {
      return index =>
        this.headerData[index].sortable &&
        this.headerData[index].hasOwnProperty("sortBy") &&
        this.headerData[index].sortBy == "async";
    },

    arrowDown() {
      return index =>
        this.headerData[index].sortable &&
        this.headerData[index].hasOwnProperty("sortBy") &&
        this.headerData[index].sortBy == "desc";
    },

    showElement() {
      return function ({ row, cell }) {
        for (let key in row) {
          if (row[key] === cell) {
            const object = this.headerData.find(function (element) {
              return element.name == key;
            });

            if (object && object.hasOwnProperty("visible")) {
              if (object.visible == false) {
                return false;
              }
            }
            return true;
          }
        }
      };
    },

    showHeader() {
      return function ({ header, index }) {
        if (header.hasOwnProperty("visible") && header.visible == false) {
          return true;
        } else {
          return false;
        }
      };
    },
  },

  mounted() {
    this.headerData = this.headers;

    for (let data of this.data) {
      let row = {};
      for (let header of this.headers) {
        row[header.name] = data[header.name];
      }
      this.tableData.push(row);
    }
  },

  watch: {
    data(value) {
      this.tableData = value;
    },
  },

  methods: {
    sortable(item, index) {
      if (item.hasOwnProperty("sortable")) {
        if (!item.hasOwnProperty("sortBy")) {
          this.headerData[index].sortBy = "async";
          this.removeSortBy(index);
          this.sortTableData(this.headerData[index].name, "async");
        } else {
          if (this.headerData[index].sortBy == "async") {
            this.headerData[index].sortBy = "desc";
            this.removeSortBy(index);
            this.sortTableData(this.headerData[index].name, "desc");
          } else {
            this.headerData[index].sortBy = "async";
            this.removeSortBy(index);
            this.sortTableData(this.headerData[index].name, "async");
          }
        }
        this.sortTableData();
        this.$forceUpdate();
      }
    },

    removeSortBy(index) {
      for (let i = 0; i < this.headerData.length; i++) {
        if (i !== index) {
          delete this.headerData[i].sortBy;
        }
      }
    },

    sortTableData(sortingItem, sortBy) {
      const n = this.tableData.length;
      let swapped;
      do {
        swapped = false;
        for (let i = 0; i < this.tableData.length - 1; i++) {
          if (sortBy == "async") {
            if (this.tableData[i][sortingItem] > this.tableData[i + 1][sortingItem]) {
              const temp = this.tableData[i];
              this.tableData[i] = this.tableData[i + 1];
              this.tableData[i + 1] = temp;
              swapped = true;
            }
          } else {
            if (this.tableData[i][sortingItem] < this.tableData[i + 1][sortingItem]) {
              const temp = this.tableData[i];
              this.tableData[i] = this.tableData[i + 1];
              this.tableData[i + 1] = temp;
              swapped = true;
            }
          }
        }
      } while (swapped);
    },

    handleMouseOver(item) {
      this.headerData[item].sortBy = "desc";
      this.$forceUpdate();
    },

    handleMouseLeave(item) {
      delete this.headerData[item].sortBy;
      this.$forceUpdate();
    },

    handleSelectValue(value) {
      if (this.selectedData) {
        this.$emit("selected", value);
      }
    },
  },
};
</script>

<style scoped>
.component {
  overflow-y: auto;
}

.pointer {
  cursor: pointer;
}
.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}

.table-header {
  display: table-header-group;
  border-bottom: 1px solid #acacaf;
  background-color: #e5e5ea;
}

.table-header-cell {
  display: table-cell;
  padding: 10px;
  color: var(--black);
}

.table-header-cell label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-header-cell label svg {
  margin-left: 2px;
}

.table-data {
  display: table-row-group;
  max-height: 50%;
}

.table-row {
  display: table-row;
  border-bottom: 1px solid #e5e5ea;
}

.table-row:hover {
  background-color: #f8f8fa;
  box-shadow: 0px 0px 3px 0px grey;
}

.table-cell {
  display: table-cell;
  padding: 10px;
  color: var(--black);
}
</style>
