<template>
  <div class="page">
    <app-header />
    <div v-if="loading == true"><app-loader /></div>

    <div v-if="!loading">
      <!-- Empty Inbox -->
      <div class="form-empty">
        <div class="card" v-if="total == 0">
          <Empty :title="this.$t('inbox.noEmails')" />
        </div>
      </div>

      <!-- Email List -->
      <div class="card" v-if="total > 0">
        <div class="list" ref="list">
          <div class="data" v-for="(item, index) in this.emails" :key="index" @click="showEmail(item)">
            <div class="top-info">
              <label class="header5-bold">{{ item.sender }}</label> <label class="header5"> {{ item.receiveDate }}</label>
            </div>
            <div class="subject">
              <label class="header5-semi-bold">{{ item.subject }}</label>
            </div>
            <div class="body">
              <pre class="header6" v-html="renderedBody(item.body)"></pre>
            </div>
            <div class="bottom-info">
              <Chip class="label" :color="item.category_color" :title="item.label_show" v-if="item.label != 'None'" />
              <Chip
                class="company"
                :color="'purple'"
                :title="item.company"
                v-if="!item.company && item.category_color && item.company != ''"
              />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        class="footer"
        :selected="page"
        :total="total"
        :itemsPerPage="perPage"
        :itemsPerPageOption="[10, 20]"
        @page-change="handlePagination"
        @page-option-change="handlePageOption"
      />
      <EmailDialog v-if="showEmailDialog" :email="selectedEmail" @close-modal="closeEmailDialog" />
    </div>
  </div>
</template>
<script>
import Chip from "@/framework/Components/Chip.vue";
import { constant } from "@/store/constant";
import { eCategoryEmail } from "@/helpers/enums/eCategoryEmail";
import Pagination from "@/framework/Components/Pagination.vue";
import EmailDialog from "@/components/dashboard/inbox/Email.vue";
import { formatDateToLocalMMDDYY } from "@/helpers/dateUtils";
import Empty from "@/framework/Form/Empty.vue";
import { formatEmailBody } from "@/helpers/utils/stringUtils";

const user = constant.user;

export default {
  components: {
    Chip,
    Pagination,
    EmailDialog,
    Empty,
  },

  watch: {},

  data() {
    return {
      emails: [],
      total: 0,
      page: 1,
      perPage: 10,
      showEmailDialog: false,
      selectedEmail: {},
      loading: false,
    };
  },

  async mounted() {
    await this.getEmails();
  },

  methods: {
    renderedBody(item) {
      const body = formatEmailBody(item).slice(0, 400);
      const lines = body.split("<br/>");
      const truncated = lines.slice(0, 2).join("<br/>");
      return truncated + "...";
    },
    async getEmails() {
      this.loading = true;
      try {
        const data = await this.$store.dispatch(user.actions.GetMyEmails, { page: this.page, perPage: this.perPage });
        this.emails = data.emails;
        this.total = data.total;
        this.fixEmailObject();
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    fixEmailObject() {
      for (let email of this.emails) {
        email.receiveDate = formatDateToLocalMMDDYY(email.receivedAt);
        if (email.label == eCategoryEmail.InitialConsideration) {
          email.category_color = "green";
          email.label_show = this.$t("inbox.status.initial_consideration");
        } else if (email.label == eCategoryEmail.CareersPage) {
          email.category_color = "orange";
          email.label_show = this.$t("inbox.status.careers_page");
        } else if (email.label == eCategoryEmail.Irrelevant) {
          email.category_color = "yellow";
          email.label_show = this.$t("inbox.status.irrelevant");
        } else if (email.label == eCategoryEmail.WrongAddress) {
          email.category_color = "red";
          email.label_show = this.$t("inbox.status.wrong_address");
        }
      }
    },

    showEmail(value) {
      this.selectedEmail = value;
      this.showEmailDialog = true;
    },

    async handlePagination(value) {
      this.page = value;
      await this.getEmails();
      this.$refs.list.scrollTo(0, 0);
    },

    async handlePageOption(value) {
      this.perPage = value;
      await this.getEmails();
      this.$refs.list.scrollTo(0, 0);
    },

    closeEmailDialog() {
      this.showEmailDialog = false;
    },
  },
};
</script>

<style scoped>
.list {
  max-height: 70vh;
  overflow-x: auto;
}

.data {
  padding-inline: 5px;
  padding-block: 10px;
  border-bottom: 1px solid #acacaf;
  cursor: pointer;
}

.data * {
  cursor: pointer !important;
}

.data:hover {
  background-color: #f8f8fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* #region email template */
.top-info {
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
}

.subject {
  margin-left: 5px;
}

.body {
  margin-left: 5px;
}

.body pre {
  all: initial;
  word-wrap: break-word;
  color: #989696 !important;
}

.bottom-info {
  display: flex;
  margin-top: 10px;
}

.company {
  margin-right: 5px;
}
/* #endregion */
</style>
