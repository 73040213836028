<template>
  <div class="main-panel w-100" id="app" v-cloak>
    <v-app-bar app flat class="clean-header">
      <img :src="logo" alt="LoopCV logo" class="header-logo" />

      <v-spacer></v-spacer>
      <slot></slot>

      <v-menu close-on-click close-on-content-click offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text tile v-bind="attrs" v-on="on">
            <app-icon size="medium" name="xdProfileAppBar" />
            <span class="pl-2">{{ user?.firstName }} {{ user?.lastName }}</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link @click="logout">
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <div class="outer-page d-flex justify-center">
      <div class="clean-main">
        <div class="setupHeader pb-5 pt-10 px-3">Pricing Plans</div>
        <NewPayment @successfulSubmit="pricingPlanSubmitted" :allPlansActive="true" :preselectedPlan="preselectedPlan" />
      </div>
    </div>
  </div>
</template>

<script>
import NewPayment from "@/components/subscription_plan/NewPayment.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  components: {
    NewPayment,
    LocaleSwitcher,
  },

  watch: {
    status(value) {
      switch (value) {
        case user.status.FailureSignOut:
          this.$notify({
            message: this.$t("errors.unexpected"),
            type: "danger",
          });
          break;

        case user.status.SuccessAuth:
          this.$router.replace("/loops/create");
          break;
      }
    },
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, getStatus: user.getters.getStatus }),

    user() {
      return this.getUser;
    },

    logo() {
      return this.$store.getters.logoUrl;
    },

    preselectedPlan() {
      // @ts-ignore
      return parseInt(this.$route.query?.plan, 10); // may be NaN
    },

    status() {
      return this.getStatus;
    },
  },

  methods: {
    pricingPlanSubmitted() {
      this.$store.dispatch(user.actions.Auth);
    },

    async logout() {
      await this.$store.dispatch(user.actions.SignOut);
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.header-logo {
  height: 40px;
}
.clean-header {
  background-color: var(--white);
}
.setupInfo {
  font-size: 20px;
  margin-top: 1em;
}
.setupHeader {
  text-align: left;
  font: normal normal 800 18px/24px Manrope;
  letter-spacing: 0.27px;
  color: #35383c;
}
.outer-page {
  background-color: #f8f8fa;
  height: 100%;
}
.clean-main {
  max-width: 1000px;
}
.main-panel {
  height: 100%;
}
</style>
