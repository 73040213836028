import { state } from "./state";

export function getUser() {
  return state.user;
}

export function userLoaded() {
  return !!state.user?.email;
}

export function loggedInUserFullName() {
  if (!state.user) {
    return "";
  }
  const { firstName, lastName } = state.user;
  if (!firstName || !lastName) {
    return "";
  }
  return `${firstName} ${lastName}`;
}

export function getLoading() {
  return state.loading;
}

export function getStatus() {
  return state.status;
}

export function isUserPartner() {
  return state.user?.is_partner || false;
}

export function getCvs() {
  return state.cvs;
}

export function getUploadedCV() {
  return state.uploadedCV;
}
