<template>
  <div class="page card">
    <app-header />

    <div v-if="loading == true"><app-loader /></div>
    <form v-else @submit.prevent="saveApplication" class="form_body">
      <div>
        <Input
          :value="jobTitle"
          :title="this.$t('ManualApplication.job_title')"
          :placeholder="this.$t('ManualApplication.job_title_placeholder')"
          @input="e => (jobTitle = e)"
          :error="errorJobTitle"
          :required="true"
        />
      </div>
      <div>
        <Input
          :required="true"
          :value="company"
          :title="this.$t('ManualApplication.company_name')"
          :placeholder="this.$t('ManualApplication.company_name_placeholder')"
          @input="e => (company = e)"
          :error="errorCompany"
        />
      </div>
      <div>
        <Input
          :required="true"
          :value="url"
          :title="this.$t('ManualApplication.url')"
          :placeholder="this.$t('ManualApplication.url_placeholder')"
          @input="e => (url = e)"
          :error="errorUrl"
        />
      </div>
      <div class="job_title">
        <Input
          :required="true"
          :value="location"
          :title="this.$t('ManualApplication.location')"
          :placeholder="this.$t('ManualApplication.location_placeholder')"
          @input="e => (location = e)"
          :error="errorLocation"
        />
      </div>
      <div class="manualApplication_double_row">
        <DateTime @input="e => (date = e)" :value="date" :width="'25rem'" />
        <SwitchToggle
          :value="interviewed"
          :title="this.$t('ManualApplication.interviewed')"
          @input="handleInterviewed"
        ></SwitchToggle>
      </div>
      <div class="manualApplication_button">
        <Button :title="this.$t('ManualApplication.clear_all')" @click="clear" :buttonStyle="'clear'" />
        <Button :title="this.$t('ManualApplication.save_application')" :type="'submit'" :buttonStyle="'save'" />
      </div>
    </form>
  </div>
</template>

<script>
import { constant } from "@/store/constant";
import { Button, CheckBox, DateTime, Input, SwitchToggle } from "@/framework/index";
import { formattedDateYYYYMMDD } from "@/helpers/dateUtils";
import { validateUrl } from "@/helpers/utils/formUtils";

const clientAction = constant.client;

export default {
  name: "Manual_Application",
  components: {
    Input,
    CheckBox,
    Button,
    DateTime,
    SwitchToggle,
  },

  props: {
    clientId: {
      type: String,
      required: false,
    },
  },

  mounted() {
    this.clientId = this.$router.currentRoute.params.clientId;
    const date = new Date();
    this.date = formattedDateYYYYMMDD(new Date());
  },

  data() {
    return {
      searchJobTitle: null,
      loading: false,
      jobTitle: "",
      company: "",
      location: "",
      url: "",
      date: formattedDateYYYYMMDD(new Date()),
      interviewed: false,
      errorCompany: "",
      errorUrl: "",
      errorJobTitle: "",
      errorLocation: "",
      canSubmit: true,
    };
  },

  watch: {
    jobTitle(value) {
      if (value) {
        this.errorJobTitle = "";
        this.canSubmit = true;
      } else {
        this.canSubmit = false;
        this.errorJobTitle = this.$t("ManualApplication.job_title_mandatory");
      }
    },

    location(value) {
      if (value) {
        this.canSubmit = true;
        this.errorLocation = "";
      } else {
        this.canSubmit = false;
        this.errorLocation = this.$t("ManualApplication.location_mandatory");
      }
    },

    company(value) {
      if (value) {
        this.canSubmit = true;
        this.errorCompany = "";
      } else {
        this.canSubmit = false;
        this.errorCompany = this.$t("ManualApplication.company_name_mandatory");
      }
    },

    url(value) {
      if (value && value != "") {
        this.errorUrl = "";
        this.canSubmit = true;
      } else if (!this.value || this.value == "") {
        this.canSubmit = false;
        this.errorUrl = this.$t("ManualApplication.url_mandatory");
      } else {
        this.errorUrl = validateUrl(this, this.url);
        if (this.errorUrl) {
          this.canSubmit = false;
        }
      }
    },
  },
  methods: {
    async saveApplication() {
      try {
        this.loading = true;
        if (!this.location) {
          this.canSubmit = false;
          this.errorLocation = this.$t("ManualApplication.location_mandatory");
        }
        if (!this.company || this.company == "") {
          this.canSubmit = false;
          this.errorCompany = this.$t("ManualApplication.company_name_mandatory");
        }
        if (!this.url || this.url == "") {
          this.canSubmit = false;
          this.errorUrl = this.$t("ManualApplication.url_mandatory");
        } else {
          this.errorUrl = validateUrl(this, this.url);
          if (this.errorUrl) {
            this.canSubmit = false;
          }
        }
        if (!this.jobTitle || this.jobTitle == "") {
          this.canSubmit = false;
          this.errorJobTitle = this.$t("ManualApplication.job_title_mandatory");
        }
        if (this.canSubmit) {
          const payload = {
            title: this.jobTitle,
            company: this.company,
            location: this.location,
            url: this.url,
            userId: this.clientId,
            date: this.date,
            interviewed: this.interviewed,
          };

          const res = await this.$store.dispatch(clientAction.actions.ManualApplication, payload);
          this.$notify({
            message: this.$t("ManualApplication.application_saved"),
            type: "success",
          });
          this.clear();
          console.log(res);
        }
      } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.jobTitle = "";
      this.company = "";
      this.location = "";
      this.url = "";
      this.date = formattedDateYYYYMMDD(new Date());
      this.interviewed = false;
    },

    handleInterviewed() {
      this.interviewed = !this.interviewed;
    },
  },
};
</script>

<style scoped>
.form_body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.job_title {
  margin-top: 10px;
}

.manualApplication_button {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.manualApplication_double_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
</style>
