<template>
  <div>
    <div class="mx-2">
      <slot name="header">
        <div class="row">
          <div class="col-12">
            <h3 class="heading">{{ $t("profile.title") }}</h3>
          </div>
        </div>
      </slot>

      <v-tabs v-model="activeTab">
        <v-tab to="info">
          {{ $t("profile.info.title") }}
        </v-tab>
        <v-tab to="smtp" v-if="showSmtpSettings">
          {{ $t("Profile.smtp_settings") }}
        </v-tab>
        <v-tab to="pricing" v-if="showPricing">
          {{ $t("pricing-plan") }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <keep-alive>
          <router-view :model="model" v-on="$listeners" />
        </keep-alive>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import ChangePassword from "@/pages/user/Profile/ChangePassword.vue";
import PricingPlans from "@/components/subscription_plan/PricingPlans.vue";
import ProfileInfo from "@/pages/user/Profile/ProfileInfo.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import { Role } from "@/helpers/enums/eRole";

const user = constant.user;

export default {
  components: {
    ChangePassword,
    PricingPlans,
    ProfileInfo,
  },

  data() {
    return {
      activeTab: 0,
    };
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    showPricing() {
      return !this.isUserPartner && this.$store.getters.configs?.showPricingPlans;
    },
    user() {
      return this.getUser;
    },
    supportEmail() {
      return this.$store.getters.configs.supportEmail;
    },
    brand() {
      return this.$store.getters.brand;
    },
    email() {
      return this.user?.email;
    },

    showSmtpSettings() {
      if (
        (this.user?.role == Role.USER || this.user?.role == Role.MANAGED_USER) &&
        (this.user?.pricingPlan == 2 || this.user?.pricingPlan == 3)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style></style>
