<template>
  <v-card>
    <v-card-title class="headline">
      <span class="text--secondary mr-2"> {{ $t('subject') }}: </span>{{ message.subject }}
    </v-card-title>
    <v-card-text>
      <p class="px-5" v-html="message.body"></p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    message: {},
  },
};
</script>

<style>
</style>