<template>
  <ul id="app-info" class="app-info" v-show="show">
    <li class="env">
      <span>ENV:</span> <span>{{ $store.getters.env }}</span>
    </li>
    <li>
      <span>Version:</span> <span>{{ $store.getters.appVersion }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      show: process.env.VUE_APP_SHOW_VERSION === "enable" || process.env.NODE_ENV === "development",
    };
  },
};
</script>

<style>
.env {
  margin-right: 5px;
}
.app-info {
  position: fixed;
  bottom: 0;
  display: flex;
  right: 0;
  padding: 0px 5px;
  background-color: red;
  z-index: 9999;
  color: white;
  font-weight: bold;
  list-style: none;
  padding-left: 10px !important;
  border-radius: 10px;
}
</style>
