import Vue from 'vue';
import components from "../components/index";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    components.forEach(comp => {
      Vue.component(comp.name, comp);
    });
  }
};

Vue.use(GlobalComponents);

export default GlobalComponents;
