<template>
  <div>
    <app-loader v-if="loading" />
    <canvas id="stackedBarChart" ref="stackedBarChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import { chartColors } from "./Colors.js";
import { formattedDateYYYYMMDD } from "@/helpers/dateUtils";

export default {
  name: "StackedBarChart",

  props: {
    chartData: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
      validator: function (value) {
        return value.every(item => typeof item === "string");
      },
      default: ["Linkedin"],
    },
    isEmail: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      myChart: null,
      loading: true,
      options: {
        plugins: {
          legend: {
            display: false, // This line hides the legend at the top
          },
          tooltip: {
            enabled: true,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      const canvas = this.$refs.stackedBarChart;
      if (canvas) {
        this.createChart();
      } else {
        console.error("Canvas element not found!");
      }
    });
  },

  watch: {
    chartData: {
      immediate: true, // This ensures the watcher is triggered upon the component's creation
      handler() {
        if (this.myChart && Object.keys(this.chartData).length > 0) {
          this.updateChart();
        }
      },
    },

    labels: {
      immediate: true,
      deep: true,
      handler() {
        if (this.myChart) {
          this.updateChart();
        }
      },
    },
  },

  methods: {
    createChart() {
      // Create the chart
      const ctx = this.$refs.stackedBarChart.getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "bar",
        data: this.getData(),
        options: this.options,
      });
      this.loading = false;
    },

    updateChart() {
      this.myChart.data = this.getData();
      this.myChart.update();
    },

    getData() {
      const model = { labels: this.labels, datasets: [] };
      const datasetArray = [];
      if (this.isEmail) {
        const color = chartColors[2];
        const dataset = {
          label: "Outgoing Emails",
          backgroundColor: color.backgroundColor,
          borderColor: color.borderColor,
          borderWidth: 1,
          data: this.chartData.map(x => x.count),
        };
        datasetArray.push(dataset);
      } else {
        if (Object.keys(this.chartData).length > 0) {
          for (let [index, data] of this.chartData.entries()) {
            var indexCount = model.labels.indexOf(formattedDateYYYYMMDD(new Date(data.date)));
            const existDataset = datasetArray.find(x => x.label == data.platform);
            if (existDataset) {
              existDataset.data[indexCount] = data.count;
            } else {
              const color = chartColors[index % chartColors.length];
              const dataset = {
                label: data.platform,
                backgroundColor: color.backgroundColor,
                borderColor: color.borderColor,
                borderWidth: 1,
              };
              dataset.data = new Array(model.labels.length).fill(0);
              if (indexCount !== -1) {
                dataset.data[indexCount] = data.count;
              }
              datasetArray.push(dataset);
            }
          }
        }
      }
      model.datasets = datasetArray;
      console.log(model.datasets);
      return model;
    },
  },

  beforeDestroy() {
    // Cleanup chart when component is unmounted
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style>
#stackedBarChart {
  background-color: var(--white);
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 20px;
}

.chart-legend {
  display: none !important;
}
</style>
