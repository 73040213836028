<template>
  <v-chip
    v-if="appear"
    class="ma-2"
    close
    outlined
    @click:close="
      // @ts-ignore
      dismiss()
    "
    >{{ this.label }}
  </v-chip>
</template>

<script>
export default {
  name: "FilterTag",

  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      appear: true,
    };
  },

  methods: {
    dismiss() {
      // @ts-ignore
      this.$parent.dismissFilter({ name: this.label, type: this.type });
    },
  },
};
</script>
