import Vue from "vue";
import store from "@/store";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: store.getters.configs.theme,
    },
  },
  icons: {
    iconfont: "mdiSvg",
  },
});
