import i18n from "@/core/i18n";

export function formatDateToLocalMMDDYY(date) {
  return new Date(date).toLocaleDateString(i18n.locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function formattedDateYYYYMMDD(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;

  const dateStr = year + "-" + month + "-" + day;
  return dateStr.toString();
}
