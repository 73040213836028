import axios from "axios";
import i18n from "@/core/i18n";
import store from "../store/index";
import Cookies from "js-cookie";
import { USER_TOKEN_COOKIE } from "@/assets/js/constants.js";

const BaseURL = process.env.VUE_APP_B2B_BASEURL || "https://b2b-api.loopcv.com";

const eErrorStatus = {
  UnAuthorized: 401,
  Forbidden: 403,
  NotAllowed: 405
};

export default function createAxiosInstance({ auth = true, contentType = true, formData = false }) {
  const headers = {
    Accept: "application/json",
  };
  if (auth) {
    headers.Authorization = `Bearer ${Cookies.get(USER_TOKEN_COOKIE)}`;
  }
  if (contentType && !formData) {
    headers["Content-Type"] = "application/json";
  }
  if (formData) {
    headers["Content-Type"] = "application/x-www-form-urlencoded";
  }

  const encryptedPartnerId = Cookies.get("encryptedPartnerId");
  if (encryptedPartnerId) {
    headers["p"] = encryptedPartnerId;
  }

  const instance = axios.create({
    baseURL: BaseURL,
    headers: headers,
  });

  const requestInterceptor = config => {
    console.debug(config.method.toUpperCase(), config.url);
    console.debug("Payload:", config.data);
    return config;
  };

  const responseInterceptor = response => {
    console.debug("Response:", response);
    return response.data;
  };

  const errorInterceptor = error => {
    console.warn("found error in fetch", error);
    
    if (error?.message?.includes("Failed to fetch")) {
      // console.error(`failed to fetch, online: ${window.navigator?.onLine}`);
      return;
    }

    if (error.response && error.response.status === eErrorStatus.Forbidden) {
      error.response.message = i18n.t("Generic.Messages.Error.refresh")
    } else if (
      error.response &&
      error.response.status == eErrorStatus.UnAuthorized &&
      error.config.headers.hasOwnProperty("Authorization")
    ) {
      error.response.message = i18n.t("Generic.Messages.Error.unauthorized", { email: store.getters.configs?.supportEmail })
    } else if (error.response && error.response.status === eErrorStatus.NotAllowed) {
      error.response.message = i18n.t("Generic.Messages.Error.notAllowed")
    } else if (error.code === "ERR_NETWORK") {
      error.response.message = i18n.t("Generic.Messages.Error.timeout")
    } else {
      console.error(error);
    }

    return Promise.reject(error.response);
  };

  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, errorInterceptor);

  return instance;
}
