<template>
  <div class="question_body">
    <div class="question-body">
      <div class="question-block">
        <label class="header4">
          {{ `${question.index}. ${question.question}` }}
        </label>
        <div class="left-elements desktop">
          <div class="question-date">
            <DateIcon />
            <label class="header4 question-label"> {{ `${$t("Questions.last_appeared")}: ` }}</label>
            <label class="header4 question-value bold"> {{ this.getLastUpdateAtFormatted }}</label>
          </div>
          <hr class="question-border" />
          <div class="occurrences" v-if="question.occurrences">
            <HashIcon />
            <label class="header4"> {{ `${$t("Questions.appear")}:` }}</label>

            <label class="header4 bold">{{
              `${question.occurrences} ${question.occurrences == 1 ? $t("Generic.Common.time") : $t("Generic.Common.times")}`
            }}</label>
          </div>
        </div>
      </div>

      <div class="answer-block" v-if="question.question_type === 'text' || question.question_type === 'number'">
        <Input
          v-model="answer"
          :type="question.question_type"
          @input="handleAnswer"
          :width="question.question_type == 'number' ? 'fit-content' : 'initial'"
          :style="question.question_type == 'number' ? '' : 'flex: auto'"
        />
        <FillSuggestedAnswerButton
          v-if="showSuggestedAnswerButton"
          :color="suggestedAnswerIconColor"
          @click="fillWithSuggestedAnswer"
        />
      </div>
      <div class="answer-block" v-if="question.question_type == 'paragraph'">
        <TextArea v-model="answer" @input="handleAnswer" />
        <FillSuggestedAnswerButton
          v-if="showSuggestedAnswerButton"
          :color="suggestedAnswerIconColor"
          @click="fillWithSuggestedAnswer"
        />
      </div>
      <div
        :class="question.options.length == 2 ? 'answer-block-checkbox two-answer' : 'answer-block-checkbox multiple-answer'"
        v-if="question.question_type == 'multiple_choice'"
      >
        <div class="answer-checkbox" v-for="option in question.options" :key="option">
          <CheckButton
            @input="value => handleCheckBoxAnswer(option, value)"
            :checked="question.answer == option"
            :title="option"
          />
        </div>
      </div>
      <div class="answer-block" v-if="question.question_type == 'terms'">
        <SwitchToggle :value="this.booleanValue" @input="handleAnswer" :title="$t('Questions.agree')"> </SwitchToggle>
      </div>
      <div class="answer-block" v-if="question.question_type == 'date'">
        <DateTime @input="handleAnswer" :value="answer" />
      </div>

      <div class="left-elements-mobile">
        <div class="question-date">
          <DateIcon />
          <label class="header4 question-label"> {{ `${$t("Questions.last_appeared")}: ` }}</label>
          <label class="header4 question-value bold"> {{ this.getLastUpdateAtFormatted }}</label>
        </div>
        <div class="occurrences" v-if="question.occurrences">
          <HashIcon />
          <label class="header4"> {{ `${$t("Questions.appear")}:` }}</label>

          <label class="header4 bold">{{
            `${question.occurrences} ${question.occurrences == 1 ? $t("Generic.Common.time") : $t("Generic.Common.times")}`
          }}</label>
        </div>
      </div>

      <div v-if="question.jobs_details && question.jobs_details.length > 0">
        <div class="questions_footer_block" v-if="question.jobs_details.length == 1">
          <label class="header4">{{ $t("Questions.show_this_question") }}</label>
          <span v-on:click="this.openMatch">
            <a class="header4 bold">{{ question.jobs_details[0].jobTitle }}</a>
          </span>
        </div>
        <div class="questions_footer_block" v-else>
          <span style="width: 12rem">
            <v-select
              :items="getMatchesDropdownOptions"
              :label="this.$t('Questions.show_this_question')"
              v-model="selectedItem"
              @change="handleMatchesDropdown"
            ></v-select>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateToLocalMMDDYY } from "@/helpers/dateUtils";
import { TextArea, CheckBox, CheckButton, Input, SwitchToggle, DateTime, Dropdown } from "@/framework/index";
import { HashIcon, DateIcon } from "@/assets/fonts/icons";
import { constant } from "@/store/constant";
import { mapGetters } from "vuex";
import FillSuggestedAnswerButton from "@/components/user/questions/FillSuggestedAnswerButton.vue";

const user = constant.user;

export default {
  components: {
    TextArea,
    CheckBox,
    CheckButton,
    SwitchToggle,
    Input,
    DateTime,
    HashIcon,
    DateIcon,
    Dropdown,
    FillSuggestedAnswerButton,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      answer: null,
      selectedItem: null,
      suggestedAnswerIconColor: "primary",
    };
  },

  watch: {
    question(value) {
      if (value.answer == "") {
        this.answer = null;
      } else {
        this.answer = value.answer;
      }
    },
  },

  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser }),

    booleanValue() {
      if (this.answer && this.answer != "true") {
        return true;
      } else {
        return false;
      }
    },

    getLastUpdateAtFormatted() {
      return formatDateToLocalMMDDYY(this.question.updatedAt);
    },

    getMatchesDropdownOptions() {
      return this.question.jobs_details.map(x => x.jobTitle);
    },

    currentUser() {
      return this.getUser;
    },

    aiAnsweringAllowed() {
      return this.$store.getters.configs?.showSuggestedAnswerIcon;
    },

    showSuggestedAnswerButton() {
      return (
        ["text", "paragraph"].includes(this.question.question_type) &&
        this.question.suggestedAnswer &&
        this.question.suggestedAnswer !== "-" &&
        this.aiAnsweringAllowed
      );
    },
  },

  mounted() {
    this.answer = this.question.answer;
  },

  methods: {
    handleAnswer(value) {
      const new_question = this.question;
      new_question.answer = value;
      this.$emit("updateQuestion", new_question);
    },

    handleCheckBoxAnswer(option, value) {
      const new_question = this.question;
      if (value) {
        new_question.answer = option;
      } else {
        new_question.answer = "";
      }

      this.$emit("updateQuestion", new_question);
    },

    openMatch() {
      const path = "/match";
      const url = `${window.location.origin}${path}/${this.question.jobs_details[0].matchId}`;
      window.open(url, "_blank");
    },

    handleMatchesDropdown() {
      const question = this.question.jobs_details.find(x => x.jobTitle == this.selectedItem);
      const path = "/match";
      const url = `${window.location.origin}${path}/${question.matchId}`;
      window.open(url, "_blank");
    },

    fillWithSuggestedAnswer() {
      if (!this.showSuggestedAnswerButton) {
        return;
      }

      this.answer = this.question.suggestedAnswer;
      this.handleAnswer(this.question.suggestedAnswer);
      this.suggestedAnswerIconColor = "green";
    },
  },
};
</script>

<style scoped>
@import "./question.module.css";
</style>

<style>
.v-select__selections input {
  display: none;
}
</style>
