<template>
  <div>
    <h4 class="h2">
      {{ $t("loop.slides.excludedKeywords.title") }}
      <span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="black"
              v-bind="attrs"
              v-on="on"
              style="opacity: 0.5"
              >help_outline</v-icon>
          </template>
          {{ $t('loop.slides.excludedKeywords.description') }}
        </v-tooltip>
      </span>
    </h4>
    <div class="row">
      <div class="col-md-6 col-lg-6 col-sm-12">
        <div class="text-body-2">{{ $t("loop.slides.keywords.prompt") }}</div>
        <v-text-field
          type="text"
          :label="$t('loop.slides.keywords.keyword')"
          placeholder="Machine learning"
          v-model="keyword"
          v-on:keyup.enter="addKeyword"
          outlined
          class="mt-3"
        >
          <template v-slot:append>
            <div style="margin-top: -6px" v-if="isKeywordValid">
              <v-btn icon small color="primary" @click="addKeyword">
                <v-icon>check</v-icon>
              </v-btn>
            </div>
          </template>
        </v-text-field>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12">
        <div class="text-body-2">{{ $t("loop.slides.keywords.chosen") }}</div>
        <div class="col-12">
          <div v-if="keywordsSelected.length === 0" class="mt-4">
            <div class="text-body-2">
              {{ $t("loop.slides.keywords.noChosen") }}
            </div>
          </div>
          <div v-else>
            <v-chip
              v-for="(keyword, index) in keywordsSelected"
              :key="(keyword, index)"
              close
              class="ma-2"
              :ripple="false"
              @click:close="removeKeyword(index)"
            >
              {{ keyword }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validEnglishString } from "../../assets/js/utils";

export default {
  name: "loopExcludeKeywords",
  data: () => ({
    keywordsSelected: [],
    keyword: "",
  }),
  mounted() {
    if (this.$store.getters.isEditOrDuplicate) {
      this.keywordsSelected = this.$store.getters.getLoopData.excludedKeywords || []; // if [], firebase does not save it at all, so it is undefined
    }
  },
  computed: {
    isKeywordValid() {
      return validEnglishString(this.keyword) && this.keyword.trim() !== "";
    },
  },
  methods: {
    postData() {
      this.$store.commit("setLoopData", {
        excludedKeywords: this.keywordsSelected,
      });
    },
    addKeyword() {
      if (this.keyword != "" && validEnglishString(this.keyword)) {
        this.keywordsSelected.unshift(this.keyword);
        this.$amplitude.logEvent("ADD_EXCLUDED_KEYWORD", { keyword: this.keyword });
        this.keyword = "";
      } else {
        this.$notify({
          message: this.$t("errors.shouldEnglishKeyword"),
          type: "danger",
        });
      }
    },

    removeKeyword(index) {
      this.keywordsSelected.splice(index, 1);
    },
  },
};
</script>
