export function equalObjects(obj1, obj2) {
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    } else {
      return false;
    }
  }
  return true;
}

export function compareArrays(array1, array2) {
  // Check if arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Compare each element of the arrays
  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2[i];

    // Check if the objects have the same number of properties
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }

    // Check if the properties of the objects are equal
    for (let prop in obj1) {
      if (obj1[prop] !== obj2[prop]) {
        return false;
      }
    }
  }

  return true;
}
