import { state } from "./state";

export function getClients(state) {
  return state.clients;
}

export function getTotalClients(state) {
  return state.totalClients;
}
export function getLoading() {
  return state.loading;
}

export function getStatus() {
  return state.status;
}

export function getError() {
  return state.error;
}

export function getDeleteEmailUser() {
  return state.emailOfUserThatWasDeleted;
}

export function getSubscription() {
  return state.subscription;
}
