<template>
  <v-alert outlined dense v-if="showCarrusBanner" class="warning-banner w-100">
    <span class="text-body-1">
      {{ $t("carrus.get") }}
      <a href="https://carrus.io/?via=6fb2ca" target="_blank" @click="$amplitude.logEvent('CLICK_CARRUS_REFERRAL')">{{
        $t("carrus.interviewPrep")
      }}</a>
      {{ $t("carrus.withAnExpert") }}
    </span>
    <v-spacer></v-spacer>

    <template v-slot:append>
      <v-btn
        outlined
        href="https://carrus.io/?via=6fb2ca"
        @click="$amplitude.logEvent('CLICK_CARRUS_REFERRAL')"
        target="_blank"
      >
        {{ $t("carrus.start") }}
        <v-icon small class="ml-2">open_in_new</v-icon>
      </v-btn>
      <v-btn @click="hideBanner" icon x-small class="ml-2">
        <app-icon name="close" />
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      showCarrusBanner: true,
    };
  },
  watch: {
    "$route.path": {
      handler() {
        this.showCarrusBanner = this.$route.path === "/loops" && localStorage.getItem("carrusBanner") !== "hide";
      },
      immediate: true,
    },
  },
  methods: {
    hideBanner() {
      localStorage.setItem("carrusBanner", "hide");
      this.showCarrusBanner = false;
    },
  },
};
</script>

<style></style>
