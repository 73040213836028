<template>
  <div>
    <div class="pa-2" v-if="!sharedNotes">
      <div class="text-caption text-center">There are no notes yet.</div>
    </div>
    <div class="scrollable" v-else>
      <div class="pa-2">
        <div v-for="note in sharedNotes" :key="note.createdAt">
          <div class="text-caption text-left text--secondary">
            {{ getDateString(note.createdAt) }}
          </div>
          <v-card class="mb-2 pa-2" outlined style="white-space: pre-line">
            {{ note.text }}
          </v-card>
        </div>
      </div>
    </div>
    <div class="pa-2">
      <div class="text-h7"><b>Add new note</b></div>
      <v-textarea v-model="newNote" :rules="[]" label="Note"></v-textarea>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="showCancel" text @click="$emit('cancel')">Cancel</v-btn>
        <v-btn text color="primary" @click="saveNote">Save</v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sharedNotes: {},
    showCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return { newNote: null };
  },
  methods: {
    saveNote() {
      this.$emit("submitNote", this.newNote);
      this.newNote = "";
    },
    getDateString(createdAt) {
      const date = new Date(createdAt);
      return date.toDateString() + " at " + date.toLocaleTimeString();
    },
  },
};
</script>

<style>
.scrollable {
  max-height: 500px;
  overflow-y: scroll;
}
</style>