/**
 * Get the first words from a long string
 * @param {string} string
 * @param {number} wordsCount
 */
export function getFirstWords(string, wordsCount) {
  const words = string.split(" ");
  return words.slice(0, wordsCount).join(" ");
}

/**
 * Returns true if a string is valid for our backend; it must contain only English characters, numbers, spaces and not be empty
 * @param {string} string
 */
export function validEnglishString(string) {
  if (!string) return false;

  return string.match(/^[a-zA-Z0-9 ,.àèéìíîòóùúÀÈÉÌÍÎÒÓÙÚÀ-ÖØ-öø-ÿ-]{1,}$/) !== null;
  // @TODO allow , and . only in locations
  // special characters like a stressed
  // use it in real time validation with red indicator
  // TODO disallow spaces-only, trim first
}

export function validKeywordString(string) {
  if (!string) return false;

  return string.match(/^[a-zA-Z0-9 ,.àèéìíîòóùúÀÈÉÌÍÎÒÓÙÚÀ-ÖØ-öø-ÿ+*/_-]{1,}$/) !== null;
}

export function validNames(firstName, lastName) {
  return firstName?.length > 0 && lastName?.length > 0 && validEnglishString(firstName) && validEnglishString(lastName);
}

export const emailTemplatePlaceholders = [
  { id: 1, name: "JOB_URL", keywords: "job, url, path" },
  { id: 2, name: "JOB_LOCATION", keywords: "job, location, area" },
  { id: 3, name: "JOB_TITLE", keywords: "job, title, name" },
  { id: 4, name: "COMPANY_NAME", keywords: "company, name" },
  { id: 5, name: "USER_FIRSTNAME", keywords: "user, first, name" },
  { id: 6, name: "USER_LASTNAME", keywords: "user, last, name" },
];

export const placeholderNames = emailTemplatePlaceholders.map(item => item.name);

export function validEmailTemplateMustacheString(string) {
  let text = string;

  for (const placeholder of placeholderNames) {
    text = text.replace(new RegExp(`{{${placeholder}}}`, "g"), "");
    text = text.replace(new RegExp(`{{ ${placeholder} }}`, "g"), "");
  }

  for (const term of ["{", "}", ...placeholderNames]) {
    if (text.includes(term)) {
      return false;
    }
  }

  return true;
}

export function timestampToDateString(timestamp) {
  const date = new Date(timestamp);
  const n = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  return n.toDateString();
}

export function debounce(func, delay, immediate) {
  let timerId;
  return function (...args) {
    const boundFunc = func.bind(this, ...args);
    clearTimeout(timerId);
    if (immediate && !timerId) {
      boundFunc();
    }
    const calleeFunc = immediate
      ? () => {
          timerId = null;
        }
      : boundFunc;
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    timerId = setTimeout(calleeFunc, delay);
  };
}

// call this after calling the signup "user.partialCreate" endpoint, because it creates the stripe customer object
export function triggerRewardfulConversion(email) {
  if (process.env.VUE_APP_REWARDFUL === "enable") {
    try {
      rewardful("convert", { email });
      console.log("affiliate conversion");
    } catch (e) {
      console.log(e);
    }
  }
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function highlightKeywords(html, phrases) {
  // @TODO sanitize on backend
  if (!phrases?.length) return html;

  const rgx = phrases
    .map(escapeRegExp)
    .map(x => "\\b" + x.trim() + "\\b")
    .join("|");

  return html.replace(new RegExp(rgx, "gi"), '<span class="highlight">$&</span>');
}

export function pascalCase(s) {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function cvExtensionValid(fileName) {
  return [".pdf", ".doc", ".docx"].some(ext => fileName.toLowerCase().endsWith(ext));
}

export function resetFileInput(element) {
  element.value = "";

  if (!/safari/i.test(navigator.userAgent)) {
    element.type = "";
    element.type = "file";
  }
}

export function validEmail(value) {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value);
}
