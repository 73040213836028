<template>
  <div>
    <v-simple-table class="mb-5" v-if="recentMatches">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">{{ $t('jobTitle')}}</th>
            <th class="text-left">{{ $t('company')}}</th>
            <th class="text-left">{{ $t('location')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in recentMatches" :key="item.matchId">
            <td>{{ item.jobTitle }}</td>
            <td>{{ item.company }}</td>
            <td>{{ item.location }}</td>
            <td>
              <v-btn icon :to="`/match/${item.matchId}`"
                ><v-icon>open_in_new</v-icon></v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="d-flex align-center" v-else>
      There are no matches yet. The most recent jobs that we find given your
      search critera will appear here.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recentMatches: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style>
</style>