<template>
  <button class="back-button" @click="$emit('click')">
    <div class="button-inside">
      <div class="back-icon">
        <svg
          class="InlineSVG Icon Header-BusinessLink-arrow mr2 Icon--sm"
          focusable="false"
          width="12"
          height="12"
          viewBox="0 0 16 16"
        >
          <path
            d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z"
            fill-rule="evenodd"
          ></path>
        </svg>
      </div>
      <div class="texts">
        <div class="visible-element">{{ $t('plans.allPlans') }}</div>
        <div class="invisible-element">{{ $t('back') }}</div>
      </div>
    </div>
  </button>
</template>

<script>
export default {};
</script>

<style>
.visible-element {
  display: inline-block;
}
.invisible-element {
  display: none;
}
.back-button:hover .invisible-element {
  display: inline-block;
}
.back-button:hover .visible-element {
  /* display: none; */
  position: absolute;
}
.texts {
  display: inline-block;
  font-weight: 600;
}
.button-inside {
  white-space: nowrap;
  padding: 15px;
}
.back-icon {
  display: inline-block;
  padding-right: 10px;
}
.back-button:hover .back-icon {
  animation: slide 0.3s ease-in-out 0s 1 alternate forwards;
}
@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-3px);
  }
}
.back-button:hover .texts .invisible-element {
  animation: fadeIn 0.3s ease-in-out 0s 1 forwards;
}
.back-button:hover .texts .visible-element {
  animation: fadeOut 0.3s ease-in-out 0s 1 forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
