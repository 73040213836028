<template>
  <div class="row flex-b">
    <div
      v-for="pricingPlan in pricingPlansData"
      :key="pricingPlan.index"
      class="col-md-4 col-lg-4 col-xl-4 col-sm-4 col-xs-12"
    >
      <div class="pricing-box">
        <div class="name-area mb-3">
          <h3 class="name-text mb-3 text-h3">{{ pricingPlan.name }}</h3>
          <div style="text-align: center">
            <span class="price-text">{{ pricingPlan.description }}</span>
            <span class="period-text">{{ $t("plans.perMonth") }}</span>
          </div>
        </div>
        <div class="image-area">
          <img
            :src="pricingPlan.imageUrl"
            :class="{ blurFlower: activePricing == pricingPlan.index }"
            class="pricing-plan-image"
            width="100%"
            alt="Pricing Plan Illustration"
          />
        </div>
        <div style="text-align: left">
          <div class="d-flex align-center description_text_block">
            <img :src="tick" alt="tick" />
            <p class="description-text">
              {{ pricingPlan.maxLoops }}
            </p>
          </div>
          <div class="d-flex align-center description_text_block">
            <img :src="tick" alt="tick" />
            <p class="description-text">
              {{ pricingPlan.description2 }}
            </p>
          </div>
          <div class="d-flex align-center description_text_block">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.jobs }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.priority }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('remote_anywhere')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.remote_anywhere }}
            </label>
          </div>
          <div class="d-flex align-center description_text_block" v-if="pricingPlan.hasOwnProperty('smtp')">
            <img :src="tick" alt="tick" />
            <label class="description-text">
              {{ pricingPlan.smtp }}
            </label>
          </div>
        </div>
        <v-spacer></v-spacer>
        <center>
          <v-btn
            color="default"
            text
            outlined
            class="mt-4 text-button"
            :disabled="activePricing === pricingPlan.index || subscriptionIsActive || disableButtons"
            @click="$emit('selected', pricingPlan.index)"
          >
            {{ activePricing === pricingPlan.index ? $t("plans.selected") : $t("plans.select") }}
          </v-btn>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tick from "@/assets/images/tick-price.svg";
import xdBike from "@/assets/new-design-icons/Group 23572.svg";
import xdMotorbike from "@/assets/new-design-icons/Group 23573.svg";
import xdCar from "@/assets/new-design-icons/Group 23574.svg";
import { constant } from "@/store/constant";
import { toCurrency } from "@/helpers/utils/stringUtils.js";

const plans = constant.plans;
const user = constant.user;

export default {
  data() {
    return {
      tick,
      disableButtons: false,
      localActivePricing: null,
    };
  },
  created() {
    this.$bus.$on('payment-success', this.onPaymentSuccess);
  },
  beforeDestroy() {
    this.$bus.$off('payment-success', this.onPaymentSuccess);
  },
  computed: {
    ...mapGetters("plans", { getPlans: plans.getters.GetPlans }),
    ...mapGetters("user", { getUser: user.getters.getUser }),

    activePricing() {
      return this.localActivePricing !== null ? this.localActivePricing : this.getUser?.pricingPlan;
    },

    pricingPlansData() {
      if (process.env.VUE_APP_MODE === "b2c") {
        const frontendObject = [
          {
            index: 1,
            name: this.$t("plans.basic.name"),
            maxLoops: this.$t("plans.basic.maxLoops"),
            description2: this.$t("plans.basic.description2"),
            jobs: this.$t("plans.basic.job_board"),
            priority: this.$t("plans.basic.priority"),
            imageUrl: xdBike,
            icon: "looks_one",
          },
          {
            index: 2,
            name: this.$t("plans.standard.name"),
            maxLoops: this.$t("plans.standard.maxLoops"),
            description2: this.$t("plans.standard.description2"),
            jobs: this.$t("plans.standard.job_board"),
            priority: this.$t("plans.standard.priority"),
            smtp: this.$t("plans.smtp"),
            remote_anywhere: this.$t("plans.remote_anywhere"),
            imageUrl: xdMotorbike,
            icon: "looks_two",
          },
          {
            index: 3,
            name: this.$t("plans.premium.name"),
            maxLoops: this.$t("plans.premium.maxLoops"),
            description2: this.$t("plans.premium.description2"),
            jobs: this.$t("plans.premium.job_board"),
            priority: this.$t("plans.premium.priority"),
            smtp: this.$t("plans.smtp"),
            remote_anywhere: this.$t("plans.remote_anywhere"),
            imageUrl: xdCar,
            icon: "looks_3",
          },
        ];

        const model = frontendObject.map((frontendItem, index) => {
          const cost = this.getPlans.plans[index].cost;
          const fullItem = {
            ...frontendItem,
            ...this.getPlans.plans[index],
            description: `${toCurrency(cost, this.getPlans.currency)}`,
            currency: this.getPlans.currency,
          };
          return fullItem;
        });
        return model;
      } else {
        return this.$store.getters.pricingPlansData;
      }
    },

    subscriptionIsActive() {
      // TODO should be 1, 2, 3
      if (!this.activePricing) {
        return false;
      }
      const activePlansData = this.pricingPlansData.find(({ index }) => index === this.activePricing);
      if (!activePlansData) {
        return false;
      }
      return activePlansData.cost !== 0;
    },
  },
  methods: {
    onPaymentSuccess(plan) {
      this.disableButtons = true;
      this.localActivePricing = plan;
    },
  }
};
</script>

<style>
.pricing-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 15px 10px;
  max-width: 400px;
  height: 100%;
}
.flex-b {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1000px;
}
.name-area {
  min-height: 5em;
}
.image-area {
  margin: 0 auto;
  display: block;
}
.pricing-plan-image {
  margin: 0 auto;
  display: block;
  min-height: 200px;
  max-width: 160px;
}
.price-text {
  font: normal normal 600 29px/29px Manrope;
  text-align: center;
  padding-right: 5px;
}
.period-text {
  font: normal normal medium 14px/19px Manrope;
  text-align: center;
  letter-spacing: 0.14px;
  color: #989696;
}
.name-text {
  font: normal normal 600 16px/20px Manrope;
  text-align: center;
  letter-spacing: 0.16px;
  color: #35383c;
  padding-top: 5px;
}

.description_text_block {
  margin-top: 4px;
}

.description-text {
  font: normal normal medium 14px/18px Manrope;
  text-align: left;
  letter-spacing: 0.14px;
  color: #35383c;
  min-height: 50px;
  padding-left: 8px;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
}
.dot {
  width: 9px;
  height: 9px;
  background: #2f7bc3 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 9999px;
}
</style>
