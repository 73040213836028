<template>
  <div>
    <app-loader v-if="loading" />
    <div v-else>
      <div>
        <div class="header_body">
          <h5 class="text-h1 my-5 mx-3">{{ $t(`automations.stats.statisticsClients`) }}</h5>

          <div class="filters">
            <DateTime
              @input="handleFromDate"
              :label="$t('Overview.ApplicationStats.from_date')"
              width="10rem"
              :value="filter.fromDate"
              :max="getFromDateMax"
            />
            - &nbsp;
            <DateTime
              @input="handleToDate"
              :label="$t('Overview.ApplicationStats.to_date')"
              width="10rem"
              :value="filter.toDate"
              :max="new Date()"
            />
            <Dropdown
              :value="filter.dateOption"
              :options="[
                $t('Overview.ApplicationStats.Weekly'),
                $t('Overview.ApplicationStats.monthly'),
                $t('Overview.ApplicationStats.6_months'),
              ]"
              @input="handleDateOption"
            />
          </div>
        </div>
        <app-loader v-if="statisticsLoading"></app-loader>
        <div v-else>
          <div class="row">
            <div class="col-sm-4 col-xs-12">
              <StatsItemCard :value="stats.totalClients" icon="xdLoop" :label="$t('automations.stats.totalClients')" />
            </div>
            <div class="col-sm-4 col-xs-12">
              <StatsItemCard
                :value="stats.totalLoops"
                icon="xdOpenedEmail"
                :label="$t('automations.stats.activeLoopsByAllClients')"
              />
            </div>
            <div class="col-sm-4 col-xs-12">
              <StatsItemCard :value="stats.totalJobs" icon="xdCV" :label="$t('automations.stats.totalJobs')" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 col-xs-12">
              <StatsItemCard :value="stats.totalMatches" icon="xdConnections" :label="$t('automations.stats.totalMatches')" />
            </div>
            <div class="col-sm-4 col-xs-12">
              <StatsItemCard
                :value="stats.totalMatchesWithEmails"
                icon="xdClosedEmail"
                :label="$t('automations.stats.emailsSent')"
              />
            </div>
            <div class="col-sm-4 col-xs-12" v-if="!onlyEmail">
              <StatsItemCard
                :value="stats.totalMatchesWithApplications"
                icon="xdApplication"
                :label="$t('automations.stats.applicationsSubmitted')"
              />
            </div>
            <!-- <div class="col-sm-3 col-xs-12">
            <StatsItemCard
              :value="stats.totalMatchesAppliedManually"
              icon="xdApplication"
              :label="$t('automations.stats.applyManuallyClicks')"
            />
          </div> -->
          </div>
        </div>
      </div>

      <ApplicationStats v-if="isAdmin && showDiagram" :fromDate="filter.fromDate" :toDate="filter.toDate" />

      <div v-if="stats.totalLoops">
        <h5 class="text-h1 my-5 mx-3">Matches</h5>
        <div class="row">
          <v-card flat class="col-12">
            <div class="d-flex pa-2">
              <div>
                <v-avatar color="#F8F8FA">
                  <v-icon>info</v-icon>
                </v-avatar>
                <span class="text-h4 px-2">{{ $t("automations.stats.recentMatches") }}</span>
              </div>
              <v-spacer></v-spacer>
              <v-btn text :to="`/clients`">{{ $t("automations.stats.viewAllClients") }}</v-btn>
            </div>

            <RecentMatchesTable :recentMatches="stats.recentMatches" />
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsItemCard from "@/components/overview/StatsItemCard.vue";
import MatchesPerLoopPieChart from "@/components/overview/MatchesPerLoopPieChart.vue";
import RecentMatchesTable from "@/components/overview/RecentMatchesTable.vue";
import ApplicationStats from "@/components/overview/ApplicationStats.vue";
import appSDK from "@/client/AppSDK";
import { Role } from "@/helpers/enums/eRole";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";
import { Dropdown, DateTime } from "@/framework";
import { formattedDateYYYYMMDD } from "@/helpers/dateUtils";

export default {
  components: {
    StatsItemCard,
    MatchesPerLoopPieChart,
    RecentMatchesTable,
    ApplicationStats,
    Dropdown,
    DateTime,
  },

  data() {
    return {
      loading: true,
      statisticsLoading: true,
      filter: {
        fromDate: "",
        toDate: "",
        dateOption: "",
      },
      stats: {},
    };
  },

  computed: {
    ...mapGetters("user", {
      getUser: constant.user.getters.getUser,
    }),

    isAdmin() {
      return this.getUser?.role == Role.ADMIN || this.getUser?.role == Role.SupportAgent;
    },

    showDiagram() {
      return this.$store.getters.configs.showJobApplicationsGraph || this.$store.getters.configs.showOutgoingEmailsGraph;
    },

    getFromDateMax() {
      const toDate = new Date(this.filter.toDate);
      toDate.setDate(toDate.getDate() - 1);
      return toDate;
    },

    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
  },

  created() {
    const now = new Date();
    this.filter.toDate = formattedDateYYYYMMDD(now);
    now.setDate(now.getDate() - 7);
    this.filter.fromDate = formattedDateYYYYMMDD(now);

    this.filter.dateOption = this.$t("Overview.ApplicationStats.Weekly");
    appSDK["partner.overviewStats"](this.filter.fromDate, this.filter.toDate)
      .then(stats => {
        this.stats = stats;
      })
      .catch(console.error)
      .finally(() => {
        this.loading = false;
        this.statisticsLoading = false;
      });
  },

  methods: {
    async handleFromDate(value) {
      this.filter.fromDate = value;
      this.filter.dateOption = this.$t("Overview.ApplicationStats.custom_date");
      const toDate = new Date(this.filter.toDate);
      const fromDate = new Date(value);
      if (fromDate > toDate) {
        fromDate.setDate(fromDate.getDate() - 1);
        this.filter.toDate = formattedDateYYYYMMDD(fromDate);
      }
      this.getStatistics();
    },

    async handleToDate(value) {
      this.filter.toDate = value;
      this.filter.dateOption = this.$t("Overview.ApplicationStats.custom_date");
      const toDate = new Date(value);
      const fromDate = new Date(this.filter.fromDate);
      if (fromDate > toDate) {
        toDate.setDate(toDate.getDate() - 1);
        this.filter.fromDate = formattedDateYYYYMMDD(toDate);
      }
      this.getStatistics();
    },

    async handleDateOption(value) {
      const now = new Date();
      switch (value) {
        case "Last week":
          this.filter.dateOption = this.$t("Overview.ApplicationStats.Weekly");
          this.filter.toDate = formattedDateYYYYMMDD(now);
          now.setDate(now.getDate() - 7);
          this.filter.fromDate = formattedDateYYYYMMDD(now);
          break;
        case "Last month":
          this.filter.dateOption = this.$t("Overview.ApplicationStats.monthly");
          this.filter.toDate = formattedDateYYYYMMDD(now);
          now.setMonth(now.getMonth() - 1);
          this.filter.fromDate = formattedDateYYYYMMDD(now);
          break;
        case "Last 6 months":
          this.filter.dateOption = this.$t("Overview.ApplicationStats.6_months");
          this.filter.toDate = formattedDateYYYYMMDD(now);
          now.setMonth(now.getMonth() - 6);
          this.filter.fromDate = formattedDateYYYYMMDD(now);
          break;
      }
      this.getStatistics();
    },

    getStatistics() {
      this.statisticsLoading = true;
      appSDK["partner.overviewStats"](this.filter.fromDate, this.filter.toDate)
        .then(stats => {
          this.stats = stats;
        })
        .catch(console.error)
        .finally(() => {
          this.statisticsLoading = false;
        });
    },
  },
};
</script>

<style>
.filters {
  padding-inline: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}

.header_body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
