import Clients from "@/pages/partners/clients.vue";
import Managers from "@/pages/partners/managers.vue";
import Loops from "@/pages/partners/loops.vue";
import LoopAction from "@/pages/loops/action.vue";
import LoopView from "@/pages/loops/view.vue";
import LoopInfo from "@/pages/loops/info.vue";
import LoopStats from "@/pages/loops/stats.vue";
import LoopResults from "@/pages/loops/results.vue";
import LoopNotes from "@/pages/partners/LoopNotes.vue";
import UserMatches from "@/pages/UserMatches.vue";
import Billing from "@/pages/partners/billing.vue";
import { Role } from "@/helpers/enums/eRole";

export default [
  {
    path: "/clients",
    name: "partner-clients",
    component: Clients,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/managers",
    name: "partner-managers",
    component: Managers,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/matches",
    name: "partner-matches",
    component: UserMatches,
    props: true,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/loops",
    name: "partner-client-loops",
    component: Loops,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/loops/create",
    name: "partner-loop-create",
    props: route => ({ action: "create", ...route.params }),
    component: LoopAction,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/loops/:loopId/edit",
    name: "partner-loop-edit",
    props: route => ({ action: "edit", ...route.params }),
    component: LoopAction,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/clients/:clientId/loops/:loopId/duplicate",
    name: "partner-loop-duplicate",
    props: route => ({ action: "duplicate", ...route.params }),
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
    component: LoopAction,
  },
  {
    path: "/clients/:clientId/loops/:loopId",
    redirect: { name: "partner-loop-matches" },
    props: true,
    name: "partner-loop-view",
    component: LoopView,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
    children: [
      {
        path: "info",
        name: "partner-loop-info",
        component: LoopInfo,
        meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
      {
        path: "notes",
        name: "partner-loop-notes",
        component: LoopNotes,
        meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
      {
        path: "stats",
        name: "partner-loop-stats",
        component: LoopStats,
        meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
      {
        path: "matches",
        name: "partner-loop-matches",
        component: LoopResults,
        meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
      },
    ],
  },
  {
    path: "/partner-billing",
    name: "partner-billing",
    component: Billing,
    meta: { authorized: [Role.MANAGER, Role.ADMIN, Role.SupportAgent] },
  },
];
