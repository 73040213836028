<template>
  <div v-if="role === 'admin' || role === 'support-agent'" class="w-100">
    <Billing></Billing>
  </div>
</template>

<script>
import Billing from "@/components/settings/payments/Billing.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Billing,
  },
  data: () => ({}),
  computed: {
    ...mapGetters("user", { getUser: "getUser" }),

    user() {
      return this.getUser;
    },

    role() {
      return this.user?.role || "";
    },
  },
};
</script>
<style></style>
