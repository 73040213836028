<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-badge dot offset-y="7" :color="color">
        <pre v-bind="attrs" v-on="on">{{ " " }}</pre>
      </v-badge>
      <!-- <v-chip outlined x-small v-bind="attrs" v-on="on">
              Easy Apply
            </v-chip> -->
    </template>
    <span>This job from {{ text }} supports applying using our extension</span>
  </v-tooltip>
</template>

<script>
const platforms = {
  ReedCoUk: {
    displayText: "Reed",
    dotColor: "#ff00cd",
  },
  LinkedIn: {
    displayText: "LinkedIn",
    dotColor: "blue",
  },
  Indeed: {
    displayText: "Indeed",
    dotColor: "#00ffff",
  },
  Dice: {
    displayText: "Dice",
    dotColor: "#be3432",
  },
  default: {
    displayText: "-",
    dotColor: "black",
  },
};
export default {
  props: {
    platform: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    color() {
      return platforms[this.platform]?.dotColor || platforms.default.dotColor;
    },
    text() {
      return platforms[this.platform]?.displayText || platforms.default.displayText;
    },
  },
};
</script>

<style></style>
