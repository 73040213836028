<template>
  <v-row>
    <app-header></app-header>
    <div class="col-12">
      <v-row align="center">
        <v-col>
          <h2 class="heading">
            <span>{{ $t("partner.managers") }}</span>
          </h2>
        </v-col>

        <v-col cols="auto">
          <v-switch
            v-model="showDeactivated"
            :label="`Show Deactivated Managers`"
            @change="fetchManagersData"
          ></v-switch>
        </v-col>
        <v-col cols="auto">
          <CreateManager @createManager="handleManagerCreation" />
        </v-col>
      </v-row>

      <ManagersTable ref="managersTable" :showDeactivated="showDeactivated" />
    </div>
  </v-row>
</template>

<script>
import appSDK from "@/client/AppSDK";

import ManagersTable from "@/components/partners/ManagersTable.vue";
import CreateManager from "@/components/partners/CreateManager.vue";
export default {
  components: { ManagersTable, CreateManager },
  data() {
    return {
      loading: false,
      showDeactivated: false,
    };
  },
  methods: {
    fetchManagersData() {
      return this.$refs.managersTable.getManagers(this.showDeactivated);
    },
    async handleManagerCreation(managerData) {
      this.loading = true;
      try {
        await appSDK["partner.childUser.create"]({
          ...managerData,
          role: "manager",
        });
        this.fetchManagersData();
      } catch (error) {
        if (error?.responseContent?.error === "email-already-exists") {
          this.$notify({
            message: `This email address already exists. Use another email address for the new account.`,
            type: "danger",
          });
          return;
        }

        if (error?.responseContent?.error === "maximum-managed-users-reached") {
          this.$notify({
            message: `You have reached your limit of maximum users. Please contact support if you want to buy more licences.`,
            type: "danger",
          });
          return;
        }

        console.error(error);

        this.$notify({
          message:
            "Sorry! An error occured. Our team was notified; please try again later.",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.center-vertically {
  height: 56px;
  line-height: 56px;
}
</style>