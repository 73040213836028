<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-title class="headline">
        {{ $t("plans.addPromoCode") }}
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('plans.promoCode')" v-model="currentPromoCode" outlined></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ma-2" @click="this.close">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn class="ma-2" @click="this.submitPromoDialog">
          {{ $t("submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const plans = constant.plans;

export default {
  data() {
    return {
      dialog: false,
      currentPromoCode: null,
    };
  },

  components: {
    ...mapGetters("plans", {
      getPlans: plans.getters.GetPlans,
    }),
  },

  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    async submitPromoDialog() {
      this.promoError = null;
      this.showPromoDialog = false;
      try {
        await this.$store.dispatch(plans.actions.AddPromoCode, { promoCode: this.currentPromoCode });
        this.$amplitude.logEvent("SUBMIT_PROMO_CODE_DIALOG", {
          result: "success",
          promoCode: this.currentPromoCode,
        });
      } catch (e) {
        console.error(e);
        this.promoError = this.$t("plans.invalidPromoCode");
        this.$amplitude.logEvent("SUBMIT_PROMO_CODE_DIALOG", {
          result: "failure",
          promoCode: this.currentPromoCode,
        });
      } finally {
        this.close();
      }
    },
  },
};
</script>
