export const countriesAndVats = [
  ['Andorra', 'ad_nrt', 'Andorran NRT number', 'A-123456-Z'],
  ['Australia', 'au_abn', 'Australian Business Number (AU ABN)', '12345678912'],
  ['Australia', 'au_arn', 'Australian Taxation Office Reference Number', '123456789123'],
  ['Austria', 'eu_vat', 'European VAT number', 'ATU12345678'],
  ['Belgium', 'eu_vat', 'European VAT number', 'BE0123456789'],
  ['Canada', 'ca_bn', 'Canadian BN', '123456789'],
  ['Canada', 'ca_gst_hst', 'Canadian GST/HST number', '123456789RT0002'],
  ['Canada', 'ca_pst_bc', 'Canadian PST number (British Columbia)', 'PST-1234-5678'],
  ['Canada', 'ca_pst_mb', 'Canadian PST number (Manitoba)', '123456-7'],
  ['Canada', 'ca_pst_sk', 'Canadian PST number (Saskatchewan)', '1234567'],
  ['Canada',  'ca_qst', 'Canadian QST number (Québec)', '1234567890TQ1234'],
  ['Cyprus','eu_vat', 'European VAT number', 'CY12345678Z'],
  ['EU', 'eu_oss_vat', 'European One Stop Shop VAT number for non-Union scheme', 'EU123456789'],
  ['France', 'eu_vat', 'European VAT number', 'FRAB123456789'],
  ['Germany', 'eu_vat', 'European VAT number', 'DE123456789'],
  ['Greece', 'eu_vat', 'European VAT number', 'EL123456789'],
  ['India', 'in_gst', 'Indian GST number', '12ABCDE3456FGZH'],
  ['Ireland', 'eu_vat', 'European VAT number','IE1234567AB'],
  ['Israel',  'il_vat', 'Israel VAT', '000012345'],
  ['Italy', 'eu_vat', 'European VAT number', 'IT12345678912'],
  ['Netherlands', 'eu_vat', 'European VAT number', 'NL123456789B12'],
  ['Portugal', 'eu_vat', 'European VAT number', 'PT123456789'],
  ['Saudi Arabia', 'sa_vat', 'Saudi Arabia VAT', '123456789012345'],
  ['Spain','es_cif', 'Spanish NIF number (previously Spanish CIF number)', 'A12345678'],
  ['Spain', 'eu_vat', 'European VAT number',  'ESA1234567Z'],
  ['Sweden','eu_vat', 'European VAT number',  'SE123456789123'],
  ['Switzerland', 'ch_vat', 'Switzerland VAT number', 'CHE-123.456.789 MWST'],
  ['United Arab Emirates', 'ae_trn', 'United Arab Emirates TRN', '123456789012345'],
  ['United Kingdom', 'eu_vat', 'Northern Ireland VAT number', 'XI123456789'],
  ['United Kingdom', 'gb_vat', 'United Kingdom VAT number', 'GB123456789'],
  ['United States', 'us_ein', 'United States EIN', '12-3456789']
]
